//Action Types
export const FETCH_COMPULSORY_COMPONENTS_TYPE = 'FETCH_COMPULSORY_COMPONENTS_TYPE';
export const PUSH_COMPULSORY_COMPONENTS_TYPE = 'PUSH_COMPULSORY_COMPONENTS_TYPE';
export const UPDATE_COMPULSORY_COMPONENTS_TYPE = 'UPDATE_COMPULSORY_COMPONENTS_TYPE';
export const REMOVE_COMPULSORY_COMPONENTS_TYPE = 'REMOVE_COMPULSORY_COMPONENTS_TYPE';

export const FETCH_COMPONENTS_TYPE = 'FETCH_COMPONENTS_TYPE';
export const PUSH_COMPONENTS_TYPE = 'PUSH_COMPONENTS_TYPE';
export const UPDATE_COMPONENTS_TYPE = 'UPDATE_COMPONENTS_TYPE';
export const REMOVE_COMPONENTS_TYPE = 'REMOVE_COMPONENTS_TYPE';


//Action Creator
export const fetchCompulsoryComponentsType = (data) => ({
    type: FETCH_COMPULSORY_COMPONENTS_TYPE,
    data: data
});

export const pushCompulsoryComponentsType = (data) => ({
    type: PUSH_COMPULSORY_COMPONENTS_TYPE,
    data: data
});

export const updateCompulsoryComponentsType = (data) => ({
    type: UPDATE_COMPULSORY_COMPONENTS_TYPE,
    data: data
});

export const removeCompulsoryComponentsType = (data) => ({
    type: REMOVE_COMPULSORY_COMPONENTS_TYPE,
    data: data
});

export const fetchComponentsType = (data) => ({
    type: FETCH_COMPONENTS_TYPE,
    data: data
});

export const pushComponentsType = (data) => ({
    type: PUSH_COMPONENTS_TYPE,
    data: data
});

export const updateComponentsType = (data) => ({
    type: UPDATE_COMPONENTS_TYPE,
    data: data
});

export const removeComponentsType = (data) => ({
    type: REMOVE_COMPONENTS_TYPE,
    data: data
});