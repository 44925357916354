import {
    FETCH_MARGIN_PROFILES,
    PUSH_MARGIN_PROFILES,
    UPDATE_MARGIN_PROFILES,
    REMOVE_MARGIN_PROFILES,
} from '../actions/marginProfilesActions';

const INITIAL_STATE = {
    data: [],
};

export default function marginProfilesReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_MARGIN_PROFILES:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_MARGIN_PROFILES:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_MARGIN_PROFILES:
            return {
                ...state,
                data: state.data.map((v)=>{
                    if(v._id === action.data._id) {
                        return action.data;
                    } else return v;
                })
            };
        case REMOVE_MARGIN_PROFILES:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        default:
            return state;

    }
}