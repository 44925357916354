import React from 'react';
import { withRouter } from 'react-router';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/common/plus.svg';
import NonDraggableCard from './NonDraggableCard';

import {
    Row,
    Button,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

const MarginSection = (props) => {
    const {
        disabledMarginProfile,
        values,
        marginsData,
        marginProfilesData,
        role,
        setFieldValue,
        onAddMargin,
    } = props;
    return (
        <div className='rounded bg-grey px-3 py-2 mb-3'>
            <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                <h4 className='m-0'>Profit Margin</h4>
                <Button
                    color='primary'
                    className='shadow-none rounded-circle p-1 m-0 d-flex'
                    style={{ height: 25, width: 25 }}
                    disabled={values.marginName && disabledMarginProfile}
                    onClick={onAddMargin}
                >
                    <PlusIcon
                        height={15}
                        width={15}
                    />
                </Button>
            </Row>

            {role === 'admin' && values.marginName && (
                <NonDraggableCard
                    title={'Margin'}
                    name={marginsData.find((x) => x._id === values.marginName)?.marginName}
                    description={marginsData.find((x) => x._id === values.marginName)?.description}
                    storedLocation={'marginName'}
                    setFieldValue={setFieldValue}
                    profileName={'Default'}
                />
            )}

            {role === 'superadmin' && values.marginSuperName && (
                <NonDraggableCard
                    title={'Margin'}
                    name={marginsData.find((x) => x._id === values.marginSuperName)?.marginName}
                    description={marginsData.find((x) => x._id === values.marginSuperName)?.description}
                    storedLocation={'marginSuperName'}
                    setFieldValue={setFieldValue}
                />
            )}

            {values.marginProfiles?.length > 0 && (
                values.marginProfiles?.map((x, i) => {
                    if (x) {
                        return (
                            <NonDraggableCard
                                key={x._id}
                                title={'Margin'}
                                name={marginsData.find((y) => y._id === x.marginId)?.marginName}
                                description={marginsData.find((x) => x._id === values.marginName)?.description}
                                storedLocation={`marginProfiles[${i}]`}
                                setFieldValue={setFieldValue}
                                profileName={marginProfilesData?.find((y) => x.profileId === y._id)?.profileName}
                            />
                        );
                    } else {
                        return null;
                    }
                })
            )}
        </div>
    );
};

export default withRouter(MarginSection);
