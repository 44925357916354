/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchGroups, pushGroups, updateGroups, removeGroups } from '../redux/actions/groupsActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, sortBy } from 'lodash';
// nodejs library that concatenates classes
import classnames from 'classnames';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Modal,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import { useParams } from 'react-router-dom';

import client from '../feathers.js';

const validationSchema = Yup.object().shape({
    groupName: Yup.string()
        .required('Group Name is required'),
});

const GroupsCreate = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const { id } = useParams();
    const [ groupFocused, setGroupFocused ] = useState(false);
    const [ productsData, setProductsData ] = useState([]);
    const [ displayProductsData, setDisplayProductsData ] = useState([]);
    const [ usersData, setUsersData ] = useState([]);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ tableSorting, setTableSorting ] = useState([]);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,
    
        actions: modalActions,
    } = useModal();
    
    useEffect(() => {
    
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').get(id);
            })
            .then((res)=>{
                setProductsData(res.productIds);
                setDisplayProductsData(res.productIds);
                setUsersData(res.userIds);
                setDataEdit(res);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
        // eslint-disable-next-line
  }, [id])

    const onSubmit = async(value) => {
    
        value.userIds = usersData.map(x => x._id);
        value.productIds = productsData.map(x => {
            return {
                productId: x._id,
                marginId: !isEmpty(x.marginSuperName)?x.marginSuperName._id: ''
            };
        });
    
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').patch(dataEdit._id, value);
            })
            .then((res) => {
                notificationOpen(true, 'success', 'Group ' + res.groupName + ' Added Successfully!');
                props.history.push(`/${props.role}/groups-management`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const getProducts = (data) => {
        let result = [];
        data.map(x => {
            const found = productsData.some(el => el._id === x._id);
            if (!found) result.push(x);
            return x;
        });
        setProductsData(productsData.concat(result));
    };

    const getUsers = (data) => {
        if(!isEmpty(data)){
            let findIfExist = usersData.findIndex(x => x._id === data._id);
            if(findIfExist === -1){
                setUsersData(usersData.concat(data));
            }
        }
    };

    const sortList = (item, value) => {
        if(value === -1){
            // if click sort
            let result = tableSorting.filter(e => e !== item);
            result.unshift(item);
            setDisplayProductsData(sortBy(displayProductsData, result));
            setTableSorting(result);
        }else{
            // if click remove sort
            let result = tableSorting.filter(e => e !== item);
            // result.push(item)
            setDisplayProductsData(sortBy(displayProductsData, result));
            setTableSorting(result);
        }
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        groupName: dataEdit.groupName,
                        description: dataEdit.description,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body pt-0'>

                                    <Breadcrumb
                                        items={[
                                            {title: 'Groups', link: '/superadmin/groups-management'},
                                            {title: 'Edit group'}
                                        ]}
                                    />

                                    <Card className='shadow mb-2'>
                                        <CardHeader className='py-3'>
                                            <h2 className='m-0'>Group setting</h2>
                                        </CardHeader>
                                        <CardBody className='bg-product rounded'>
                                            <div>
                                                <Row>
                                                    <Col md='6'>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='groupName'
                                                        >
                                                            Name of Group
                                                        </label>
                                                        <FormGroup className={classnames({
                                                            focused: groupFocused
                                                        }, {
                                                            'has-danger': errors.groupName && touched.groupName
                                                        })}>
                                                            <InputGroup className='input-group-alternative mb-3'>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <i className='ni ni-hat-3' />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input 
                                                                    placeholder='Group Name'
                                                                    type='text'
                                                                    id='groupName'
                                                                    value={values.groupName}
                                                                    onChange={handleChange}
                                                                    onFocus={e =>
                                                                        setGroupFocused(true)
                                                                    }
                                                                    onBlur={e => {
                                                                        setGroupFocused(false);
                                                                        handleBlur(e);
                                                                    }} 
                                                                />
                                                            </InputGroup>
                                                            {errors.groupName && touched.groupName && (
                                                                <div className='input-feedback'>{errors.groupName}</div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='6'>
                                                        <FormGroup>
                                                            <div className='mb-2'>
                                                                <small className='font-weight-bold'>
                                                                    Description
                                                                </small>
                                                            </div>
                                                            <Input
                                                                className='form-control-alternative'
                                                                placeholder='Description'
                                                                id='description'
                                                                value={values.description}
                                                                onChange={handleChange}
                                                                autoComplete='off'
                                                                type='textarea'
                                                                rows='1'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card> 

                                    {/* <div className="mb-4">
                                    <ProductTable 
                                    getProducts={getProducts} 
                                    notificationOpen={notificationOpen}
                                    />
                                </div> */}

                                </div>

                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={() => {
                                            props.history.push(`/${props.role}/groups-management`);
                                        }}
                                    >
                                        Discard
                                    </Button>
                                    <Button
                                        color='primary' 
                                        type='submit'
                                    >
                                        Save as Active
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
});
  
const mapDispatchToProps = {
    fetchGroups: fetchGroups,
    pushGroups: pushGroups,
    updateGroups: updateGroups,
    removeGroups: removeGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupsCreate);