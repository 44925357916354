//Action Types
export const FETCH_LICENSES = 'FETCH_LICENSES';
export const PUSH_LICENSES = 'PUSH_LICENSES';
export const UPDATE_LICENSES = 'UPDATE_LICENSES';
export const REMOVE_LICENSES = 'REMOVE_LICENSES';

//Action Creator
export const fetchLicenses = (data) => ({
    type: FETCH_LICENSES,
    data: data
});

export const pushLicenses = (data) => ({
    type: PUSH_LICENSES,
    data: data
});

export const updateLicenses = (data) => ({
    type: UPDATE_LICENSES,
    data: data
});

export const removeLicenses = (data) => ({
    type: REMOVE_LICENSES,
    data: data
});