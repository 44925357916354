/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

// reactstrap components
import {
    Modal,
    Row,
    Col,
    Input
} from 'reactstrap';

import client from '../../feathers.js';
import axios from 'axios';

var typingTimer = 0;

const ModalPreview = (props) => {
    const [ dataEdit, setDataEdit ] = useState({});
    const [ data, setData ] = useState([]);
    const [ additionalData, setAdditionalData ] = useState([]);
    const [ additionalInfo, setAdditionalInfo ] = useState([]);
    const [ productData, setProductData ] = useState('');
    const [ additionalDetails, setAdditionalDetails ] = useState({});
    const [ price, setPrice ] = useState(0);
    const [ additionalPrice, setAdditionalPrice ] = useState([]);
    const [ isOther, setIsOther ] = useState(false);
    const [ isRound, setIsRound ] = useState(false);
    const [ widthMessage, setWidthMessage ] = useState('');
    const [ heightMessage, setHeightMessage ] = useState('');

    useEffect(() => {
        if(props.modalVisible){
            if(!isEmpty(props.dataEdit)){
                if(props.dataEdit._id !== dataEdit._id){
                    setDataEdit(props.dataEdit);

                    axios({
                        method: 'post',
                        url: `${client.io.io.uri}fetchProductSpecs`,
                        data: {userId: props.userId, productName: props.dataEdit.name},
                        config: { headers: {'Content-Type': 'application/json' }}
                    })
                        .then((res) => {
                            console.log (res);
                            setIsOther('');
                            setWidthMessage('');
                            setHeightMessage('');
                            setData(res.data.data);
                            setAdditionalData(res.data.additionalData);
                            setAdditionalInfo(res.data.additionalInfo);
                            setProductData(res.data.productData);
                            setPrice(res.data.price);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        }
    }, [props.modalVisible, props.dataEdit, props.userId, props.dataEdit._id, dataEdit._id]);

    const handleChange = (index, title, value) => {
        if(value){
            if(!dataEdit.source.includes('csv')){
                if(title.includes('Size')){
                    if(value.includes('Others')){ 
                        setIsOther(true);
                    }else{
                        setIsOther(false);
                    }
                }
                if(title.includes('Category')){
                    if(value.includes('Round')){ 
                        setIsRound(true);
                    }else{
                        setIsRound(false);
                    }
                }
            }

            if(title.toLowerCase().includes('quantity') && dataEdit.source.includes('csv') && dataEdit?.customQuantity?.enabled){
                value = '1';
            }
            let values = [];
            data.map((x, i) => {
                let value = '';
                let selector = document.getElementById(x.title);
                if(selector){
                    value = selector[selector.selectedIndex]?selector[selector.selectedIndex].value:'';
                }
                if(selector){
                    if(((i) > index)){
                        selector.selectedIndex = 0;
                    }
                }
                values.push({
                    name: x.title,
                    value,
                });
                return x;
            });
  
            let widthVal = 0;
            if(document.getElementById('width')){
                widthVal = document.getElementById('width').value;
            }
            let heightVal = 0;
            if(document.getElementById('height')){
                heightVal = document.getElementById('height').value;
            }
            let quantityVal = 0;
            if(document.getElementById('quantity')){
                quantityVal = document.getElementById('quantity').value;
            }
            let dyamicQuantityVal = 0;
            if(productData.dynamicPriceName && document.getElementById('quantity')){
                dyamicQuantityVal = Number(document.getElementById('quantity').value);
                quantityVal = 1;
            }
  
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    productData: {
                        ...productData,
                        dynamicPriceQuantity: dyamicQuantityVal,
                    },
                    userId: props.userId,
                    index, 
                    title, 
                    value,  
                    values,
                    data,
                    width: widthVal,
                    height: heightVal,
                    quantity: Number(quantityVal)
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setAdditionalDetails(res.data.additionalDetails);
                    setData(res.data.data);
                    setPrice(res.data.price);
                    if(productData.source.includes('csv') || productData.source.includes('api')){
                        res.data.data.map(x => {
                            if(x.data.length === 1){
                                let selector = document.getElementById(x.title);
                                if(selector){
                                    if(x.title_1 === 'Size' && x.data[0] === 'Others'){
                                        setIsOther(true);
                                    }
                                    selector.selectedIndex = 1;
                                }
                            }
                            return x;
                        });
                    }
                    setAdditionalInfo(res.data.additionalInfo);
                    setAdditionalData(res.data.additionalData);
                    handleAdditionalPrice();
                    validateWidthAndHeight();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleAdditionalPrice = () => {
        let prices = [];
        additionalData.map(x => {
            let price = 0;
            let selector = document.getElementById(x.title);
            if(selector){
                if(selector[selector.selectedIndex].value.split('|')[1]){
                    price = Number(selector[selector.selectedIndex].value.split('|')[1]);
                }
                prices.push(price);
            }
            return x;
        });
    
        setAdditionalPrice(prices);
    };
  
    const callChangeProductSpecs = (title, e) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
      
            if(title === 'width' || title === 'height' || title === 'quantity'){
                // call ChangeProductSpecs here
                handleChange(10, title, e.target.value);
            }else{
                handleChange(0, title, e.target.value);
            }

        }, 500);
    };

    function validateWidthAndHeight(){
        var findWidth = document.getElementById('width');
        var findHeight = document.getElementById('height');
    
        if(findWidth){
            if(Number(findWidth.value) < Number(findWidth.min)){
                setWidthMessage(`Value cannot be less than ${findWidth.min}!`);
            }else if(Number(findWidth.value) > Number(findWidth.max)){
                setWidthMessage(`Value cannot be more than ${findWidth.max}!`);
            }else{
                setWidthMessage('');									
            }
        }

        if(findHeight){
            if(Number(findHeight.value) < Number(findHeight.min)){
                setHeightMessage(`Value cannot be less than ${findHeight.min}!`);
            }else if(Number(findHeight.value) > Number(findHeight.max)){
                setHeightMessage(`Value cannot be more than ${findHeight.max}!`);
            }else{
                setHeightMessage('');										
            }
        }
    }

    const renderWidthHeightComponents = (x) => {
        if((dataEdit.source.includes('sticker') || (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)) && x.title.includes('Cutting Shape')){
            return (
                <Row>
                    <Col>
                        <div>
            Width(mm)
                            <Input 
                                bsSize="sm" 
                                type="number" 
                                id="width"
                                min={productData.widthRange?productData.widthRange.min:0} 
                                max={productData.widthRange?productData.widthRange.max:0} 
                                onChange={(e) => callChangeProductSpecs('width', e)} 
                                onWheel={ event => event.currentTarget.blur() }
                            />
                            <span>{widthMessage || ''}</span>
                        </div>
                    </Col>
                    <Col>
                        <div>
            Height(mm)
                            <Input 
                                bsSize="sm" 
                                type="number" 
                                id="height" 
                                min={productData.heightRange?productData.heightRange.min:0} 
                                max={productData.heightRange?productData.heightRange.max:0} 
                                onChange={(e) => callChangeProductSpecs('height', e)} 
                                onWheel={ event => event.currentTarget.blur() }
                            />
                            <span>{heightMessage || ''}</span>
                        </div>
                    </Col>
                </Row>
            );
        }else if((dataEdit.source.includes('poster') || (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)) && x.title.includes('Size')){
            if(isOther){
                let getMaterial = document.getElementById('Material');
                let widthRange = productData?.items?.find(e => e.name === getMaterial?.value)?.width || {min:10, max:300};
                let heightRange = productData?.items?.find(e => e.name === getMaterial?.value)?.height || {min:10, max:420};
        
                // let widthMin = 300;
                // let widthMax = 1200;
                // let heightMin = 300;
                // let heightMax = 3000;

                // if(productData.DatacalcSqFtIn === 'ft'){
                // //   widthMin = 1;
                // //   widthMax = 4;
                // //   heightMin = 1;
                // //   heightMax = 10;
                // }else if(productData.calcSqFtIn === 'inch'){
                // //   widthMin = 12;
                // //   widthMax = 47;
                // //   heightMin = 12;
                // //   heightMax = 120;
                // }

                return (
                    <Row>
                        <Col>
                            <div>
              Width({productData.calcSqFtIn})
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="width"
                                    min={widthRange.min} 
                                    max={widthRange.max} 
                                    onChange={(e) => callChangeProductSpecs('width', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{widthMessage || ''}</span>
                            </div>
                        </Col>
                        <Col>
                            <div>
              Height({productData.calcSqFtIn})
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="height" 
                                    min={heightRange.min} 
                                    max={heightRange.max} 
                                    onChange={(e) => callChangeProductSpecs('height', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{heightMessage || ''}</span>
                            </div>
                        </Col>
                    </Row>
                );
            }else{return null;}
        }else if((dataEdit.source.includes('api') || (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)) && x.title === ('Size')){
            if(isOther){
                let widthRange = productData.widthRange || {min:10, max:300};
                let heightRange = productData.heightRange || {min:10, max:420};
                if(additionalDetails?.widthRange?.min){
                    widthRange = additionalDetails.widthRange || {min:10, max:300};
                    heightRange = additionalDetails.heightRange || {min:10, max:420};
                }

                if(isRound){
                    return (
                        <Row>
                            <Col>
                                <div>
                  Width{`(${widthRange.min || 0}mm - ${widthRange.max || 0}mm)`}
                                    <Input 
                                        bsSize="sm" 
                                        type="number" 
                                        id="width"
                                        min={widthRange.min || 0} 
                                        max={widthRange.max || 0} 
                                        onChange={(e) => callChangeProductSpecs('width', e)} 
                                        onWheel={ event => event.currentTarget.blur() }
                                    />
                                    <span>{widthMessage || ''}</span>
                                </div>
                            </Col>
                        </Row>
                    );
                }
                return (
                    <Row>
                        <Col>
                            <div>
                Width{`(${widthRange.min || 0}mm - ${widthRange.max || 0}mm)`}
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="width"
                                    min={widthRange.min || 0} 
                                    max={widthRange.max || 0} 
                                    onChange={(e) => callChangeProductSpecs('width', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{widthMessage || ''}</span>
                            </div>
                        </Col>
                        <Col>
                            <div>
                Height{`(${heightRange.min}mm - ${heightRange.max}mm)`}
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="height" 
                                    min={heightRange.min} 
                                    max={heightRange.max} 
                                    onChange={(e) => callChangeProductSpecs('height', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{heightMessage || ''}</span>
                            </div>
                        </Col>
                    </Row>
                );
            }else{return null;}
        }else if((dataEdit.source.includes('loose-sheet') || (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)) && x.title.includes('Size')){
            if(isOther){
                return (
                    <Row>
                        <Col>
                            <div>
              Width(mm)
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="width"
                                    min={productData.widthRange?productData.widthRange.min:0} 
                                    max={productData.widthRange?productData.widthRange.max:0} 
                                    onChange={(e) => callChangeProductSpecs('width', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{widthMessage || ''}</span>
                            </div>
                        </Col>
                        <Col>
                            <div>
              Height(mm)
                                <Input 
                                    bsSize="sm" 
                                    type="number" 
                                    id="height" 
                                    min={productData.heightRange?productData.heightRange.min:0} 
                                    max={productData.heightRange?productData.heightRange.max:0} 
                                    onChange={(e) => callChangeProductSpecs('height', e)} 
                                    onWheel={ event => event.currentTarget.blur() }
                                />
                                <span>{heightMessage || ''}</span>
                            </div>
                        </Col>
                    </Row>
                );
            }else{return null;}
        }else{
            return null;
        }
    };

    return ( 
        <>
            {/* Modal */}
            <Modal
                className="modal-dialog-centered"
                isOpen={props.modalVisible}
                toggle={() => props.toggleModal(!props.modalVisible)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {dataEdit.name}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => props.toggleModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <>
                        {/* {
              dataEdit.source? 
                dataEdit.source.includes('sticker') || isOther ? (
                <Row>
                  <Col>
                    <div>
                      Width (50mm x 297mm)
                      <Input 
                        bsSize="sm" 
                        type="number" 
                        id="width" 
                        onChange={(e) => callChangeProductSpecs('width', e)} 
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div>
                      Height (50mm x 420mm)
                      <Input 
                        bsSize="sm" 
                        type="number" 
                        id="height" 
                        onChange={(e) => callChangeProductSpecs('height', e)} 
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </div>
                  </Col>
                </Row>
                ) : null 
              : null
            } */}
                        {/* loop data 1 */}
                        {/* this will call api everytime change */}
                        {data.length > 0?
                            data.map((x, i) => {
                                if((x.data) && x.data.length > 0){
                                    if (dataEdit.source.includes('csv') && x.title === 'Quantity' && (dataEdit.customQuantity?.enabled) && dataEdit.customQuantity?.type === 'minMax') {
                                        return null;
                                    }
                                    return (
                                        <div key={i}>
                                            <div>
                                                <label className="m-0">{x.title}</label>
                                                <div className="">
                                                    <select className="form-select form-control form-control-sm" 
                                                        id={x.title}
                                                        onChange={(e) => handleChange(i, x.title, e.target.value)}
                                                    >
                                                        <option value={''}>-Please Select-</option>
                                                        {x.data.map((op, o1) => {
                                                            if(i === 0){
                                                                if(o1 === 0){
                                                                    return (
                                                                        <option key={o1} value={op} selected>{op.split('~~')[0]}</option>
                                                                    );
                                                                }else{
                                                                    return (
                                                                        <option key={o1} value={op}>{op.split('~~')[0]}</option>
                                                                    );
                                                                }
                                                            }else{
                                                                if(typeof op === 'string') {
                                                                    return (
                                                                        <option key={o1} value={op}>{op.split('~~')[0]}</option>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <option key={o1} value={op}>{op}</option>
                                                                    );
                                                                }
                                                            }
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            {renderWidthHeightComponents(x)}
                                        </div>
                                    );
                                }else{return null;}
                            })
                            :null}
            
                        {/* loop data 2 */}
                        {/* this will NOT call api */}
                        {additionalData.length > 0?
                            additionalData.map((x, i) => {
                                if(x.data.length > 0){
                                    return (
                                        <div key={i}>
                                            <label>{x.title}</label>
                                            <div className="">
                                                <select className="form-select form-control form-control-sm" 
                                                    id={x.title}
                                                    onChange={(e) => handleAdditionalPrice()}
                                                >
                                                    <option value={''}>-Please Select-</option>
                                                    {x.data.map((op, o1) => {
                                                        return (
                                                            <option key={o1} value={op.title + '|' + op.price}>{op.title}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    );
                                }else{return null;}
                            })
                            :null}

                        {additionalInfo.length > 0?
                            additionalInfo.map((x, i) => {
                                return (
                                    <div key={i}>
                                        <label>{x.title}</label>
                                        <p>{x.value}</p>
                                    </div>
                                );
                            })
                            :null}

{((!dataEdit.customQuantity?.enabled) || dataEdit.customQuantity?.type === 'minMax') &&
                            dataEdit.source? 
                                dataEdit.source.includes('sticker') ||
              dataEdit.source.includes('document') ||
              dataEdit.source.includes('certificate') ||
                dataEdit.source.includes('loose-sheet') || 
                dataEdit.source.includes('booklet') ||
                dataEdit.source.includes('poster') ||
                (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)
                ? (
                                        <div>
                                            {
                                                dataEdit.source.includes('document')?
                                                    'Total Printing Pages':
                                                    'Quantity'
                                            }
                                            
                                            <Input 
                                                bsSize="sm" 
                                                type="number" 
                                                id="quantity"
                                                defaultValue={1}
                                                onChange={(e) => callChangeProductSpecs('quantity', e)} 
                                                onWheel={ event => event.currentTarget.blur() }
                                            />
                                        </div>
                                    ): null
                                : null
                        }
                        {((dataEdit.customQuantity?.enabled) && dataEdit.customQuantity?.type === 'minMax') &&
                            dataEdit.source? 
                            dataEdit.source.includes('csv') ||
                (dataEdit.source?.length > 20 && dataEdit.source?.indexOf(' ') <= 0)
                ? (
                                        <div>
                                            Quantity
                                            <Input 
                                                bsSize="sm" 
                                                type="number" 
                                                id="quantity"
                                                defaultValue={1}
                                                onChange={(e) => callChangeProductSpecs('quantity', e)} 
                                                onWheel={ event => event.currentTarget.blur() }
                                            />
                                        </div>
                                    ): null
                                : null
                        }

                        <div className="mt-2">
              Price: <b><span className="text-uppercase">{!(productData?.source?.includes('api')) && props.currency}</span>{price}</b>
                            <br/>
              Additional Price: <b><span className="text-uppercase">{props.currency}</span>{additionalPrice.reduce((partialSum, a) => partialSum + a, 0)}</b>
                            <br/>
              Total Amount: <b><span className="text-uppercase">{props.currency}</span>{price + additionalPrice.reduce((partialSum, a) => partialSum + a, 0)}</b>
                        </div>
                    </>
                </div>
            </Modal>
        </>
    );
};
 
export default ModalPreview;