/*eslint-disable*/
import React from "react";
import cloneDeep from "lodash/cloneDeep"

// reactstrap components
import {
  Button,
  Input,
  Card,
  CardBody,
  Row,
  Col,
  Table
} from "reactstrap";

const AdditionalCalc = ({ 
  timelineArray, 
  setTimelineArray,
  marginsData,
  currency
}) => {
  
  const timeLineOnChange = (val, name, index) => {
    let cloneTimeLine = cloneDeep(timelineArray)
    cloneTimeLine[index][name] = val
    
    setTimelineArray(cloneTimeLine)
  }

  const removeTimeline = (index) => {
    let cloneTimeLine = cloneDeep(timelineArray)
    cloneTimeLine.splice(index, 1)
    
    setTimelineArray(cloneTimeLine)
  }

  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <small className=" font-weight-bold">
                Production Timeline
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={() => setTimelineArray(timelineArray.concat({
                    name: '',
                    minAmt: 0,
                    amount: 0,
                    percent: 0
                  }))}
                >
                  <div className="btn-inner--text">
                    <span>Add Timeline</span>
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mac-scrollbar-white">
            <Table className="align-items-center table-flush" size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Timeline Name</th>
                  <th></th>
                  <th>Percent %</th>
                  <th></th>
                  <th>Min Amount({currency})</th>
                  <th></th>
                  {/* <th>After calc %<br/> if not reach min<br/> then (Amount)</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  timelineArray.length > 0? 
                    timelineArray.map((x, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">
                            {i+1}.
                          </th>
                          <td className="px-1">
                            <Input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                              bsSize="sm"
                              value={x.name}
                              onChange={(e) => timeLineOnChange(e.target.value, 'name', i)}
                            />
                          </td>
                          <td>
                            Increase price by %
                          </td>
                          <td className="px-1">
                            <Input
                              className="form-control-alternative"
                              placeholder="Percent Charge(%)"
                              type="number"
                              bsSize="sm"
                              value={Number(x.percent).toString()}
                              onChange={(e) => timeLineOnChange(Number(e.target.value), 'percent', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </td>
                          <td>Or <span className="">({currency})</span></td>
                          <td className="px-1">
                            <Input
                              className="form-control"
                              placeholder="Min Amount"
                              type="number"
                              bsSize="sm"
                              value={Number(x.minAmt).toString()}
                              onChange={(e) => timeLineOnChange(Number(e.target.value), 'minAmt', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </td>
                          <td>Whichever is higher</td>
                          {/* <td className="px-1">
                            <Input
                              className="form-control-alternative"
                              placeholder="Amount Charge"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amount).toString()}
                              onChange={(e) => timeLineOnChange(Number(e.target.value), 'amount', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </td> */}
                          <td>
                            <i 
                            className="ni ni-fat-remove"
                            style={{fontSize: '1rem', cursor:'pointer'}}
                            onClick={() => removeTimeline(i)}
                            />
                          </td>
                        </tr>
                      )
                    }): null
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
 
export default AdditionalCalc;