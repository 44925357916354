import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router';
import Breadcrumb from '../Common/Breadcrumb.js';

// reactstrap components
import {
    Button,
    Form,
    Input,
    // Modal,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import DynamicPriceCalc from './DynamicPriceCalc';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    dynamicPriceName: Yup.string()
        .required('Name is required'),
});

const DynamicPriceModal = (props) => {
    const { dynamicPriceData, role } = props;
    const [ dynamicPriceBetween, setDynamicPriceBetween ] = useState([]);
    const [ dynamicPriceFocused, setDynamicPriceFocused ] = useState(false);
    const [ dynamicPriceLastCondition, setDynamicPriceLastCondition ] = useState(0);
  
    const clone = (previousData) => {
        let cloneDynamicPriceBetween = cloneDeep(previousData);
        if(cloneDynamicPriceBetween.length > 0) {
            setDynamicPriceLastCondition(cloneDynamicPriceBetween[cloneDynamicPriceBetween.length - 1].type);
        }
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };
	
    const dynamicPriceBetweenOnChange = (val, name, index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween[index][name] = val;
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const removeDynamicPriceBetween = (index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween.splice(index, 1);
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const onSubmit = async(value) => {
        if (value?.type !== 'sqft' && value?.type !== 'length') {
            dynamicPriceBetween[dynamicPriceBetween.length - 1].type = dynamicPriceLastCondition;
            value.dynamicPriceBetween = dynamicPriceBetween;
        }
        
        await client.authenticate()
            .then(()=>{
                if (value?.type === 'sqft') {
                    let clonedSqftBetween = cloneDeep(value.sqftBetween);
                    clonedSqftBetween.sort((a, b) => a.minSqft - b.minSqft);
                    return client.service('dynamicPrice').create({
                        ...value,
                        sqftBetween: clonedSqftBetween
                    });
                } else if (value?.type === 'length') {
                    let clonedLengthBetween = cloneDeep(value.lengthBetween);
                    clonedLengthBetween.sort((a, b) => a.min - b.min);
                    return client.service('dynamicPrice').create({
                        ...value,
                        lengthBetween: clonedLengthBetween
                    });
                } else {
                    let clonedDynamicPriceBetween = cloneDeep(dynamicPriceBetween);

                    clonedDynamicPriceBetween.sort((a, b) => a.quantity - b.quantity);
                    return client.service('dynamicPrice').create({
                        ...value,
                        dynamicPriceBetween: clonedDynamicPriceBetween
                    });
                }
                
            })
            .then((res) => {
                props.pushDynamicPrice(res);
                props.notificationOpen(true, 'success', 'Dynamic Price ' + res.dynamicPriceName + ' Added Successfully!');
                props.history.push(`/${props.role}/dynamic-price`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Formik
                initialValues={{
                    dynamicPriceName: '',
                    description: '',
                    type: 'quantity',
                    dynamicPriceBetween: [],
                    sqftBetween: [],
                    lengthBetween: [],
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    } = propsChild;
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body pt-0'>
                                <Row className='m-1 mt-3'>
                                    <h2 className='mr-4 mb-0'>Create Dynamic Price</h2>
                                </Row>
                                <Breadcrumb
                                    items={[
                                        {title: 'Dynamic Price', link: `/${role}/dynamic-price`},
                                        {title: 'Create Dynamic Price'}
                                    ]}
                                />
                                <Card className='mb-4'>
                                    <CardBody className='rounded'>
                                        <div className='px-3 mb-4 border rounded'>
                                            <Row className='p-3 px-4'>
                                                <h4 className='m-0'>Dynamic Price Setting</h4>
                                            </Row>
                                            <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Name</h5>
                                                    <Input 
                                                        placeholder='Dynamic Price Name'
                                                        type='text'
                                                        id='dynamicPriceName'
                                                        value={values.dynamicPriceName}
                                                        onChange={handleChange}
                                                        onFocus={() =>
                                                            setDynamicPriceFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setDynamicPriceFocused(false);
                                                            handleBlur(e);
                                                        }} 
                                                    />
                                                    {errors.dynamicPriceName && touched.mardynamicPriceNameginName && (
                                                        <div className='input-feedback'>{errors.dynamicPriceName}</div>
                                                    )}
                                                </Col>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Description</h5>
                                                    <Input
                                                        className='form-control-alternative'
                                                        placeholder='Description'
                                                        id='description'
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        autoComplete='off'
                                                        type='text'
                                                        rows='3'
                                                    />                                             
                                                </Col>
                                            </Row>
                                        </div>
                                        <DynamicPriceCalc
                                            dynamicPriceBetween={dynamicPriceBetween}
                                            dynamicPriceLastCondition={dynamicPriceLastCondition}
                                            setDynamicPriceBetween={setDynamicPriceBetween}
                                            dynamicPriceBetweenOnChange={dynamicPriceBetweenOnChange}
                                            removeDynamicPriceBetween={removeDynamicPriceBetween}
                                            dynamicPriceData={dynamicPriceData}
                                            setDynamicPriceLastCondition={setDynamicPriceLastCondition}
                                            clone={clone}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='modal-footer'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                        props.history.push(`/${props.role}/dynamic-price`);
                                    }}
                                >
                                    Discard
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='submit'
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(DynamicPriceModal);