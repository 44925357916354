import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Card,
    CardBody,
    Row,
    Col,
    // Table
} from 'reactstrap';

const MarginCalc = ({
    marginBetween,
    setMarginBetween,
    marginBetweenOnChange,
    removeMarginBetween,

    marginsData,
    currency
}) => {

    const applyMargin = (value) => {
        setMarginBetween(value.marginBetween);
    };

    const addCondition = () => {
        if(marginBetween[marginBetween.length -1]){
            setMarginBetween(marginBetween.concat({
                maxAmt: 0,
                percent: 0
            }));
        }else{
            setMarginBetween(marginBetween.concat({
                maxAmt: 0,
                percent: 0
            }).concat({
                maxAmt: 0,
                percent: 0
            }));
        }
    };

    const lastTwoInput = (e, i) => {
        let cloneMarginBetween = cloneDeep(marginBetween);

        cloneMarginBetween[i]['maxAmt'] = Number(e.target.value);

        if(marginBetween.length -2 === i){
            if(e.target.value){
                cloneMarginBetween[i+1]['maxAmt'] = (parseFloat(e.target.value) + 0.0001).toFixed(4); 
            }else{
                cloneMarginBetween[i+1]['maxAmt'] = (0.0001).toFixed(4);
            }
        }

    
        setMarginBetween(cloneMarginBetween);
    };

    return (  
        <>
            <Card className='border'>
                <CardBody className='p-0'>
                    <Row className='px-4 py-3 m-0 d-flex align-items-center'>
                        <h4 className='m-0 mr-4'> Profit Margin Properties</h4>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret className='text-uppercase'>
                                Clone Margin
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    {
                                        marginsData.map((x, i) => {
                                            return (
                                                <DropdownItem
                                                    key={i} 
                                                    className='text-capitalize' 
                                                    value={x.marginName} 
                                                    onClick={() => {
                                                        applyMargin(x);
                                                    }}
                                                >
                                                    {x.marginName}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>
                    
                    <div className='p-4 bg-light-grey'>
                        <Row className='mb-2'>
                            <Col style={{ maxWidth: 40 }}>
                            </Col>
                            <Col style={{ maxWidth: 140 }}>
                                <small className='font-weight-bold'>
                                    Costing
                                </small>
                            </Col>
                            <Col style={{ maxWidth: 140 }}>
                            </Col>
                            <Col style={{ maxWidth: 140 }}>
                                <small className='font-weight-bold'>
                                    Profit Margin (%)
                                </small>
                            </Col>
                            <Col style={{ maxWidth: 140 }}>
                            </Col>
                        </Row>
                        {
                            marginBetween.length > 0? 
                                marginBetween.map((x, i) => {
                                    return (
                                        <Row key={i} className='mb-2 d-flex align-items-center'>
                                            <Col className='text-right' style={{ maxWidth: 40 }}>
                                                $
                                            </Col>
                                            <Col style={{ maxWidth: 140 }}>
                                                <Input
                                                    className='pl-2'
                                                    placeholder='Amount Charge'
                                                    type='number'
                                                    bsSize='sm'
                                                    min='0'
                                                    step='.01'
                                                    value={Number(x.maxAmt).toString()}
                                                    disabled={marginBetween.length === i+1?true:false}
                                                    onChange={(e) => lastTwoInput(e, i)}
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </Col>
                                            {marginBetween.length === i+1? (
                                                <Col style={{ maxWidth: 140 }}><p className='text-sm font-weight-normal'>{'& above'}</p></Col>
                                            ): (
                                                <Col style={{ maxWidth: 140 }}><p className='text-sm font-weight-normal'>{'& below, add'}</p></Col>
                                            )}
                                            <Col style={{ maxWidth: 140 }}>
                                                <InputGroup className='input-group-alternative'>
                                                    <Input
                                                        placeholder='Percent Charge'
                                                        type='number'
                                                        bsSize='sm'
                                                        value={x.percent}
                                                        onChange={(e) => marginBetweenOnChange(Number(e.target.value), 'percent', i)}
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                    <InputGroupAddon addonType='append' className='bg-secondary'>
                                                        <InputGroupText className='bg-transparent' style={{ padding: '0rem 0.75rem' }}>
                                                            <span>%</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col className='d-flex justify-content-start align-items-center' style={{ maxWidth: 140 }}>
                                                <Button
                                                    color='secondary'
                                                    type='button'
                                                    size='sm'
                                                    onClick={() => removeMarginBetween(i)}
                                                >
                                                    <div className='btn-inner--text text-red'>
                                                        <i 
                                                            className='fa fa-minus'
                                                        />
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                }): null
                        }
                        <Row>
                            <Col md='12'>
                                <a 
                                    href='/' 
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        addCondition();
                                    }} 
                                >
                                    <span>
                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                        Add new line
                                    </span>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
 
export default MarginCalc;