/*eslint-disable*/
import React, { useLayoutEffect } from "react";
import cloneDeep from "lodash/cloneDeep"

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

const AdditionalOptions = ({
  additionOption,
  setAdditionOption,
  currency
}) => {

  useLayoutEffect(() => {
    if(additionOption.length === 0){
      addOption()
    }
  }, [additionOption])

  const optionOnChange = (val, name, index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][name] = val
    
    setAdditionOption(cloneOption)
  }

  const pushOptionVar = (index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index]['option'].push({ name: '', amount: 0, percent: 0 })
    
    setAdditionOption(cloneOption)
  }

  const childOptionOnChange = (val, child, name, index, index1) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][child][index1][name] = val
    
    setAdditionOption(cloneOption)
  }

  const removeOptionChild = (child, index, index1) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption[index][child].splice(index1, 1)
    
    setAdditionOption(cloneOption)
  }

  const removeOption = (index) => {
    let cloneOption = cloneDeep(additionOption)
    cloneOption.splice(index, 1)
    
    setAdditionOption(cloneOption)
  }

  const addOption = () => {
    setAdditionOption(additionOption.concat({
      name: '',
      calcWith: 'amount',
      option: []
    }))
  }

  return (  
    <>
     <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Additional Options</h4>
        <Button
          color="primary"
          type="button"
          onClick={() => addOption()}
        >
          <div className="btn-inner--text">
            <span>Add option</span>
          </div>
        </Button>
      </div>
      {
        additionOption.length > 0? 
          additionOption.map((x, i) => {
            return (
              <Card key={i} className="shadow mb-4">
                <CardBody>
                  <Row className="mb-2">
                    <Col md="12">
                      <small className="font-weight-bold">Label Name</small>
                      <Input
                        className="form-control"
                        placeholder="Name"
                        type="text"
                        bsSize="sm"
                        value={x.name}
                        onChange={(e) => optionOnChange(e.target.value, 'name', i)}
                      />
                    </Col>
                  </Row>
        
                  <Row className="mb-2">
                    <Col md="10">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold">Option Name</small>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold">
                            Amount (<span className="text-uppercase">{currency}</span>)
                          </small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {
                    x.option.map((x1, i1) => {
                      return (
                        <Row key={i1} className="mb-2">
                          <Col md="10">
                            <Row>
                              <Col md="6">
                                <Input
                                  className="form-control"
                                  placeholder="Name"
                                  type="text"
                                  bsSize="sm"
                                  value={x1.name}
                                  onChange={(e) => childOptionOnChange(e.target.value, 'option', 'name', i, i1)}
                                />
                              </Col>
                              <Col md="6">
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend" className="bg-secondary mr-2">
                                    <select
                                      className="form-control-alternative mx-2 shadow-none text-uppercase bg-secondary rounded"
                                      placeholder="Calc With"
                                      value={x.calcWith}
                                      onChange={(e) => optionOnChange(e.target.value, 'calcWith', i)}
                                    >
                                      <option value="amount">
                                        {currency}
                                      </option>
                                      <option value="percent">
                                        %
                                      </option>
                                    </select>
                                  </InputGroupAddon>
                                  {
                                    x.calcWith === 'percent'? (
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Percent"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x1.percent).toString()}
                                        onChange={(e) => childOptionOnChange(Number(e.target.value), 'option', 'percent', i, i1)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    ): (
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Amount"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x1.amount).toString()}
                                        onChange={(e) => childOptionOnChange(Number(e.target.value), 'option', 'amount', i, i1)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    )
                                  }
                                </InputGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="2" className="d-flex justify-content-end align-items-center">
                            <Button
                              color="secondary"
                              type="button"
                              size="sm"
                              onClick={() => removeOptionChild('option', i, i1)}
                            >
                              <div className="btn-inner--text text-red">
                                <i 
                                  className="fa fa-minus"
                                />
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      )
                    })
                  }

                  <Row>
                    <Col md="12">
                      <a 
                        href="/" 
                        onClick={ (event) => {
                          event.preventDefault();
                          pushOptionVar(i)
                        }} 
                      >
                        <span>
                          <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                          Add new line
                        </span>
                      </a>
                    </Col>
                    <Col md="12" className="d-flex justify-content-end">
                      <Button
                        color="secondary"
                        type="button"
                        onClick={() => removeOption(i)}
                      >
                        <div className="btn-inner--text text-red">
                          <i 
                            className="fa fa-trash"
                            style={{fontSize: '1rem'}}
                          />
                          <span>Delete</span>
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )
          }):null
        }

    </>
  );
}
 
export default AdditionalOptions;