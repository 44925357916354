import React from 'react';
// reactstrap components
import {
    Row,
    Col
} from 'reactstrap';

const performChecks = (values) => {
    if(!values.avaiSources.booklet) values.avaiSources.booklet = false;
};
const AvaiSources = ({
    values,
    handleChange,
    setFieldValue
}) => {
    performChecks(values);
    return (  
        <>
            <hr className='my-4' />
            <label
                className='form-control-label mb-0'
                htmlFor='currency'
            >
                Sources
            </label>
            <Row>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Excard (Api)
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.api'
                                checked={values.avaiSources.api}
                                onChange={(e) => setFieldValue('avaiSources.api', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Excard SG (Api)
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.apiSg'
                                checked={values.avaiSources.apiSg}
                                onChange={(e) => setFieldValue('avaiSources.apiSg', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Excard East (Api)
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.apiEast'
                                checked={values.avaiSources.apiEast}
                                onChange={(e) => setFieldValue('avaiSources.apiEast', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
            </Row>
            <Row>
                
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Poster
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.poster'
                                checked={values.avaiSources.poster}
                                onChange={(e) => setFieldValue('avaiSources.poster', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Loose
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.looseSheet'
                                checked={values.avaiSources.looseSheet}
                                onChange={(e) => setFieldValue('avaiSources.looseSheet', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Booklet
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.booklet'
                                checked={values.avaiSources.booklet}
                                onChange={(e) => setFieldValue('avaiSources.booklet', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Item
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.product'
                                checked={values.avaiSources.product}
                                onChange={(e) => setFieldValue('avaiSources.product', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
                <Col sm='3' md='4'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        Packaging
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.packaging'
                                checked={values.avaiSources.packaging}
                                onChange={(e) => setFieldValue('avaiSources.packaging', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
            </Row>
        </>
    );
};
 
export default AvaiSources;