/*eslint-disable*/
import React, { useLayoutEffect } from "react";
import cloneDeep from "lodash/cloneDeep"
import PreSize from "./PreSize";

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Table,
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

function addPercentToAmount(amount, percent){
  let defaultAmt = (percent / 100) * amount
  return (amount + defaultAmt).toFixed(2)
}

const StickerForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  manualCostBetween,
  setManualCostBetween,
  manualCostGreater,
  setManualCostGreater,
  manualCostDefault,
  setManualCostDefault,
  source,
  currency,
  setFieldValue
}) => {

  useLayoutEffect(() => {
    if(items.length === 0){
      addItem()
    }
  }, [items])

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', basePrice: 0, amount: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  const ManualBetweenOnChange = (val, name, index) => {
    let cloneManualCostBetween = cloneDeep(manualCostBetween)
    cloneManualCostBetween[index][name] = val
    
    setManualCostBetween(cloneManualCostBetween)
  }

  const removeManualBetween = (index) => {
    let cloneManualCostBetween = cloneDeep(manualCostBetween)
    cloneManualCostBetween.splice(index, 1)
    
    setManualCostBetween(cloneManualCostBetween)
  }

  const addItem = () => {
    setItems(items.concat({
      name: '',
      basePrice: 0,
      amountDefault: {minQ: 0, amount: 0},
      amountGreater: {minQ: 0, amount: 0},
      amountBetween: [],
      variations: []
    }))
  }

  const duplicateItem = (x) => {
    let cloneItems = cloneDeep(items)

    if(x.amountBetween){
      if(x.amountBetween.length > 0){
        x.amountBetween.map(x => {
          delete x._id
          return x
        })
      }
    }

    if(x.variations){
      if(x.variations.length > 0){
        x.variations.map(x => {
          delete x._id
          return x
        })
      }
    }

    cloneItems = cloneItems.concat(x)
    
    setItems(cloneItems)
  }

  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="d-flex justify-content-center">
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Product Base Price (<span className="text-uppercase">{currency}</span>)
                </small>
              </div>
              <FormGroup
                className={classnames("mb-0", {
                  focused: amountFocused
                }, {
                  "has-danger": errors.netAmount && touched.netAmount
                })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend" className="bg-secondary mr-2">
                    <InputGroupText className="bg-transparent">
                      <span className="text-uppercase">{currency}</span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Product Net Amount"
                    type="number"
                    id="netAmount"
                    value={Number(values.netAmount).toString()}
                    onChange={handleChange}
                    onFocus={e => setAmountFocused(true)}
                    onBlur={e => {
                      setAmountFocused(false);
                      handleBlur(e)
                    }}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
                {errors.netAmount && touched.netAmount && (
                  <div className="input-feedback">{errors.netAmount}</div>
                )}
              </FormGroup>
            </Col>
            {
              source !== 'loose-sheet'? (
                <Col md="3">
                  <div>
                    <small className=" font-weight-bold">
                      Custom Shape Percent (%)
                    </small>
                  </div>
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                      <Input
                        className="form-control-alternative"
                        placeholder="Product Net Amount"
                        type="number"
                        id="manualPercent"
                        bsSize="sm"
                        value={Number(values.manualPercent).toString()}
                        onChange={handleChange}
                        onWheel={ event => event.currentTarget.blur() }
                      />
                      <InputGroupAddon addonType="append" className="bg-secondary">
                        <InputGroupText className="bg-transparent">
                          <span>%</span>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              ): null
            }
            {
              source === 'loose-sheet'? (
                <>
                <Col md="3">
                  <div>
                    <small className=" font-weight-bold">
                      Folding Percent
                    </small>
                  </div>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Folding Percent"
                        type="number"
                        id="foldingPercent"
                        value={Number(values.foldingPercent).toString()}
                        onChange={handleChange}
                        onWheel={ event => event.currentTarget.blur() }
                      />
                      <InputGroupAddon addonType="append" className="bg-secondary">
                        <InputGroupText className="bg-transparent">
                          <span>%</span>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="3">
                <div>
                  <small className=" font-weight-bold">
                    Folding Base Price (<span className="text-uppercase">{currency}</span>)
                  </small>
                </div>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Folding Percent"
                      type="number"
                      id="foldingBase"
                      value={Number(values.foldingBase).toString()}
                      onChange={handleChange}
                      onWheel={ event => event.currentTarget.blur() }
                    />
                    <InputGroupAddon addonType="append" className="bg-secondary">
                      <InputGroupText className="bg-transparent">
                        <span className="text-uppercase">{currency}</span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
              <div>
                <small className="font-weight-bold">
                  Double Side (exp: 1.3)
                </small>
              </div>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Double Side Percent (exp: 1.3)"
                    type="number"
                    id="doubleSidePercent"
                    value={Number(values.doubleSidePercent).toString()}
                    onChange={handleChange}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
              </>
              ): null
            }
          </Row>
        </CardBody>
      </Card>

      {
        source === 'loose-sheet' &&
            <PreSize values={values} setFieldValue={setFieldValue} currency={currency} />
      }

      <Card className="shadow mb-4">
        <CardBody>
          <h4>Printing Dimensions</h4>
          <Row className="d-flex justify-content-center align-items-center mb-2">
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Min Width (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="widthRange.min"
                  bsSize="sm"
                  value={Number(values.widthRange.min).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Max Width (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="widthRange.max"
                  bsSize="sm"
                  value={Number(values.widthRange.max).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            {/* <Col md="4" className="pl-1 pr-0">
              <p className="font-weight-bold mb-0">Excluding Bleeding</p>
            </Col> */}
          </Row>

          <Row className="d-flex justify-content-center align-items-center mb-2">
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Min Height (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="heightRange.min"
                  bsSize="sm"
                  value={Number(values.heightRange.min).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Max Height (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="heightRange.max"
                  bsSize="sm"
                  value={Number(values.heightRange.max).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Bleeding Width (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="bleeding.width"
                  bsSize="sm"
                  value={Number(values.bleeding.width).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col md="3">
              <div>
                <small className=" font-weight-bold">
                  Bleeding Height (mm)
                </small>
              </div>
              <InputGroup className="input-group-alternative">
                <Input
                  className="form-control-alternative"
                  placeholder="0"
                  type="number"
                  id="bleeding.height"
                  bsSize="sm"
                  value={Number(values.bleeding.height).toString()}
                  onChange={handleChange}
                  onWheel={ event => event.currentTarget.blur() }
                />
                <InputGroupAddon addonType="append" className="bg-secondary">
                  <InputGroupText className="bg-transparent">
                    <span>mm</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Material</h4>
        <Button
          color="primary"
          type="button"
          onClick={() => addItem()}
        >
          <div className="btn-inner--text">
            <span>Add Material</span>
          </div>
        </Button>
      </div>

      {
        items.length > 0? 
          items.map((x, i) => {
            return (
              <Card key={i} className="shadow mb-4">
                <CardBody>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      color="default"
                      type="button"
                      onClick={() => duplicateItem(x)}
                    >
                      <div className="btn-inner--text">
                        <i 
                          className="fa fa-copy"
                          style={{fontSize: '1rem'}}
                        />
                        <span>Duplicate</span>
                      </div>
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => removeSticker(i)}
                    >
                      <div className="btn-inner--text text-red">
                        <i 
                          className="fa fa-trash"
                          style={{fontSize: '1rem'}}
                        />
                        <span>Delete</span>
                      </div>
                    </Button>
                  </div>
                  <div className="bg-product-highlight rounded p-4 mb-3">
                    <div className="product-max-width">
                      <Row className="align-items-center">
                        <Col md="6">
                          <small className="font-weight-bold">Name of Material</small>
                          <Input
                            className="form-control-alternative"
                            placeholder="Name"
                            type="text"
                            bsSize="sm"
                            value={x.name}
                            onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                          />
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold">Material Base Price (<span className="text-uppercase">{currency}</span>)</small>
                          <Input
                            className="form-control-alternative"
                            placeholder="Base Price"
                            type="number"
                            bsSize="sm"
                            value={Number(x.basePrice).toString()}
                            onChange={(e) => itemOnChange(Number(e.target.value), 'basePrice', i)}
                            onWheel={ event => event.currentTarget.blur() }
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="mb-5">
                      <h4>Printing Cost</h4>
                      <Row className="mb-2">
                        <Col md="10">
                          <Row>
                            <Col md="4">
                              <small className="font-weight-bold">
                                Min Quantity
                              </small>
                              <Input
                                className="form-control-alternative"
                                placeholder="Min Q"
                                type="number"
                                bsSize="sm"
                                value={Number(x.amountDefault.minQ).toString()}
                                onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountDefault.amount}, 'amountDefault', i)}
                                onWheel={ event => event.currentTarget.blur() }
                              />
                            </Col>
                            <Col md="4">
                              <small className="font-weight-bold">
                                Max Quantity
                              </small>
                              <div className="d-flex align-items-center">
                                <Card className="shadow-sm" style={{
                                  width: 'fit-content'
                                }}>
                                  <CardBody className="py-0 px-2">
                                  {"& below"}
                                  </CardBody>
                                </Card>
                              </div>
                            </Col>
                            <Col md="4">
                              <small className="font-weight-bold">
                                <span className="text-uppercase">{currency}</span>/Sheet
                              </small>
                              <Input
                                className="form-control-alternative"
                                placeholder="Amount"
                                type="number"
                                bsSize="sm"
                                value={Number(x.amountDefault.amount).toString()}
                                onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amount: Number(e.target.value)}, 'amountDefault', i)}
                                onWheel={ event => event.currentTarget.blur() }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {
                        x.amountBetween.length > 0? 
                          x.amountBetween.map((x1, i1) => {
                            return (
                              <Row className="mb-2">
                                <Col md="10">
                                  <Row>
                                    <Col md="4">
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Min Q"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x1.minQ).toString()}
                                        onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'minQ', i, i1)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    <Col md="4">
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Max Q"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x1.maxQ).toString()}
                                        onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'maxQ', i, i1)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                    <Col md="4">
                                      <Input
                                        className="form-control-alternative"
                                        placeholder="Amount"
                                        type="number"
                                        bsSize="sm"
                                        value={Number(x1.amount).toString()}
                                        onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'amount', i, i1)}
                                        onWheel={ event => event.currentTarget.blur() }
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="2" className="d-flex justify-content-end align-items-center">
                                  <Button
                                    color="secondary"
                                    type="button"
                                    size="sm"
                                    onClick={() => removeChild('amountBetween', i, i1)}
                                  >
                                    <div className="btn-inner--text text-red">
                                      <i 
                                        className="fa fa-minus"
                                      />
                                    </div>
                                  </Button>
                                  </Col>
                              </Row>
                            )
                          }): null
                        }
                        <Row className="mb-2">
                          <Col md="10">
                            <Row>
                              <Col md="4">
                              <Input
                                className="form-control-alternative"
                                placeholder="Min Q"
                                type="number"
                                bsSize="sm"
                                value={Number(x.amountGreater.minQ).toString()}
                                onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountGreater.amount}, 'amountGreater', i)}
                                onWheel={ event => event.currentTarget.blur() }
                              />
                              </Col>
                              <Col md="4" className="d-flex align-items-center">
                                <Card className="shadow-sm" style={{
                                  width: 'fit-content'
                                }}>
                                  <CardBody className="py-0 px-2">
                                  {"& above"}
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="4">
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Amount"
                                  type="number"
                                  bsSize="sm"
                                  value={Number(x.amountGreater.amount).toString()}
                                  onChange={(e) => itemOnChange({minQ: x.amountGreater.minQ, amount: Number(e.target.value)}, 'amountGreater', i)}
                                  onWheel={ event => event.currentTarget.blur() }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      <Row>
                        <Col md="12">
                          <a 
                            href="/" 
                            onClick={ (event) => {
                              event.preventDefault();
                              itemOnChange(x.amountBetween.concat({
                                minQ: 0,
                                maxQ: 0,
                                amount: 0
                              }), 'amountBetween', i)
                            }} 
                          >
                            <span>
                              <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                              Add new line
                            </span>
                          </a>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <h4>Finishing Cost</h4>
                      <Row className="mb-2">
                        <Col md="10">
                          <Row>
                            <Col md="4">
                              <small className="font-weight-bold">
                                Name
                              </small>
                            </Col>
                            <Col md="4">
                              <small className="font-weight-bold">
                                Finishing Base Price (<span className="text-uppercase">{currency}</span>)
                              </small>
                            </Col>
                            <Col md="4">
                              <small className="font-weight-bold">
                                <span className="text-uppercase">{currency}</span>/Sheet
                              </small>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {
                        x.variations.map((x1, i1) => {
                          return (
                            <Row key={i1} className="mb-2">
                              <Col md="10">
                                <Row>
                                  <Col md="4">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Name"
                                      type="text"
                                      bsSize="sm"
                                      value={x1.name}
                                      onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Base Price"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.basePrice).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'basePrice', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                    />
                                  </Col>
                                  <Col md="4">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Per Big Sticker Amount"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.amount).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="2" className="d-flex justify-content-end align-items-center">
                                <Button
                                  color="secondary"
                                  type="button"
                                  size="sm"
                                  onClick={() => removeChild('variations', i, i1)}
                                >
                                  <div className="btn-inner--text text-red">
                                    <i 
                                      className="fa fa-minus"
                                    />
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <Row>
                        <Col md="12">
                          <a 
                            href="/" 
                            onClick={ (event) => {
                              event.preventDefault();
                              pushStickerVar(i)
                            }} 
                          >
                            <span>
                              <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                              Add new line
                            </span>
                          </a>
                        </Col>
                      </Row>
                    </div>
                      
                  </div>
                </CardBody>
              </Card>
            )
          }): null
      }
          
    </>
  );
}
 
export default StickerForm;