//Action Types
export const FETCH_COMPULSORY_COMPONENTS = 'FETCH_COMPULSORY_COMPONENTS';
export const PUSH_COMPULSORY_COMPONENTS = 'PUSH_COMPULSORY_COMPONENTS';
export const UPDATE_COMPULSORY_COMPONENTS = 'UPDATE_COMPULSORY_COMPONENTS';
export const REMOVE_COMPULSORY_COMPONENTS = 'REMOVE_COMPULSORY_COMPONENTS';

export const FETCH_COMPONENTS = 'FETCH_COMPONENTS';
export const PUSH_COMPONENTS = 'PUSH_COMPONENTS';
export const UPDATE_COMPONENTS = 'UPDATE_COMPONENTS';
export const REMOVE_COMPONENTS = 'REMOVE_COMPONENTS';

//Action Creator
export const fetchCompulsoryComponents = (data) => ({
    type: FETCH_COMPULSORY_COMPONENTS,
    data: data
});

export const pushCompulsoryComponents = (data) => ({
    type: PUSH_COMPULSORY_COMPONENTS,
    data: data
});

export const updateCompulsoryComponents = (data) => ({
    type: UPDATE_COMPULSORY_COMPONENTS,
    data: data
});

export const removeCompulsoryComponents = (data) => ({
    type: REMOVE_COMPULSORY_COMPONENTS,
    data: data
});
export const fetchComponents = (data) => ({
    type: FETCH_COMPONENTS,
    data: data
});

export const pushComponents = (data) => ({
    type: PUSH_COMPONENTS,
    data: data
});

export const updateComponents = (data) => ({
    type: UPDATE_COMPONENTS,
    data: data
});

export const removeComponents = (data) => ({
    type: REMOVE_COMPONENTS,
    data: data
});
