import React, { useState, useLayoutEffect } from 'react';

// nodejs library that concatenates classes
import classnames from 'classnames';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import PerfectBindForm from './PerfectBindForm';
import SaddleStitchForm from './SaddleStitchForm';

// reactstrap components

const BookletForm = ({
    amountFocused,
    setAmountFocused,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    items,
    setItems,
    currency,
}) => {
    useLayoutEffect(() => {
        if (items.length === 0) {
            addItem();
        }
    }, [items]);

    const addItem = () => {
        setItems(items.concat({
            name: '',
            amountDefault: { minQ: 0, amount: 0 },
            amountGreater: { minQ: 0, amount: 0 },
            amountBetween: [],
            variations: [],
        }));
    };

    const [currentActiveTab, setCurrentActiveTab] = useState('saddleStitch', 'perfectBind');

    // Toggle active state for Tab
    const toggle = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };
    if(values.assignProduct) {
        return (
            <></>
        );
    }
    return (
        <>
            <Nav tabs className='shadow card' style={{ flexDirection: 'row' }}>
                <NavItem className='shadow'>
                    <NavLink
                        className={classnames({
                            active:
                          currentActiveTab === 'saddleStitch',
                        })}
                        onClick={() => { toggle('saddleStitch'); }}
                    >
                        <h2>Saddle Stitch</h2>
                    </NavLink>
                </NavItem>
                <NavItem className='shadow'>
                    <NavLink
                        className={classnames({
                            active:
                          currentActiveTab === 'perfectBind',
                        })}
                        onClick={() => { toggle('perfectBind'); }}
                    >
                        <h2>Perfect Bind</h2>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
                <TabPane tabId='saddleStitch'>
                    <SaddleStitchForm
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        source={values.source}
                        currency={currency}
                    />
                </TabPane>
                <TabPane tabId='perfectBind'>
                    <PerfectBindForm
                        amountFocused={amountFocused}
                        setAmountFocused={setAmountFocused}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={errors}
                        touched={touched}
                        items={items}
                        setItems={setItems}
                        source={values.source}
                        currency={currency}
                    />
                </TabPane>
            </TabContent>
        </>
    );
};

export default BookletForm;