import React from 'react';
import { Field, FieldArray } from 'formik';

import {
    Card,
    CardBody,
    Row,
    Col,
    Button,
} from 'reactstrap';

const CustomQuantity = ({
    values,
    role,
    disableFields,
    source,
}) => {
    return ( 
        <Card className='shadow mb-4'>
            <CardBody>
                <Row className='d-flex mb-2 pl-3 align-items-center' style={{ gap: 20 }}>
                    <h4 className='font-weight-bold'>
                        Set Custom Quantity <span style={{ color: 'red' }}>{(source === 'csv') && '(Quantities in csv must all be "1")'}</span>
                    </h4>
                    <label className='d-flex align-items-center mb-0' style={{ gap: 5 }}>
                        <div>
                            <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                <Field type='checkbox' name='customQuantity.enabled' />
                                <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON' />
                            </label>
                        </div>
                    </label>
                </Row>

                {(values?.customQuantity?.enabled) && (
                    <Row>
                        <Col md='12' className='text-center'>
                            <div role='group' className='d-flex flex-column' aria-labelledby='my-radio-group'>
                                <Row className='d-flex align-items-center pl-3 pb-4 mb-4' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
                                    <Field disabled={disableFields} type='radio' name='customQuantity.type' value='minMax' />
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>Min: </small>
                                        <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name='customQuantity.min' />
                                    </Col>
                                    <Col md='2' />
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>Max: </small>
                                        <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name='customQuantity.max' />
                                    </Col>
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>{'Enter "0" for unlimited'}</small>
                                    </Col>
                                </Row>
                                <Row className='d-flex align-items-center pl-3 pb-4 mb-4' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
                                    <Field disabled={disableFields} type='radio' name='customQuantity.type' value='incrementBy' />
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>Start: </small>
                                        <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name='customQuantity.incrementStart' />
                                    </Col>
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>Increment: </small>
                                        <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name='customQuantity.incrementBy' />
                                    </Col>
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                        <small>Max: </small>
                                        <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name='customQuantity.incrementMax' />
                                    </Col>
                                    <Col md='2' className='d-flex flex-row align-items-center' style={{ gap: 10 }}>
                                    </Col>
                                </Row>
                                <Row className='pl-3'>
                                    <Field disabled={disableFields} type='radio' name='customQuantity.type' value='custom' />
                                    <Col md='3' className='d-flex flex-row' style={{ gap: 10 }}>
                                        <small style={{ paddingTop: 10 }}>Custom: </small>
                                        <FieldArray
                                            name={'customQuantity.quantities'}
                                            render={quantitiesArrayHelpers => (
                                                <>
                                                    <Col>
                                                        {
                                                            values.customQuantity.quantities.map((q, qIndex) => {
                                                                return (
                                                                    <>
                                                                        <Row className='d-flex mb-2' key={qIndex}>
                                                                            <Col>
                                                                                <Field disabled={disableFields} className='form-control form-control-sm form-control-alternative' type='number' onWheel={(e) => e.target.blur()} name={`customQuantity.quantities[${qIndex}]`} />

                                                                            </Col>
                                                                            <Col>
                                                                                <Row>
                                                                                    <Col md='12'>
                                                                                        <Button
                                                                                            color='secondary'
                                                                                            type='button'
                                                                                            onClick={() => quantitiesArrayHelpers.remove(qIndex)}
                                                                                        >
                                                                                            <div className='btn-inner--text text-red'>
                                                                                                <i 
                                                                                                    className='fa fa-minus'
                                                                                                />
                                                                                            </div>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        
                                                                        </Row>
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                        <Row className='d-flex align-items-start' style={{ paddingLeft: 15}}>
                                                            <a 
                                                                href='/' 
                                                                onClick={ (event) => {
                                                                    event.preventDefault();
                                                                    if(!disableFields) {
                                                                        quantitiesArrayHelpers.push(1);
                                                                    }
                                                                }}      
                                                            >
                                                                <span>
                                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                    Add new line
                                                                </span>
                                                            </a>
                                                        </Row>
                                                    </Col>
                                                </>
                                            )}/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};
 
export default CustomQuantity;