/*eslint-disable*/
import React, { useLayoutEffect } from "react";
import cloneDeep from "lodash/cloneDeep"

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

const DocumentForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  source,
  currency
}) => {

  useLayoutEffect(() => {
    if(items.length === 0){
      addItem()
    }
  }, [items])

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', amount: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  const addItem = () => {
    setItems(items.concat({
      name: '',
      printingColor: source === 'document'? 'Colour': '4C (Front)',
      amountDefault: {minQ: 0, amount: 0},
      amountGreater: {minQ: 0, amount: 0},
      amountBetween: [],
      variations: []
    }))
  }

  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="d-flex justify-content-center">
            <Col md="4">
              <div>
                <small className=" font-weight-bold">
                Product Base Price (<span className="text-uppercase">{currency}</span>)
                </small>
              </div>
              <FormGroup
                className={classnames("mb-0", {
                  focused: amountFocused
                }, {
                  "has-danger": errors.netAmount && touched.netAmount
                })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend" className="bg-secondary mr-2">
                    <InputGroupText className="bg-transparent">
                      <span className="text-uppercase">{currency}</span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Product Net Amount"
                    type="number"
                    id="netAmount"
                    value={Number(values.netAmount).toString()}
                    onChange={handleChange}
                    onFocus={e => setAmountFocused(true)}
                    onBlur={e => {
                      setAmountFocused(false);
                      handleBlur(e)
                    }}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
                {errors.netAmount && touched.netAmount && (
                  <div className="input-feedback">{errors.netAmount}</div>
                )}
              </FormGroup>
            </Col>
            {
              source !== 'document'? (
                <>
                  <Col md="4">
                    <div>
                      <small className=" font-weight-bold">
                      Data Merging Base Price (<span className="text-uppercase">{currency}</span>)
                      </small>
                    </div>
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend" className="bg-secondary mr-2">
                        <InputGroupText className="bg-transparent">
                          <span className="text-uppercase">{currency}</span>
                        </InputGroupText>
                      </InputGroupAddon>
                        <Input
                          placeholder="Merging fee"
                          type="number"
                          id="mergingFee"
                          value={Number(values.mergingFee).toString()}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <div>
                      <small className=" font-weight-bold">
                      Data Merging Fee (in %)
                      </small>
                    </div>
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Mergin Variable"
                          type="number"
                          id="merginVariable"
                          value={Number(values.merginVariable).toString()}
                          onChange={handleChange}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            %
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </>
              ): null
            }
          </Row>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Material</h4>
        <Button
          color="primary"
          type="button"
          onClick={() => addItem()}
        >
          <div className="btn-inner--text">
            <span>Add Material</span>
          </div>
        </Button>
      </div>

        {
          items.length > 0? 
            items.map((x, i) => {
              return (
                <div key={i}>
                  <Card className="shadow mb-4">
                    <CardBody className="rounded">
                      <div className="d-flex justify-content-end mb-3">
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => removeSticker(i)}
                        >
                          <div className="btn-inner--text text-red">
                            <i 
                              className="fa fa-trash"
                              style={{fontSize: '1rem'}}
                            />
                            <span>Delete</span>
                          </div>
                        </Button>
                      </div>
                      <div className="bg-product-highlight rounded p-4 mb-3">
                        <div className="product-max-width">
                          <Row>
                            <Col md="6">
                              <small className=" font-weight-bold">
                                Paper Size
                              </small>
                              <Input
                                className="form-control-alternative"
                                placeholder="Name"
                                type="text"
                                value={x.name}
                                onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                              />
                            </Col>
                            <Col md="6">
                              <small className=" font-weight-bold">
                                Print Color
                              </small>
                              <Input
                                className="form-control-alternative"
                                type="select"
                                value={x.printingColor}
                                onChange={(e) => itemOnChange(e.target.value, 'printingColor', i)}
                              >
                                {
                                  source === 'document'? (
                                    <>
                                      <option value={"Colour"}>
                                        Colour
                                      </option>
                                      <option value={"Black / Grayscale"}>
                                        Black / Grayscale
                                      </option>
                                    </>
                                  ): (
                                    <>
                                      <option value={"4C (Front)"}>
                                        4C (Front)
                                      </option>
                                      <option value={"4C (Both)"}>
                                        4C (Both)
                                      </option>
                                      <option value={"1C-Grayscale (Front)"}>
                                        1C-Grayscale (Front)
                                      </option>
                                      <option value={"1C-Grayscale (Both)"}>
                                        1C-Grayscale (Both)
                                      </option>
                                    </>
                                  )
                                }
                              </Input>      
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <Row className="mb-1">
                        <Col md="5">
                          <small className=" font-weight-bold">
                            Name of Material
                          </small>
                          
                        </Col>
                        <Col md="5">
                          <small className=" font-weight-bold">
                          Price per Printing Page
                          </small>
                        </Col>
                      </Row>
                      {
                        x.variations.map((x1, i1) => {
                          return (
                            <Row key={i1} className="mb-2">
                              <Col md="5">
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Name"
                                  type="text"
                                  value={x1.name}
                                  onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                />
                              </Col>
                              <Col md="5">
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Per Big Sticker Amount"
                                    type="number"
                                    value={Number(x1.amount).toString()}
                                    onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                    onWheel={ event => event.currentTarget.blur() }
                                  />
                                  <InputGroupAddon addonType="append" className="bg-secondary">
                                    <InputGroupText className="bg-transparent">
                                      <span className="text-uppercase">{currency}</span>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                              <Col md="2" className="d-flex justify-content-end align-items-center">
                                <Button
                                  color="secondary"
                                  type="button"
                                  size="sm"
                                  onClick={() => removeChild('variations', i, i1)}
                                >
                                  <div className="btn-inner--text text-red">
                                    <i 
                                      className="fa fa-minus"
                                    />
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      <Row>
                        <Col md="12">
                          <a 
                            href="/" 
                            onClick={ (event) => {
                              event.preventDefault();
                              pushStickerVar(i)
                            }} 
                          >
                            <span>
                              <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                              Add material
                            </span>
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              )
            }): null
        }
    </>
  );
}
 
export default DocumentForm;