import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
// nodejs library that concatenates classes
import classnames from 'classnames';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Modal,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap';

import { connect } from 'react-redux';
import { fetchGroups, pushGroups, updateGroups, removeGroups } from '../redux/actions/groupsActions';
import getSymbolFromCurrency from 'currency-symbol-map';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import client from '../feathers.js';
import Dropdown from '../components/Common/Dropdown.js';
import dayjs from 'dayjs';
import AvaiSources from '../components/Users/AvaiSources.js';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

const GroupsCreate = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ emailFocused, setEmailFocused ] = useState(false);
    const [ passwordFocused, setPasswordFocused ] = useState(false);
    const [ confirmPasswordFocused, setConfirmPasswordFocused ] = useState(false);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,

        actions: modalActions,
    } = useModal();

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    const onSubmit = async(value) => {
        value.username = value.email;
    
        client.authenticate()
            .then(()=>{
                const data = {
                    ...value,
                    superAdminId: props.userId,
                    isInternal: props.userInfo.isInternal,
                };
                return client.service('users').create(data);
            })
            .then((res) => {
                notificationOpen(true, 'success', 'User ' + res.email + ' Added Successfully!');
                props.history.push(`/${props.role}/users`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const setEndDate = (packageId, startDate, setFieldValue) => {
        if(packageId) {
            const packageDetails = props.packages.find(x => x._id === packageId);
            if (packageDetails) {
                if(startDate) {
                    setFieldValue('endDate', dayjs(startDate).add(packageDetails.duration, 'month').add(-1, 'd').format('YYYY-MM-DD'));
                } else {
                    setFieldValue('startDate', dayjs().format('YYYY-MM-DD'));
                    setFieldValue('endDate', dayjs().add(packageDetails.duration, 'month').add(-1, 'd').format('YYYY-MM-DD'));
                }
            }
        }
    };

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: '',
                        firstName: '',
                        lastName: '',
                        wablas_key: '',
                        wablas_domain: '',
                        companyName: '',
                        phoneNumber: '',
                        address: '',
                        state: '',
                        country: '',
                        postalCode: '',
                        numbOfMargins: 200,
                        numbOfActiveProduct: 200,
                        startDate: undefined,
                        endDate: undefined,
                        description: '',
                        role: 'admin',
                        currency: 'myr',
                        avaiSources: {
                            csv: true,
                            api: false,
                            apiSg: false,
                            apiEast: false,
                            poster: true,
                            looseSheet: true,
                            booklet: true,
                            product: true,
                            packaging: true,
                        }
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body py-0'>

                                    <Breadcrumb
                                        items={[
                                            {title: 'Users', link: '/superadmin/users'},
                                            {title: 'Add user'}
                                        ]}
                                    />

                                    <Card className='shadow mb-2'>
                                        <CardHeader className='py-3'>
                                            <h2 className='m-0'>User setting</h2>
                                        </CardHeader>
                                        <CardBody className='bg-product rounded'>
                                            <div>
                                                <Row>
                                                    <Col md='6'>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='email'
                                                        >
                                                            Email Address
                                                        </label>
                                                        <FormGroup
                                                            className={classnames('mb-3', {
                                                                focused: emailFocused
                                                            }, {
                                                                'has-danger': errors.email && touched.email
                                                            })}
                                                        >
                                                            <InputGroup className='input-group-alternative'>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <i className='ni ni-email-83' />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder='Email'
                                                                    type='email'
                                                                    id='email'
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    onFocus={e =>
                                                                        setEmailFocused(true)
                                                                    }
                                                                    onBlur={e => {
                                                                        setEmailFocused(false);
                                                                        handleBlur(e);
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            {errors.email && touched.email && (
                                                                <div className='input-feedback'>{errors.email}</div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='6'>
                                                        <FormGroup>
                                                            <label>About User</label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                placeholder='A few words about user ...'
                                                                rows='1'
                                                                id='description'
                                                                value={values.description}
                                                                onChange={handleChange} 
                                                                type='textarea'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='6'>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='Passowrd'
                                                        >
                                                            Password
                                                        </label>
                                                        <FormGroup
                                                            className={classnames({
                                                                focused: passwordFocused
                                                            }, {
                                                                'has-danger': errors.password && touched.password
                                                            })}
                                                        >
                                                            <InputGroup className='input-group-alternative'>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <i className='ni ni-lock-circle-open' />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder='Password'
                                                                    type='password'
                                                                    autoComplete='off'
                                                                    id='password'
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    onFocus={e =>
                                                                        setPasswordFocused(true)
                                                                    }
                                                                    onBlur={e => {
                                                                        setPasswordFocused(false);
                                                                        handleBlur(e);
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            {errors.password && touched.password && (
                                                                <div className='input-feedback'>{errors.password}</div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='6'>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='confirmPassword'
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <FormGroup
                                                            className={classnames({
                                                                focused: confirmPasswordFocused
                                                            }, {
                                                                'has-danger': errors.confirmPassword && touched.confirmPassword
                                                            })}
                                                        >
                                                            <InputGroup className='input-group-alternative'>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <i className='ni ni-lock-circle-open' />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder='Confirm Password'
                                                                    type='password'
                                                                    autoComplete='off'
                                                                    id='confirmPassword'
                                                                    value={values.confirmPassword}
                                                                    onChange={handleChange}
                                                                    onFocus={e =>
                                                                        setConfirmPasswordFocused(true)
                                                                    }
                                                                    onBlur={e => {
                                                                        setConfirmPasswordFocused(false);
                                                                        handleBlur(e);
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            {errors.confirmPassword && touched.confirmPassword && (
                                                                <div className='input-feedback'>{errors.confirmPassword}</div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='firstName'
                                                            >
                                                                First name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='firstName'
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                placeholder='First name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='lastName'
                                                            >
                                                                Last name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='lastName'
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                placeholder='Last name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='phoneNumber'
                                                            >
                                                                Contact Number
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='phoneNumber'
                                                                value={values.phoneNumber}
                                                                onChange={handleChange}
                                                                placeholder='Contact'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='companyName'
                                                            >
                                                                Company Name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='companyName'
                                                                value={values.companyName}
                                                                onChange={handleChange}
                                                                placeholder='Company Name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card> 

                                    <Row className='mt-4'>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='startDate'
                                                >
                                                    Start date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='startDate'
                                                    value={values.startDate}
                                                    onChange={(e) => [
                                                        handleChange(e), 
                                                        setEndDate(values.package, e.target.value, setFieldValue),
                                                    ]}
                                                    placeholder='Start Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='endDate'
                                                >
                                                    End date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='endDate'
                                                    value={values.endDate}
                                                    onChange={handleChange}
                                                    placeholder='End Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                    disabled={values.package !== 'Please Select' && values.package}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <AvaiSources values={values} handleChange={handleChange} setFieldValue={setFieldValue}/>

                                    <Row>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='package'
                                                >
                                                    Package
                                                </label>
                                                <Dropdown
                                                    name={'package'}
                                                    values={props.packages.map((x) => {
                                                        return {
                                                            value: x._id,
                                                            display: x.name
                                                        };
                                                    })}
                                                    setFieldValue={setFieldValue}
                                                    callback={(id) => setEndDate(id, values.startDate, setFieldValue)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='wablas_key'
                                                >
                                                    Custom WhatsApp API (wablas)
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <Input
                                                        className='form-control-alternative mr-2'
                                                        id='wablas_key'
                                                        value={values.wablas_key}
                                                        onChange={handleChange}
                                                        placeholder='eg: YTFGUHYUFGYUHIUHT^&TGUHUIHFTDTRDFYFYUGUGUGUGGIG'
                                                        type='text'
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='wablas_domain'
                                                >
                                                    WhatsApp Domain Url (wablas)
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <Input
                                                        className='form-control-alternative mr-2'
                                                        id='wablas_domain'
                                                        value={values.wablas_domain}
                                                        onChange={handleChange}
                                                        placeholder='eg: https://kudus.wablas.com'
                                                        type='text'
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>

                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={() => {
                                            props.history.push(`/${props.role}/users`);
                                        }}
                                    >
                                        x Cancel
                                    </Button>
                                    <Button
                                        color='primary' 
                                        type='submit'
                                    >
                                        + Save as Active
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packages: state.allPackages.data,
});
  
const mapDispatchToProps = {
    fetchGroups: fetchGroups,
    pushGroups: pushGroups,
    updateGroups: updateGroups,
    removeGroups: removeGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupsCreate);