import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import ComponentNumberInput from './ComponentNumberInput.js';
import ComponentTextInput from './ComponentTextInput.js';
import DynamicPriceInput from './DynamicPriceInput.js';
import CalculationType from './CalculationType.js';

import { withRouter } from 'react-router';
import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    InputGroup
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const PrintingCost = (props) => {
    const { isEdit, componentId, dynamicPriceData, componentsTypeId, userId, componentTypeName, onSubmit, duplicate, componentsTypeData, userInfo } = props;
    const [supportedSource, setSupportedSource] = useState(env.componentSources);
    const [existingComponent, setExistingComponent] = useState();
    const [sqftDynamicPrice] = useState(dynamicPriceData.filter((x) => x.type === 'sqft'));
    const [sizeComponents, setSizeComponents] = useState([]);

    useEffect(() => {
        
        let avaiSources = userInfo?.avaiSources || {};
        let tempEnv = env.componentSources.filter(x => {
            if(x.value === 'packaging'){if(avaiSources.packaging){ return x; }}
            else { return x; }
        });
        setSupportedSource(tempEnv.filter((x) => { return (x.value !== 'csv' && x.value !== 'api' && x.value !== 'packaging'); }));

    }, []);

    useEffect(() => {
        client.authenticate()
            .then(()=>{
                const sizeId = componentsTypeData.find((x) => x.name === 'Printing Size')?._id;
                return client.service('components').find({
                    query: {
                        userId,
                        componentsTypeId: sizeId,
                        applicableSource: 'product',
                        $sort: { createdAt: -1 },
                        $limit: 1000,
                    }
                });
            })
            .then((res) => {
                setSizeComponents(res.data.map((x) => ({
                    value: x._id,
                    display: `${x.code} - ${x.name}`
                })));
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    }, []);

    useEffect(() => {
        if (componentId) {
            client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, [componentId]);

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : '',
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    calcType: (existingComponent) ? existingComponent.calcType : 'Sheet',
                    listingType: (existingComponent) ? existingComponent.listingType : 'single',
                    data: (existingComponent) ? existingComponent.data : {
                        printingCost: [{
                            label: undefined,
                            minSqft: 0,
                            maxSqft: 0,
                            basePrice: 0,
                            pricePer: 0,
                            wastage: 0,
                            dynamicPriceId: undefined,
                            sizeId: undefined,
                        }]
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;

                    const renderPrintCostItems = (source, values, index, remove) => {
                        if (source.includes('poster')) {
                            return (
                                <Row key={index} className='m-0 align-items-center justify-content-start mb-1'>
                                    <Col md='3' className='pr-0'>
                                        <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                            <ComponentTextInput
                                                hasDrag
                                                placeholder='Label'
                                                location={`data.printingCost[${index}].label`}
                                            />
                                        </Row>
                                    </Col>
                                    <Col md='2' className='p-0'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Base Price'}
                                            location={`data.printingCost[${index}].basePrice`}
                                        />
                                    </Col>
                
                                    <Col md='3' className='p-0 pr-2'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Print Cost'}
                                            location={`data.printingCost[${index}].pricePer`}
                                            chipText={values.calcType}
                                        />
                                    </Col>
                                    <Col md='3' className='p-0 pr-2'>
                                        <DynamicPriceInput
                                            setFieldValue={setFieldValue}
                                            dynamicPriceData={sqftDynamicPrice.filter((x) => x.type === 'sqft')}
                                            location={`data.printingCost[${index}].dynamicPriceId`}
                                        />
                                    </Col>
                                    <Button 
                                        color='danger'
                                        className='btn-round'
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteIcon 
                                            width={14}
                                            height={14}
                                        />
                                    </Button>
                                </Row>
                            );
                        } else if (source.includes('product')) {
                            return (
                                <Row key={index} className='m-0 align-items-center justify-content-start mb-1'>
                                    <Col md='2' className='pr-0'>
                                        <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                            <ComponentTextInput
                                                hasDrag
                                                placeholder='Label'
                                                location={`data.printingCost[${index}].label`}
                                            />
                                        </Row>
                                    </Col>
                                    <Col md='3' className='p-0'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Base Price'}
                                            location={`data.printingCost[${index}].basePrice`}
                                        />
                                    </Col>
            
                                    <Col md='3' className='p-0 pr-2'>
                                        <ComponentNumberInput
                                            type={'number'}
                                            className='mr-2'
                                            title={'Print Cost'}
                                            location={`data.printingCost[${index}].pricePer`}
                                            chipText={values.calcType}
                                        />
                                    </Col>
                                    <Col md='3' className='p-0 pr-2'>
                                        <Row className={'m-0 d-flex align-items-center justify-content-between rounded'}>
                                            <div className='input-card d-flex align-items-center bg-white' style={{ width: 350 }}>
                                                <small className='mr-1 mb-0 w-50' >Size: </small>
                                                <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF' }}>
                                                    <Dropdown
                                                        className='bg-transparent'
                                                        setFieldValue={setFieldValue}
                                                        name={`data.printingCost[${index}].sizeId`}
                                                        values={sizeComponents}
                                                        width='100%'
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Button 
                                        color='danger'
                                        className='btn-round'
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteIcon 
                                            width={14}
                                            height={14}
                                        />
                                    </Button>
                                </Row>
                            );
                        } else {
                            return (
                                <Col md='12' className='p-0'>
                                    <Row key={index} className='m-0 align-items-center justify-content-start mb-1'>
                                        <Col md='3' className='pr-0'>
                                            <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                <ComponentTextInput
                                                    hasDrag
                                                    placeholder='Label'
                                                    location={`data.printingCost[${index}].label`}
                                                />
                                            </Row>
                                        </Col>
                                        <Col md='3' className='p-0'>
                                            <ComponentNumberInput
                                                type={'number'}
                                                className='mr-2'
                                                title={'Base Price'}
                                                location={`data.printingCost[${index}].basePrice`}
                                            />
                                        </Col>
                
                                        <Col md='3' className='p-0 pr-2'>
                                            <ComponentNumberInput
                                                type={'number'}
                                                className='mr-2'
                                                title={'Print Cost'}
                                                location={`data.printingCost[${index}].pricePer`}
                                                chipText={values.calcType}
                                            />
                                        </Col>
                                        <Col md='1'>
                                            <Button 
                                                key={'asd'}
                                                color='danger'
                                                className='btn-round'
                                                onClick={() => remove(index)}
                                            >
                                                <DeleteIcon 
                                                    width={14}
                                                    height={14}
                                                />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                        }
                    };
                    
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Applicable for:</h5>
                                                        <Dropdown
                                                            name={'applicableSource'}
                                                            setFieldValue={setFieldValue}
                                                            disabled={(existingComponent)}
                                                            values={[
                                                                ...supportedSource
                                                            ]}
                                                            width={'100%'}
                                                        />
                                                        {errors.applicableSource && touched.applicableSource ? (
                                                            <small className='text-danger xs'>{errors.applicableSource}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.calcType ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                1
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <CalculationType 
                                                            width={350}
                                                            background={'white'}
                                                            title={'Printing Cost Type*'}
                                                            hasSqft={((values.applicableSource) && values.applicableSource.includes('poster'))}
                                                            hasSheets={((values.applicableSource) && (!values.applicableSource.includes('poster') && !values.applicableSource.includes('product')))}
                                                            hasAbsolute={((values.applicableSource) && (values.applicableSource.includes('loose-sheet') || values.applicableSource.includes('product')))}
                                                        />
                                                    </Col>
                                                </Row>
                                                {values.applicableSource.includes('loose-sheet') && (
                                                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                        <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                            {values.listingType ? (
                                                                <DoneStepIcon/>
                                                            ) : (
                                                                <div className='d-flex align-items-center justify-content-center'
                                                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                    2
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col md='11'>
                                                            <div style={{ width: 350 }}>
                                                                <Row className='m-0 d-flex align-items-center'>
                                                                    <small className='mr-1 mb-1'>Listing type*</small>
                                                                </Row>
                                                                <Row className='m-0 px-3 d-flex align-items-center border rounded' style={{ background: 'white', justifyContent: 'space-evenly' }}>
                                                                    <label className='d-flex align-items-center mb-0 mr-2'>
                                                                        <Field style={{marginRight: '3px'}} type='radio' name='listingType' value='single' default/>
                                                                        <small className='mr-1 mb-0 w-10'>Single Dropdown</small>
                                                                    </label>
                                                                    <label className='d-flex align-items-center mb-0 mr-2'>
                                                                        <Field style={{marginRight: '3px'}} type='radio' name='listingType' value='listByQuantity' />
                                                                        <small className='mr-1 mb-0 w-10'>List By Quantity</small>
                                                                    </label>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {(values.data.printingCost.length > 0) ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                3
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <Col md='2' className='p-0'>
                                                                <small className='mr-5 mb-1'>Print Cost Details* </small>
                                                            </Col>
                                                        </Row>
                                                        <FieldArray
                                                            className='mb-3'
                                                            name='data.printingCost'
                                                            render={({ push, remove, move }) => (
                                                                <>
                                                                    <Col className='mb-3 p-0'>
                                                                        <DragDropContext onDragEnd={(result) => {
                                                                            if (!result.destination) {
                                                                                return;
                                                                            }
                        
                                                                            move(result.source.index, result.destination.index);
                                                                        }}>

                                                                            <Droppable droppableId='printingCost'>
                                                                                {(provided) => (
                                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                        {values.data.printingCost.map((costDetail, index) => (
                                                                                            <Draggable isDragDisabled={values.applicableSource?.includes('poster')} key={index} draggableId={`costDetail-${index}`} index={index}>
                                                                                                {(provided) => (
                                                                                                    <div
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                    >
                                                                                                        {renderPrintCostItems(values.applicableSource, values, index, remove)}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        ))}
                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext>
                                                                    </Col>

                                                                    <Row className='d-flex align-items-start mb-2' style={{ paddingLeft: 15}}>
                                                                        <a 
                                                                            href='/' 
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                push({
                                                                                    label: undefined,
                                                                                    basePrice: 0,
                                                                                    pricePer: 0,
                                                                                    wastage: 0
                                                                                });
                                                                            }}      
                                                                        >
                                                                            <span>
                                                                                <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                                Add Print Cost
                                                                            </span>
                                                                        </a>
                                                                    </Row>
                                                                    
                                                                </>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>

                                                {values.applicableSource && !values.applicableSource.includes('poster') && !values.applicableSource.includes('product') && (
                                                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                        <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                            {(values.dynamicPriceId) ? (
                                                                <DoneStepIcon/>
                                                            ) : (
                                                                <div className='d-flex align-items-center justify-content-center'
                                                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                    4
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col className='bg-transparent'>
                                                            <Row className='m-0 d-flex align-items-center'>
                                                                <small className='mr-1 mb-1'>Dynamic Price (optional) </small>
                                                            </Row>
                                                            <DynamicPriceInput
                                                                setFieldValue={setFieldValue}
                                                                dynamicPriceData={dynamicPriceData.filter((x) => x.type !== 'sqft')}
                                                                location={'dynamicPriceId'}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='modal-footer'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                        props.history.push(`/${props.role}/components`);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color='secondary' 
                                    type='submit'
                                    onClick={() => setFieldValue('status', 0)}
                                >
                                    Save as Draft
                                </Button>
                                <Button
                                    color='primary' 
                                    type='submit'
                                    onClick={() => setFieldValue('status', 1)}
                                >
                                    Save as Active
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
const mapStateToProps = state => ({
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:'',
});
  
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrintingCost));

