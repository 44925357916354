/*eslint-disable*/
import React, { useLayoutEffect } from "react";
import cloneDeep from "lodash/cloneDeep"
import PreSize from "./PreSize";

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  FormGroup,
  Button,
  Input,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from "reactstrap";

const PosterForm = ({
  amountFocused,
  setAmountFocused,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  items, 
  setItems,
  setFieldValue,
  currency
}) => {

  useLayoutEffect(() => {
    if(items.length === 0){
      addItem()
    }
  }, [items])

  const itemOnChange = (val, name, index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][name] = val
    
    setItems(cloneItems)
  }

  const removeSticker = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems.splice(index, 1)
    
    setItems(cloneItems)
  }

  const pushStickerVar = (index) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index]['variations'].push({ name: '', basePrice: 0, amount: 0, amountWastage: 0 })
    
    setItems(cloneItems)
  }

  const childOnChange = (val, child, name, index, index1) => {
    let cloneItems = cloneDeep(items)

    if(child === 'amountBetween' && name === 'maxQ'){
      if(cloneItems[index][child][index1+1]){
        cloneItems[index][child][index1+1]['minQ'] = (parseFloat(val) + 0.0001).toFixed(4) 
      }else{
        cloneItems[index]['amountGreater'].minQ = (parseFloat(val) + 0.0001).toFixed(4)
      }
      cloneItems[index][child][index1][name] = val
    }
    cloneItems[index][child][index1][name] = val
    
    setItems(cloneItems)
  }

  const removeChild = (child, index, index1) => {
    let cloneItems = cloneDeep(items)
    cloneItems[index][child].splice(index1, 1)
    
    setItems(cloneItems)
  }

  const addItem = () => {
      setItems(items.concat({
        name: '',
        basePrice: 0,
        width: {
          min: 0,
          max: 0,
          amt: 0,
        },
        height: {
          min: 0,
          max: 0,
          amt: 0,
        },
        amountDefault: {minQ: 0, amount: 0, amountWastage: 0},
        amountGreater: {minQ: 0, amount: 0, amountWastage: 0},
        amountBetween: [],
        variations: []
      }))
  }

  const addBetweenLine = (x, i) => {
    itemOnChange(x.amountBetween.concat({
      minQ: x.amountBetween.length > 0? (parseFloat(x.amountBetween[x.amountBetween.length-1]['maxQ']) + 0.0001).toFixed(4): (parseFloat(x.amountDefault.minQ) + 0.0001).toFixed(4),
      maxQ: 0,
      amount: 0,
      amountWastage: 0
    }), 'amountBetween', i)
  }

  const onChangeAmoutDefault = (e, index, x) => {
    let cloneItems = cloneDeep(items)
    let val = e.target.value? e.target.value: 0
    cloneItems[index]['amountDefault'] = {minQ: val, amount: x.amountDefault.amount}
    
    if(x.amountBetween.length > 0){
      cloneItems[index]['amountBetween'][0].minQ = (parseFloat(val) + 0.0001).toFixed(4)
    }else{
      cloneItems[index]['amountGreater'].minQ = (parseFloat(val) + 0.0001).toFixed(4)
    }

    setItems(cloneItems)
    
  }

  const duplicateItem = (x) => {
    let cloneItems = cloneDeep(items)

    if(x.amountBetween){
      if(x.amountBetween.length > 0){
        x.amountBetween.map(x => {
          delete x._id
          return x
        })
      }
    }

    if(x.variations){
      if(x.variations.length > 0){
        x.variations.map(x => {
          delete x._id
          return x
        })
      }
    }

    cloneItems = cloneItems.concat(x)
    
    setItems(cloneItems)
  }

  return (  
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row className="d-flex justify-content-center">
            <Col md="4">
              <div className="mb-2">
                <small className="font-weight-bold">
                  Product Base Price (<span className="text-uppercase">{currency}</span>)
                </small>
              </div>
              <FormGroup
                className={classnames("mb-3", {
                  focused: amountFocused
                }, {
                  "has-danger": errors.netAmount && touched.netAmount
                })}
              >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend" className="bg-secondary mr-2">
                    <InputGroupText className="bg-transparent">
                      <span className="text-uppercase">{currency}</span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Product Net Amount"
                    type="number"
                    id="netAmount"
                    value={Number(values.netAmount).toString()}
                    onChange={handleChange}
                    onFocus={e => setAmountFocused(true)}
                    onBlur={e => {
                      setAmountFocused(false);
                      handleBlur(e)
                    }}
                    onWheel={ event => event.currentTarget.blur() }
                  />
                </InputGroup>
                {errors.netAmount && touched.netAmount && (
                  <div className="input-feedback">{errors.netAmount}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="4">
              <div className="mb-2">
                <small className="font-weight-bold">
                Frontend display measure unit
                </small>
              </div>
              <FormGroup className="text-center">
                <UncontrolledDropdown nav inNavbar className="w-100">
                  <DropdownToggle caret color="secondary" className="w-100 text-uppercase">
                    {values.calcSqFtIn?values.calcSqFtIn: '-'}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <div className="editInputs-colors-scrollbar mac-scrollbar">
                      <DropdownItem 
                        className="text-uppercase" 
                        value={'mm'} 
                        onClick={() => {
                          setFieldValue('calcSqFtIn', 'mm')
                        }}
                      >
                        MM
                      </DropdownItem>
                      <DropdownItem 
                        className="text-uppercase" 
                        value={'ft'} 
                        onClick={() => {
                          setFieldValue('calcSqFtIn', 'inch')
                        }}
                      >
                        INCH
                      </DropdownItem>
                      <DropdownItem 
                        className="text-uppercase" 
                        value={'ft'} 
                        onClick={() => {
                          setFieldValue('calcSqFtIn', 'ft')
                        }}
                      >
                        FT
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <PreSize values={values} setFieldValue={setFieldValue} currency={currency} />

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Materials</h4>
        <Button
          color="primary"
          type="button"
          onClick={() => addItem()}
        >
          <div className="btn-inner--text">
            <span>Add Material</span>
          </div>
        </Button>
      </div>

      {
        items.length > 0? 
          items.map((x, i) => {
            return (
              <Card key={i} className="shadow mb-4">
                <CardBody>
                <div className="d-flex justify-content-end mb-2">
                  <Button
                    color="default"
                    type="button"
                    onClick={() => duplicateItem(x)}
                  >
                    <div className="btn-inner--text">
                      <i 
                        className="fa fa-copy"
                        style={{fontSize: '1rem'}}
                      />
                      <span>Duplicate</span>
                    </div>
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => removeSticker(i)}
                  >
                    <div className="btn-inner--text text-red">
                      <i 
                        className="fa fa-trash"
                        style={{fontSize: '1rem'}}
                      />
                      <span>Delete</span>
                    </div>
                  </Button>
                </div>
                <div className="bg-product-highlight rounded p-4 mb-3">
                  <div className="product-max-width">
                    <Row className="align-items-center">
                      <Col md="6">
                        <small className="font-weight-bold">Name of Material</small>
                        <Input
                          className="form-control-alternative"
                          placeholder="Name"
                          type="text"
                          bsSize="sm"
                          value={x.name}
                          onChange={(e) => itemOnChange(e.target.value, 'name', i)}
                        />
                      </Col>
                      <Col md="6">
                        <small className="font-weight-bold">
                          Material Base Price (<span className="text-uppercase">{currency}</span>)
                        </small>
                        <Input
                          className="form-control-alternative"
                          placeholder="Base Price"
                          type="number"
                          bsSize="sm"
                          value={Number(x.basePrice).toString()}
                          onChange={(e) => itemOnChange(Number(e.target.value), 'basePrice', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                  
                <h4>Dimmensions</h4>
                <div className="mb-5">
                  <Row className="mb-2">
                    <Col md="4">
                      <small className="font-weight-bold">Min Width (mm)</small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          placeholder="Min Width"
                          type="number"
                          bsSize="sm"
                          value={Number(x.width.min).toString()}
                          onChange={(e) => itemOnChange({min: e.target.value, max: x.width.max, amt: x.width.amt}, 'width', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            <span>mm</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col md="4">
                      <small className="font-weight-bold">Max Width (mm)</small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          placeholder="Max Width"
                          type="number"
                          bsSize="sm"
                          value={Number(x.width.max).toString()}
                          onChange={(e) => itemOnChange({max: e.target.value, min: x.width.min, amt: x.width.amt}, 'width', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            <span>mm</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col md="4">
                      <small className="font-weight-bold">
                        Min Amount (<span className="text-uppercase">{currency}</span>)
                      </small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          placeholder="Base Price"
                          type="number"
                          bsSize="sm"
                          value={Number(x.width.amt).toString()}
                          onChange={(e) => itemOnChange({amt: e.target.value, min: x.width.min, max: x.width.max}, 'width', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            <span className="text-uppercase">{currency}</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md="4">
                      <small className="font-weight-bold">Min Height (mm)</small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          placeholder="Min Height"
                          type="number"
                          bsSize="sm"
                          value={Number(x.height.min).toString()}
                          onChange={(e) => itemOnChange({min: e.target.value, max: x.height.max, amt: x.height.amt}, 'height', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            <span>mm</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col md="4">
                      <small className="font-weight-bold">Max Height (mm)</small>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          placeholder="Max Height"
                          type="number"
                          bsSize="sm"
                          value={Number(x.height.max).toString()}
                          onChange={(e) => itemOnChange({max: e.target.value, min: x.height.min, amt: x.height.amt}, 'height', i)}
                          onWheel={ event => event.currentTarget.blur() }
                        />
                        <InputGroupAddon addonType="append" className="bg-secondary">
                          <InputGroupText className="bg-transparent">
                            <span>mm</span>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    {/* <Col>
                      <small className="font-weight-bold">Min Amount</small>
                      <Input
                        className="form-control-alternative"
                        placeholder="Base Price"
                        type="number"
                        bsSize="sm"
                        value={Number(x.height.amt).toString()}
                        onChange={(e) => itemOnChange({amt: e.target.value, min: x.height.min, max: x.height.max}, 'height', i)}
                        onWheel={ event => event.currentTarget.blur() }
                      />
                    </Col> */}
                  </Row>
                </div>

                <div>
                  <div className="mb-5">
                    <h4>Printing Cost</h4>
                    <Row className="mb-2">
                      <Col md="10">
                        <Row>
                          <Col md="3">
                            <small className="font-weight-bold">
                              Min Sq Ft
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              Max Sq Ft
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              <span className="text-uppercase">Print {currency}</span>/Sq Ft
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              <span className="text-uppercase">Wastage {currency}</span>/Sq Ft
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md="10">
                        <Row>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Min Q"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountDefault.minQ).toString()}
                              onChange={(e) => onChangeAmoutDefault(e, i, x)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </Col>
                          <Col md="3"></Col>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Amount"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountDefault.amount).toString()}
                              onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amountWastage: x.amountDefault.amountWastage, amount: Number(e.target.value)}, 'amountDefault', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </Col>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Amount"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountDefault.amountWastage).toString()}
                              onChange={(e) => itemOnChange({minQ: x.amountDefault.minQ, amount: Number(x.amountDefault.amount), amountWastage: Number(e.target.value)}, 'amountDefault', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="2">
                      </Col>
                    </Row>

                    {
                      x.amountBetween.length > 0? 
                        x.amountBetween.map((x1, i1) => {
                          return (
                            <Row className="mb-2">
                              <Col md="10">
                                <Row>
                                  <Col md="3">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Min Q"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.minQ).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'minQ', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                      disabled
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Max Q"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.maxQ).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'maxQ', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Amount"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.amount).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'amount', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                    />
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Amount"
                                      type="number"
                                      bsSize="sm"
                                      value={Number(x1.amountWastage).toString()}
                                      onChange={(e) => childOnChange(Number(e.target.value), 'amountBetween', 'amountWastage', i, i1)}
                                      onWheel={ event => event.currentTarget.blur() }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="2" className="d-flex justify-content-end align-items-center">
                                <Button
                                  color="secondary"
                                  type="button"
                                  size="sm"
                                  onClick={() => removeChild('amountBetween', i, i1)}
                                >
                                  <div className="btn-inner--text text-red">
                                    <i 
                                      className="fa fa-minus"
                                    />
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          )
                        }): null
                      }

                    <Row className="mb-2">
                      <Col md="10">
                        <Row>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Min Q"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountGreater.minQ).toString()}
                              onChange={(e) => itemOnChange({minQ: e.target.value, amount: x.amountGreater.amount}, 'amountGreater', i)}
                              onWheel={ event => event.currentTarget.blur() }
                              disabled
                            />
                          </Col>
                          <Col md="3"></Col>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Amount"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountGreater.amount).toString()}
                              onChange={(e) => itemOnChange({minQ: x.amountGreater.minQ, amountWastage: x.amountGreater.amountWastage, amount: Number(e.target.value)}, 'amountGreater', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </Col>
                          <Col md="3">
                            <Input
                              className="form-control-alternative"
                              placeholder="Amount"
                              type="number"
                              bsSize="sm"
                              value={Number(x.amountGreater.amountWastage).toString()}
                              onChange={(e) => itemOnChange({minQ: x.amountGreater.minQ, amount: x.amountGreater.amount, amountWastage: Number(e.target.value)}, 'amountGreater', i)}
                              onWheel={ event => event.currentTarget.blur() }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="2">
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <a 
                          href="/" 
                          onClick={ (event) => {
                            event.preventDefault();
                            addBetweenLine(x, i)
                          }} 
                        >
                          <span>
                            <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                            Add new line
                          </span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                
                  <div>
                    <h4>Finishing Cost</h4>
                    <Row className="mb-2">
                      <Col md="10">
                        <Row>
                          <Col md="3">
                            <small className="font-weight-bold">
                              Name
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              Finishing Base Price(<span className="text-uppercase">{currency}</span>)
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              <span className="text-uppercase">{currency}</span>/Sq Ft
                            </small>
                          </Col>
                          <Col md="3">
                            <small className="font-weight-bold">
                              <span className="text-uppercase">Wastage {currency}</span>/Sq Ft
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>              
                    {
                      x.variations.map((x1, i1) => {
                        return (
                          <Row key={i1} className="mb-2">
                            <Col md="10">
                              <Row>
                                <Col md="3">
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Name"
                                    type="text"
                                    bsSize="sm"
                                    value={x1.name}
                                    onChange={(e) => childOnChange(e.target.value, 'variations', 'name', i, i1)}
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Base Price"
                                    type="number"
                                    bsSize="sm"
                                    value={Number(x1.basePrice).toString()}
                                    onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'basePrice', i, i1)}
                                    onWheel={ event => event.currentTarget.blur() }
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Per Sq Ft Amount"
                                    type="number"
                                    bsSize="sm"
                                    value={Number(x1.amount).toString()}
                                    onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amount', i, i1)}
                                    onWheel={ event => event.currentTarget.blur() }
                                  />
                                </Col>
                                <Col md="3">
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Per Sq Ft Amount"
                                    type="number"
                                    bsSize="sm"
                                    value={Number(x1.amountWastage).toString()}
                                    onChange={(e) => childOnChange(Number(e.target.value), 'variations', 'amountWastage', i, i1)}
                                    onWheel={ event => event.currentTarget.blur() }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col md="2" className="d-flex justify-content-end align-items-center">
                              <Button
                                color="secondary"
                                type="button"
                                size="sm"
                                onClick={() => removeChild('variations', i, i1)}
                              >
                                <div className="btn-inner--text text-red">
                                  <i 
                                    className="fa fa-minus"
                                  />
                                </div>
                              </Button>
                            </Col>
                          </Row>
                        )
                      })
                    }
                    <Row>
                      <Col md="12">
                        <a 
                          href="/" 
                          onClick={ (event) => {
                            event.preventDefault();
                            pushStickerVar(i)
                          }} 
                        >
                          <span>
                            <i className="mr-1 fa fa-plus-circle" style={{fontSize: '1rem'}}/>
                            Add new line
                          </span>
                        </a>
                      </Col>
                    </Row>      
                  </div>
                </div>
              </CardBody>
            </Card>
            )
          }): null
      }
    </>
  );
}
 
export default PosterForm;