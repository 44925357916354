//Action Types
export const FETCH_MARGIN_PROFILES = 'FETCH_MARGIN_PROFILES';
export const PUSH_MARGIN_PROFILES = 'PUSH_MARGIN_PROFILES';
export const UPDATE_MARGIN_PROFILES = 'UPDATE_MARGIN_PROFILES';
export const REMOVE_MARGIN_PROFILES = 'REMOVE_MARGIN_PROFILES';

//Action Creator
export const fetchMarginProfiles = (data) => ({
    type: FETCH_MARGIN_PROFILES,
    data: data
});

export const pushMarginProfiles = (data) => ({
    type: PUSH_MARGIN_PROFILES,
    data: data
});

export const updateMarginProfiles = (data) => ({
    type: UPDATE_MARGIN_PROFILES,
    data: data
});

export const removeMarginProfiles = (data) => ({
    type: REMOVE_MARGIN_PROFILES,
    data: data
});