import { create } from 'zustand';

const useReportStore = create((set) => ({
    componentTypeCurrentIndex: 0,
    componentTypeMaxIndex: 0,
    componentTypePerPage: 30,
    componentTypeData: [],

    componentCurrentIndex: 0,
    componentMaxIndex: 0,
    componentPerPage: 30,
    componentData: [],

    packagesCurrentIndex: 0,
    packagesMaxIndex: 0,
    packagesPerPage: 30,
    packagesData: [],

    actions: {
        setComponentTypeCurrentIndex: (data) => set((state) => ({ componentTypeCurrentIndex: data })),
        setComponentTypeMaxIndex: (data) => set((state) => ({ componentTypeMaxIndex: data })),
        setComponentTypePerPage: (data) => set((state) => ({ componentTypePerPage: data })),
        setComponentTypeData: (data) => set((state) => ({ componentTypeData: data })),

        setComponentCurrentIndex: (data) => set((state) => ({ componentCurrentIndex: data })),
        setComponentMaxIndex: (data) => set((state) => ({ componentMaxIndex: data })),
        setComponentPerPage: (data) => set((state) => ({ componentPerPage: data })),
        setComponentData: (data) => set((state) => ({ componentData: data })),

        setPackagesCurrentIndex: (data) => set((state) => ({ packagesCurrentIndex: data })),
        setPackagesMaxIndex: (data) => set((state) => ({ packagesMaxIndex: data })),
        setPackagesPerPage: (data) => set((state) => ({ packagesPerPage: data })),
        setPackagesData: (data) => set((state) => ({ packagesData: data })),
    },
}));

export const useReport = () => useReportStore((state) => state);
