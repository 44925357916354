import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import {
    Input,
    Card,
    CardBody,
    Row,
    Col,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,

} from 'reactstrap';

const CoverToContentPairingForm = ({
    coversToContentPairing,
    setCoverToContentPairing,
}) => {
    const [selectedCover, setSelectedCover] = useState('');
    const [contentList, setContentList] = useState([]);
    
    const [showCoversToContentPairing, setShowCoversToContentPairing] = useState(true);

    const toggleC2CPairing = () => {
        setShowCoversToContentPairing(!showCoversToContentPairing);
    };

    useEffect(() => {
        const cover = coversToContentPairing.find((item) => item.cover === selectedCover);

        if (cover) {
            setContentList(cover.content);
        }
    }, [selectedCover]);

    useEffect(() => {
        const newCoverToContentPairing = cloneDeep(coversToContentPairing);
        const coverIndex = newCoverToContentPairing.findIndex((item) => item.cover === selectedCover);

        if (newCoverToContentPairing[coverIndex]) {
            setContentList(newCoverToContentPairing[coverIndex].content);
        }
    }, [coversToContentPairing]);

    const onChangeC2C = (content, contentIndex, value) => {
        const newCoverToContentPairing = cloneDeep(coversToContentPairing);

        const coverIndex = newCoverToContentPairing.findIndex((item) => item.cover === selectedCover);
        newCoverToContentPairing[coverIndex].content[contentIndex].enabled = value;

        setCoverToContentPairing(newCoverToContentPairing);
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className='d-flex flex-direction-row align-items-center'>
                    <h2 className='mb-0 mr-3'>Cover to content pairing</h2>
                    <Button
                        color='secondary'
                        type='button'
                        onClick={toggleC2CPairing }
                    >
                        <div className='btn-inner--text'>
                            <span>{(showCoversToContentPairing) ? 'Hide' : 'Show'}</span>
                        </div>
                    </Button>
                </div>
            </div>

            {(showCoversToContentPairing) && (
                <Card className='shadow mb-4'>
                    <CardBody>
                        <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                            <Row className='d-flex flex-row align-items-center justify-content-start mb-2' style={{ gap: 15 }}>
                                <h4 style={{ margin: 0 }}>
                                    {'Select cover material: '}
                                </h4>
                                <UncontrolledDropdown nav inNavbar className='w-50'>
                                    <DropdownToggle caret color='default' className='w-50 text-uppercase'>
                                        {(selectedCover) ? selectedCover : 'Select Cover'}
                                    </DropdownToggle>
                                    <DropdownMenu className='w-50'>
                                        <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                            {coversToContentPairing.map((ccp) => {
                                                return (
                                                    <DropdownItem 
                                                        key={ccp.cover}
                                                        className='text-capitalize' 
                                                        value={ccp.cover} 
                                                        onClick={() => setSelectedCover(ccp.cover)}
                                                    >
                                                        {ccp.cover}
                                                    </DropdownItem>
                                                );
                                            })}
                                         
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Row>
                     
                            <Row className='mb-2 d-flex justify-content-center'>
                                <Col md='10'>
                                    {(contentList.length > 0) && (
                                        <>
                                            <Row
                                                key={'content-table-header'}
                                                className='d-flex justify-content-center align-items-center'
                                            >
                                                <Col md='3'>
                                                    <h4 className='font-weight-bold'>
                                                        Content name
                                                    </h4>
                                                </Col>
                                                <Col md='2'>
                                                    <h4 className='font-weight-bold'>
                                                        Enabled
                                                    </h4>
                                                </Col>
                                            </Row>
                                            {contentList.map((item, contentIndex) => {
                                                return (
                                                    <Row
                                                        key={`${item.name}`}
                                                        className='d-flex justify-content-center align-items-center'
                                                    >
                                                        <Col md='3' style={{ height: 34 }}>
                                                            <small className='font-weight-bold'>
                                                                {item.name}
                                                            </small>
                                                        </Col>
                                                        <Col md='2'>
                                                            <Input
                                                                style={{ position: 'relative', margin: 0 }}
                                                                type='checkbox'
                                                                checked={item.enabled}
                                                                onChange={(e) => onChangeC2C(item.name, contentIndex, e.target.checked)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </CardBody>
                </Card>
            )}


        </>
    );
};

export default CoverToContentPairingForm;
