export const convertUnit = (value, unitFrom, unitTo) => {
    if (unitFrom === 'mm' && unitTo === 'inch') return value * 0.0393701;
    if (unitFrom === 'mm' && unitTo === 'cm') return value * 0.1;
    if (unitFrom === 'mm' && unitTo === 'feet') return value * 0.00328084;
  
    if (unitFrom === 'inch' && unitTo === 'mm') return value * 25.4;
    if (unitFrom === 'inch' && unitTo === 'cm') return value * 2.54;
    if (unitFrom === 'inch' && unitTo === 'feet') return value * 0.0833333;
  
    if (unitFrom === 'cm' && unitTo === 'mm') return value * 10;
    if (unitFrom === 'cm' && unitTo === 'inch') return value * 0.393701;
    if (unitFrom === 'cm' && unitTo === 'feet') return value * 0.0328084;
  
    if (unitFrom === 'feet' && unitTo === 'mm') return value * 304.8;
    if (unitFrom === 'feet' && unitTo === 'inch') return value * 12;
    if (unitFrom === 'feet' && unitTo === 'cm') return value * 30.48;

    return value;
};

export default {
    convertUnit
};