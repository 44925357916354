//Action Types
export const FETCH_DYNAMIC_PRICE = 'FETCH_DYNAMIC_PRICE';
export const PUSH_DYNAMIC_PRICE = 'PUSH_DYNAMIC_PRICE';
export const UPDATE_DYNAMIC_PRICE = 'UPDATE_DYNAMIC_PRICE';
export const REMOVE_DYNAMIC_PRICE = 'REMOVE_DYNAMIC_PRICE';

//Action Creator
export const fetchDynamicPrice = (data) => ({
    type: FETCH_DYNAMIC_PRICE,
    data: data
});

export const pushDynamicPrice = (data) => ({
    type: PUSH_DYNAMIC_PRICE,
    data: data
});

export const updateDynamicPrice = (data) => ({
    type: UPDATE_DYNAMIC_PRICE,
    data: data
});

export const removeDynamicPrice = (data) => ({
    type: REMOVE_DYNAMIC_PRICE,
    data: data
});