import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import CoverPrintingComponent from './CoverPrintingComponent';
import OptionForm from './OptionForm';

import {
    Input,
    Card,
    CardBody,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
} from 'reactstrap';

const CoverMaterialForm = ({
    coverMaterials,
    setCoverMaterials,
    pushCoverMaterials,
    childOnChange,
    currency,
    setAmountFocused,
    handleBlur,
}) => {
    const [showCovers, setShowCovers] = useState(true);

    const pushCoverPrintings = (cmIndex) => {
        const coverMaterialsClone = cloneDeep(coverMaterials);
        coverMaterialsClone[cmIndex].coverPrintings.push({ name: '', price: 0 });

        setCoverMaterials(coverMaterialsClone);
    };

    const removeCoverPrinting = (cmIndex, cpIndex) => {
        const coverMaterialsClone = cloneDeep(coverMaterials);

        const before = [...coverMaterialsClone[cmIndex].coverPrintings.slice(0, cpIndex)];
        const after = [...coverMaterialsClone[cmIndex].coverPrintings.slice(cpIndex + 1)];

        const newArray = [...before, ...after];

        coverMaterialsClone[cmIndex].coverPrintings = newArray;
        setCoverMaterials(coverMaterialsClone);
    };

    const removeCoverMaterial = (cmindex) => {
        let coverMaterialsClone = cloneDeep(coverMaterials);

        const before = [...coverMaterialsClone.slice(0, cmindex)];
        const after = [...coverMaterialsClone.slice(cmindex + 1)];

        const newArray = [...before, ...after];

        coverMaterialsClone = newArray;
        setCoverMaterials(coverMaterialsClone);
    };

    const duplicateCoverMaterial = (cmindex) => {
        const coverMaterialsClone = cloneDeep(coverMaterials);

        coverMaterialsClone.push(coverMaterialsClone[cmindex]);
        setCoverMaterials(coverMaterialsClone);
    };

    const toggleCovers = () => {
        setShowCovers(!showCovers);
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className='d-flex flex-direction-row align-items-center'>
                    <h2 className='mb-0 mr-3'>Cover Material</h2>
                    <Button
                        color='secondary'
                        type='button'
                        onClick={toggleCovers}
                    >
                        <div className='btn-inner--text'>
                            <span>{(showCovers) ? 'Hide' : 'Show'}</span>
                        </div>
                    </Button>
                </div>
                <Button
                    color='primary'
                    type='button'
                    onClick={() => pushCoverMaterials()}
                >
                    <div className='btn-inner--text'>
                        <span>Add Cover Material</span>
                    </div>
                </Button>
            </div>

            {(showCovers) && (
                <>
                    {coverMaterials.map((cover, cmindex) => (
                        <Card key={`${cmindex}`} className='shadow mb-4'>
                            <CardBody>
                                <div className='d-flex justify-content-end mb-3'>
                                    <Button
                                        color='default'
                                        type='button'
                                        onClick={() => duplicateCoverMaterial(cmindex)}
                                    >
                                        <div className='btn-inner--text'>
                                            <i
                                                className='fa fa-copy'
                                                style={{ fontSize: '1rem' }}
                                            />
                                            <span>Duplicate</span>
                                        </div>
                                    </Button>
                                    <Button
                                        color='secondary'
                                        type='button'
                                        onClick={() => removeCoverMaterial(cmindex)}
                                    >
                                        <div className='btn-inner--text text-red'>
                                            <i
                                                className='fa fa-trash'
                                                style={{ fontSize: '1rem' }}
                                            />
                                            <span>Delete</span>
                                        </div>
                                    </Button>
                                </div>
                                <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                                    <Row className='mb-2'>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='4'>
                                                    <small className='font-weight-bold'>
                                                        {`Cover Material (${cmindex + 1})`}
                                                    </small>
                                                </Col>
                                                <Col md='4'>
                                                    <small className='font-weight-bold'>
                                                        Price / Sheet
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row key={cmindex} className='mb-2'>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='4'>
                                                    <Input
                                                        className='form-control-alternative'
                                                        placeholder='Name'
                                                        type='text'
                                                        bsSize='sm'
                                                        value={cover.name}
                                                        onChange={(e) => childOnChange(coverMaterials, setCoverMaterials, 'name', e.target.value, cmindex)}
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <InputGroup className='input-group-alternative'>
                                                        <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                            <InputGroupText className='bg-transparent'>
                                                                <span className='text-uppercase'>{currency}</span>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            type='number'
                                                            value={Number(cover.price)}
                                                            onChange={(e) => childOnChange(coverMaterials, setCoverMaterials, 'price', e.target.value, cmindex)}
                                                            onFocus={() => setAmountFocused(true)}
                                                            onBlur={(e) => {
                                                                setAmountFocused(false);
                                                                handleBlur(e);
                                                            }}
                                                            onWheel={(event) => event.currentTarget.blur()}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='4'>
                                                    <small className='font-weight-bold'>
                                                        Cover Printing
                                                    </small>
                                                </Col>
                                                <Col md='4'>
                                                    <small className='font-weight-bold'>
                                                        Price / Sheet
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        cover.coverPrintings.map((coverPrinting, cpindex) => (
                                            <CoverPrintingComponent
                                                key={`${coverPrinting.name}-${cpindex}`}
                                                coverPrinting={coverPrinting}
                                                cpindex={cpindex}
                                                removeCoverPrinting={removeCoverPrinting}
                                                coverMaterials={coverMaterials}
                                                cmindex={cmindex}
                                                setCoverMaterials={setCoverMaterials}
                                                currency={currency}
                                                setAmountFocused={setAmountFocused}
                                                handleBlur={handleBlur}
                                            />
                                        ))
                                    }
                                    <Row className='mb-3'>
                                        <Col md='12'>
                                            <a
                                                href='/'
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    pushCoverPrintings(cmindex);
                                                }}
                                            >
                                                <span>
                                                    <i className='mr-1 fa fa-plus-circle' style={{ fontSize: '1rem' }} />
                                                    Add new line
                                                </span>
                                            </a>
                                        </Col>
                                    </Row>
                                    <OptionForm
                                        key={`${cover.name}-${cmindex}`}
                                        parent={cover}
                                        cmindex={cmindex}
                                        parents={coverMaterials}
                                        setParents={setCoverMaterials}
                                        currency={currency}
                                        setAmountFocused={setAmountFocused}
                                        handleBlur={handleBlur}
                                        childOnChange={childOnChange}
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    ))}
                </>
            )}
        </>
    );
};

export default CoverMaterialForm;
