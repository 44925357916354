import {
    FETCH_COMPANY,
} from '../actions/companyActions';

const INITIAL_STATE = {
    data: {
        rateMYRtoSGD: 3.08,
        companyName: '',
        address: '',
        state: 'Wilayah Persekutuan (Kuala Lumpur)',
        country: 'Malaysia',
        postalCode: '40150',
        phoneNumber: '',
        countryCode: 'MY',
        personName: '',
        stateOrProvinceCode: '14',
        localDelivery_sm: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }],
        localDelivery_md: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }],
        localDelivery_lg: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }],
        sgDelivery_sm: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }],
        sgDelivery_md: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }],
        sgDelivery_lg: [{
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        },
        {
            minAmt: 0,
            amount_1: 0,
            amount_2: 0
        }]
    },
};

export default function companyReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_COMPANY:
            return {
                ...state,
                data:action.data,
            };
        default:
            return state;

    }
}