import React, { useState } from 'react';
import { Formik } from 'formik';

// reactstrap components
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const ActiveProducts = (props) => {
    const [ loading, setLoading ] = useState(false);

    const onSubmit = async(value) => {
        async function activeAllProduct(productsToActive){
            let processInsert = await productsToActive.map(async(x1) => {
                return await new Promise(resolve => {

                    client.authenticate()
                        .then(()=>{
                            return client.service('products').patch(x1._id, {
                                status: true
                            });
                        })
                        .then((res) => {
                            res.status = true;

                            props.updateProducts(res);
                            return resolve(res);
                        })
                        .catch((err)=>{
                            console.log(err.message);
                        });
                });
            });
      
            const result = await Promise.all(processInsert);
            if(result){
        
                let numOfActive = result.filter(e => e.source.includes('prefix')).length;
                props.setActiveProduct(props.activeProduct+numOfActive);

                setLoading(false);
                props.toggleModal();
                props.notificationOpen(true, 'success', 'Products Status Update Successfully');
            }
        }

        setLoading(true);
        client.authenticate()
            .then(async(auth)=>{
                let numbOfActiveProduct = props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5;

                let numOfActive = await client.service('products').find({
                    query: {
                        source: {
                            $in: [
                                'csv-prefix',
                                'sticker-prefix',
                                'certificate-prefix',
                                'document-prefix',
                                'poster-prefix',
                                'loose-sheet-prefix'
                            ]
                        },
                        status: true,
                        userId: props.userId,
                        $select: ['_id'],
                        $limit: numbOfActiveProduct
                    }
                });

      
                let products = [];
                props.checkboxChecked.map(async(x) => {
                    props.productsData.map(async(x1) => {
                        if(x === x1._id){
                            products.push(x1);
                        }
                        return x1;
                    });
                    return x;
                });
      
                if((numOfActive.data.length + products.filter(e => e.status === false).length ) <= props.numbOfActiveProduct){
                    activeAllProduct(products.filter(e => e.status === false));
                }else{
                    setLoading(false);
                    props.notificationOpen(true, 'danger', 'Exceed Number of Active Product!');
                }
            })
            .catch((err)=>{
                console.log(err);
                setLoading(false);
                props.notificationOpen(true, 'warning', err.message);
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Activate Products
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Row>
                                        <Col md='12' className='text-center'>
                                            Activate Products?
                                        </Col>
                                    </Row>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default ActiveProducts;