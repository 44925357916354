import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import OptionTypeComponent from './OptionTypeComponent';

import {
    Input,
    Card,
    CardBody,
    Row,
    Col,
    Button,
} from 'reactstrap';

const OptionForm = ({
    parent,
    cmindex,
    parents,
    setParents,
    childOnChange,
    currency,
    setAmountFocused,
    handleBlur,
}) => {
    
    const [ options, setOptions ] = useState(parent.options);

    const pushOptions = () => {
        let optionsClone = cloneDeep(options);
        optionsClone.push({
            label: '',
            optionType: []
        });

        setOptions(optionsClone);
    };

    const pushOptionType = (oindex) => {
        let optionsClone = cloneDeep(options);
        optionsClone[oindex].optionType.push({
            name: '',
            basePrice: 0,
            pricePerSheet: 0,
        });

        setOptions(optionsClone);
    };

    const removeOptionType = (oindex, otindex) => {
        let optionsClone = cloneDeep(options);

        const before = [...optionsClone[oindex].optionType.slice(0, otindex)];
        const after = [...optionsClone[oindex].optionType.slice(otindex + 1)];

        const newArray = [...before, ...after]; 

        optionsClone[oindex].optionType = newArray;
        setOptions(optionsClone);
    };

    const removeOption = (oindex) => {
        let optionsClone = cloneDeep(options);

        const before = [...optionsClone.slice(0, oindex)];
        const after = [...optionsClone.slice(oindex + 1)];

        const newArray = [...before, ...after]; 

        optionsClone = newArray;
        setOptions(optionsClone);
    };

    const duplicateOption = (oindex) => {
        let optionsClone = cloneDeep(options);
        optionsClone.push(optionsClone[oindex]);
        setOptions(optionsClone);
    };

    useEffect(() => {
        let parentsClone = cloneDeep(parents);
        parentsClone[cmindex].options = options;

        setParents(parentsClone);
    }, [options]);

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h2>Options</h2>
                <Button
                    color='primary'
                    type='button'
                    onClick={() => pushOptions()}
                >
                    <div className='btn-inner--text'>
                        <span>Add Options</span>
                    </div>
                </Button>
            </div>

            {options.map((option, index) => {
                return (
                    <Card key={`${cmindex}-${index}-option`} className='shadow mb-4'>
                        <CardBody>
                            <div className='d-flex justify-content-end mb-3'>
                                <Button
                                    color='default'
                                    type='button'
                                    onClick={() => duplicateOption(index)}
                                >
                                    <div className='btn-inner--text'>
                                        <i 
                                            className='fa fa-copy'
                                            style={{fontSize: '1rem'}}
                                        />
                                        <span>Duplicate</span>
                                    </div>
                                </Button>
                                <Button
                                    color='secondary'
                                    type='button'
                                    onClick={() => removeOption(index)}
                                >
                                    <div className='btn-inner--text text-red'>
                                        <i 
                                            className='fa fa-trash'
                                            style={{fontSize: '1rem'}}
                                        />
                                        <span>Delete</span>
                                    </div>
                                </Button>
                            </div>
                            <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                                <Row className='mb-2'>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='4'>
                                                <small className='font-weight-bold'>
                                                    Option Label
                                                </small>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='4'>
                                                <Input
                                                    className='form-control-alternative'
                                                    placeholder='Label'
                                                    type='text'
                                                    bsSize='sm'
                                                    value={option.label}
                                                    onChange={(e) => childOnChange(options, setOptions, 'label', e.target.value, index)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='4'>
                                                <small className='font-weight-bold'>
                                                    Option Type
                                                </small>
                                            </Col>
                                            <Col md='4'>
                                                <small className='font-weight-bold'>
                                                    Base Price
                                                </small>
                                            </Col>
                                            <Col md='4'>
                                                <small className='font-weight-bold'>
                                                    Price / Sheet
                                                </small>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    option.optionType.map((ot, otindex) => {
                                        return (
                                            <OptionTypeComponent 
                                                key={`${cmindex}-${ot._id}-${otindex}-optiontype`}
                                                optionType={ot}
                                                removeOptionType={removeOptionType}
                                                otindex={otindex}
                                                options={options}
                                                setOptions={setOptions}
                                                oindex={index}
                                                currency={currency}
                                                setAmountFocused={setAmountFocused}
                                                handleBlur={handleBlur}
                                            />
                                        );
                                    })
                                }
                                <Row>
                                    <Col md='12'>
                                        <a 
                                            href='/' 
                                            onClick={ (event) => {
                                                event.preventDefault();
                                                pushOptionType(index);
                                            }} 
                                        >
                                            <span>
                                                <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                Add new line
                                            </span>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </CardBody>
                    </Card>
                );
            })}
        </>
    );
};
 
export default OptionForm;