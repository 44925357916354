import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Yup from 'yup';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import ComponentNumberInput from './ComponentNumberInput.js';
import ComponentTextInput from './ComponentTextInput.js';

import { withRouter } from 'react-router';
import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
});

const ProductionTimeline = (props) => {
    const { isEdit, componentId, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [existingComponent, setExistingComponent] = useState();

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    allowAllSource: true,
                    data: (existingComponent) ? existingComponent.data : {
                        productionTimeline: [{
                            label: '',
                            percent: 0,
                            minAmount: 0,
                        }],
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;
                    
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-2 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.data.productionTimeline.length > 0 ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                1
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <Row className='m-0 mb-1 d-flex align-items-center'>
                                                            <Col md='5' className='p-0 m-0'>
                                                                <small className='mr-5 mb-1'>Production Timeline Details* </small>
                                                            </Col>
                                                            <Col md='5' className='p-0 m-0'>
                                                                <small className='ml-5 mb-1'>Or whichever is higher</small>
                                                            </Col>
                                                        </Row>
                                                        <FieldArray
                                                            className='mb-3'
                                                            name='data.productionTimeline'
                                                            render={({ push, remove, move }) => (
                                                                <>
                                                                    <Col className='mb-3 p-0'>
                                                                        <DragDropContext onDragEnd={(result) => {
                                                                            if (!result.destination) {
                                                                                return;
                                                                            }
                        
                                                                            move(result.source.index, result.destination.index);
                                                                        }}>

                                                                            <Droppable droppableId='productionTimeline'>
                                                                                {(provided) => (
                                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                        {values.data.productionTimeline.map((option, index) => (
                                                                                            <Draggable key={index} draggableId={`option-${index}`} index={index}>
                                                                                                {(provided) => (
                                                                                                    <div
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                    >
                                                                                                        <Col md='11' className='p-0'>
                                                                                                            <Row key={index} className='m-0 mb-1 align-items-center'>
                                                                                                                <Col md='4' className='pr-0'>
                                                                                                                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                                        <ComponentTextInput
                                                                                                                            hasDrag
                                                                                                                            placeholder={'Label'}
                                                                                                                            location={`data.productionTimeline[${index}].label`}
                                                                                                                        />
                                                                                                                    </Row>
                                                                                                                </Col>

                                                                                                                <Col md='3' className='p-0'>
                                                                                                                    <ComponentNumberInput
                                                                                                                        type={'number'}
                                                                                                                        className='mr-2'
                                                                                                                        title={'Percent'}
                                                                                                                        location={`data.productionTimeline[${index}].percent`}
                                                                                                                        chipText={'%'}
                                                                                                                    />
                                                                                                                </Col>

                                                                                                                <Col md='4' className='p-0'>
                                                                                                                    <ComponentNumberInput
                                                                                                                        type={'number'}
                                                                                                                        className='mr-2'
                                                                                                                        title={'Min Amount'}
                                                                                                                        location={`data.productionTimeline[${index}].minAmount`}
                                                                                                                    />
                                                                                                                </Col>

                                                                                                                <Col md='1'>
                                                                                                                    <Button 
                                                                                                                        key={'asd'}
                                                                                                                        color='danger'
                                                                                                                        className='btn-round'
                                                                                                                        onClick={() => remove(index)}
                                                                                                                    >
                                                                                                                        <DeleteIcon 
                                                                                                                            width={14}
                                                                                                                            height={14}
                                                                                                                        />
                                                                                                                    </Button>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        ))}
                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext>
                                                                    </Col>

                                                                    <Row className='d-flex align-items-start mb-2' style={{ paddingLeft: 15}}>
                                                                        <a 
                                                                            href='/' 
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                push({
                                                                                    label: undefined,
                                                                                    basePrice: undefined,
                                                                                    pricePer: undefined,
                                                                                });
                                                                            }}      
                                                                        >
                                                                            <span>
                                                                                <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                                Add new row
                                                                            </span>
                                                                        </a>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(ProductionTimeline);