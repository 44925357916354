//Action Types
export const FETCH_MARGINS = 'FETCH_MARGINS';
export const PUSH_MARGINS = 'PUSH_MARGINS';
export const UPDATE_MARGINS = 'UPDATE_MARGINS';
export const REMOVE_MARGINS = 'REMOVE_MARGINS';

//Action Creator
export const fetchMargins = (data) => ({
    type: FETCH_MARGINS,
    data: data
});

export const pushMargins = (data) => ({
    type: PUSH_MARGINS,
    data: data
});

export const updateMargins = (data) => ({
    type: UPDATE_MARGINS,
    data: data
});

export const removeMargins = (data) => ({
    type: REMOVE_MARGINS,
    data: data
});