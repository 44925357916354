//Action Types
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const PUSH_PRODUCTS = 'PUSH_PRODUCTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const MOVE_PRODUCTS = 'MOVE_PRODUCTS';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';

//Action Creator
export const fetchProducts = (data) => ({
    type: FETCH_PRODUCTS,
    data: data
});

export const pushProducts = (data) => ({
    type: PUSH_PRODUCTS,
    data: data
});

export const updateProducts = (data) => ({
    type: UPDATE_PRODUCTS,
    data: data
});

export const moveProducts = (data) => ({
    type: MOVE_PRODUCTS,
    data: data
});

export const removeProducts = (data) => ({
    type: REMOVE_PRODUCTS,
    data: data
});