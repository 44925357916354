import React from 'react';
// reactstrap components
import {
    Modal
} from 'reactstrap';

const ModalEmpty = (props) => {

    return ( 
        <>
            {/* Modal */}
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={() => props.toggleModal(!props.modalVisible)}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        {props.title}
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => props.toggleModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body pt-0'>
                    {props.content}
                </div>
            </Modal>
        </>
    );
};
 
export default ModalEmpty;