import React, { useState } from 'react';
import { Field, FieldArray } from 'formik';
import OptionTypeFieldArray from './OptionTypeFieldArray';

// reactstrap components
import {
    Button,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';

const OptionFieldArray = ({
    values,
    materialIndex,
    currency
}) => {
    const [ toggleContent, setToggleContent ] = useState(true);

    return (  
        <FieldArray
            name={`looseSheetData.materials[${materialIndex}].options`}
            render={optionArrayHelpers => (
                <>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <div className='d-flex flex-direction-row align-items-center'>
                            <h2 className='mb-0 mr-3'>Options</h2>
                            <a
                                type='button'
                                onClick={() => setToggleContent(!toggleContent)}
                            >
                                <div className='btn-inner--text'>
                                    {(toggleContent) ? (<i className='fa fa-chevron-down' />) : (<i className='fa fa-chevron-up' />)} 
                                </div>
                            </a>
                        </div>
                        <Button
                            color='primary'
                            type='button'
                            onClick={ (event) => {
                                event.preventDefault();
                                optionArrayHelpers.push({ label: '', optionType: [] });
                            }}                                                                      >
                            <div className='btn-inner--text'>
                                <span>Add Options</span>
                            </div>
                        </Button>
                    </div>

                    {(toggleContent) && values.map((option, oIndex) => {
                        return (
                            <Card key={`${oIndex}`} className='shadow mb-4'>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <Card className='shadow' style={{background: 'rgb(82, 95, 127, 0.2)'}}>
                                            <CardBody className='pl-4 pr-4 pt-2 pb-2'>
                                                <h4 className='mb-0'>{`(${materialIndex + 1}) Material: Option (${oIndex + 1})`}</h4>
                                            </CardBody>
                                        </Card>
                                        <div className='d-flex justify-content-end mb-3'>
                                            <Button
                                                color='default'
                                                type='button'
                                                onClick={ (event) => {
                                                    event.preventDefault();
                                                    optionArrayHelpers.push(option);
                                                }}                                            >
                                                <div className='btn-inner--text'>
                                                    <i 
                                                        className='fa fa-copy'
                                                        style={{fontSize: '1rem'}}
                                                    />
                                                    <span>Duplicate</span>
                                                </div>
                                            </Button>
                                            <Button
                                                color='secondary'
                                                type='button'
                                                onClick={() => optionArrayHelpers.remove(oIndex)}
                                            >
                                                <div className='btn-inner--text text-red'>
                                                    <i 
                                                        className='fa fa-trash'
                                                        style={{fontSize: '1rem'}}
                                                    />
                                                    <span>Delete</span>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    
                                    <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                                        <Row className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Option Label
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Label'
                                                            type='text'
                                                            bsSize='sm'
                                                            name={`looseSheetData.materials[${materialIndex}].options[${oIndex}].label`}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Option Type
                                                        </small>
                                                    </Col>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Base Price
                                                        </small>
                                                    </Col>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Price / Sheet
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <OptionTypeFieldArray
                                            values={option.optionType}
                                            materialIndex={materialIndex}
                                            optionIndex={oIndex}
                                            currency={currency}
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        );
                    })}
                </>
            )} />
    );
};
 
export default OptionFieldArray;