import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchGroups, pushGroups, updateGroups, removeGroups } from '../redux/actions/groupsActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik } from 'formik';
import * as Yup from 'yup';
// nodejs library that concatenates classes
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Modal,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    UncontrolledTooltip
} from 'reactstrap';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import { useParams } from 'react-router-dom';

import client from '../feathers.js';
import Dropdown from '../components/Common/Dropdown.js';
import dayjs from 'dayjs';
import AvaiSources from '../components/Users/AvaiSources.js';

const GroupsCreate = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const { id } = useParams();
    const [copiedText, setCopiedText] = useState();
    const [ dataEdit, setDataEdit ] = useState({});
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        client.authenticate()
            .then(()=>{
                return client.service('users').get(id);
            })
            .then((res)=>{
                setDataEdit(res);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    }, [id]);


    const setEndDate = (packageId, startDate, setFieldValue) => {
        if(packageId) {
            const packageDetails = props.packages.find(x => x._id === packageId);
            if (packageDetails) {
                if(startDate) {
                    setFieldValue('endDate', dayjs(startDate).add(packageDetails.duration, 'month').add(-1, 'd').format('YYYY-MM-DD'));
                } else {
                    setFieldValue('startDate', dayjs().format('YYYY-MM-DD'));
                    setFieldValue('endDate', dayjs().add(packageDetails.duration, 'month').add(-1, 'd').format('YYYY-MM-DD'));
                }
            }
        }
    };


    const onSubmit = async(value) => {
    
        value.status = (value.status === 'true' || value.status === true) || false;
        value.package = (value.package === 'Please Select') ? null : value.package;

        client.authenticate()
            .then(()=>{
                return client.service('users').patch(dataEdit._id, value);
            })
            .then((res) => {
                res.totalProducts = dataEdit.totalProducts;
                notificationOpen(true, 'success', 'User ' + res.email + ' Edited Successfully!');
                props.history.push(`/${props.role}/users`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };


    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName: dataEdit.firstName,
                        lastName: dataEdit.lastName,
                        wablas_key: dataEdit.wablas_key,
                        wablas_domain: dataEdit.wablas_domain,
                        companyName: dataEdit.companyName,
                        phoneNumber: dataEdit.phoneNumber,
                        address: dataEdit.address,
                        state: dataEdit.state,
                        country: dataEdit.country,
                        postalCode: dataEdit.postalCode,
                        numbOfMargins: dataEdit.numbOfMargins,
                        numbOfActiveProduct: dataEdit.numbOfActiveProduct,
                        description: dataEdit.description,
                        currency: dataEdit.currency?dataEdit.currency:'myr',
                        status: dataEdit?.status?.toString()?dataEdit.status:true,
                        startDate: dataEdit.startDate ? dayjs(dataEdit.startDate).format('YYYY-MM-DD') : undefined,
                        endDate: dataEdit.endDate ? dayjs(dataEdit.endDate).format('YYYY-MM-DD') : undefined,
                        package: dataEdit.package,
                        avaiSources: {
                            csv: dataEdit?.avaiSources?.csv,
                            api: dataEdit?.avaiSources?.api,
                            apiSg: dataEdit?.avaiSources?.apiSg,
                            apiEast: dataEdit?.avaiSources?.apiEast,
                            poster: dataEdit?.avaiSources?.poster,
                            looseSheet: dataEdit?.avaiSources?.looseSheet,
                            booklet: dataEdit?.avaiSources?.booklet,
                            product: dataEdit?.avaiSources?.product,
                            packaging: dataEdit?.avaiSources?.packaging,
                        }
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body py-0'>

                                    <Breadcrumb
                                        items={[
                                            {title: 'Users', link: '/superadmin/users'},
                                            {title: 'Edit user'}
                                        ]}
                                    />

                                    <Card className='shadow mb-2'>
                                        <CardHeader className='py-3'>
                                            <h2 className='m-0'>User setting</h2>
                                        </CardHeader>
                                        <CardBody className='bg-product rounded'>
                                            <div>
                                                <Row>
                                                    <Col md='6'>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='email'
                                                        >
                                                            Email Address
                                                        </label>
                                                        <FormGroup>
                                                            <InputGroup className='input-group-alternative'>
                                                                <InputGroupAddon addonType='prepend'>
                                                                    <InputGroupText>
                                                                        <i className='ni ni-email-83' />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder='Email'
                                                                    type='email'
                                                                    id='email'
                                                                    defaultValue={dataEdit.email}
                                                                    disabled
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='6'>
                                                        <FormGroup>
                                                            <label>About User</label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                placeholder='A few words about user ...'
                                                                rows='1'
                                                                id='description'
                                                                value={values.description}
                                                                onChange={handleChange} 
                                                                type='textarea'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='firstName'
                                                            >
                                                                First name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='firstName'
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                placeholder='First name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='lastName'
                                                            >
                                                                Last name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='lastName'
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                placeholder='Last name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='phoneNumber'
                                                            >
                                                                Contact Number
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='phoneNumber'
                                                                value={values.phoneNumber}
                                                                onChange={handleChange}
                                                                placeholder='Contact'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='3'>
                                                        <FormGroup>
                                                            <label
                                                                className='form-control-label'
                                                                htmlFor='companyName'
                                                            >
                                                                Company Name
                                                            </label>
                                                            <Input
                                                                className='form-control-alternative'
                                                                id='companyName'
                                                                value={values.companyName}
                                                                onChange={handleChange}
                                                                placeholder='Company Name'
                                                                type='text'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card> 

                                    <Row className='mt-4'>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='startDate'
                                                >
                                                    Start date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='startDate'
                                                    value={values.startDate}
                                                    onChange={(e) => [
                                                        handleChange(e), 
                                                        setEndDate(values.package, e.target.value, setFieldValue),
                                                    ]}
                                                    placeholder='Start Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='endDate'
                                                >
                                                    End date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='endDate'
                                                    value={values.endDate}
                                                    onChange={handleChange}
                                                    placeholder='End Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                    disabled={values.package !== 'Please Select' && values.package}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <AvaiSources values={values} handleChange={handleChange} setFieldValue={setFieldValue}/>

                                    <Row>
                                        <Col md='3'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='package'
                                                >
                                                    Package
                                                </label>
                                                <Dropdown
                                                    name={'package'}
                                                    values={props.packages.map((x) => {
                                                        return {
                                                            value: x._id,
                                                            display: x.name
                                                        };
                                                    })}
                                                    setFieldValue={setFieldValue}
                                                    callback={(id) => setEndDate(id, values.startDate, setFieldValue)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='wablas_key'
                                                >
                                                    Custom WhatsApp API (wablas)
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <Input
                                                        className='form-control-alternative mr-2'
                                                        id='wablas_key'
                                                        value={values.wablas_key}
                                                        onChange={handleChange}
                                                        placeholder='eg: YTFGUHYUFGYUHIUHT^&TGUHUIHFTDTRDFYFYUGUGUGUGGIG'
                                                        type='text'
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='wablas_domain'
                                                >
                                                    WhatsApp Domain Url (wablas)
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <Input
                                                        className='form-control-alternative mr-2'
                                                        id='wablas_domain'
                                                        value={values.wablas_domain}
                                                        onChange={handleChange}
                                                        placeholder='eg: https://kudus.wablas.com'
                                                        type='text'
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                >
                                                    Wordpress API
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <Input
                                                        className='form-control-alternative mr-2'
                                                        defaultValue={dataEdit._id}
                                                        onChange={handleChange}
                                                        placeholder='eg: YTFGUHYUFGYUHIUHT^&TGUHUIHFTDTRDFYFYUGUGUGUGGIG'
                                                        type='text'
                                                        disabled
                                                    />
                                                    <CopyToClipboard
                                                        text={dataEdit?._id}
                                                        onCopy={() => setCopiedText(dataEdit?._id)}
                                                    >
                                                        <Button
                                                            color='secondary'
                                                            data-clipboard-text='time-alarm'
                                                            id='tooltip982655500'
                                                            type='button'
                                                        >
                                                            Copy
                                                        </Button>
                                                    </CopyToClipboard>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        trigger='hover focus'
                                                        target='tooltip982655500'
                                                    >
                                                        {copiedText === dataEdit?._id
                                                            ? 'This was Copied!'
                                                            : 'Copy To Clipboard'}
                                                    </UncontrolledTooltip>
                                                    {/* <Button
                                                        color='secondary'
                                                        data-dismiss='modal'
                                                        type='button'
                                                    >
                                                        Copy
                                                    </Button> */}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>

                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={() => {
                                            props.history.push(`/${props.role}/users`);
                                        }}
                                    >
                                        x Cancel
                                    </Button>
                                    <Button
                                        color='primary' 
                                        type='submit'
                                    >
                                        + Save as Active
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packages: state.allPackages.data,
});
  
const mapDispatchToProps = {
    fetchGroups: fetchGroups,
    pushGroups: pushGroups,
    updateGroups: updateGroups,
    removeGroups: removeGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupsCreate);