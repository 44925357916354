import React, { useState, useEffect } from 'react';

import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
} from 'reactstrap';

const ContentPrintingComponent = ({
    contentPrinting,
    cpindex,
    removeContentPrinting,
    contentMaterials,
    cmindex,
    currency,
    setAmountFocused,
    handleBlur,
}) => {

    const [ _contentPrinting, setContentPrinting ] = useState(contentPrinting);

    const onChange = (variable, value) => {
        setContentPrinting({
            ..._contentPrinting,
            [variable]: value,
        });
    };

    useEffect(() => {
        contentMaterials[cmindex].contentPrintings[cpindex] = _contentPrinting;
    }, [_contentPrinting]);

    return (
        <Row key={`${cpindex}`} className='mb-2'>
            <Col md='10'>
                <Row>
                    <Col md='4'>
                        <Input
                            className='form-control-alternative'
                            placeholder='Label'
                            type='text'
                            bsSize='sm'
                            value={_contentPrinting.name}
                            onChange={(e) => onChange('name', e.target.value)}
                        />
                    </Col>
                    <Col md='4'>
                        <InputGroup className='input-group-alternative'>
                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                <InputGroupText className='bg-transparent'>
                                    <span className='text-uppercase'>{currency}</span>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type='number'
                                value={Number(_contentPrinting.price)}
                                onChange={(e) => onChange('price', e.target.value)}
                                onFocus={() => setAmountFocused(true)}
                                onBlur={e => {
                                    setAmountFocused(false);
                                    handleBlur(e);
                                }}
                                onWheel={ event => event.currentTarget.blur() }
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
            <Col md='2' className='d-flex justify-content-end align-items-center'>
                <Button
                    color='secondary'
                    type='button'
                    size='sm'
                    onClick={() => removeContentPrinting(cmindex, cpindex)}
                >
                    <div className='btn-inner--text text-red'>
                        <i 
                            className='fa fa-minus'
                        />
                    </div>
                </Button>
            </Col>
        </Row>
    );
};
 
export default ContentPrintingComponent;