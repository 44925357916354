/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import './assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

import './assets/css/index.css';

import SuperAdminLayout from './layouts/SuperAdmin';
import WhiteLabelSuperAdminLayout from './layouts/WhiteLabelSuperAdmin';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';

import Template from './views/Template';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Switch>
                    <Route path='/whitelabelsuperadmin' render={(props) => <WhiteLabelSuperAdminLayout {...props} />} />
                    <Route path='/superadmin' render={(props) => <SuperAdminLayout {...props} />} />
                    <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
                    <Route path='/auth' render={(props) => <AuthLayout {...props} />} />
                    <Route path='/template/:id' component={Template} />
                    <Redirect from='/' to='/auth' />
                </Switch>
            </PersistGate>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);
