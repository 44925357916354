import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
} from 'reactstrap';

const OptionTypeComponent = ({
    optionType,
    removeOptionType,
    otindex,
    options,
    setOptions,
    oindex,
    currency,
    setAmountFocused,
    handleBlur,
}) => {
    const [_optionType, setOptionType] = useState(optionType);

    const onChange = (variable, value) => {
        setOptionType({
            ..._optionType,
            [variable]: value,
        });
    };

    useEffect(() => {
        const optionsClone = cloneDeep(options);
        optionsClone[oindex].optionType[otindex] = _optionType;

        setOptions(optionsClone);
    }, [_optionType]);

    return (
        <Row key={`${oindex}-${otindex}-optiontype-component`} className='mb-2'>
            <Col md='10'>
                <Row key={`${oindex}-${otindex}-optiontype-component-inner`}>
                    <Col md='4'>
                        <Input
                            className='form-control-alternative'
                            placeholder='Name'
                            type='text'
                            bsSize='sm'
                            value={_optionType.name}
                            onChange={(e) => onChange('name', e.target.value)}
                        />
                    </Col>
                    <Col md='4'>
                        <InputGroup className='input-group-alternative'>
                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                <InputGroupText className='bg-transparent'>
                                    <span className='text-uppercase'>{currency}</span>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type='number'
                                value={Number(_optionType.basePrice)}
                                onChange={(e) => onChange('basePrice', e.target.value)}
                                onFocus={() => setAmountFocused(true)}
                                onBlur={(e) => {
                                    setAmountFocused(false);
                                    handleBlur(e);
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                            />
                        </InputGroup>
                    </Col>
                    <Col md='4'>
                        <InputGroup className='input-group-alternative'>
                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                <InputGroupText className='bg-transparent'>
                                    <span className='text-uppercase'>{currency}</span>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type='number'
                                value={Number(_optionType.pricePerSheet)}
                                onChange={(e) => onChange('pricePerSheet', e.target.value)}
                                onFocus={() => setAmountFocused(true)}
                                onBlur={(e) => {
                                    setAmountFocused(false);
                                    handleBlur(e);
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
            <Col md='2' className='d-flex justify-content-end align-items-center'>
                <Button
                    color='secondary'
                    type='button'
                    size='sm'
                    onClick={() => removeOptionType(oindex, otindex)}
                >
                    <div className='btn-inner--text text-red'>
                        <i
                            className='fa fa-minus'
                        />
                    </div>
                </Button>
            </Col>
        </Row>
    );
};

export default OptionTypeComponent;
