import React from 'react';

// reactstrap components
import { Alert } from 'reactstrap';

const AlertType = (props) => {
    if(props.color === 'primary'){
        return (
            <Alert color='primary' fade={false}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-like-2' />
                </span>
                <span className='alert-inner--text'>
                    <strong>Primary!</strong> {props.message}!
                </span>
            </Alert>
        );
    }else if(props.color === 'secondary'){
        return (
            <Alert color='secondary' fade={false}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-like-2' />
                </span>
                <span className='alert-inner--text'>
                    <strong>Secondary!</strong> {props.message}!
                    out!
                </span>
            </Alert>
        );
    }else if(props.color === 'success'){
        return (
            <Alert color='success' fade={true}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-like-2' />
                </span>
                <span className='alert-inner--text ml-1'>
                    <strong>Success!</strong> {props.message}!
                </span>
            </Alert>
        );
    }else if(props.color === 'info'){
        return (
            <Alert color='info' fade={true}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-bell-55' />
                </span>
                <span className='alert-inner--text ml-1'>
                    <strong>Info!</strong> {props.message}!
                </span>
            </Alert>
        );
    }else if(props.color === 'warning'){
        return (
            <Alert color='warning' fade={true}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-bell-55' />
                </span>
                <span className='alert-inner--text ml-1'>
                    <strong>Warning!</strong> {props.message}!
                </span>
            </Alert>
        );
    }else if(props.color === 'danger'){
        return (
            <Alert color='danger' fade={true}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-support-16' />
                </span>
                <span className='alert-inner--text ml-1'>
                    <strong>Danger!</strong> {props.message}!
                </span>
            </Alert>
        );
    }else{
        return (
            <Alert className='alert-default' fade={false}>
                <button type='button' className='close' onClick={() => props.handleOpen(false)}>
                    <span className='text-white'>×</span>
                </button>
                <span className='alert-inner--icon'>
                    <i className='ni ni-like-2' />
                </span>
                <span className='alert-inner--text'>
                    <strong>Default!</strong> {props.message}!
                </span>
            </Alert>
        );
    }
};

const Alerts = (props) => {
    return ( 
        <>
            {props.isOpen? (
                <AlertType isOpen={props.isOpen} handleOpen={props.handleOpen} color={props.color} message={props.message}/>
            ): null}
        </>
    );
};
 
export default Alerts;