import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/trash.svg';
import { withRouter } from 'react-router';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/common/search.svg';

// reactstrap components
import {
    Table,
    Card,
    CardHeader,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    InputGroup,
    CardBody
} from 'reactstrap';

const SelectedUsers = ({
    usersData,
    displayUsersData,
    setDisplayUsersData,
    setUsersData,
    // tableSorting,
    // sortList,
    notificationOpen,
    history,
    role,
    dataEdit,
    groupId
}) => {
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ PAGESIZE, setPAGESIZE ] = useState(30);
    const [ pagination, setPagination ] = useState({});

    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            displayUsersData.map(x => {
                cloneCheckbox.push(x._id);
                return x;
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const removeUser = (id) => {
        let cloneOption = cloneDeep(usersData);
        let findId = cloneOption.findIndex(e => e._id === id);
        if(findId !== -1){
            cloneOption.splice(findId, 1);   
            setUsersData(cloneOption);
            setDisplayUsersData(cloneOption);
        }
    };
    
    useEffect(() => {
        fetchDisplayUserData(0, 1, PAGESIZE);
    }, [usersData]);

    const fetchDisplayUserData = (skip, current_page, pageSize) => {
        setPagination(paginationCount(usersData.length, current_page, pageSize));
        setDisplayUsersData(cloneDeep(usersData).splice(skip, pageSize));
    };

    function paginationCount(length, currentPage, pageSize) {
        return {
            total: length,
            per_page: pageSize,
            current_page: currentPage,
            last_page: Math.ceil(length / pageSize),
            from: ((currentPage - 1) * pageSize) + 1,
            to: currentPage * pageSize
        };
    }

    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                fetchDisplayUserData((pagination.current_page-2)*PAGESIZE, pagination.current_page-1, PAGESIZE);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i, index) => {
                        return (
                            <PaginationItem key={index} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        fetchDisplayUserData((i-1)*PAGESIZE, i, PAGESIZE);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                fetchDisplayUserData((pagination.current_page)*PAGESIZE, pagination.current_page+1, PAGESIZE);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            <Row className='m-1 mt-3'>
                <h2 className='mb-0'>{dataEdit?.groupName}</h2>
            </Row>
            <Card className='shadow'>
                <Card>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='4'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <SearchIcon height={20} className='mx-1'/>
                                    <Input
                                        placeholder='Search User Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        onChange={(e) => {
                                            if(e.target.value){
                                                let data = cloneDeep(usersData).filter((i) => i.email
                                                    .toLowerCase()
                                                    .includes(e.target.value.toLowerCase()));
                        
                                                setDisplayUsersData(data);
                                                setPagination(paginationCount(data.length, 1, PAGESIZE));
                                            }else{
                                                setDisplayUsersData(usersData);
                                                setPagination(paginationCount(usersData.length, 1, PAGESIZE));
                                            } 
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md='8' className='text-right'>
                                <Button
                                    color='primary'
                                    onClick={() => history.push(`/${role}/groups-users-add/${groupId}`)}
                                >
                                    <span className='btn-inner--text'>+ New Member</span>
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody className='p-0'>
                                <div className='table-responsive mac-scrollbar'>
                                    <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                        <thead>
                                            <tr>
                                                <th scope='col'>
                                                    <input
                                                        className=''
                                                        type='checkbox'
                                                        id='rememberMe'
                                                        // checked={values.rememberMe}
                                                        // value={values.rememberMe}
                                                        onChange={(e) => handleCheckAll(e.target.checked)}
                                                    />
                                                </th>
                                                <th scope='col'>
                                                    Added Date
                                                </th>
                                                <th scope='col'>
                                                    Email
                                                </th>
                                                <th scope='col'>
                                                    First Name
                                                </th>
                                                <th scope='col'>
                                                    Last Name
                                                </th>
                                                <th scope='col' className='text-right'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayUsersData.length > 0? (
                                                displayUsersData.map((v, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope='row'>
                                                                <input
                                                                    className=''
                                                                    type='checkbox'
                                                                    id={v._id}
                                                                    checked={checkboxChecked.includes(v._id)}
                                                                    value={checkboxChecked.includes(v._id)}
                                                                    onChange={(e) => handleCheckboxChange(v._id)}
                                                                />
                                                            </th>
                                                            <td>{moment(v.updatedAt).format('DD-MM-YYYY')}</td>
                                                            <td>
                                                                <span className='mb-0'>
                                                                    {v.email}
                                                                </span>
                                                            </td>
                                                            <td>{v.firstName || '-'}</td>
                                                            <td>{v.lastName || '-'}</td>
                                                            <td className='text-right'>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => removeUser(v._id)}
                                                                >
                                                                    <DeleteIcon height={15} width={15}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ): (
                                                <tr>
                                                    <td>
                                                        <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                        <CardFooter className='py-4'>
                            <div className='d-flex'>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        className='btn-icon-only text-black p-0'
                                        style={{width: 100}}
                                        caret
                                        href='#pablo'
                                        role='button'
                                        size='sm'
                                        color=''
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {PAGESIZE} / page
                                    </DropdownToggle>
                                    <DropdownMenu className='w-100'>
                                        <DropdownItem 
                                            value={10}
                                            onClick={() => {
                                                setPAGESIZE(10);
                                                fetchDisplayUserData(0, 1, 10);
                                            }}
                                        >
                                            10 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={30} 
                                            onClick={() => {
                                                setPAGESIZE(30);
                                                fetchDisplayUserData(0, 1, 30);
                                            }}
                                        >
                                            30 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={60} 
                                            onClick={() => {
                                                setPAGESIZE(60);
                                                fetchDisplayUserData(0, 1, 60);
                                            }}
                                        >
                                            60 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={100} 
                                            onClick={() => {
                                                setPAGESIZE(100);
                                                fetchDisplayUserData(0, 1, 100);
                                            }}
                                        >
                                            100 / page
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className='d-flex align-items-center'>
                                    <label className='text-xs font-weight-600 mb-0'>Go to </label>
                                    <Input
                                        placeholder='Page'
                                        className='d-inline-block ml-1 height-30-important'
                                        style={{width: 50}}
                                        // onChange={(e) => {
                                        //     fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, (e.target.value ? (Number(e.target.value)-1)*PAGESIZE : 0), Number(e.target.value), props.fetchMargins, regexProductName, supplier, source, PAGESIZE)
                                        // }}
                                        type='number'
                                        bsSize='sm'
                                    />
                                </div>
                            </div>
                    
                            <nav aria-label='...'>
                                <PaginationRender />
                            </nav>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Card>
        </>
    );
};
 
export default withRouter(SelectedUsers);