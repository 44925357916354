/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchGroups, pushGroups, updateGroups, removeGroups } from '../redux/actions/groupsActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, sortBy } from 'lodash';
// nodejs library that concatenates classes
import Breadcrumb from '../components/Common/Breadcrumb.js';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    Form,
} from 'reactstrap';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import { useParams } from 'react-router-dom';

// import ProductTable from "./Products.js"
import SelectedProducts from '../components/Groups/SelectedProducts.js';

import client from '../feathers.js';

const GroupsProductsEdit = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const { id } = useParams();
    const [ productsData, setProductsData ] = useState([]);
    const [ displayProductsData, setDisplayProductsData ] = useState([]);
    const [ usersData, setUsersData ] = useState([]);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ tableSorting, setTableSorting ] = useState([]);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,
    
        actions: modalActions,
    } = useModal();
    
    useEffect(() => {
    
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').get(id);
            })
            .then((res)=>{
                setProductsData(res.productIds);
                setDisplayProductsData(res.productIds);
                setUsersData(res.userIds);
                setDataEdit(res);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
        // eslint-disable-next-line
  }, [id])

    const onSubmit = async(value) => {
        
        // value.userIds = usersData.map(x => x._id);
        value.productIds = productsData.map(x => {
            return {
                productId: x._id,
                marginId: !isEmpty(x.marginSuperName)?x.marginSuperName._id: ''
            };
        });
    
        client.authenticate()
            .then(()=>{
                return client.service('product-groups').patch(dataEdit._id, value);
            })
            .then((res) => {
                notificationOpen(true, 'success', 'Group ' + res.groupName + ' Added Successfully!');
                props.history.push(`/${props.role}/groups-management`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const sortList = (item, value) => {
        if(value === -1){
            // if click sort
            let result = tableSorting.filter(e => e !== item);
            result.unshift(item);
            setDisplayProductsData(sortBy(displayProductsData, result));
            setTableSorting(result);
        }else{
            // if click remove sort
            let result = tableSorting.filter(e => e !== item);
            // result.push(item)
            setDisplayProductsData(sortBy(displayProductsData, result));
            setTableSorting(result);
        }
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <Formik
                    initialValues={{}}
                    enableReinitialize
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body pt-0'>

                                    <Breadcrumb
                                        items={[
                                            {title: 'Groups', link: '/superadmin/groups-management'},
                                            {title: 'Edit Group Products'}
                                        ]}
                                    />

                                    {/* <div className="mb-4">
                                    <ProductTable 
                                    getProducts={getProducts} 
                                    notificationOpen={notificationOpen}
                                    />
                                </div> */}

                                    <SelectedProducts 
                                        groupId={id}
                                        dataEdit={dataEdit}
                                        productsData={productsData}
                                        displayProductsData={displayProductsData}
                                        setProductsData={setProductsData}
                                        setDisplayProductsData={setDisplayProductsData}
                                        tableSorting={tableSorting}
                                        sortList={sortList}
                                        notificationOpen={notificationOpen}
                                        marginsData={props.marginsData}
                                        role={props.role}
                                    />

                                </div>

                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={() => {
                                            props.history.push(`/${props.role}/groups-management`);
                                        }}
                                    >
                                        <span className='btn-inner--text'>Discard</span>
                                    </Button>
                                    <Button
                                        color='primary'
                                        type='submit'
                                    >
                                        <span className='btn-inner--text'>+ Save</span>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    marginsData: state.margins.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
});
  
const mapDispatchToProps = {
    fetchGroups: fetchGroups,
    pushGroups: pushGroups,
    updateGroups: updateGroups,
    removeGroups: removeGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupsProductsEdit);