/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import ProductModalEdit from '../components/Products/ProductModalEdit';
import { connect } from 'react-redux';
import { fetchMargins } from '../redux/actions/marginsActions';
import { fetchDynamicPrice } from '../redux/actions/dynamicPriceActions';
import { fetchProducts, pushProducts, updateProducts, moveProducts, removeProducts } from '../redux/actions/productsActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useParams , useLocation } from 'react-router-dom';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import { useModal } from '../zustand/modalStore.js';

// import Notifications from '../components/Extra/Notifications.js';

const ProductsEdit = (props) => {
    const { id } = useParams();
    const { search } = useLocation();
    // const [ message, setMessage ] = useState('');
    // const [ color, setColor ] = useState('');
    // const [ isOpen, setIsOpen ] = useState(false);

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (params.get('isComponent') === 'true') {
            props.history.push(`/${props.role}/products-create-with-components?id=${id}`);
        }
    }, []);

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <ProductModalEdit 
                // modalVisible={modalVisible} 
                // toggleModal={toggleModal}
                    notificationOpen={notificationOpen} 
                    pushProducts={props.pushProducts}
                    currency={props.currency}
                    rateMYRtoSGD={props.rateMYRtoSGD}
                    marginsData={props.marginsData}
                    dynamicPriceData={props.dynamicPriceData}
                    role={props.role}
                    updateProducts={props.updateProducts}
                    userId={props.userId}
                    userInfo={props.userInfo}
                />
            </div>
            {/* <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            /> */}
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    role: state.role.details.user?state.role.details.user.role:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    userInfo: state.role.details.user?state.role.details.user:{},
    productsData: state.products.data,
    companyInfo: state.company.data,
    marginsData: state.margins.data,
    dynamicPriceData: state.dynamicPrice.data,
    rateMYRtoSGD: state?.company?.data?.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
});
  
const mapDispatchToProps = {
    fetchProducts: fetchProducts,
    pushProducts: pushProducts,
    updateProducts: updateProducts,
    moveProducts: moveProducts,
    removeProducts: removeProducts,
    fetchMargins: fetchMargins,
    fetchDynamicPrice: fetchDynamicPrice,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsEdit);