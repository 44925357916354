import React from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import firbase from your directory
import firebase from '../../firebase';

class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

  
    handleImgChange = (oriFile) => {
        let singleFile = oriFile;
        // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

        // if(singleFile.size <= 2000000 && fileType){
        let newFileName = (Math.random().toString(36).substring(2, 15) + '-' + 
        singleFile.lastModified + '-' + 
        singleFile.name);
        let formData = new FormData();
        formData.append('file', singleFile, newFileName);

        let modified = formData.get('file');

        return modified;
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
    };

    // Starts the upload process.
    async upload() {
        return await this.loader.file.then(
            oriFile => {
                let file = this.handleImgChange(oriFile);
                return new Promise((resolve, reject) => {
                    let storage = firebase.storage().ref(`ckImages/${file.name}`);
                    let uploadTask = storage
                        .put(file);
                    uploadTask.on(
                        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function(snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                                default:
                                    console.log('Something went wrong!');
                                    break;
                            }
                        },
                        function(error) {
                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            // eslint-disable-next-line default-case
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    reject(' User doesn\'t have permission to access the object');
                                    break;

                                case 'storage/canceled':
                                    reject('User canceled the upload');
                                    break;

                                case 'storage/unknown':
                                    reject(
                                        'Unknown error occurred, inspect error.serverResponse'
                                    );
                                    break;
                            }
                        },
                        function() {
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref
                                .getDownloadURL()
                                .then(function(downloadURL) {
                                    // console.log("File available at", downloadURL);
                                    return resolve({
                                        default: downloadURL
                                    });
                                });
                        }
                    );
                });
            }
        );

    }
}


const ProductDesc = ({productDetail, setProductDetail}) => {
    return (  
        <>
            <div>
                <h2>Product Detail</h2>
                <CKEditor
                    editor={ ClassicEditor }
                    data={productDetail?productDetail:''}
                    onReady={editor => {
                        if(editor){
                            if(editor.plugins){
                                if(editor.plugins.get('FileRepository')){
                                    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                                        return new MyUploadAdapter(loader);
                                    };
                                }
                            }
                        }
                    }}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setProductDetail(data);
                        // console.log( { event, editor, data } );
                    } }
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
                />
            </div>
        </>
    );
};
 
export default ProductDesc;
