import React, { useLayoutEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import findIndex from 'lodash/findIndex';

// reactstrap components
import {
    Button,
    FormGroup,
    Row,
    Col,
    Card,
    CardBody,
    Input
} from 'reactstrap';

const PreSize = ({
    values,
    setFieldValue
    // currency
}) => {
    const [ otherSize, setOtherSize ] = useState(false);

    let sizes = [];
    values.source === 'poster'?
        sizes = [
            {title: 'A0', height: 1188, width: 841 },
            {title: 'A1', height: 841, width: 594 },
            {title: 'A2', height: 594, width: 420 },
            {title: 'A3', height: 420, width: 297 },
            // {title: 'A4', height: 1182, width: 841 },
        ]
        :
        sizes = 
  [
      {title: 'A7 (74mm x 105mm)', height: 105, width: 74 },
      {title: 'A6 (105mm x 148mm)', height: 148.5, width: 105 },
      {title: 'A5 (148mm x 210mm)', height: 210, width: 148.5 },
      {title: 'A4 (210mm x 297mm)', height: 297, width: 210 },
      {title: 'A3 (297mm x 420mm)', height: 420, width: 297 },
      {title: 'DL (99mm x 210mm)', height: 210, width: 99 },
  ];

    useLayoutEffect(() => {
        if(values?.preSize?.length === 0){
            setFieldValue('preSize', sizes);
        }
        let findPaper = findIndex(values.preSize, (item) => item.title === 'Others');
        if(findPaper !== -1){
            setOtherSize(true);
        } 
        // eslint-disable-next-line 
    }, []);

    const handleOtherSize = (checked) => {

        if(checked){
            let findPaper = findIndex(values.preSize, (item) => item.title === 'Others');
            if(findPaper === -1){
                values.preSize.push({title: 'Others', height: 0, width: 0 });
            } 
        }else{
            let findPaper = findIndex(values.preSize, (item) => item.title === 'Others');
            if(findPaper !== -1){
                values.preSize.splice(findPaper, 1);
            } 
        }
        setFieldValue('preSize', values.preSize);
    };

    return (  
        <>
            <h4>Set Default Size</h4>
            <Card className='shadow mb-4'>
                <CardBody>
                    <FormGroup>
                        <FieldArray
                            name='preSize'
                            render={arrayHelpers => (
                                <div>
                                    <Row>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='4'>
                                                    <small className=' font-weight-bold'>
                                                        Label
                                                    </small>
                                                </Col>
                                                <Col md='4'>
                                                    <small className=' font-weight-bold'>
                                                        Width (mm)
                                                    </small>
                                                </Col>
                                                <Col md='4'>
                                                    <small className=' font-weight-bold'>
                                                        Height (mm)
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        values.preSize.map((size, index) => {
                                            if(size.title !== 'Others'){
                                                return (
                                                    <Row key={index} className='mb-2'>
                                                        <Col md='10'>
                                                            <Row>
                                                                <Col md='4'>
                                                                    <Field 
                                                                        className='form-control form-control-sm'
                                                                        placeholder='Label Name'
                                                                        type='text'
                                                                        name={`preSize[${index}].title`} />
                                                                </Col>
                                                                <Col md='4'>
                                                                    <Field 
                                                                        className='form-control form-control-sm'
                                                                        placeholder='Width'
                                                                        type='number'
                                                                        name={`preSize[${index}].width`} />
                                                                </Col>
                                                                <Col md='4'>
                                                                    <Field 
                                                                        className='form-control form-control-sm'
                                                                        placeholder='Height'
                                                                        type='number'
                                                                        name={`preSize.${index}.height`} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md='2' className='row d-flex align-items-center'>
                                                            <Button
                                                                color='secondary'
                                                                type='button'
                                                                size='sm'
                                                                className='mb-1'
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >
                                                                <div className='btn-inner--text text-red'>
                                                                    <i 
                                                                        className='fa fa-minus'
                                                                    />
                                                                </div>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                );
                                            }
                                            return null;
                                        })
                                    }
                                    <Row className='mb-2'>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='4'>
                                                    <Input
                                                        className='form-control-alternative'
                                                        placeholder='Name'
                                                        type='text'
                                                        bsSize='sm'
                                                        value='Custom Size'
                                                        disabled
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    {/* <p className='mb-0 text-sm'>(Custom Size)</p> */}
                                                    <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                        <input 
                                                            type='checkbox' 
                                                            checked={otherSize}
                                                            onChange={(e) => {
                                                                handleOtherSize(e.target.checked); 
                                                                setOtherSize(e.target.checked);
                                                            }}
                                                        />
                                                        <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                                    </label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <a 
                                        href='/' 
                                        onClick={ (event) => {
                                            event.preventDefault();
                                            arrayHelpers.push('');
                                        }} 
                                    >
                                        <span>
                                            <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                            Add new line
                                        </span>
                                    </a>
                                </div>
                            )}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    );
};
 
export default PreSize;