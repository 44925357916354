import {
    FETCH_ALL_PACKAGE,
} from '../actions/allPackageActions';

const INITIAL_STATE = {
    data: {
        packages: [],
    },
};

export default function allPackageReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_ALL_PACKAGE:
            return {
                ...state,
                data:action.data,
            };
        default:
            return state;

    }
}