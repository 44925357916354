import React, { useState } from 'react';
import Sizes from './Sizes';
import OptionFieldArray from './OptionFieldArray';
import { Field, FieldArray } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

// nodejs library that concatenates classes

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from 'reactstrap';

const LooseSheetForm = ({
    values,
    source,
    currency,
    setFieldValue
}) => {
    const [ toggleContent, setToggleContent ] = useState(true);
    const [ hideMaterials, setHideMaterials ] = useState([]);

    const [ minWidthErr, setMinWidthErr ] = useState(['']);
    const [ maxWidthErr, setMaxWidthErr ] = useState(['']);
    const [ minHeightErr, setMinHeightErr ] = useState(['']);
    const [ maxHeightErr, setMaxHeightErr ] = useState(['']);

    const toggleHideMaterial = (index) => {
        let clone = cloneDeep(hideMaterials);
        clone[index] = (!clone[index]);
        setHideMaterials([...clone]);
    };

    const setError = (original, setter, message, index) => {
        let clone = cloneDeep(original);
        clone[index] = message;
        setter([...clone]);
    };

    const getSmallestValue = (original, value) => {
        let clone = cloneDeep(original);
        clone.pop();
        return clone.reduce((prev, curr) => prev[value] < curr[value] ? prev : curr)[value];
    };

    // const getLargestValue = (original, value) => {
    //     let clone = cloneDeep(original);
    //     clone.pop();
    //     return clone.reduce((prev, curr) => prev[value] > curr[value] ? prev : curr)[value];
    // };

    return (  
        <>
            {
                source === 'loose-sheet-v2' &&
                <Sizes values={values} setFieldValue={setFieldValue} currency={currency} />
            }

            <FieldArray
                name='looseSheetData.materials'
                render={materialsArrayHelpers => (
                    <>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex flex-direction-row align-items-center'>
                                <h2 className='mb-0 mr-3'>Material</h2>
                                <Button
                                    color='secondary'
                                    type='button'
                                    onClick={() => setToggleContent(!toggleContent)}
                                >
                                    <div className='btn-inner--text'>
                                        <span>{(toggleContent) ? 'Hide' : 'Show'}</span>
                                    </div>
                                </Button>
                            </div>
                            <Button
                                color='primary'
                                type='button'
                                onClick={ (event) => {
                                    event.preventDefault();
                                    materialsArrayHelpers.push({
                                        name: '',
                                        options: [],
                                        basePrice: 0,
                                        pricePerSheet: 0,
                                        minWidth: 0,
                                        maxWidth: 0,
                                        minHeight: 0,
                                        maxHeight: 0,
                                        bleedWidth: 0,
                                        bleedHeight: 0,
                                    });
                                }}>
                                <div className='btn-inner--text'>
                                    <span>Add Material</span>
                                </div>
                            </Button>
                        </div>
                        {(toggleContent) && 
                            values.looseSheetData.materials.length > 0? 
                            values.looseSheetData.materials.map((material, mIndex) => {
                                return (
                                    <Card key={mIndex} className='shadow mb-4'>
                                        <CardBody>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <Card className='shadow mr-3' style={{background: 'rgb(82, 95, 127, 0.2)'}}>
                                                        <CardBody className='pl-4 pr-4 pt-2 pb-2'>
                                                            <h4 className='mb-0'>{`(${mIndex + 1}) Material`}</h4>
                                                        </CardBody>
                                                    </Card>
                                                    <a
                                                        type='button'
                                                        onClick={() => toggleHideMaterial(mIndex)}
                                                    >
                                                        <div className='btn-inner--text'>
                                                            {(!hideMaterials[mIndex]) ? (<i className='fa fa-chevron-down' />) : (<i className='fa fa-chevron-up' />)} 
                                                        </div>
                                                    </a>
                                                </div>
                                                
                                                <div className='d-flex justify-content-end'>
                                                    <Button
                                                        color='default'
                                                        type='button'
                                                        onClick={ (event) => {
                                                            event.preventDefault();
                                                            materialsArrayHelpers.push(material);
                                                        }}>
                                                        <div className='btn-inner--text'>
                                                            <i 
                                                                className='fa fa-copy'
                                                                style={{fontSize: '1rem'}}
                                                            />
                                                            <span>Duplicate</span>
                                                        </div>
                                                    </Button>
                                                    <Button
                                                        color='secondary'
                                                        type='button'
                                                        onClick={() => materialsArrayHelpers.remove(mIndex)}
                                                    >
                                                        <div className='btn-inner--text text-red'>
                                                            <i 
                                                                className='fa fa-trash'
                                                                style={{fontSize: '1rem'}}
                                                            />
                                                            <span>Delete</span>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>

                                            {(!hideMaterials[mIndex]) && (
                                                <div className='rounded p-4'>
                                                    <Row className='d-flex justify-content-center align-items-center mb-5'>
                                                        <Col md='3' className='d-flex flex-column'>
                                                            <small className='font-weight-bold'>Name of Material</small>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='Name'
                                                                type='text'
                                                                bsSize='sm'
                                                                name={`looseSheetData.materials[${mIndex}].name`}
                                                            />
                                                        </Col>
                                                        <Col md='3'>
                                                            <small className='font-weight-bold'>Material Base Price (<span className='text-uppercase'>{currency}</span>)</small>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                placeholder='Base Price'
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                bsSize='sm'
                                                                name={`looseSheetData.materials[${mIndex}].basePrice`}
                                                            />
                                                        </Col>
                                                        <Col md='3'>
                                                            <small className='font-weight-bold'><span className='text-uppercase'>{currency}</span>/Sheet</small>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                bsSize='sm'
                                                                name={`looseSheetData.materials[${mIndex}].pricePerSheet`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex align-items-center mb-2'>
                                                        <Col md='2'>
                                                            <h4>Printing Dimensions</h4>
                                                        </Col>
                                                        <Col md='4'>
                                                            <h4>Min Printing (before bleed)</h4>
                                                        </Col>
                                                        <Col md='4'>
                                                            <h4>Max Print Size (aka Paper Dimension)</h4>
                                                        </Col>
                                                    </Row>

                                                    <Row className='d-flex justify-content-center align-items-center mb-2'>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Min Width (mm) <span style={{ color: 'red' }}>{`${minWidthErr[mIndex] ?? ''}`}</span>
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].minWidth`}
                                                                    onBlur={() => {
                                                                        const smallestWidth = getSmallestValue(values.looseSheetData.labels, 'width');
                                                                        if (material.minWidth > smallestWidth){
                                                                            setError(minWidthErr, setMinWidthErr, `Min width must be lesser than ${smallestWidth}!`, mIndex);
                                                                        } else {
                                                                            setError(minWidthErr, setMinWidthErr, '', mIndex);
                                                                        }
                                                                    }}
                                                                
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Max Width (mm) <span style={{ color: 'red' }}>{`${maxWidthErr[mIndex] ?? ''}`}</span>
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].maxWidth`}
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>  
                                                    </Row>

                                                    <Row className='d-flex justify-content-center align-items-center mb-5'>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Min Height (mm) <span style={{ color: 'red' }}>{`${minHeightErr[mIndex] ?? ''}`}</span>
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].minHeight`}
                                                                    onBlur={() => {
                                                                        const smallestHeight = getSmallestValue(values.looseSheetData.labels, 'height');

                                                                        if (material.minHeight > smallestHeight){
                                                                            setError(minHeightErr, setMinHeightErr, `Min height must be smaller than ${smallestHeight}!`, mIndex);
                                                                        } else {
                                                                            setError(minHeightErr, setMinHeightErr, '', mIndex);
                                                                        }
                                                                    }}
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Max Height (mm) <span style={{ color: 'red' }}>{`${maxHeightErr[mIndex] ?? ''}`}</span>
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].maxHeight`}
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex justify-content-start align-items-start'>
                                                        <Col md='2'>
                                                            <h4>Bleeding</h4>
                                                        </Col>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Bleeding Width (mm)
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].bleedWidth`}
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md='4'>
                                                            <div>
                                                                <small className=' font-weight-bold'>
                                                                    Bleeding Height (mm)
                                                                </small>
                                                            </div>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Field 
                                                                    className='form-control form-control-sm form-control-alternative'
                                                                    type='number'
                                                                    onWheel={(e) => e.target.blur()}
                                                                    bsSize='sm'
                                                                    name={`looseSheetData.materials[${mIndex}].bleedHeight`}
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent'>
                                                                        <span>mm</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}                                            
                                            <hr className='mt-4 mb-4'/>
                                            <OptionFieldArray
                                                values={material.options}
                                                materialIndex={mIndex}
                                                currency={currency}
                                            />
                                                
                                        </CardBody>
                                    </Card>
                                );
                            }): null
                        }
                    </>

                )}/>
        </>
    );
};
 
export default LooseSheetForm;