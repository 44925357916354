/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, pushUsers, updateUsers } from '../redux/actions/usersActions';
// import dayjs from 'dayjs';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { useModal } from '../zustand/modalStore.js';
// import moment from 'moment';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';

import UserModal from '../components/Users/UserModal.js';
import UserModalEdit from '../components/Users/UserModalEdit.js';
import PasswordModal from '../components/Users/PasswordModal.js';
import StatusModal from '../components/Users/StatusModal.js';
import AssignUserGroups from '../components/Groups/AssignUserGroups.js';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import client from '../feathers';
import moment from 'moment';

const today = new Date();
const oneMonthFromToday = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

function paginationCount(length, currentPage, pageSize) {
    return {
        total: length,
        per_page: pageSize,
        current_page: currentPage,
        last_page: Math.ceil(length / pageSize),
        from: ((currentPage - 1) * pageSize) + 1,
        to: currentPage * pageSize
    };
}

const Users = (props) => {
    const [ modalVisible, setMmodalVisible ] = useState(false);
    const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false);
    const [ modalVisiblePassword, setMmodalVisiblePassword ] = useState(false);
    const [ modalVisibleStatus, setModalVisibleStatus ] = useState(false);
    const [ activeLicense, setActiveLicense ] = useState(0);
    const [ totalLicense, setTotalLicense ] = useState(0);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ currentDeactiveStatus, setCurrentDeactiveStatus] = useState(false);
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ modalVisibleAssignGroup, setModalVisibleAssignGroup ] = useState(false);
    const [ PAGESIZE, setPAGESIZE ] = useState(30);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,

        actions: modalActions,
    } = useModal();

    useEffect(() => {
        fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, PAGESIZE);
    }, [props.userId, props.fetchUsers, props.deactive, PAGESIZE]);

    useEffect(() => {
        fetchStatictics(props.userId, props.fetchLicenses, notificationOpen, 0, 1);
    }, []);

    const toggleModal = () => {
        setMmodalVisible(!modalVisible);
    };

    const toggleModalEdit = () => {
        setMmodalVisibleEdit(!modalVisibleEdit);
    };

    const toggleModalPassword = () => {
        setMmodalVisiblePassword(!modalVisiblePassword);
    };

    const toggleModalStatus = () => {
        setModalVisibleStatus(!modalVisibleStatus);
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    const toggleModalAssignGroup = () => {
        setModalVisibleAssignGroup(!modalVisibleAssignGroup);
    };

    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            props.usersData.map(x => {
                cloneCheckbox.push(x._id);
                return x;
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const fetchStatictics = (userId, fetchLicenses, notificationOpen, skip, currentPage) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('licenses').find({
                        query: {
                            userId,
                        }
                    });
                })
                .then((res) => {
                    let totalActiveLicense = 0;
                    let totalAvaiableLicense = 0;
                    res.data.forEach((item) => {
                        totalActiveLicense += item.accUsed;
                        totalAvaiableLicense += item.numOfAccounts;
                    });
                    setActiveLicense(totalActiveLicense);
                    setTotalLicense(totalAvaiableLicense);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };
  
    const fetchData = (userId, fetchUsers, notificationOpen, skip, currentPage, pageSize) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('users').find({
                        query: {
                            role: 'admin',
                            superAdminId: userId,
                            $skip: skip,
                            $limit: pageSize,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    setCheckboxChecked([]);
                    setPagination(paginationCount(res.total, currentPage, pageSize));
                    fetchUsers(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const sendInvitationEmail = (userId) => {
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}sendInvitationEmail`,
                    {
                        userId
                    },
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                notificationOpen(true, 'success', 'Invitation sent!');
            })
            .catch((err)=>{
                notificationOpen(true, 'danger', 'Something went wrong. Failed to sent email!');
            });
    };

  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                                fetchData(props.userId, props.fetchUsers, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        setPagination(paginationCount(pagination.total, i)); 
                                        fetchData(props.userId, props.fetchUsers, notificationOpen, (i-1)*PAGESIZE, i);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                                fetchData(props.userId, props.fetchUsers, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                <Row className='pt-4 pb-3 d-flex align-items-center'>
                    <div className='col'>
                        <Card className='shadow'>
                            <Card>
                                <CardBody>
                                    <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                                        <Col md='4'>
                                            <h3 className='mb-0'>
                                                List of Users (<span>{activeLicense}</span> of <span>{totalLicense}</span>)
                                            </h3>
                                        </Col>
                                        <Col md='7' className='text-right'>
                                            <Button
                                                color='default'
                                                onClick={() => toggleModalAssignGroup()}
                                            >
                                                <span className='btn-inner--text'>Assign Group</span>
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={() => props.history.push(`/${props.role}/user-create`)}
                                            >
                                                <span className='btn-inner--text'>+ User</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody className='p-0'>
                                            <div className='table-responsive mac-scrollbar'>
                                                <Table className='align-items-center table-flush'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col' className='d-flex align-items-center'>
                                                                <input
                                                                    className='mr-3'
                                                                    type='checkbox'
                                                                    id='rememberMe'
                                                                    onChange={(e) => handleCheckAll(e.target.checked)}
                                                                />
                                                                Added Date
                                                            </th>
                                                            <th scope='col'>
                                                                Email
                                                            </th>
                                                            <th scope='col'>Company Name</th>
                                                            <th scope='col'>First Name</th>
                                                            <th scope='col'>Last Name</th>
                                                            <th scope='col'>Package</th>
                                                            <th scope='col'>Expiry</th>
                                                            <th scope='col'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.usersData.length > 0? (
                                                            props.usersData.map((v, i) => {     
                                                                let isExpiring = false;
                                                                if (v.endDate && new Date(v.endDate) < oneMonthFromToday) {
                                                                    isExpiring = true;
                                                                }
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className='d-flex align-items-center'>
                                                                            <input
                                                                                className='mr-3'
                                                                                type='checkbox'
                                                                                id={v._id}
                                                                                checked={checkboxChecked.includes(v._id)}
                                                                                value={checkboxChecked.includes(v._id)}
                                                                                onChange={(e) => handleCheckboxChange(v._id)}
                                                                            />
                                                                            <span className='mb-0'>
                                                                                {moment(v.createdAt).format('DD-MM-YYYY')}
                                                                            </span>
                                                                        </td>
                                                                        <td>{v.email}</td>
                                                                        <td>{v.companyName || '-'}</td>
                                                                        <td>{v.firstName || '-'}</td>
                                                                        <td>{v.lastName || '-'}</td>
                                                                        <td>
                                                                            {(props?.packages?.length > 0 && v.package) ? (
                                                                                props.packages.find((x) => x._id === v.package)?.name
                                                                            ) : ('-')}
                                                                        </td>
                                                                        {(isExpiring) ? (
                                                                            <td style={{ color: 'red' }}>{(v.endDate) ? new Date(v.endDate).toISOString().split('T')[0] : ''}</td>
                                                                        ) : (
                                                                            <td>{(v.endDate) ? new Date(v.endDate).toISOString().split('T')[0] : ''}</td>
                                                                        )}
                                                                        <td className='text-right'>
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle
                                                                                    className='btn-icon-only text-light'
                                                                                    href='#pablo'
                                                                                    role='button'
                                                                                    size='sm'
                                                                                    color=''
                                                                                    onClick={(e) => e.preventDefault()}
                                                                                >
                                                                                    <i className='fas fa-ellipsis-v' />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className='dropdown-menu-arrow' right>
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            setDataEdit(v); 
                                                                                            props.history.push(`/${props.role}/user-edit/${v._id}`);
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => {setDataEdit(v); toggleModalPassword();}}
                                                                                    >
                                                                                        Edit Password
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => props.history.push('/superadmin/userProducts/'+v._id)}
                                                                                    >
                                                                                        Edit Products
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => window.open(`/auth/login?key=${v._id}`, '_blank', 'noreferrer')}
                                                                                    >
                                                                                        Log in as
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => {setDataEdit(v); toggleModalStatus(); setCurrentDeactiveStatus(v.deactive);}}
                                                                                    >
                                                                                        {v.deactive ? 'Activate Merchant' : 'Deactivate Merchant'}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => {sendInvitationEmail(v._id);}}
                                                                                    >
                                                                                        Email user account info
                                                                                    </DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ): (
                                                            <tr>
                                                                <td>
                                                                    <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='py-4'>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className='btn-icon-only text-black p-0'
                                                            style={{width: 100}}
                                                            caret
                                                            href='#pablo'
                                                            role='button'
                                                            size='sm'
                                                            color=''
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            {PAGESIZE} / page
                                                        </DropdownToggle>
                                                        <DropdownMenu className='w-100'>
                                                            <DropdownItem 
                                                                value={30}
                                                                onClick={() => {
                                                                    // setPAGESIZE(30);
                                                                    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, 30);
                                                                }
                                                                }
                                                            >
                                                                30 / page
                                                            </DropdownItem>
                                                            <DropdownItem 
                                                                value={50} 
                                                                onClick={() => {
                                                                    // setPAGESIZE(50);
                                                                    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, 50);
                                                                }}
                                                            >
                                                                50 / page
                                                            </DropdownItem>
                                                            <DropdownItem 
                                                                value={100} 
                                                                onClick={() => {
                                                                    // setPAGESIZE(100);
                                                                    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, 100);
                                                                }}
                                                            >
                                                                100 / page
                                                            </DropdownItem>
                                                            <DropdownItem 
                                                                value={150} 
                                                                onClick={() => {
                                                                    // setPAGESIZE(150);
                                                                    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, 150);
                                                                }}
                                                            >
                                                                150 / page
                                                            </DropdownItem>
                                                            <DropdownItem 
                                                                value={200} 
                                                                onClick={() => {
                                                                    // setPAGESIZE(200);
                                                                    fetchData(props.userId, props.fetchUsers, notificationOpen, 0, 1, 200);
                                                                }}
                                                            >
                                                                200 / page
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            
                                                <nav aria-label='...'>
                                                    <PaginationRender />
                                                </nav>
                                            </div> 
                                        </CardFooter>
                                    </Card>
                                </CardBody>
                            </Card>
                        </Card>
                    </div>
                </Row>
            </Container>
            <UserModal 
                modalVisible={modalVisible} 
                toggleModal={toggleModal}
                notificationOpen={notificationOpen} 
                pushUsers={props.pushUsers}
            />
            <UserModalEdit 
                dataEdit={dataEdit}
                modalVisible={modalVisibleEdit} 
                toggleModal={toggleModalEdit}
                notificationOpen={notificationOpen} 
                updateUsers={props.updateUsers}
            />
            <AssignUserGroups 
                userId={props.userId}
                checkboxChecked={checkboxChecked}
                usersData={props.usersData}
                modalVisible={modalVisibleAssignGroup} 
                toggleModal={toggleModalAssignGroup}
                notificationOpen={notificationOpen}
                total={checkboxChecked.length}
            />
            <PasswordModal 
                dataEdit={dataEdit}
                modalVisible={modalVisiblePassword} 
                toggleModal={toggleModalPassword}
                notificationOpen={notificationOpen} 
                updateUsers={props.updateUsers}
            />
            <StatusModal 
                dataEdit={dataEdit}
                modalVisible={modalVisibleStatus} 
                toggleModal={toggleModalStatus}
                notificationOpen={notificationOpen} 
                updateUsers={props.updateUsers}
                status={currentDeactiveStatus}
            />
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    role: state.role.details.user?state.role.details.user.role:'',
    userId: state.role.details.user?state.role.details.user._id:'',
    usersData: state.users.data,
    packages: state.allPackages.data,
});

const mapDispatchToProps = {
    fetchUsers: fetchUsers,
    pushUsers: pushUsers,
    updateUsers: updateUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);