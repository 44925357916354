import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    Card,
    CardBody,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const MarginsModalEdit = (props) => {
    const [ marginSuperName, setMarginSuperName ] = useState('');
  
    useEffect(() => {
        if(!isEmpty(props.dataEdit)){
            // new function
            if(props.dataEdit.marginSuperName){
                setMarginSuperName(props.dataEdit.marginSuperName._id);
            }
        }
    }, [props.modalVisible, props.dataEdit]);

    const onSubmit = async() => {
    // new function

        client.authenticate()
            .then(()=>{
                return client.service('products').patch(props.dataEdit._id, {
                    marginSuperName,
                });
            })
            .then((res) => {
                res.marginSuperName = props.marginsData.find(e => e._id === marginSuperName);
      
                props.toggleModal();
                props.updateProducts(res);
                props.notificationOpen(true, 'success', 'Product ' + res.name + ' Edited Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered status-modal'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Edit Margin
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <Card className='shadow'>
                        <CardBody>
                            <Row className='mb-2'>
                                <Col>
                                    <small className='text-uppercase font-weight-bold'>
                                        Profit Margin
                                    </small>
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-end'>
                                        <Input 
                                            className='mr-2 w-auto d-inline-block'
                                            value={props.marginsData.find(e => e._id === marginSuperName)? props.marginsData.find(e => e._id === marginSuperName).marginName: ''} 
                                            bsSize='sm'
                                            disabled
                                        />
                                        <UncontrolledDropdown nav inNavbar size='sm'>
                                            <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                Apply Margin
                                            </DropdownToggle>
                                            <DropdownMenu className='w-100'>
                                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                    <DropdownItem 
                                                        className='bg-danger text-white text-capitalize' 
                                                        value={''} 
                                                        onClick={() => {
                                                            setMarginSuperName('');
                                                        }}
                                                    >
                                                        Unlink Margin
                                                    </DropdownItem>
                                                    {
                                                        props.marginsData.map((x, i) => {
                                                            return (
                                                                <DropdownItem
                                                                    key={i} 
                                                                    className='text-capitalize' 
                                                                    value={x.marginName} 
                                                                    onClick={() => {
                                                                        setMarginSuperName(x._id);
                                                                    }}
                                                                >
                                                                    {x.marginName}
                                                                </DropdownItem>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        Close
                    </Button>
                    <Button 
                        color='primary' 
                        type='button'
                        onClick={onSubmit}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </>
    );
};
 
export default MarginsModalEdit;