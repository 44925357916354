import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

const CompanyHeader = ({name}) => {
    return (
        <>
            <div
                className='header pb-8 pt-5 pt-lg-8 d-flex align-items-center'
                style={{
                    backgroundImage:
            'url(' +
            require('../../assets/img/theme/profile-cover.jpg').default +
            ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center top',
                }}
            >
                {/* Mask */}
                {/* <span className="mask bg-gradient-default opacity-8" /> */}
                <span className='mask' style={{backgroundColor: '#fff'}} />
                {/* Header container */}
                <Container className='align-items-center' fluid>
                    <Row>
                        <Col lg='7' md='10'>
                            <h1 className='display-4'>Hello {name}</h1>
                            {/* <p className="mt-0 mb-5">
                This is your company profile page. You can see the progress you've made
                with your work and manage your projects or assigned tasks
              </p> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CompanyHeader;
