import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router';
import Breadcrumb from '../Common/Breadcrumb.js';


// reactstrap components
import {
    Button,
    Form,
    Input,
    // Modal,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import MarginCalc from '../Products/MarginCalc';

import client from '../../feathers.js';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    marginName: Yup.string()
        .required('Algorithm Name is required'),
});

const MarginsModalEdit = (props) => {
    const { id } = useParams();
    const [ marginBetween, setMarginBetween ] = useState([]);
    const [ marginFocused, setMarginFocused ] = useState(false);
  
    const [ dataEdit, setDataEdit ] = useState({});

    useEffect(() => {
    
        client.authenticate()
            .then(()=>{
                return client.service('margins').get(id);
            })
            .then((res)=>{
                setDataEdit(res);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    }, [id]);

    useEffect(() => {
        if(!isEmpty(dataEdit)){
            setMarginBetween(dataEdit.marginBetween);
        }
    }, [dataEdit]);

    const marginBetweenOnChange = (val, name, index) => {
        let cloneMarginBetween = cloneDeep(marginBetween);
        cloneMarginBetween[index][name] = val;
    
        setMarginBetween(cloneMarginBetween);
    };

    const removeMarginBetween = (index) => {
        let cloneMarginBetween = cloneDeep(marginBetween);
        cloneMarginBetween.splice(index, 1);
    
        setMarginBetween(cloneMarginBetween);
    };

    const onSubmit = async(value) => {

        value.marginBetween = marginBetween;

        client.authenticate()
            .then(()=>{
                return client.service('margins').patch(dataEdit._id, value);
            })
            .then((res) => {
                props.updateMargins(res);
                props.notificationOpen(true, 'success', 'Margin ' + res.marginName + ' Edited Successfully!');
                props.history.push(`/${props.role}/margin`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    marginName: dataEdit.marginName,
                    description: dataEdit.description,
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = propsChild;
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body pt-0'>
                                <Row className='m-1 mt-3'>
                                    <h2 className='mr-4 mb-0'>Edit Margin</h2>
                                </Row>
                                <Breadcrumb
                                    items={[
                                        {title: 'Margins', link: `/${props.role}/margin`},
                                        {title: 'Edit Margin'}
                                    ]}
                                />
                                <Card className='mb-4'>
                                    <CardBody className='rounded'>
                                        <div className='px-3 mb-4 border rounded'>
                                            <Row className='p-3 px-4'>
                                                <h4 className='m-0'>Profit Margin Setting</h4>
                                            </Row>
                                            <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Name</h5>
                                                    <Input 
                                                        placeholder='Algorithm Name'
                                                        type='text'
                                                        id='marginName'
                                                        value={values.marginName}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setMarginFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setMarginFocused(false);
                                                            handleBlur(e);
                                                        }} 
                                                    />
                                                    {errors.marginName && touched.marginName && (
                                                        <div className='input-feedback'>{errors.marginName}</div>
                                                    )}
                                                </Col>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Description</h5>
                                                    <Input
                                                        className='form-control-alternative'
                                                        placeholder='Description'
                                                        id='description'
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        autoComplete='off'
                                                        type='text'
                                                        rows='3'
                                                    />                                              
                                                </Col>
                                            </Row>
                                                            
                                        </div>

                                        <MarginCalc 
                                            marginBetween={marginBetween}
                                            setMarginBetween={setMarginBetween}
                                            marginBetweenOnChange={marginBetweenOnChange}
                                            removeMarginBetween={removeMarginBetween}
                                            marginsData={props.marginsData}
                                            currency={props.currency}
                                        />   
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='modal-footer'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                        props.history.push(`/${props.role}/margin`);
                                    }}
                                >
                                    Discard
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='submit'
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {/* </Drawer> */}
            {/* </Modal> */}
        </>
    );
};
 
export default withRouter(MarginsModalEdit);