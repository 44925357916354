import {
    FETCH_COMPONENTS,
    PUSH_COMPONENTS,
    UPDATE_COMPONENTS,
    REMOVE_COMPONENTS,
    FETCH_COMPULSORY_COMPONENTS,
    PUSH_COMPULSORY_COMPONENTS,
    UPDATE_COMPULSORY_COMPONENTS,
    REMOVE_COMPULSORY_COMPONENTS,
} from '../actions/componentsActions';

const INITIAL_STATE = {
    data: [],
};

export default function componentsReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_COMPONENTS:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_COMPONENTS:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_COMPONENTS:
            return {
                ...state,
                data: state.data.map((v)=>{
                    if(v._id === action.data._id) {
                        return action.data;
                    } else return v;
                })
            };
        case REMOVE_COMPONENTS:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        case FETCH_COMPULSORY_COMPONENTS:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_COMPULSORY_COMPONENTS:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_COMPULSORY_COMPONENTS:
            return {
                ...state,
                data: state.data.map((v)=>{
                    if(v._id === action.data._id) {
                        return action.data;
                    } else return v;
                })
            };
        case REMOVE_COMPULSORY_COMPONENTS:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        default:
            return state;

    }
}