import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/common/search.svg';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import { useModal } from '../zustand/modalStore.js';
import { useReport } from '../zustand/reportStore.js';

// reactstrap components
import {
    Button,
    // Badge,
    Card,
    CardBody,
    CardFooter,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
    InputGroup,
} from 'reactstrap';


import client from '../feathers.js';

// const PAGESIZE = 30;
var typingTimer = 0;

const Packages = (props) => {
    const { userId } = props;
    const [ pagination, setPagination ] = useState({});
    const [ selectedSearch, setSelectedSearch ] = useState('name');
    const [ regexPackageName, setRegexPackageName ] = useState('');
    const [ tableSorting, setTableSorting ] = useState({
        name: 1,
        source: 1,
        supplier: 1,
        updatedAt: 1
    });
    const timeoutRef = useRef(null);


    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    const {
        packagesCurrentIndex,
        packagesMaxIndex,
        packagesPerPage,
        packagesData,

        actions: reportActions,
    } = useReport();

    const fetchData = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            if(userId){    
                client.authenticate()
                    .then(()=>{
                        let query = {
                            userId,
                            $limit: packagesPerPage,
                            $skip: packagesCurrentIndex * packagesPerPage,
                            $sort: tableSorting,
                            orRegex: { [selectedSearch]: regexPackageName },
                        };
                        return client.service('packages').find({
                            query
                        });
                    })
                    .then((res) => {
                        reportActions.setPackagesMaxIndex(Math.ceil(res.total / packagesPerPage));
                        reportActions.setPackagesData(res.data);
                    });
            }
        }, 100);
    };

    useEffect(() => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            fetchData();
        }, 500);

    }, [props.userId, regexPackageName, tableSorting]);

  
    const sortList = (item, value) => {
        setTableSorting(prevState => {
            delete prevState[item];
            return {
                [item]: value,
                ...prevState,
            };
        });
    };

    const PaginationRender = () => {
        // if(packagesMaxIndex > 0){
        console.log(packagesMaxIndex);
        let pageArr = [];
        for(let i = 1; i <= packagesMaxIndex; i++){
            pageArr.push(i);
        }
        return (
            <Pagination
                className='pagination justify-content-center mb-0'
                listClassName='justify-content-center mb-0'
            >
                <PaginationItem className={(packagesCurrentIndex > 1?'':'disabled')}>
                    <PaginationLink
                        onClick={() => {
                            reportActions.setPackagesCurrentIndex(packagesCurrentIndex - 1);
                        }} 
                        tabIndex='-1'
                    >
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {pageArr.map((i) => {
                    return (
                        <PaginationItem key={i} className={(packagesCurrentIndex + 1 === i? 'active': '')}>
                            <PaginationLink
                                onClick={() => {
                                    reportActions.setPackagesCurrentIndex(i);
                                }} 
                            >
                                {i}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}
                <PaginationItem className={((packagesCurrentIndex+1) > packagesMaxIndex?'disabled':'')}>
                    <PaginationLink
                        onClick={() => {
                            reportActions.setPackagesCurrentIndex(packagesCurrentIndex + 1);
                        }} 
                    >
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        );
        // }else{
        //     return null;
        // }
    };

    const deletePackage = (id) => {
        if(userId){
            client.authenticate()
                .then(() => {
                    return client.service('packages').remove(id);
                })
                .then((res) => {
                    fetchData();
                });
        }
    };

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='m-0'>Packages</h2>
                </Row>
                <Card className='mt-3'>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='4'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <SearchIcon height={20} className='mx-1'/>
                                    <Input
                                        placeholder='Search Package Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        value={regexPackageName}
                                        onChange={(e) => setRegexPackageName(e.target.value)}
                                    />
                                </InputGroup>
                                
                            </Col>
                            <Col md='7' className='text-right'>
                                <Button
                                    color='primary'
                                    onClick={() => props.history.push(`/${props.role}/packages-create`)}
                                >
                                    <span className='btn-inner--text'>+ Package</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className='col'>
                                <Card>
                                    <div className='table-responsive mac-scrollbar'>
                                        <Table className='align-items-center table-flush'>
                                            <thead>
                                                <tr>
                                                    <th scope='col'>
                                                        Name
                                                    </th>
                                                    <th scope='col'>
                                                        Created Date
                                                    </th>
                                                    <th scope='col'>Duration</th>
                                                    <th scope='col'>Description</th>
                                                    <th scope='col'>Users</th>
                                                    <th scope='col' className='text-right'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {packagesData.length > 0? (
                                                    packagesData.map((p, i) => {
                                                        let duration = p.duration;
                                                        if (p.duration === 1) {
                                                            duration = 'Monthly';
                                                        } else if (p.duration === 3) {
                                                            duration = 'Quarterly';
                                                        } else if (p.duration === 12) {
                                                            duration = 'Annually';
                                                        }
                                                        return (
                                                            <tr key={i}>
                                                                <td>{p.name}</td>
                                                                <td>{dayjs(p.createdAt).format('DD-MM-YYYY')}</td>
                                                                <td>{duration}</td>
                                                                <td>{p.description}</td>
                                                                <td>{p.users}</td>
                                                                
                                                                <td className='text-right'>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => {
                                                                            props.history.push(`/${props.role}/packages-create?id=${p._id}`);
                                                                        }}
                                                                    >
                                                                        <EditIcon height={15} width={15}/>
                                                                    </Button>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => [
                                                                            modalActions.setIsOpen(true),
                                                                            modalActions.setTitle('Delete Package'),
                                                                            modalActions.setContent('Are you sure you want to delete this Package?'),
                                                                            modalActions.setCallback(() => deletePackage(p._id)),
                                                                        ]}
                                                                    >
                                                                        <DeleteIcon height={15} width={15}/>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ): (
                                                    <tr>
                                                        <td>
                                                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <CardFooter className='py-4'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className='btn-icon-only text-black p-0'
                                                        style={{width: 100}}
                                                        caret
                                                        href='#pablo'
                                                        role='button'
                                                        size='sm'
                                                        color=''
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        {packagesPerPage} / page
                                                    </DropdownToggle>
                                                    <DropdownMenu className='w-100'>
                                                        <DropdownItem 
                                                            value={30}
                                                            onClick={() => {
                                                                reportActions.setPackagesPerPage(30);
                                                            }
                                                            }
                                                        >
                                                            30 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={50} 
                                                            onClick={() => {
                                                                reportActions.setPackagesPerPage(50);
                                                            }}
                                                        >
                                                            50 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={100} 
                                                            onClick={() => {
                                                                reportActions.setPackagesPerPage(100);
                                                            }}
                                                        >
                                                            100 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={150} 
                                                            onClick={() => {
                                                                reportActions.setPackagesPerPage(150);
                                                            }}
                                                        >
                                                            150 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={200} 
                                                            onClick={() => {
                                                                reportActions.setPackagesPerPage(200);
                                                            }}
                                                        >
                                                            200 / page
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            
                                            <nav aria-label='...'>
                                                <PaginationRender />
                                            </nav>
                                        </div> 
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </CardBody>
                </Card>        
            </Container>
            
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
        </>
    );
};
 
const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    companyInfo: state.company.data,
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Packages);