import React from 'react';
import Dropdown from '../Common/Dropdown.js';

import {
    Row,
    InputGroup,
} from 'reactstrap';
const DynamicPriceInput = (props) => {
    const { dynamicPriceData, location, setFieldValue } = props;
    return (  
        <Row className={'m-0 d-flex align-items-center justify-content-between rounded'}>
            <div className='input-card d-flex align-items-center bg-white' style={{ width: 350 }}>
                <small className='mr-1 mb-0 w-50' >Dynamic Price: </small>
                <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF' }}>
                    <Dropdown
                        className='bg-transparent'
                        setFieldValue={setFieldValue}
                        name={location}
                        values={[
                            ...dynamicPriceData.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.dynamicPriceName
                                };
                            })]}
                        width='100%'
                    />
                </InputGroup>
            </div>
        </Row>
    );
};
 
export default DynamicPriceInput;
