import React, { useState, useLayoutEffect } from 'react';
import { Formik } from 'formik';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/common/search.svg';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    Button,
    Input,
    Card,
    CardBody,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const AssignUserGroups = ({
    checkboxChecked,
    userId,
    usersData,
    notificationOpen,
    total,
    toggleModal,
    modalVisible,
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ groupName, setGroupName ] = useState('');
    const [ groupList, setGroupList ] = useState([]);
    const [ regexProductGroupName, setRegexProductGroupName ] = useState('');
    
    const fetchData = () => {
        if(userId)
            client.authenticate()
                .then((auth)=>{
                    return client.service('product-groups').find({
                        query: {
                            createdBy: userId,
                            orRegex: { groupName: regexProductGroupName },
                        }
                    });
                })
                .then((res) => {
                    setGroupList(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
    };

    useLayoutEffect(() => {
        fetchData();
    }, [userId, regexProductGroupName]);

    const onSubmit = async(value) => {
        if(groupName){
            setLoading(true);
            let getProductGroup = groupList.find(e => e._id === groupName);

            let getUser = [];
            checkboxChecked.map(x => {
                usersData.map(x1 => {
                    if(x === x1._id){
                        getUser.push(x1);
                    }
                    return x1;
                });
                return x;
            });

            let result = [];
            getUser.map(x => {
                const found = getProductGroup.userIds.some(el => el._id === x._id);
                if (!found) result.push(x);
                return x;
            });

            let filterExistUser = getProductGroup.userIds.concat(result);

            let userIds = filterExistUser.map(x => {
                return x._id;
            });
            
            client.authenticate()
                .then(()=>{
                    return client.service('product-groups').patch(getProductGroup._id, {
                        userIds,
                    });
                })
                .then((res) => {
                    notificationOpen(true, 'success', 'Group ' + res.groupName + ' Edit Successfully!');
                    toggleModal();
                    setLoading(false);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                    setLoading(false);
                });
        }else{
            notificationOpen(true, 'warning', 'No Group is selected!');
        }
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered status-modal'
                isOpen={modalVisible}
                toggle={toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Assign {total} Products Group
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <div className='d-flex align-items-center mb-2'>
                                        {/* <input
                                            className='mr-2'
                                            type='checkbox'
                                            id='rememberMe'
                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                        /> */}
                                        <InputGroup className='input-group-alternative d-flex align-items-center'>
                                            <SearchIcon height={20} className='mx-1'/>
                                            <Input
                                                placeholder='Search Group Name...'
                                                className='w-auto d-inline-block mr-2'
                                                type='text'
                                                bsSize='sm'
                                                size={20}
                                                value={regexProductGroupName}
                                                onChange={(e) => setRegexProductGroupName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </div>
                                    <Card className='btn-grey mb-4'>
                                        <CardBody>
                                            <Row>
                                                <Col md='12' className='text-center'>

                                                    {
                                                        groupList.map((v, i) => {
                                                            return (
                                                                <div key={i} className='d-flex align-items-center'>
                                                                    <input
                                                                        className='mr-3'
                                                                        type='checkbox'
                                                                        id={v._id}
                                                                        checked={groupName === v._id}
                                                                        value={groupName}
                                                                        onChange={(e) => setGroupName(v._id)}
                                                                    />
                                                                    <span className='mb-0'>
                                                                        {v.groupName}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default AssignUserGroups;