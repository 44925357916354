import {
    LOGIN_SUCCESS,
    UPDATE_SUCCESS,
    REMEMBER_ME,
    LOGOUT_SUCCESS
} from '../actions/roleActions';

const INITIAL_STATE = {
    auth: false,
    details: {},
    rmbEmail: '',
    rmbCbox: false
};

export default function roleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state, 
                auth: true,
                details: action.data,
            };
        case UPDATE_SUCCESS:
            let details = state.details;
            details.user = action.data;
            return {
                ...state, 
                details: details
            };
        case REMEMBER_ME:
            let email = '';
            if(action.bool){
                email = action.email;
            }
            return {
                ...state, 
                rmbEmail: email,
                rmbCbox: action.bool
            };
        case LOGOUT_SUCCESS:
            // localStorage.clear()
            return {
                ...state,
                auth: false,
                details: {}
            };
        default:
            return state;
    }
}