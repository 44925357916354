import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from 'reactstrap';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

const PasswordModal = (props) => {
    const [ passwordFocused, setPasswordFocused ] = useState(false);
    const [ confirmPasswordFocused, setConfirmPasswordFocused ] = useState(false);

    const onSubmit = async(value) => {
        client.authenticate()
            .then(()=>{
                return client.service('users').patch(props.dataEdit.userId, value);
            })
            .then((res) => {
                props.toggleModal();
                props.notificationOpen(true, 'success', 'User ' + res.username + ' Edited Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Edit Password ({props.dataEdit.email?props.dataEdit.email:''})
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Row>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='Passowrd'
                                            >
                                                Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: passwordFocused
                                                }, {
                                                    'has-danger': errors.password && touched.password
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='password'
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.password && touched.password && (
                                                    <div className='input-feedback'>{errors.password}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='confirmPassword'
                                            >
                                                Confirm Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: confirmPasswordFocused
                                                }, {
                                                    'has-danger': errors.confirmPassword && touched.confirmPassword
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Confirm Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='confirmPassword'
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setConfirmPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setConfirmPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.confirmPassword && touched.confirmPassword && (
                                                    <div className='input-feedback'>{errors.confirmPassword}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default PasswordModal;