/* eslint-disable no-undef */
import React, { useState } from 'react';
import DynamicPriceModalEdit from '../components/DynamicPrice/DynamicPriceModalEdit';
import { connect } from 'react-redux';
import { fetchDynamicPrice, pushDynamicPrice, updateDynamicPrice, removeDynamicPrice } from '../redux/actions/dynamicPriceActions';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModal } from '../zustand/modalStore.js';

import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

const DynamicPriceEdit = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,
    
        actions: modalActions,
    } = useModal();

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <div className='mt-4'>
                <DynamicPriceModalEdit 
                    // dataEdit={dataEdit}
                    // modalVisible={modalVisibleEdit} 
                    // toggleModal={toggleModalEdit}
                    notificationOpen={notificationOpen} 
                    updateDynamicPrice={props.updateDynamicPrice}
                    dynamicPriceData={props.dynamicPriceData}
                    currency={props.currency}
                    role={props.role}
                />
            </div>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    dynamicPriceData: state.dynamicPrice.data,
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
});
  
const mapDispatchToProps = {
    fetchDynamicPrice: fetchDynamicPrice,
    pushDynamicPrice: pushDynamicPrice,
    updateDynamicPrice: updateDynamicPrice,
    removeDynamicPrice: removeDynamicPrice,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicPriceEdit);