import React, { useState, useLayoutEffect } from 'react';
import { Formik } from 'formik';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/common/search.svg';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Form,
    Modal,
    Row,
    Col,
    InputGroup,
    Input,
} from 'reactstrap';

import client from '../../../feathers.js';
import { cloneDeep } from 'lodash';
import axios from 'axios';

const CreateSample = ({
    modalVisible,
    toggleModal,
    userId,
    dataEdit,
    notificationOpen
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ regexUserName, setRegexUserName ] = useState('');

    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };
  
    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            users.map(x => {
                cloneCheckbox.push(x._id);
                return x;
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const fetchData = (userId, regexUserName, notificationOpen) => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    return client.service('users').find({
                        query: {
                            role: 'admin',
                            superAdminId: userId,
                            $skip: 0,
                            orRegex: { email: regexUserName },
                            $limit: 100,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    setUsers(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    useLayoutEffect(() => {
        setCheckboxChecked([]);
    }, []);

    useLayoutEffect(() => {
        fetchData(userId, regexUserName, notificationOpen, 0, 1);
    }, [userId, regexUserName]);

    const onSubmit = () => {
        if(dataEdit.source.includes('api')){
            notificationOpen(true, 'danger', 'Unable to create API Product!');
            return false;
        }
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}v1/create/sample-prodcut`,
                    {
                        _id: dataEdit._id,
                        userIds: checkboxChecked,
                        superAdminId: userId
                    },
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                console.log(res);
                notificationOpen(true, 'danger', 'Sample Product created successful!');
            })
            .catch((err) => {
                console.log(err);
                notificationOpen(true, 'danger', err.message);
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={modalVisible}
                toggle={toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Selected {checkboxChecked.length} Users
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <input
                                            className='mr-2'
                                            type='checkbox'
                                            id='rememberMe'
                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                        />
                                        <InputGroup className='input-group-alternative d-flex align-items-center'>
                                            <SearchIcon height={20} className='mx-1'/>
                                            <Input
                                                placeholder='Search Email...'
                                                className='w-auto d-inline-block mr-2'
                                                type='text'
                                                bsSize='sm'
                                                size={20}
                                                value={regexUserName}
                                                onChange={(e) => setRegexUserName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </div>
                                    <Card className='btn-grey mb-4'>
                                        <CardBody>
                                            <Row>
                                                <Col md='12' className='text-center'>

                                                    {
                                                        users.map((v, i) => {
                                                            return (
                                                                <div key={i} className='d-flex align-items-center'>
                                                                    <input
                                                                        className='mr-2'
                                                                        type='checkbox'
                                                                        id={v._id}
                                                                        checked={checkboxChecked.includes(v._id)}
                                                                        value={checkboxChecked.includes(v._id)}
                                                                        onChange={(e) => handleCheckboxChange(v._id)}
                                                                    />
                                                                    <span className='mb-0'>
                                                                        {v.email}
                                                                    </span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                              
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default CreateSample;