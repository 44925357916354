import {
    FETCH_COMPONENTS_TYPE,
    PUSH_COMPONENTS_TYPE,
    UPDATE_COMPONENTS_TYPE,
    REMOVE_COMPONENTS_TYPE,
    FETCH_COMPULSORY_COMPONENTS_TYPE,
    PUSH_COMPULSORY_COMPONENTS_TYPE,
    UPDATE_COMPULSORY_COMPONENTS_TYPE,
    REMOVE_COMPULSORY_COMPONENTS_TYPE,
} from '../actions/componentsTypeActions';

import env from '../../env.js';

const INITIAL_STATE = {
    data: [],
    componentTypeColors: [],
};

export default function componentsTypeReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_COMPONENTS_TYPE:{
            let indexUsed = 0;
            const colors = action.data.map((x) => {
                if (env.componentColors[x.name]) {
                    return {
                        name: x.name,
                        color: env.componentColors[x.name]
                    };
                } else {
                    const color = env.componentColors.remaining[indexUsed];
                    indexUsed++;
                    if (color) {
                        return {
                            name: x.name,
                            color,
                        };
                    } else {
                        return undefined;
                    }
                }
            });

            return {
                ...state,
                data: action.data,
                componentTypeColors: colors,
            };
        }
        case PUSH_COMPONENTS_TYPE:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_COMPONENTS_TYPE:
            return {
                ...state,
                data: state.data.map((v)=>{
                    if(v._id === action.data._id) {
                        return action.data;
                    } else return v;
                })
            };
        case REMOVE_COMPONENTS_TYPE:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        case FETCH_COMPULSORY_COMPONENTS_TYPE:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_COMPULSORY_COMPONENTS_TYPE:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_COMPULSORY_COMPONENTS_TYPE:
            return {
                ...state,
                data: state.data.map((v)=>{
                    if(v._id === action.data._id) {
                        return action.data;
                    } else return v;
                })
            };
        case REMOVE_COMPULSORY_COMPONENTS_TYPE:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        default:
            return state;

    }
}