/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchGroups, pushGroups, updateGroups, removeGroups } from '../redux/actions/groupsActions';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import ModalConfirmation from "../components/Extra/ModalConfirmation.js"

import Notifications from "../components/Extra/Notifications.js"
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import client from "../feathers"
import axios from "axios"

const Groups = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ PAGESIZE, setPAGESIZE ] = useState(30);
  const {
    isOpen: modalIsOpen,
    title: modalTitle,
    content: modalContent,
    callback: modalCallbackNew,

    actions: modalActions,
} = useModal();

  function paginationCount(length, currentPage, pageSize) {
    return {
        total: length,
        per_page: pageSize,
        current_page: currentPage,
        last_page: Math.ceil(length / pageSize),
        from: ((currentPage - 1) * pageSize) + 1,
        to: currentPage * pageSize
    };
  };

  useEffect(() => {
    fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1)
  }, [props.userId, props.fetchGroups])

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    client.authenticate()
    .then((auth)=>{
      return client.service('product-groups').remove(dataEdit._id)
    })
    .then((res) => {
      // console.log(res)
      props.removeGroups({
        _id: res._id
      })
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }

  const notificationOpen = (bool, color, message) => {
    modalActions.setIsOpen(bool);
    modalActions.setTitle('Alert');
    modalActions.setContent(message);
    modalActions.setCallback(null);
    // setIsOpen(bool)
    // setColor(color)
    // setMessage(message)
  }
  
  const fetchData = (userId, fetchGroups, notificationOpen, skip, currentPage, pageSize) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('product-groups').find({
          query: {
            userId: userId,
            $skip: skip,
            $limit: pageSize,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage, pageSize))
        fetchGroups(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-center mb-0"
          listClassName="justify-content-center mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1, PAGESIZE)); 
                fetchData(props.userId, props.fetchGroups, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1, PAGESIZE)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i, PAGESIZE)); 
                    fetchData(props.userId, props.fetchGroups, notificationOpen, (i-1)*PAGESIZE, i, PAGESIZE)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1, PAGESIZE)); 
                fetchData(props.userId, props.fetchGroups, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1, PAGESIZE)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      {(window.self === window.top) && (
          <div className="header" style={{height: 64}}>
          <span className="mask" style={{backgroundColor: '#fff'}} />
        </div>
      )}
      {/* Page content */}
      <Container fluid>
        {/* Dark table */}
        <Row className="mt-4">
          <div className="col">
            <Card className="shadow">
              <Card>
                <CardBody>
                  <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                    <Col md="5">
                      <h3 className="mb-0">Group List</h3>
                    </Col>
                    <Col md="7" className="text-right">
                      <Button
                            color='primary'
                            onClick={() => props.history.push(`/${props.role}/groups-create`)}
                        >
                            <span className='btn-inner--text'>+ Group</span>
                        </Button>
                    </Col>
                  </Row>
                  <Card>
                    <CardBody className="p-0">
                      <div className="table-responsive mac-scrollbar">
                        <Table className="align-items-center table-flush" style={{minHeight: 200}}>
                          <thead>
                            <tr>
                              <th scope="col">Name of Group</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Products</th>
                              <th scope="col">Description</th>
                              <th scope="col">Users</th>
                              <th scope="col" className="text-right">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.groupsData.length > 0? (
                              props.groupsData.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {v.groupName}
                                    </td>
                                    <td>{moment(v.createdAt).format("DD-MM-YYYY")}</td>
                                    <td>{v.productIds?.length || 0}</td>
                                    <td>{v.description}</td>
                                    <td>{v.userIds?.length || 0}</td>
                                    <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <DropdownItem
                                            onClick={() => {
                                              props.history.push(`/${props.role}/groups-edit/${v._id}`)
                                              setDataEdit(v);
                                            }}
                                          >
                                            Edit
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => {
                                              props.history.push(`/${props.role}/groups-products/${v._id}`)
                                              setDataEdit(v);
                                            }}
                                          >
                                            Edit Products
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => {
                                              props.history.push(`/${props.role}/groups-users/${v._id}`)
                                              setDataEdit(v);
                                            }}
                                          >
                                            Edit Users
                                          </DropdownItem>
                                          <DropdownItem
                                            className="text-danger"
                                            onClick={() => {
                                              setDataEdit(v)
                                              toggleModalConfirm()
                                            }}
                                          >
                                              Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                  )
                                })
                              ): (
                                <tr>
                                  <td>
                                    <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                  </td>
                                </tr>
                                )
                              }
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                    <CardFooter className="py-4">
                      <div className='d-flex justify-content-between'>
                      <div>
                                  <UncontrolledDropdown>
                                      <DropdownToggle
                                          className='btn-icon-only text-black p-0'
                                          style={{width: 100}}
                                          caret
                                          href='#pablo'
                                          role='button'
                                          size='sm'
                                          color=''
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          {PAGESIZE} / page
                                      </DropdownToggle>
                                      <DropdownMenu className='w-100'>
                                          <DropdownItem 
                                              value={30}
                                              onClick={() => {
                                                  setPAGESIZE(30);
                                                  fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1, 30)
                                              }
                                              }
                                          >
                                              30 / page
                                          </DropdownItem>
                                          <DropdownItem 
                                              value={50} 
                                              onClick={() => {
                                                  setPAGESIZE(50);
                                                  fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1, 50)
                                              }}
                                          >
                                              50 / page
                                          </DropdownItem>
                                          <DropdownItem 
                                              value={100} 
                                              onClick={() => {
                                                  setPAGESIZE(100);
                                                  fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1, 100)
                                              }}
                                          >
                                              100 / page
                                          </DropdownItem>
                                          <DropdownItem 
                                              value={150} 
                                              onClick={() => {
                                                  setPAGESIZE(150);
                                                  fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1, 150)
                                              }}
                                          >
                                              150 / page
                                          </DropdownItem>
                                          <DropdownItem 
                                              value={200} 
                                              onClick={() => {
                                                  setPAGESIZE(200);
                                                  fetchData(props.userId, props.fetchGroups, notificationOpen, 0, 1, 200)
                                              }}
                                          >
                                              200 / page
                                          </DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                              </div>
                              
                        <nav aria-label="...">
                          <PaginationRender />
                        </nav>
                      </div>
                    </CardFooter>
                  </Card>
                </CardBody>
              </Card>
            </Card>
          </div>
        </Row>
      </Container>
     
      <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title="Delete Group"
        content={(
          <div className="text-center">
            <div className="text-danger mb-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
            </div>
            <h3>Are you sure want delete this Group?</h3>
          </div>
        )}
      />
      <ModalWithCallback
          isOpen={modalIsOpen}
          toggle={() => modalActions.toggle()}
          title={modalTitle}
          content={modalContent}
          callback={modalCallbackNew}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
  groupsData: state.groups.data,
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  fetchGroups: fetchGroups,
  pushGroups: pushGroups,
  updateGroups: updateGroups,
  removeGroups: removeGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(Groups);