import { create } from 'zustand';

const useComponentStore = create((set) => ({
    addComponentModalIsOpen: false,
    pricingRuleModalIsOpen: false,
    marginProfileModalIsOpen: false,
    productSelectorModalIsOpen: false,
    customFieldModalIsOpen: false,

    selectedPricingRule: '',
    selectedComponentType: '',
    selectedComponentTypeId: '',
    storedLocation: '',
    excludedComponentTypes: ['Material', 'Binding Type'],
    modalAllowBack: false,
    actions: {
        setAddComponentModalIsOpen: (data) => set((state) => ({ addComponentModalIsOpen: data })),
        setPricingRuleModalIsOpen: (data) => set((state) => ({ pricingRuleModalIsOpen: data })),
        setMarginProfileModalIsOpen: (data) => set((state) => ({ marginProfileModalIsOpen: data })),
        setProductSelectorModalIsOpen: (data) => set((state) => ({ productSelectorModalIsOpen: data })),
        setCustomFieldModalIsOpen: (data) => set((state) => ({ customFieldModalIsOpen: data })),

        setSelectedPricingRule: (data) => set((state) => ({ selectedPricingRule: data })),
        setExcludedComponentTypes: (data) => set((state) => ({ excludedComponentTypes: data })),
        setModalAllowBack: (data) => set((state) => ({ modalAllowBack: data })),
        setSelectedComponentType: (data) => set((state) => ({ selectedComponentType: data })),
        setSelectedComponentTypeId: (data) => set((state) => ({ selectedComponentTypeId: data })),
        setStoredLocation: (data) => set((state) => ({ storedLocation: data })),
    },
}));

export const useComponents = () => useComponentStore((state) => state);
