import React, { useState } from 'react';
import { Formik } from 'formik';

// reactstrap components
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const DisableProducts = (props) => {
    const [ loading, setLoading ] = useState(false);

    const onSubmit = async(value) => {
        setLoading(true);

        let processInsert = await props.checkboxChecked.map(async(x) => {
            let r = await props.productsData.map(async(x1) => {
                if(x === x1._id){
                    return await new Promise(resolve => {

                        client.authenticate()
                            .then(()=>{
                                return client.service('products').patch(x1._id, {
                                    status: false
                                });
                            })
                            .then((res) => {
                                res.status = false;

                                props.updateProducts(res);
                                return resolve(res);
                            })
                            .catch((err)=>{
                                console.log(err.message);
                            });
                    });
                }else{
                    return x1;
                }
            });
            if(r){
                return x;
            }
        });
    
        const result = await Promise.all(processInsert);
        if(result){
      
            let mapRecords = (result.map(x => props.productsData.find(x1 => x1._id === x)));
            let numOfActive = mapRecords.filter(e => e.source.includes('prefix')).length;
            props.setActiveProduct(props.activeProduct-numOfActive);

            setLoading(false);
            props.toggleModal();
            props.notificationOpen(true, 'success', 'Products Status Update Successfully');
        }
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Disable Products
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Row>
                                        <Col md='12' className='text-center'>
                                            Disable Products?
                                        </Col>
                                    </Row>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default DisableProducts;