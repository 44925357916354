import React from 'react';
import { Field, useFormikContext } from 'formik';
import { ReactComponent as DragIcon} from '../../assets/img/icons/common/drag.svg';

import {
    Row,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
} from 'reactstrap';
const ComponentNumberInput = (props) => {
    const { className, title, location, chipText, onBlur, disabled, hasDrag, decimal } = props;
    const { setFieldValue } = useFormikContext(); 

    const handleChange = (e) => {
        let value = e.target.value;
        if(decimal){
            // Limit to the specified number of decimal places
            if (value.includes('.')) {
                const [integerPart, decimalPart] = value.split('.');
                if (decimalPart.length > decimal) {
                    value = `${integerPart}.${decimalPart.slice(0, decimal)}`;
                }
            }
        }

        // Set the formatted value back to the input
        e.target.value = value;
        setFieldValue(location, value);
    };

    return (  
        <Row className={`m-0 d-flex align-items-center justify-content-between rounded bg-white ${className}`}>
            <div className='input-card d-flex align-items-center justify-content-between w-100'>
                {hasDrag && (
                    <DragIcon 
                        width={25}
                        height={25}
                        className='mr-1'
                    />
                )}
                <small className='mr-1 mb-0 w-50' >{title}</small>
                <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF', maxWidth: 130 }}>
                    <Field 
                        className='form-control form-control-sm form-control-alternative mr-1 bg-transparent'
                        style={{ borderRadius: 50 }}
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={location}
                        onBlur={(e) => (onBlur) ? onBlur(e) : undefined}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                    {chipText && (
                        <InputGroupAddon addonType='append'>
                            <InputGroupText style={{ backgroundColor: '#0866FF', borderRadius: 50 }}>
                                <small className='m-0' style={{ color: 'white' }}>{chipText}</small>
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                </InputGroup>
            </div>
        </Row>
    );
};
 
export default ComponentNumberInput;
