import {
    FETCH_PACKAGE,
} from '../actions/packageActions';

const INITIAL_STATE = {
    data: undefined,
};

export default function packageReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_PACKAGE:
            return {
                ...state,
                data:action.data,
            };
        default:
            return state;

    }
}