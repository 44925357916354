import React from 'react';
import { Field, FieldArray } from 'formik';

// reactstrap components
import {
    Button,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';

const OptionTypeFieldArray = ({
    values,
    materialIndex,
    optionIndex,
    currency
}) => {
    return (  
        <FieldArray
            name={`looseSheetData.materials[${materialIndex}].options[${optionIndex}].optionType`}
            render={optionTypeArrayHelpers => (
                <>
                    {
                        values.map((ot, otIndex) => {
                            return (
                                <Row key={`${otIndex}`} className='mb-2'>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='4'>
                                                <Field 
                                                    className='form-control form-control-sm form-control-alternative'
                                                    placeholder='Name'
                                                    type='text'
                                                    bsSize='sm'
                                                    name={`looseSheetData.materials[${materialIndex}].options[${optionIndex}].optionType[${otIndex}].name`}
                                                />
                                            </Col>
                                            <Col md='4'>
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                        <InputGroupText className='bg-transparent'>
                                                            <span className='text-uppercase'>{currency}</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field 
                                                        className='form-control form-control-sm form-control-alternative'
                                                        type='number'
                                                        onWheel={(e) => e.target.blur()}
                                                        bsSize='sm'
                                                        name={`looseSheetData.materials[${materialIndex}].options[${optionIndex}].optionType[${otIndex}].basePrice`}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col md='4'>
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                        <InputGroupText className='bg-transparent'>
                                                            <span className='text-uppercase'>{currency}</span>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field 
                                                        className='form-control form-control-sm form-control-alternative'
                                                        type='number'
                                                        bsSize='sm'
                                                        name={`looseSheetData.materials[${materialIndex}].options[${optionIndex}].optionType[${otIndex}].pricePerSheet`}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md='2' className='d-flex justify-content-end align-items-center'>
                                        <Button
                                            color='secondary'
                                            type='button'
                                            size='sm'
                                            onClick={() => optionTypeArrayHelpers.remove(otIndex)}
                                        >
                                            <div className='btn-inner--text text-red'>
                                                <i
                                                    className='fa fa-minus'
                                                />
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                    <Row>
                        <Col md='12'>
                            <a 
                                href='/' 
                                onClick={ (event) => {
                                    event.preventDefault();
                                    optionTypeArrayHelpers.push({ name: '', basePrice: 0, pricePerSheet: 0 });
                                }}      
                            >
                                <span>
                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                    Add new line
                                </span>
                            </a>
                        </Col>
                    </Row>
                </>
            )}/>
    );
};
 
export default OptionTypeFieldArray;