import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ContentPrintingComponent from './ContentPrintingComponent';
import OptionForm from './OptionForm';

import {
    Input,
    Card,
    CardBody,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
} from 'reactstrap';

const ContentMaterialForm = ({
    contentMaterials,
    setContentMaterials,
    pushContentMaterials,
    childOnChange,
    currency,
    setAmountFocused,
    handleBlur,
}) => {
    const [ showContent, setShowContent ] = useState(true);

    const pushContentPrintings = (cmIndex) => {
        let contentMaterialsClone = cloneDeep(contentMaterials);
        contentMaterialsClone[cmIndex].contentPrintings.push({ name: '', price: 0 });

        setContentMaterials(contentMaterialsClone);
    };

    const removeContentPrinting = (cmIndex, cpIndex) => {
        let contentMaterialsClone = cloneDeep(contentMaterials);

        const before = [...contentMaterialsClone[cmIndex].contentPrintings.slice(0, cpIndex)];
        const after = [...contentMaterialsClone[cmIndex].contentPrintings.slice(cpIndex + 1)];

        const newArray = [...before, ...after]; 

        contentMaterialsClone[cmIndex].contentPrintings = newArray;
        setContentMaterials(contentMaterialsClone);
    };

    const ppOnChange = (original, setter, variable, value, index) => {
        const massagedPP = Math.ceil(value / 4) * 4;
        childOnChange(original, setter, variable, massagedPP, index);
    };

    const removeContentMaterial = (cmindex) => {
        let contentMaterialsClone = cloneDeep(contentMaterials);

        const before = [...contentMaterialsClone.slice(0, cmindex)];
        const after = [...contentMaterialsClone.slice(cmindex + 1)];

        const newArray = [...before, ...after]; 

        contentMaterialsClone = newArray;
        setContentMaterials(contentMaterialsClone);
    };

    const duplicateContentMaterial = (cmindex) => {
        let contentMaterialsClone = cloneDeep(contentMaterials);

        contentMaterialsClone.push(contentMaterialsClone[cmindex]);
        setContentMaterials(contentMaterialsClone);
    };

    const toggleContent = () => {
        setShowContent(!showContent);
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className='d-flex flex-direction-row align-items-center'>
                    <h2 className='mb-0 mr-3'>Content Material</h2>
                    <Button
                        color='secondary'
                        type='button'
                        onClick={toggleContent}
                    >
                        <div className='btn-inner--text'>
                            <span>{(showContent) ? 'Hide' : 'Show'}</span>
                        </div>
                    </Button>
                </div>
                <Button
                    color='primary'
                    type='button'
                    onClick={() => pushContentMaterials()}
                >
                    <div className='btn-inner--text'>
                        <span>Add Content Material</span>
                    </div>
                </Button>
            </div>

            {(showContent) && (
                <>
                    {contentMaterials.map((content, cmindex) => {
                        return (
                            <Card key={`${cmindex}`} className='shadow mb-4'>
                                <CardBody>
                                    <div className='d-flex justify-content-end mb-3'>
                                        <Button
                                            color='default'
                                            type='button'
                                            onClick={() => duplicateContentMaterial(cmindex)}
                                        >
                                            <div className='btn-inner--text'>
                                                <i 
                                                    className='fa fa-copy'
                                                    style={{fontSize: '1rem'}}
                                                />
                                                <span>Duplicate</span>
                                            </div>
                                        </Button>
                                        <Button
                                            color='secondary'
                                            type='button'
                                            onClick={() => removeContentMaterial(cmindex)}
                                        >
                                            <div className='btn-inner--text text-red'>
                                                <i 
                                                    className='fa fa-trash'
                                                    style={{fontSize: '1rem'}}
                                                />
                                                <span>Delete</span>
                                            </div>
                                        </Button>
                                    </div>
                                    <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                                        <Row className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            {`Content Material (${cmindex + 1})`}
                                                        </small>
                                                    </Col>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Price / Sheet
                                                        </small>
                                                    </Col>
                                                    <Col md='2'>
                                                        <small className='font-weight-bold'>
                                                            {'Min PP (in 4\'s)'}
                                                        </small>
                                                    </Col>
                                                    <Col md='2'>
                                                        <small className='font-weight-bold'>
                                                            {'Max PP (in 4\'s)'}
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row key={cmindex} className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <Input
                                                            className='form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            bsSize='sm'
                                                            value={content.name}
                                                            onChange={(e) => childOnChange(contentMaterials, setContentMaterials, 'name', e.target.value, cmindex)}
                                                        />
                                                    </Col>
                                                    <Col md='4'>
                                                        <InputGroup className='input-group-alternative'>
                                                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                                <InputGroupText className='bg-transparent'>
                                                                    <span className='text-uppercase'>{currency}</span>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type='number'
                                                                placeholder='0'
                                                                value={Number(content.price)}
                                                                onChange={(e) => childOnChange(contentMaterials, setContentMaterials, 'price', e.target.value, cmindex)}
                                                                onWheel={ event => event.currentTarget.blur() }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md='2'>
                                                        <InputGroup className='input-group-alternative'>
                                                            <Input
                                                                type='number'
                                                                value={Number(content.minPP)}
                                                                placeholder='0'
                                                                onChange={(e) => childOnChange(contentMaterials, setContentMaterials, 'minPP', e.target.value, cmindex)}
                                                                onBlur={(e) => ppOnChange(contentMaterials, setContentMaterials, 'minPP', e.target.value, cmindex)}
                                                                onWheel={ event => event.currentTarget.blur() }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md='2'>
                                                        <InputGroup className='input-group-alternative'>
                                                            <Input
                                                                type='number'
                                                                placeholder='0'
                                                                value={Number(content.maxPP)}
                                                                onChange={(e) => childOnChange(contentMaterials, setContentMaterials, 'maxPP', e.target.value, cmindex)}
                                                                onBlur={(e) => ppOnChange(contentMaterials, setContentMaterials, 'maxPP', e.target.value, cmindex)}
                                                                onWheel={ event => event.currentTarget.blur() }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Content Printing
                                                        </small>
                                                    </Col>
                                                    <Col md='4'>
                                                        <small className='font-weight-bold'>
                                                            Price / Sheet
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            content.contentPrintings.map((contentPrinting, cpindex) => {
                                                return (
                                                    <ContentPrintingComponent 
                                                        key={`${contentPrinting.name}-${cpindex}`}
                                                        contentPrinting={contentPrinting}
                                                        cpindex={cpindex}
                                                        removeContentPrinting={removeContentPrinting}
                                                        contentMaterials={contentMaterials}
                                                        cmindex={cmindex}
                                                        setContentMaterials={setContentMaterials}
                                                        currency={currency}
                                                        setAmountFocused={setAmountFocused}
                                                        handleBlur={handleBlur}
                                                    />
                                                );
                                            })
                                        }
                                        <Row className='mb-3'>
                                            <Col md='12'>
                                                <a 
                                                    href='/' 
                                                    onClick={ (event) => {
                                                        event.preventDefault();
                                                        pushContentPrintings(cmindex);
                                                    }} 
                                                >
                                                    <span>
                                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                        Add new line
                                                    </span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <OptionForm 
                                            key={`${content.name}-${cmindex}`}
                                            parent={content}
                                            cmindex={cmindex}
                                            parents={contentMaterials}
                                            setParents={setContentMaterials}
                                            currency={currency}
                                            setAmountFocused={setAmountFocused}
                                            handleBlur={handleBlur}
                                            childOnChange={childOnChange}
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        );
                    })}
                </>
            )}
        </>
    );
};
 
export default ContentMaterialForm;