import React from 'react';
// reactstrap components
import {
    Row,
    Col
} from 'reactstrap';

const performChecks = (values) => {
    if(!values.avaiSources.booklet) values.avaiSources.booklet = false;
};
const AvaiSources = ({
    values,
    handleChange,
    setFieldValue
}) => {
    performChecks(values);
    return (  
        <>
            <hr className='my-4' />
            <label
                className='form-control-label mb-0'
                htmlFor='currency'
            >
                Sources
            </label>
            <Row>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        CSV
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.csv'
                                checked={values.avaiSources.csv}
                                onChange={(e) => setFieldValue('avaiSources.csv', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        API
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.api'
                                checked={values.avaiSources.api}
                                onChange={(e) => setFieldValue('avaiSources.api', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        SC-Area
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.poster'
                                checked={values.avaiSources.poster}
                                onChange={(e) => setFieldValue('avaiSources.poster', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        SC-Sheet
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.looseSheet'
                                checked={values.avaiSources.looseSheet}
                                onChange={(e) => setFieldValue('avaiSources.looseSheet', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        SC-Booklet
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.booklet'
                                checked={values.avaiSources.booklet}
                                onChange={(e) => setFieldValue('avaiSources.booklet', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        SC-Item
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.product'
                                checked={values.avaiSources.product}
                                onChange={(e) => setFieldValue('avaiSources.product', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                        </label>
                    </div>
                </Col>
                <Col md='6'>
                    <label
                        className='form-control-label mb-0'
                        htmlFor='currency'
                    >
                        SC-Packaging
                    </label>
                    <div>
                        <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                            <input 
                                type='checkbox' 
                                id='avaiSources.packaging'
                                checked={values.avaiSources.packaging}
                                onChange={(e) => setFieldValue('avaiSources.packaging', e.target.checked)}
                            />
                            <span className='custom-toggle-slider rounded-circle'></span>
                        </label>
                    </div>
                </Col>
            </Row>
        </>
    );
};
 
export default AvaiSources;