import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/common/delete.svg';

import ComponentNumberInput from '../ComponentNumberInput.js';
import ComponentTextInput from '../ComponentTextInput.js';

// reactstrap components
import {
    Button,
    Row,
    Col,
} from 'reactstrap';

const Eyelet = ({
    values,
    index,
    remove
}) => {
    return (  
        <Col md='12' className='p-0'>
            <Row className='m-0 mb-1 align-items-center justify-content-start'>
                <Col className='pr-0'>
                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                        <ComponentTextInput
                            hasDrag
                            placeholder='Label'
                            location={`data.options[${index}].label`}
                        />
                    </Row>
                </Col>
                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Total Eyelet'}
                        location={`data.options[${index}].eyelet`}
                    />
                </Col>
                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Base Price'}
                        location={`data.options[${index}].basePrice`}
                    />
                </Col>

                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Min Amount'}
                        location={`data.options[${index}].minAmount`}
                    />
                </Col>

                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Per Eyelet'}
                        location={`data.options[${index}].perEyelet`}
                    />
                </Col>

                {values.calcType === 'Sqft' && (
                    <Col className='p-0'>
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'Wastage'}
                            location={`data.options[${index}].wastage`}
                            chipText={values.defaultSize || values.calcType}
                        />
                    </Col>
                )}

                <Col md='1'>
                    <Button 
                        key={'asd'}
                        color='danger'
                        className='btn-round'
                        onClick={() => remove(index)}
                    >
                        <DeleteIcon 
                            width={14}
                            height={14}
                        />
                    </Button>
                </Col>
            </Row>
        </Col>
    );
};
 
export default Eyelet;