import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import AssignMargin from './AssignMargin.js';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/trash.svg';
import { withRouter } from 'react-router';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/common/search.svg';

// reactstrap components
import {
    Table,
    Card,
    CardHeader,
    Row,
    Col,
    Button,
    CardBody,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    InputGroup
} from 'reactstrap';

function sourceName(source){
    if(source === 'csv'){
        return 'CSV';
    }else if(source === 'api'){
        return 'Excard (Api)';
    }else if(source === 'apiSg'){
        return 'Excard SG (Api)';
    }else if(source === 'apiEast'){
        return 'Excard East (Api)';
    }else if(source === 'sticker'){
        return 'SC-Sticker';
    }else if(source === 'certificate'){
        return 'SC-Certificate';
    }else if(source === 'document'){
        return 'SC-Document';
    }else if(source === 'booklet'){
        return 'SC-Booklet';
    }else if(source === 'poster'){
        return 'SC-Area';
    }else if(source === 'product'){
        return 'SC-Item';
    }else if(source === 'loose-sheet'){
        return 'SC-Sheet';
    }else if(source === 'csv-prefix'){
        return 'CSV-assigned';
    }else if(source === 'sticker-prefix'){
        return 'SC-Sticker-assigned';
    }else if(source === 'certificate-prefix'){
        return 'SC-Certificate-assigned';
    }else if(source === 'document-prefix'){
        return 'SC-Document-assigned';
    }else if(source === 'booklet-prefix'){
        return 'SC-Booklet-assigned';
    }else if(source === 'poster-prefix'){
        return 'SC-Area-assigned';
    }else if(source === 'loose-sheet-prefix'){
        return 'SC-Sheet-assigned';
    }else if(source === 'api-prefix'){
        return 'API-EC-assigned';
    }else{
        return source;
    }
}

const SelectedProducts = ({
    productsData,
    displayProductsData,
    setDisplayProductsData,
    setProductsData,
    tableSorting,
    sortList,
    notificationOpen,
    marginsData,
    history,
    role,
    dataEdit,
    groupId
}) => {
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ modalVisibleAssignMargin, setModalVisibleAssignMargin ] = useState(false);
    const [ PAGESIZE, setPAGESIZE ] = useState(30);
    const [ pagination, setPagination ] = useState({});

    const toggleModalAssignMargin = () => {
        setModalVisibleAssignMargin(!modalVisibleAssignMargin);
    };

    const assignMargin = () => {
        if(checkboxChecked.length > 0){
            toggleModalAssignMargin();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            displayProductsData.map(x => {
                cloneCheckbox.push(x._id);
                return x;
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const removeProduct = (id) => {
        let cloneOption = cloneDeep(productsData);
        let findId = cloneOption.findIndex(e => e._id === id);
        if(findId !== -1){
            cloneOption.splice(findId, 1);   
            setProductsData(cloneOption);
            setDisplayProductsData(cloneOption);
        }
    };
    
    useEffect(() => {
        fetchDisplayProductData(0, 1, PAGESIZE);
    }, [productsData]);

    const fetchDisplayProductData = (skip, current_page, pageSize) => {
        setPagination(paginationCount(productsData.length, current_page, pageSize));
        setDisplayProductsData(cloneDeep(productsData).splice(skip, pageSize));
    };

    function paginationCount(length, currentPage, pageSize) {
        return {
            total: length,
            per_page: pageSize,
            current_page: currentPage,
            last_page: Math.ceil(length / pageSize),
            from: ((currentPage - 1) * pageSize) + 1,
            to: currentPage * pageSize
        };
    }

    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                fetchDisplayProductData((pagination.current_page-2)*PAGESIZE, pagination.current_page-1, PAGESIZE);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i, index) => {
                        return (
                            <PaginationItem key={index} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        fetchDisplayProductData((i-1)*PAGESIZE, i, PAGESIZE);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                fetchDisplayProductData((pagination.current_page)*PAGESIZE, pagination.current_page+1, PAGESIZE);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            <Row className='m-1 mt-3'>
                <h2 className='mb-0'>{dataEdit?.groupName}</h2>
            </Row>
            <Card className='shadow'>
                <Card>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='4'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <SearchIcon height={20} className='mx-1'/>
                                    <Input
                                        placeholder='Search Product Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        onChange={(e) => {
                                            if(e.target.value){
                                                let data = cloneDeep(productsData).filter((i) => i.name
                                                    .toLowerCase()
                                                    .includes(e.target.value.toLowerCase()));
                    
                                                setDisplayProductsData(data);
                                                setPagination(paginationCount(data.length, 1, PAGESIZE));
                                            }else{
                                                setDisplayProductsData(productsData);
                                                setPagination(paginationCount(productsData.length, 1, PAGESIZE));
                                            } 
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md='8' className='d-flex justify-content-end'>
                                <Button
                                    color='tertiary'
                                    onClick={assignMargin}
                                >
                                    <span className='btn-inner--text'>Assign Profit Margin</span>
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={() => history.push(`/${role}/groups-products-add/${groupId}`)}
                                >
                                    <span className='btn-inner--text'>+ Product</span>
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody className='p-0'>
                                <div className='table-responsive mac-scrollbar'>
                                    <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                        <thead>
                                            <tr>
                                                <th scope='col'>
                                                    <input
                                                        className=''
                                                        type='checkbox'
                                                        id='rememberMe'
                                                        // checked={values.rememberMe}
                                                        // value={values.rememberMe}
                                                        onChange={(e) => handleCheckAll(e.target.checked)}
                                                    />
                                                </th>
                                                <th scope='col'>
                                                    Product Name
                                                    {
                                                        // !tableSorting.includes('name') &&
                                                        tableSorting.name === 1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', -1)}>
                        <i className='fa fa-arrow-down' />
                    </span>)
                                                    }
                                                    {
                                                        // tableSorting.includes('name') &&
                                                        tableSorting.name === -1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', 1)}>
                        <i className='fa fa-arrow-up' />
                    </span>)
                                                    }
                                                </th>
                                                <th scope='col'>
                                                    Data Type
                                                    {
                                                        // !tableSorting.includes('source') &&
                                                        tableSorting.source === 1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', -1)}>
                        <i className='fa fa-arrow-down' />
                    </span>)
                                                    }
                                                    {
                                                        // tableSorting.includes('source') &&
                                                        tableSorting.source === -1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', 1)}>
                        <i className='fa fa-arrow-up' />
                    </span>)
                                                    }
                                                </th>
                                                <th scope='col'>Margin</th>
                                                <th>
                                                    Last Update
                                                    {
                                                        // !tableSorting.includes('updatedAt') &&
                                                        tableSorting.updatedAt === 1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('updatedAt', -1)}>
                        <i className='fa fa-arrow-down' />
                    </span>)
                                                    }
                                                    {
                                                        // tableSorting.includes('updatedAt') &&
                                                        tableSorting.updatedAt === -1 &&
                    (<span className='ml-1 cursor-pointer' onClick={() => sortList('updatedAt', 1)}>
                        <i className='fa fa-arrow-up' />
                    </span>)
                                                    }
                                                </th>
                                                <th scope='col' className='text-right'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayProductsData.length > 0? (
                                                displayProductsData.map((v, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope='row'>
                                                                <input
                                                                    className=''
                                                                    type='checkbox'
                                                                    id={v._id}
                                                                    checked={checkboxChecked.includes(v._id)}
                                                                    value={checkboxChecked.includes(v._id)}
                                                                    onChange={(e) => handleCheckboxChange(v._id)}
                                                                />
                                                            </th>
                                                            <td>
                                                                <span className='mb-0'>
                                                                    {v.name}
                                                                </span>
                                                            </td>
                                                            <td>{v.source? sourceName(v.source) : '-'}</td>
                                                            <td>{!isEmpty(v.marginSuperName)?v.marginSuperName.marginName:'-'}</td>
                                                            <td>{moment(v.updatedAt).format('DD-MM-YYYY')}</td>
                                                            <td className='text-right'>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => removeProduct(v._id)}
                                                                >
                                                                    <DeleteIcon height={15} width={15}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ): (
                                                <tr>
                                                    <td>
                                                        <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                        <CardFooter className='py-4'>
                            <div className='d-flex'>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        className='btn-icon-only text-black p-0'
                                        style={{width: 100}}
                                        caret
                                        href='#pablo'
                                        role='button'
                                        size='sm'
                                        color=''
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {PAGESIZE} / page
                                    </DropdownToggle>
                                    <DropdownMenu className='w-100'>
                                        <DropdownItem 
                                            value={10}
                                            onClick={() => {
                                                setPAGESIZE(10);
                                                fetchDisplayProductData(0, 1, 10);
                                            }}
                                        >
                                            10 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={30} 
                                            onClick={() => {
                                                setPAGESIZE(30);
                                                fetchDisplayProductData(0, 1, 30);
                                            }}
                                        >
                                            30 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={60} 
                                            onClick={() => {
                                                setPAGESIZE(60);
                                                fetchDisplayProductData(0, 1, 60);
                                            }}
                                        >
                                            60 / page
                                        </DropdownItem>
                                        <DropdownItem 
                                            value={100} 
                                            onClick={() => {
                                                setPAGESIZE(100);
                                                fetchDisplayProductData(0, 1, 100);
                                            }}
                                        >
                                            100 / page
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className='d-flex align-items-center'>
                                    <label className='text-xs font-weight-600 mb-0'>Go to </label>
                                    <Input
                                        placeholder='Page'
                                        className='d-inline-block ml-1 height-30-important'
                                        style={{width: 50}}
                                        // onChange={(e) => {
                                        //     fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, (e.target.value ? (Number(e.target.value)-1)*PAGESIZE : 0), Number(e.target.value), props.fetchMargins, regexProductName, supplier, source, PAGESIZE)
                                        // }}
                                        type='number'
                                        bsSize='sm'
                                    />
                                </div>
                            </div>
                    
                            <nav aria-label='...'>
                                <PaginationRender />
                            </nav>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Card>
            <AssignMargin 
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleAssignMargin} 
                toggleModal={toggleModalAssignMargin}
                notificationOpen={notificationOpen}
                // updateProducts={updateProducts}
                marginsData={marginsData}
                productsData={productsData}
                setProductsData={setProductsData}
            />
        </>
    );
};
 
export default withRouter(SelectedProducts);