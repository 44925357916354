import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';

import * as Yup from 'yup';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import { withRouter } from 'react-router';
import ComponentTextInput from './ComponentTextInput.js';

import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
});

const FileStorage = (props) => {
    const { isEdit, componentId, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [existingComponent, setExistingComponent] = useState();

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);


    // TODO: upload size, supported files
    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : undefined,
                    allowAllSource: true,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    calcType: (existingComponent) ? existingComponent.calcType : 'Sheet',
                    data: (existingComponent) ? existingComponent.data : {
                        fileStorage: {
                            location: 'Wordpress',
                            accessKey: '',
                            secret: '',
                        },
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;
                    
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-2 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.data.fileStorage.location ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                1
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <div style={{ width: 350 }}>
                                                            <Row className='m-0 d-flex align-items-center'>
                                                                <small className='mr-1 mb-1'>File Storage Location*</small>
                                                            </Row>
                                                            <Row className='m-0 px-3 d-flex align-items-center justify-content-around border rounded bg-white'>
                                                                <label className='d-flex align-items-center mb-0 mr-2'>
                                                                    <Field style={{marginRight: '3px'}} type='radio' name='data.fileStorage.location' value='Wordpress' default />
                                                                    <small className='mr-1 mb-0 w-10'>Wordpress</small>
                                                                </label>
                                                                {/* <label className='d-flex align-items-center mb-0 mr-2'>
                                                                    <Field style={{marginRight: '3px'}} type='radio' name='data.fileStorage.location' value='GoogleDrive' default />
                                                                    <small className='mr-1 mb-0 w-10'>Google Drive</small>
                                                                </label> */}
                                                                <label className='d-flex align-items-center mb-0 mr-2'>
                                                                    <Field style={{marginRight: '3px'}} type='radio' name='data.fileStorage.location' value='Dropbox' default />
                                                                    <small className='mr-1 mb-0 w-10'>Dropbox</small>
                                                                </label>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {(values.data.fileStorage.location.includes('Dropbox')) && (
                                                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                        <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                            {values.data.fileStorage.accessKey ? (
                                                                <DoneStepIcon/>
                                                            ) : (
                                                                <div className='d-flex align-items-center justify-content-center'
                                                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                    2
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <Col md='11'>
                                                            <div style={{ width: 350 }}>
                                                                <Row className='m-0 d-flex align-items-center'>
                                                                    <small className='mr-1 mb-1'>Access Token*</small>
                                                                </Row>
                                                                <Col md='12' className='pr-0'>
                                                                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                        <ComponentTextInput
                                                                            placeholder={'XXXXXXX'}
                                                                            location={'data.fileStorage.accessKey'}
                                                                        />
                                                                    </Row>
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(FileStorage);