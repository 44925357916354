/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

// reactstrap components
import {
  Label,
  Button,
  FormGroup,
  Input,
  Container,
} from "reactstrap";

import client from "../feathers"
import axios from "axios";

const Template = () => {
  const params = useParams();
  const [ data, setData ] = useState([])
  const [ keyIndex, setKeyIndex ] = useState(0)
  const [ fileIndex, setFileIndex ] = useState(0)
  const [ sizeIndex, setSizeIndex ] = useState(0)

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    document.body.style.height = "100vh";
    document.body.style.paddingTop = "25px";

    if(params.id === 'default'){
      fetchTemplate('default')
    }else{
      client.service('template-downloads').get(params.id)
      .then((res) => {
        if(res.csvFile.fileName){
         fetchTemplate(res.csvFile.fileName)
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }, [])

  const fetchTemplate = (fileName) => {
    axios({
      method: 'post',
      url: `${client.io.io.uri}getCSVTemplateData`,
      data: { fileName },
      config: { headers: {'Content-Type': 'application/json' }}
    })
    .then((res) => {

      if(res.data.data.length > 0){
        res.data.data.shift() // remove titlke
  
        let getKeys = []
        
        // insert key
        res.data.data.map(x => {
          if(!getKeys.find(e => e.title === x[0])){
            getKeys.push({
              title: x[0],
              data: []
            })
          }
          return x
        })
        
        // insert file type
        res.data.data.map(x => {
          getKeys.map(x1 => {
            if(x1.title === x[0]){
              if(!x1.data.find(e => e.title === x[1])){
                x1.data.push({
                  title: x[1],
                  data: []
                })
              }
            }
            return x
          })
        })

        // insert the rest
        res.data.data.map(x => {
          getKeys.map(x1 => {
            x1.data.map(x2 => {
              if(x1.title === x[0] && x2.title === x[1]){
                if(!x2.data.find(e => e.downloadLink === x[3])){
                  x2.data.push({
                    title: x[2],
                    downloadLink: x[3]
                  })
                }
              }
            })
            return x
          })
        })
        
        setData(getKeys)
      }

    })
    .catch((err) => {
      console.log(err)
    })
  }

  return (  
    <>
      <Container className="mt-4">
        <FormGroup>
          <Label for="productSelect">
            Product
          </Label>
          <Input
            id="productSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setKeyIndex(e.target.value)
              setFileIndex(0)
              setSizeIndex(0)
            }}
          >
            {
              data.length > 0 &&
              data.map((x, i) => {
                return (
                  <option key={i} value={i}>
                    {x.title}
                  </option>
                )
              })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="fileSelect">
            File Type
          </Label>
          <Input
            id="fileSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setFileIndex(e.target.value)
              setSizeIndex(0)
            }}
          >
            {
              data.length > 0 &&
              data[keyIndex]?.data.length > 0 && 
                data[keyIndex]?.data.map((x, i) => {
                  if(fileIndex === i){
                    return (
                      <option selected key={i} value={i}>
                        {x.title}
                      </option>
                    )  
                  }
                  return (
                    <option key={i} value={i}>
                      {x.title}
                    </option>
                  )
                })
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="sizeSelect">
          Size / Type
          </Label>
          <Input
            id="sizeSelect"
            name="select"
            type="select"
            onChange={(e) => {
              setSizeIndex(e.target.value)
            }}
          >
            {
              data.length > 0 &&
              data[keyIndex]?.data[fileIndex]?.data?.length > 0 && 
                data[keyIndex]?.data[fileIndex]?.data.map((x, i) => {
                  if(sizeIndex === i){
                    return (
                      <option selected key={i} value={i}>
                        {x.title}
                      </option>
                    )  
                  }
                  return (
                    <option key={i} value={i}>
                      {x.title}
                    </option>
                  )
                })
            }
          </Input>
        </FormGroup>
        {/* <FormGroup>
          <Label for="linkSelect">
            Download Link
          </Label>
          <Input
            id="linkSelect"
            type="text"
            defaultValue={
              data.length > 0? 
                data[selectIndex].data.length > 0?
                  data[selectIndex].data[typeIndex]?
                  data[selectIndex].data[typeIndex].link:
                  ''
                :''
              :''
            }
          />
        </FormGroup> */}
        <FormGroup className='text-center'>
          <Button 
            color='primary'
            href={
              data[keyIndex]?.data[fileIndex]?.data[sizeIndex]?.downloadLink
            }
            target="_blank"
            rel="noreferrer"
          >
            Download File
          </Button>
        </FormGroup>
      </Container>
    </>
  );
}
 
export default Template;