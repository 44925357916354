import React, { useState } from 'react';
import { Formik } from 'formik';

// nodejs library that concatenates classes
// import classnames from "classnames";

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    // InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from 'reactstrap';

import client from '../../feathers.js';

const StatusModal = (props) => {

    const [ optionalRemarks, setOptionalRemarks ] = useState('');

    const onSubmit = async(value) => {

        const data = {
            ...value,
            optRemarks: optionalRemarks,
        };

        client.authenticate()
            .then(()=>{
                return client.service('users').patch(props.dataEdit._id, data);
            })
            .then((res) => {
                props.toggleModal();
                props.updateUsers(res);

                if (props.status === true) {
                    props.notificationOpen(true, 'success', `User ${res.username} Activated Successfully!`);
                }
                if (props.status === false) {
                    props.notificationOpen(true, 'success', `User ${res.username} Deactivated Successfully!`);
                }
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Deactivate Merchant ({props.dataEdit.email?props.dataEdit.email:''})
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    initialValues={{
                        deactive: !props.status,
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            // values,
                            // handleChange,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Row>
                                        <Col lg='12'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='Remarks'
                                                style={{display: 'flex', flexDirection: 'column', gap: '5px'}}
                                            >
                                                {(props.status === false) ? (
                                                    <>
                                                        Are you sure you want to Deactivate this merchant?
                                                        <FormGroup>
                                                            <InputGroup className='input-group-alternative'>
                                                                <InputGroupAddon addonType='prepend' />
                                                                <Input
                                                                    style={{padding: '0px 10px'}}
                                                                    placeholder='Remarks'
                                                                    type='text'
                                                                    autoComplete='off'
                                                                    id='remarks'
                                                                    value={optionalRemarks}
                                                                    onChange={e => setOptionalRemarks(e.target.value)}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </>
                                                ) : (
                                                    <>
                                                        Are you sure you want to Activate this merchant?
                                                    </>
                                                )
                                                }
                                            </label>
                    
                                        </Col>
                                    </Row>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default StatusModal;