import React, { useState } from 'react';
import { Formik } from 'formik';
import env from '../../env.js';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Card,
    CardBody,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';
import axios from 'axios';

const UpdateSupplier = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ supplier, setSupplier ] = useState('');

    const onSubmit = async(value) => {
        setLoading(true);
        let processInsert = await props.checkboxChecked.map(async(x) => {
            let r = await props.productsData.map(async(x1) => {
                if(x === x1._id){
                    return await new Promise(resolve => {

                        let getProduct = props.productsData.find(e => e._id === x1._id);

                        let data = {
                            name: getProduct.name,
                            supplier: supplier
                        };
            
                        client.authenticate()
                            .then((auth)=>{
                                return axios.post(`${client.io.io.uri}updateAllProduct`,
                                    {
                                        data: data,
                                        newName: getProduct.name,
                                        userId: props.userId
                                    },
                                    {
                                        headers: {
                                            'Authorization': auth.accessToken
                                        }
                                    });
                            })
                            .then((res) => {
                                getProduct.supplier = supplier;

                                props.updateProducts(getProduct);
                                resolve(x1);
                            })
                            .catch((err)=>{
                                console.log(err.message);
                            });
                    });
                }else{
                    return x1;
                }
            });
            if(r){
                return x;
            }
        });
    
        const result = await Promise.all(processInsert);
        if(result){
            setLoading(false);
            props.toggleModal();
            props.notificationOpen(true, 'success', 'Products Margin Assigned Successfully');
        }
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered status-modal'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Update Supplier
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Card className='shadow mb-4'>
                                        <CardBody>
                                            <Row>
                                                <Col md='12' className='text-center'>
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                            {supplier}
                                                        </DropdownToggle>
                                                        <DropdownMenu className='w-100'>
                                                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                                {
                                                                    env.suppliers.map(x => {
                                                                        return (
                                                                            <DropdownItem 
                                                                                className='text-uppercase' 
                                                                                value={x} 
                                                                                onClick={() => {
                                                                                    setSupplier(x);
                                                                                }}
                                                                            >
                                                                                {x}
                                                                            </DropdownItem>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default UpdateSupplier;