import React from 'react';

// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    Row,
    Col
} from 'reactstrap';

const UploadFile = ({
    csvFile, 
    uploadFileEl, 
    handleFileChange, 
    progress, 
    setCsvFile,
    values,
}) => {
    return (  
        <>
            <Row>
                <Col md='12'>
                    <div className='mb-2'>
                        <small className=' font-weight-bold'>
                            Upload Data File
                        </small>
                    </div>
                    <FormGroup className='text-center'>
                        {
                            !csvFile.name && !csvFile.fileName? (
                                <div className='file-upload'>
                                    <input 
                                        type='file' 
                                        ref={uploadFileEl}
                                        accept='.csv' 
                                        onChange={(e) => handleFileChange(e)} 
                                        hidden />                
                                    <div className='progessBar' style={{ width: progress }}>
                                    </div>
                                    <Button
                                        className='m-0 mb-1 w-100'
                                        color='default'
                                        onClick={() => uploadFileEl.current.click()}
                                    >
                                        <div className='btn-inner--icon'>
                                            <span className='btn-inner--text'>Upload File</span>
                                        </div>
                                    </Button>
                                </div>
                            ): (
                                <Row>
                                    <Col md='8 pr-0'>
                                        <Input
                                            type='text'
                                            value={csvFile.name || csvFile.fileName}
                                            disabled
                                        />
                                    </Col>
                                    {
                                        values.source !== 'csv-prefix'? (
                                            <Col md='4 pl-0'>
                                                <Button
                                                    type='button'
                                                    color='danger'
                                                    onClick={() => setCsvFile({})}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        ): null
                                    }
                                </Row>
                            )
                        }
                    </FormGroup>
                </Col>
                {/* <Col md="6">
          <div className="mb-2">
            <small className=" font-weight-bold">
              Source Code Filter By
            </small>
          </div>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-file-excel"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="CSV Code Filter"
                type="text"
                id="sourceFilterBy"
                rows="3"
                value={values.sourceFilterBy}
                onChange={handleChange}
              />
            </InputGroup>
          </FormGroup>
        </Col> */}
            </Row>
            {/* <Row>
      <Col md="6">
          <div className="">
            <small className=" font-weight-bold">
              Split Options
            </small>
          </div>
          <div className="">
            <small className="text-muted">
              *Split to options from column title
            </small>
          </div>
          <div className="">
            <small className="text-muted">
              *exp: White, Black, Blue will split to options
            </small>
          </div>
          <div className="mb-2">
            <small className="text-muted">
              *Not work on first colum*
            </small>
          </div>
          <FormGroup>
            <FieldArray
              name="splitOptions"
              render={arrayHelpers => (
                <div>
                  {values.splitOptions && values.splitOptions.length > 0 ? (
                    values.splitOptions.map((split, index) => (
                      <div key={index}>
                        <Row className="align-items-center">
                          <Col md="8">
                            <Field className="form-control" name={`splitOptions.${index}`} />
                          </Col>
                          <Col md="4">
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                      Add Title
                    </Button>
                  )}
                </div>
              )}
            />
          </FormGroup>
        </Col>
      </Row> */}
        </>
    );
};
 
export default UploadFile;