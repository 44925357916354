import React from 'react';
import { Field, FieldArray } from 'formik';

// reactstrap components
import {
    Button,
    FormGroup,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

const CSVAdditionalOptions = ({
    values,
    currency
}) => {
    return (  
        <>
            <h4>Addition Options</h4>
            <Card className='shadow mb-4'>
                <CardBody>
                    <div className='mb-2'>
                        <small className='text-muted'>
                            *Filter by the first column of CSV file
                        </small>
                    </div>
                    <FormGroup>
                        <FieldArray
                            name='additionalData'
                            render={arrayHelpers => (
                                <div>
                                    <Row>
                                        <Col md='10'>
                                            <Row>
                                                <Col md='3'>
                                                    <small className=' font-weight-bold'>
                                                        Column Name
                                                    </small>
                                                </Col>
                                                <Col md='3'>
                                                    <small className=' font-weight-bold'>
                                                        Modal
                                                    </small>
                                                </Col>
                                                <Col md='3'>
                                                    <small className=' font-weight-bold'>
                                                        Column Data
                                                    </small>
                                                </Col>
                                                <Col md='3'>
                                                    <small className=' font-weight-bold'>
                                                        Cost Price(<span className='text-uppercase'>{currency}</span>)
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md='2' className='row d-flex align-items-center'>
                                            <Col md='12' className='p-0'>
                                                <small className=' font-weight-bold'>
                                                    Split Comma
                                                </small>
                                            </Col>
                                        </Col>
                                    </Row>
                                    {values.additionalData.map((additional, index) => (
                                        <Row key={index} className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='3'>
                                                        <Field 
                                                            className='form-control form-control-sm'
                                                            placeholder='Column Name'
                                                            type='text'
                                                            name={`additionalData[${index}].columnName`} />
                                                    </Col>
                                                    <Col md='3'>
                                                        <Field 
                                                            className='form-control form-control-sm'
                                                            placeholder='Model Name'
                                                            type='text'
                                                            name={`additionalData[${index}].name`} />
                                                    </Col>
                                                    <Col md='3'>
                                                        <Field 
                                                            className='form-control form-control-sm'
                                                            placeholder='Data'
                                                            type='text'
                                                            name={`additionalData.${index}.data`} />
                                                    </Col>
                                                    <Col md='3'>
                                                        <Field 
                                                            className='form-control form-control-sm'
                                                            placeholder='Cost Price'
                                                            type='number'
                                                            onWheel={ event => event.currentTarget.blur() }
                                                            name={`additionalData.${index}.price`} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md='2' className='row d-flex align-items-center'>
                                                <Col md='6' className='p-0'>
                                                    <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                        <Field 
                                                            type='checkbox' 
                                                            checked={additional.split}
                                                            name={`additionalData.${index}.split`}
                                                        />
                                                        <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                                    </label>
                                                </Col>
                                                <Col md='6' className='p-0 d-flex justify-content-end'>
                                                    <Button
                                                        color='secondary'
                                                        type='button'
                                                        size='sm'
                                                        className='mb-1'
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        <div className='btn-inner--text text-red'>
                                                            <i 
                                                                className='fa fa-minus'
                                                            />
                                                        </div>
                                                    </Button>
                                                </Col>
                                            </Col>
                                        </Row>
                                    ))}
                                    <a 
                                        href='/' 
                                        onClick={ (event) => {
                                            event.preventDefault();
                                            arrayHelpers.push('');
                                        }} 
                                    >
                                        <span>
                                            <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                            Add new line
                                        </span>
                                    </a>
                                </div>
                            )}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    );
};
 
export default CSVAdditionalOptions;