import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { withRouter } from 'react-router';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/common/plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/common/close.svg';

import {
    Row,
    Col,
    Button,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

const CustomFieldSection = (props) => {
    const {
        values,
        setFieldValue,
        onAdd,
    } = props;

    const onRemove = (i) => {
        const cloned = cloneDeep(values.customFields);

        cloned.splice(i, 1);
        setFieldValue('customFields', cloned);
    };


    return (
        <div className='rounded bg-grey px-3 py-2 mb-3'>
            <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                <h4 className='m-0'>Custom Fields</h4>
                <Button
                    color='primary'
                    className='shadow-none rounded-circle p-1 m-0 d-flex'
                    style={{ height: 25, width: 25 }}
                    onClick={onAdd}
                >
                    <PlusIcon
                        height={15}
                        width={15}
                    />
                </Button>
            </Row>

            {values.customFields.map((x, i) => {
                return (
                    <Row
                        key={x}
                        className='d-flex mx-0 p-1 my-1 rounded align-items-center justify-content-between bg-white border-primary'
                        style={{ height: 45 }}
                    >
                        <Col md='2' className='pr-0'>
                            <small className='component-code m-0 text-uppercase'>Field</small>
                        </Col>
                        <Col md='9' className='pr-0'>
                            <small>{x}</small>
                        </Col>
                        <Col md='1' className='p-0'>
                            <a
                                className='nav-item'
                                style={{color: 'red'}}
                                onClick={() => onRemove(i)}
                            >
                                <p className='font-weight-bold m-0 text-center'>
                                    <CloseIcon 
                                        width={22}
                                        height={22}
                                        stroke={'red'}
                                    />
                                </p>
                            </a>
                        </Col>
                    </Row>);
            })}
        </div>
    );
};

export default withRouter(CustomFieldSection);
