/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import axios from 'axios';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar.js';
import AuthFooter from '../components/Footers/AuthFooter.js';

import client from '../feathers.js';
import routes from '../routes.js';

const Auth = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();
    const [ companyData, setCompanyData ] = React.useState({});
    
    const getCompanyData = () => {
        let params = (new URL(document.location)).searchParams;

        let key = params.get('key');

        axios.get(`${client.io.io.uri}company`, {
            params: {
                key,
            },
        }).then((res) => {
            setCompanyData(res.data);
        });
    };

    React.useEffect(() => {
        getCompanyData();
    }, [props?.location]);

    React.useEffect(() => {
        document.body.classList.add('bg-color');
        return () => {
            document.body.classList.remove('bg-color');
        };
    }, []);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/auth') {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <div className='main-content' ref={mainContent}>
                {(window.self === window.top) && (
                    <>
                        <AuthNavbar logoUrl={companyData.logoUrl}/>
                        <div className='header bg-color py-7 py-lg-8'>
                            <div className='text-center'>
                                <p className='mb-0 font-weight-normal'>This website is exclusively for <span className='font-weight-bold text-black'>{companyData.companyName} subscribers.</span></p>
                                <p className='mb-4 font-weight-normal'>
                                    To gain access, you are required to purchase a subscription from our <a target='_blank'rel='noopener noreferrer' href={companyData.companyUrl}>official website</a>.
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {/* Page content */}
                <Container className='mt--8 pb-5'>
                    <Row className='justify-content-center'>
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from='*' to='/auth/login' />
                        </Switch>
                    </Row>
                </Container>
                <div className='text-center'>
                    <p className='font-weight-normal'>If you forget your password, or need help to login, please <a target='_blank'rel='noopener noreferrer' href={companyData.customerSupportUrl}>contact us</a></p>
                </div>
            </div>
            <AuthFooter />
        </>
    );
};

export default Auth;
