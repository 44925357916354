import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router';
import Breadcrumb from '../Common/Breadcrumb.js';

// reactstrap components
import {
    Button,
    Form,
    Input,
    // Modal,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import DynamicPriceCalc from './DynamicPriceCalc';

import client from '../../feathers.js';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    dynamicPriceName: Yup.string()
        .required('Name is required'),
});

const DynamicPriceModalEdit = (props) => {
    const { dynamicPriceData, role } = props;
    const { id } = useParams();
    const [ dynamicPriceBetween, setDynamicPriceBetween ] = useState([]);
    const [ dynamicPriceFocused, setDynamicPriceFocused ] = useState(false);
    const [dynamicPriceLastCondition, setDynamicPriceLastCondition] = useState(0);
  
    const [ dataEdit, setDataEdit ] = useState({});

    useEffect(() => {
    
        client.authenticate()
            .then(()=>{
                return client.service('dynamicPrice').get(id);
            })
            .then((res)=>{
                setDataEdit(res);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    }, [id]);

    useEffect(() => {
        if(!isEmpty(dataEdit)){
            setDynamicPriceBetween(dataEdit.dynamicPriceBetween);
            if(dataEdit.dynamicPriceBetween.length > 0) {
                setDynamicPriceLastCondition(dataEdit.dynamicPriceBetween[dataEdit.dynamicPriceBetween.length - 1].type);
            }
        }
    }, [dataEdit]);

    const clone = (previousData) => {
        let cloneDynamicPriceBetween = cloneDeep(previousData);    
        if(cloneDynamicPriceBetween.length > 0) {
            setDynamicPriceLastCondition(cloneDynamicPriceBetween[cloneDynamicPriceBetween.length - 1].type);
        }
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const dynamicPriceBetweenOnChange = (val, name, index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween[index][name] = val;
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const removeDynamicPriceBetween = (index) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);
        cloneDynamicPriceBetween.splice(index, 1);
    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const onSubmit = async(value) => {

        const cleanedDynamicPriceBetween = [];
        dynamicPriceBetween.forEach((data, index) => {
            if(index !== (dynamicPriceBetween.length - 1)) {
                cleanedDynamicPriceBetween.push({
                    ...data,
                    type: 0
                });
            } else {
                cleanedDynamicPriceBetween.push({
                    ...data,
                    type: dynamicPriceLastCondition
                });
            }
        });
        value.dynamicPriceBetween = cleanedDynamicPriceBetween;
        client.authenticate()
            .then(()=>{
                return client.service('dynamicPrice').patch(dataEdit._id, value);
            })
            .then((res) => {
                props.updateDynamicPrice(res);
                props.notificationOpen(true, 'success', 'Dynamic Price: ' + res.dynamicPriceName + ' Edited Successfully!');
                props.history.push(`/${props.role}/dynamic-price`);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    dynamicPriceName: dataEdit.dynamicPriceName,
                    description: dataEdit.description,
                    type: dataEdit.type,
                    sqftBetween: dataEdit.sqftBetween,
                    lengthBetween: dataEdit?.lengthBetween?.length > 0? dataEdit?.lengthBetween: [],
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    } = propsChild;
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body pt-0'>
                                <Row className='m-1 mt-3'>
                                    <h2 className='mr-4 mb-0'>Create Dynamic Price</h2>
                                </Row>
                                <Breadcrumb
                                    items={[
                                        {title: 'Dynamic Price', link: `/${role}/dynamic-price`},
                                        {title: 'Create Dynamic Price'}
                                    ]}
                                />
                                <Card className='mb-4'>
                                    <CardBody className='rounded'>
                                        <div className='px-3 mb-4 border rounded'>
                                            <Row className='p-3 px-4'>
                                                <h4 className='m-0'>Dynamic Price Setting</h4>
                                            </Row>
                                            <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Name</h5>
                                                    <Input 
                                                        placeholder='Dynamic Price Name'
                                                        type='text'
                                                        id='dynamicPriceName'
                                                        value={values.dynamicPriceName}
                                                        onChange={handleChange}
                                                        onFocus={() =>
                                                            setDynamicPriceFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setDynamicPriceFocused(false);
                                                            handleBlur(e);
                                                        }} 
                                                    />
                                                    {errors.dynamicPriceName && touched.mardynamicPriceNameginName && (
                                                        <div className='input-feedback'>{errors.dynamicPriceName}</div>
                                                    )}
                                                </Col>
                                                <Col md='6'>
                                                    <h5 className='mb-1'>Description</h5>
                                                    <Input
                                                        className='form-control-alternative'
                                                        placeholder='Description'
                                                        id='description'
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        autoComplete='off'
                                                        type='text'
                                                        rows='3'
                                                    />                                             
                                                </Col>
                                            </Row>
                                                            
                                        </div>
                                        <DynamicPriceCalc
                                            dynamicPriceBetween={dynamicPriceBetween}
                                            dynamicPriceLastCondition={dynamicPriceLastCondition}
                                            setDynamicPriceBetween={setDynamicPriceBetween}
                                            dynamicPriceBetweenOnChange={dynamicPriceBetweenOnChange}
                                            removeDynamicPriceBetween={removeDynamicPriceBetween}
                                            dynamicPriceData={dynamicPriceData}
                                            setDynamicPriceLastCondition={setDynamicPriceLastCondition}
                                            clone={clone}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='modal-footer'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                        props.history.push(`/${props.role}/dynamicPrice`);
                                    }}
                                >
                                    Discard
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='submit'
                                >
                                    Confirm
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {/* </Drawer> */}
            {/* </Modal> */}
        </>
    );
};
 
export default withRouter(DynamicPriceModalEdit);