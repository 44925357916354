import React from 'react';

const StatusChip = ({
    isActive,
}) => {

    const color = isActive ? '#2D8A39' : '#E2341D';
    const bgColor = isActive ? '#F0FAF0' : '#FFF2F0';

    return (  
        <div className='d-flex align-items-center justify-content-center px-2 py-1' style={{ backgroundColor: bgColor, gap: 3, borderRadius: 3 }}>
            <div style={{ backgroundColor: color, width: 5, height: 5, borderRadius: '50%' }}></div>
            <div style={{ color: color }}>{isActive ? 'Active' : 'Inactive'}</div>
        </div>
    );
    
};
 
export default StatusChip;
