import {
    FETCH_PRODUCTS,
    PUSH_PRODUCTS,
    UPDATE_PRODUCTS,
    MOVE_PRODUCTS,
    REMOVE_PRODUCTS,
} from '../actions/productsActions';
import findIndex from 'lodash/findIndex';
import arrayMove from 'array-move';

const INITIAL_STATE = {
    data: [],
};

export default function productReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_PRODUCTS:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_PRODUCTS:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_PRODUCTS:
            let updated = state.data.map((v)=>{
                if(v._id === action.data._id) {
                    return action.data;
                } else return v;
            });
            return {
                ...state,
                data: updated
            };
        case MOVE_PRODUCTS:
            let index = findIndex(state.data, function(o) { return o._id === action.data._id; });
            let moved = arrayMove(state.data, index, 0);      
            return {
                ...state,
                data: moved
            };
        case REMOVE_PRODUCTS:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        default:
            return state;

    }
}