import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useComponents } from '../../../zustand/componentStore';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/common/search.svg';

import {
    Row,
    Col,
    Modal,
    Input,
    InputGroup,
    Card,
    CardBody,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

const PricingRuleSelector = (props) => {
    const {
        userId,
        setFieldValue,

        packageDetails,
        marginsData,
        dynamicPriceData,
        role,
    } = props;

    const [isHover, setIsHover] = useState(false);
    const [hoverCode, setHoverCode] = useState('');
    const [hoverName, setHoverName] = useState('');
    const [hoverDescription, setHoverDescription] = useState('');

    const {
        pricingRuleModalIsOpen: isOpen,
        selectedPricingRule,
        modalAllowBack,
        storedLocation,
        actions,
    } = useComponents();

    const {
        setPricingRuleModalIsOpen: toggle,
    } = actions;

    const [searchQuery, setSearchQuery] = useState('');
    const [pricingRuleType, setPricingRuleType] = useState('');
    const [ filteredDynamicPrice, setFilteredDynamicPrice ] = useState([]);
    const [ filteredMargin, setFilteredMargins ] = useState([]);
    
    const onAdd = (storedLocation, value) => {
        setFieldValue(storedLocation , value);
        toggle(false);
    };


    useEffect(() => {
        if(userId){
            setFilteredMargins([...marginsData.filter((x) => x.marginName.toLowerCase().includes(searchQuery.toLowerCase()))]);
            setFilteredDynamicPrice(
                [
                    ...dynamicPriceData.filter((x) =>
                        x.dynamicPriceName.toLowerCase().includes(searchQuery.toLowerCase()) &&
                        x.type !== 'sqft'
                    )
                ]);
        }
    },[searchQuery]);

    return (
        <Modal
            className='modal-dialog-centered'
            isOpen={isOpen}
            toggle={() => toggle(!isOpen)}
        >
            <div className='pt-3 pb-0 modal-header d-flex justify-content-between align-items-center'>
                <div></div>
                <button
                    aria-label='Close'
                    className='modal-close'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => toggle(!isOpen)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className='modal-body py-2'>
                <div className='mb-2'>
                    <InputGroup className='input-group-alternative d-flex align-items-center'>
                        <SearchIcon height={20} className='mx-1'/>
                        <Input
                            placeholder='Search Component Name...'
                            className='w-auto d-inline-block mr-2'
                            type='text'
                            bsSize='sm'
                            size={20}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
                {(isHover) && (
                    <Card className='bg-yellow rounded position-absolute' style={{ width: 300, minHeight: 100, zIndex: 10, left: 510, top: 0}}>
                        <CardBody className='p-3'>
                            <Row className='p-0 m-0 mb-2'>
                                <small>{hoverCode} | {hoverName}</small>
                            </Row>
                            <Row className='p-0 m-0'>
                                <h5 className='m-0'>Description:</h5>
                            </Row>
                            <Row className='p-0 m-0'>
                                <small>{(hoverDescription) ? hoverDescription : '-'}</small>
                            </Row>
                        </CardBody>
                        <div className='position-absolute'
                            style={{
                                left: '-10px',
                                bottom: '5%',
                                width:0,
                                borderRight:'10px solid #FFCA3A',
                                borderTop:'7px solid transparent',
                                borderBottom:'7px solid transparent',
                            }} />
                    </Card>
                )}
                {selectedPricingRule === 'Margin' && (
                    <div className='w-100 bg-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                        {filteredMargin.map((x) => {
                            return (
                                <Row
                                    key={x._id}
                                    className='d-flex py-2 mx-1 component-selector-item rounded align-items-center'
                                    onClick={() => onAdd((role === 'superadmin') ? 'marginSuperName' : 'marginName' , x._id)}
                                >
                                    <Col md={2} className='component-code m-0 text-uppercase'>
                                        Margins
                                    </Col>
                                    <Col md={10}>
                                        <h4 className='m-0'
                                            onMouseOver={() => [
                                                setIsHover(true),
                                                setHoverCode('Margin'),
                                                setHoverName(x.marginName),
                                                setHoverDescription(x.description),
                                            ]}
                                            onMouseOut={() => [
                                                setIsHover(false),
                                                setHoverCode(''),
                                                setHoverName(''),
                                                setHoverDescription(''),
                                            ]}>
                                            {x.marginName}
                                        </h4>
                                    </Col>
                                </Row>
                            );
                        })}
                        {filteredMargin.length <= 0 && (
                            <div className='w-100 d-flex justify-content-center rounded p-2'>
                                No margins found.
                            </div>
                        )}
                    </div>
                )}

                {selectedPricingRule === 'Dynamic Price' && (
                    <div className='w-100 bg-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                        {filteredDynamicPrice.map((x) => {
                            return (
                                <Row
                                    key={x._id}
                                    className='d-flex py-2 mx-1 component-selector-item rounded align-items-center'
                                    onClick={() => onAdd((role === 'superadmin') ? 'superDynamicPriceId' : 'dynamicPriceName' , x._id)}
                                >
                                    <Col md={2} className='component-code m-0 text-uppercase'>
                                        Dynamic
                                    </Col>
                                    <Col md={10}>
                                        <h4 className='m-0'
                                            onMouseOver={() => [
                                                setIsHover(true),
                                                setHoverCode('Dynamic Price'),
                                                setHoverName(x.dynamicPriceName),
                                                setHoverDescription(x.description),
                                            ]}
                                            onMouseOut={() => [
                                                setIsHover(false),
                                                setHoverCode(''),
                                                setHoverName(''),
                                                setHoverDescription(''),
                                            ]}>
                                            {x.dynamicPriceName}
                                        </h4>
                                    </Col>
                                </Row>
                            );
                        })}
                        {filteredDynamicPrice.length <= 0 && (
                            <div className='w-100 d-flex justify-content-center rounded p-2'>
                                No dynamic price found.
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = state => ({
    role: state.role.details.user?state.role.details.user.role:'',
    marginsData: state.margins.data,
    dynamicPriceData: state.dynamicPrice.data,
    packageDetails: state.packageDetails.data,
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PricingRuleSelector));
