import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Field } from 'formik';
import { getQuantities } from '../../../../utils/productComponentUtil.js';

import {
    Row,
    Col,
} from 'reactstrap';

import client from '../../../../feathers.js';
import axios from 'axios';
import Dropdown from '../../../Common/Dropdown.js';

const ProductPreviewByComponent = (props) => {
    const { role, values, userInfo, userId, productDynamicPriceId, productSuperDynamicPriceId, marginId, marginSuperId, setFieldValue, packageDetails } = props;
    const [ models, setModels ] = useState();
    const [ selectedModel, setSelectedModel ] = useState();
    const [ printingCost, setPrintingCost ] = useState();
    const [ quantity, setQuantity ] = useState();
    const [ sizes, setSizes ] = useState([]);
    const [ remainingComponents, setRemainingComponents ] = useState([]);
    const [ pricing, setPricing ] = useState(0);
    const [ additionalData, setAdditionalData ] = useState([]);
    const [ loadingPreview, setLoadingPreview ] = useState(false);

    const [ enabledCostBreakdown, setEnabledCostBreakdown ] = useState(true);

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (userInfo.package) {
            if (packageDetails?.moduleRestrictions?.costBreakdown) {
                setEnabledCostBreakdown(false);
            }
        }
    }, [userInfo, packageDetails]);
    
    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    const modelComponentsIds = values?.modelComponents.map((x) => x.componentId);
                    const printSizeComponentsIds = values?.printSizeComponents.map((x) => x.componentId);
                    const printCostComponentsIds = values?.printCostComponents.map((x) => x.componentId);
                    const additionalComponentsIds = values?.additionalComponents.map((x) => x.componentId);
                    const quantityComponentsIds = values?.quantityComponents.map((x) => x.componentId);

                    const componentIds = [
                        ...modelComponentsIds,
                        ...printSizeComponentsIds,
                        ...printCostComponentsIds,
                        ...additionalComponentsIds,
                        ...quantityComponentsIds,
                    ];

                    return client.service('components').find({
                        query: {
                            _id: { $in: componentIds },
                            $limit: 1000,
                        }
                    });
                })
                .then((res)=>{
                    const modelsData = res.data.filter((x) => x.componentTypeName === 'Model');
                    modelsData.sort((a, b) => {
                        return values.modelComponents.findIndex((a1) => a1._id === a._id) - values.modelComponents.findIndex((b1) => b1._id === b._id);
                    });
                    setModels(modelsData);
                    const defaultPrintCost = res.data.find((x) => x.componentTypeName === 'Printing Cost');
                    setPrintingCost(defaultPrintCost);
                    setQuantity(res.data.find((x) => x.componentTypeName === 'Quantity'));

                    const allRemaining = res.data.filter((x) => 
                        x.componentTypeName !== 'Model' &&
                        x.componentTypeName !== 'Quantity' &&
                        x.componentTypeName !== 'Printing Cost' &&
                        x.componentTypeName !== 'Artwork Service' &&
                        x.componentTypeName !== 'Production Timeline'
                    );

                    const remaining = values?.additionalComponents.map((x) => {
                        return allRemaining.find((c) => c._id.toString() === x.componentId);
                    });

                    setRemainingComponents([...remaining]);

                    const data = modelsData.find((x) => x._id === values.preview.model);
                    setSelectedModel(data);

                    return {remaining, defaultPrintCost};
                })
                .then(async ({remaining, defaultPrintCost})=>{
                    const selectedModels = values?.modelComponents.find((x) => x.componentId === values.preview.model && x.nestedComponents.length > 0);

                    const nestedComponentIds = [];
                    if (selectedModels?.nestedComponents.length > 0) {
                        selectedModels.nestedComponents.map((y) => {
                            nestedComponentIds.push(y.componentId);
                        });
                    }
                    const res = await client.service('components').find({
                        query: {
                            _id: { $in: nestedComponentIds },
                            $limit: 1000,
                        }
                    });

                    return {
                        data: res.data,
                        remaining,
                        defaultPrintCost,
                    };
                })
                .then(({ data, remaining, defaultPrintCost })=>{
                    const selectedModels = values?.modelComponents.find((x) => x.componentId === values.preview.model && x.nestedComponents.length > 0);
                    let selectedPrintCost = defaultPrintCost;
                    if (selectedModels) {
                        const nestedPrintingCost = data.find((y) => y.componentTypeName === 'Printing Cost');
                        if (nestedPrintingCost) {
                            setPrintingCost(nestedPrintingCost);
                            selectedPrintCost = nestedPrintingCost;
                        }

                        const nestedOptions = selectedModels.nestedComponents.filter((y) => y.componentTypeName !== 'Printing Cost');

                        const sortedNestedOptions = [];

                        nestedOptions.map((x) => {
                            const nested = data.find((c) => c._id.toString() === x.componentId.toString());
                            if (nested) {
                                sortedNestedOptions.push(nested);
                            }
                        });

                        if (nestedOptions.length > 0) {
                            setRemainingComponents([...sortedNestedOptions, ...remaining]);
                        }
                    }
                    return { selectedPrintCost };
                }).then(({ selectedPrintCost })=>{
                    // to get the quantities in print cost
                    let sizeIds;
                    if (selectedPrintCost) {
                        sizeIds = selectedPrintCost.data.printingCost.map((x) => x.sizeId);
                    } else if(printingCost) {
                        sizeIds = printingCost[0]?.data?.printingCost?.map((x) => x.sizeId);
                    }

                    if (sizeIds) {
                        return client.service('components').find({
                            query: {
                                _id: { $in: sizeIds },
                                $limit: 1000,
                            }
                        });
                    }

                    return [];
                }).then((sizeComponents) => {
                    setSizes(sizeComponents.data);
                });
        }
    },[userId, values]);

    useEffect(() => {
        if(values.preview.variableQuantityDisplay?.length > 0) {
            values.preview.variableQuantityDisplay.map((x, i) => {
                const variableQuantity = quantity.data.quantity.variableQuantity[i];
                setFieldValue(`preview.variableQuantity[${i}]`, `${quantity._id}~~${variableQuantity._id}~~${x}`); 
            });
        }
    }, [values.preview.variableQuantityDisplay]);

    useEffect(() => {
        if(values.printCostComponents.length > 0) {
            setFieldValue('preview.printCosts', []); 
        }
    }, [values.printCostComponents]);
    
    const updatePrice = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    ...values,
                    productData: {
                        productId: values._id,
                        ...values
                    },
                    type: 'component',
                    preview: {
                        ...values.preview,
                        productionTimelineComponentId: values.productionComponents[0]?.componentId ?? '',
                        artworkId: values.artworkComponents[0]?.componentId ?? '',
                        productDynamicPriceId,
                        productSuperDynamicPriceId,
                        marginId,
                        marginSuperId,
                    }
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPricing(res.data);
                    setLoadingPreview(false);

                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                })
                .catch((err) => {
                    setLoadingPreview(false);
                    console.log(err);
                });
        }, 500);
    };

    // on change preview
    useEffect(() => {
        if (values.preview.model) {
            setLoadingPreview(true);
            updatePrice();
            // Cleanup the previous timeout on re-render
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [values]);


    const renderLoader = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            if (enabledCostBreakdown) {
                if (value) {
                    return value;
                }
                return '';
            } else {
                return <span className='xs font-italic'> Upgrade to view</span>;
            }
        }
    };

    const renderFinalPrice = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            return value;
        }
    };

    const renderPrintCost = (printCostId, subPrintCost, i) => {
        if(subPrintCost && sizes.length > 0) {
            const size = sizes.find((x) => x._id.toString() === subPrintCost.sizeId.toString());
            if (!size) {
                return;
            }
            
            return (
                <Row key={subPrintCost.title} className='m-0 px-4 py-2 w-100'>
                    <h5 className='d-flex align-items-center'>{subPrintCost.label}: - {renderLoader(pricing.pricingBreakdown?.printCostPrices.find((y) => subPrintCost.label === y.name)?.price)}</h5>
                    <Dropdown
                        name={`preview.printCosts[${i}]`}
                        setFieldValue={setFieldValue}
                        values={size.data.printingSizeDetails.sizes.map((x) => {
                            return {
                                value: `${printCostId}~~${subPrintCost._id}~~${x._id}`,
                                display: x.label
                            };
                        })}
                    />
                </Row>
            );
            
        }
    };

    const getQuantities = (componentId, variableQuantity) => {
        const data = [];
    
        for (let i = variableQuantity.start; i <= variableQuantity.end; i += variableQuantity.inMultipleOf) {
            data.push(i);
        }
        const custom = variableQuantity.custom.map((x) => x.variableQuantity);
        data.push(...custom);
       
        const sorted = [...new Set(data)].filter((x) => (x)).sort((a, b) => a - b);
        return sorted.map((x) => {
            return {
                value: x,
                display: x,
            };
        });
    };

    const renderQuantity = (componentId, quantity, i) => {
        if(quantity) {
            return (
                <Row className='m-0 px-4 py-2 w-100'>
                    {(quantity.isCustom) ? (
                        <>
                            <h5>{`${quantity.label}: - ${renderLoader(pricing.pricingBreakdown?.quantityPrices.find((y) => quantity.label === y.name)?.price)}`}</h5>
                            <Dropdown
                                name={`preview.variableQuantityDisplay[${i}]`}
                                setFieldValue={setFieldValue}
                                values={getQuantities(componentId, quantity)}
                            />
                        </>
                    ) : (
                        <>
                            <h5>
                                {`${quantity.label}`} ({quantity.min} ~ {(quantity.isInfinite) ? '∞' : quantity.max}): - {renderLoader(pricing.pricingBreakdown?.quantityPrices.find((y) => quantity.label === y.name)?.price)}
                            </h5>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='string'
                                onWheel={(e) => e.target.blur()}
                                max={quantity.isInfinite ? 9999999 : quantity.max}
                                min={quantity.min}
                                name={`preview.variableQuantityDisplay[${i}]`}
                            />
                        </>
                    )}
                </Row>
            );
        }
    };


    const renderRemainingComponents = (remainingComponents) => {
        return (
            <>
                {remainingComponents.map((x, i) => {
                    const price = pricing.pricingBreakdown?.optionPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.options[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.options?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}
            </>
        );
    };

    return ( 
        <>
            <div className='preview-container bg-secondary rounded py-2 mb-3'>
                {loadingPreview && (
                    <div
                        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 100 }}
                    >
                        <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
                <Row className='m-0 px-4 pt-2 w-100'>
                    <h3 className='m-0 color-primary'>Price Calculator</h3>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <small>Preview the cost of your product here.</small>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <h5 className='d-flex align-items-center'>Model - {renderLoader(pricing.pricingBreakdown?.modelPrice)}</h5>
                    {(models) && (
                        <Dropdown
                            name={'preview.model'}
                            setFieldValue={setFieldValue}
                            values={models?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    )}
                </Row>

                {renderRemainingComponents(remainingComponents)}
            
                {(printingCost && sizes) && (
                    <>
                        {printingCost.data.printingCost.map((x, i) => {
                            return renderPrintCost(printingCost._id, x, i);
                        })}
                    </>
                )}

                {(quantity) && (
                    <>
                        {quantity.data.quantity.variableQuantity.map((x, i) => {
                            return renderQuantity(quantity._id, x, i);
                        })}
                    </>
                )}
    
                {additionalData.map((x, i) => {
                    return (
                        <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>{x.title} - {renderLoader(values.additionalDataPrices[i])}</h5>
                            <Dropdown
                                name={`additionalDataPrices[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data?.map((d) => {
                                    return {
                                        value: d.price,
                                        display: d.title
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(values.fileStorageComponents.length > 0) && (
                    <>
                        <Row className='m-0 px-4 py-2 w-100'>
                            <h5>File Upload</h5>
                            <Col md='12' className='d-flex justify-content-center p-3 m-0 rounded additional-info-container border-dashed'>
                                <small>Upload files here</small>
                            </Col>
                        </Row>
                        
                    </>
                )}

                {(values.customFields.length > 0) && (
                    <>
                        {values.customFields.map((x, i) => {
                            return (
                                <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                                    <h5 className='d-flex align-items-center'>{x}</h5>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative'
                                        type='text'
                                        name={`customFieldsTemp[${i}]`}
                                    />
                                </Row>
                            );
                        })}
                    </>
                )}
    
                <Row className='mt-4 m-0 px-4 py-0 w-100'>
                    <h5 className='m-0 d-flex align-items-center'>Order Total</h5>
                </Row>
                <Row className='m-0 px-4 py-0 w-100'>
                    <h1 className='d-flex align-items-center color-primary'>{renderFinalPrice((Number(pricing.price ?? 0) + values.additionalDataPrices?.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2))}</h1>
                </Row>
            </div>

            <Row className='m-0 px-4 py-3 w-100 rounded additional-info-container'>
                <h3 className='d-flex align-items-center'>Additional Info</h3>
                {(!!pricing?.metadata?.marginPercentage || !!pricing?.metadata?.superMarginPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Margin Percentage: +{renderLoader((((role === 'superadmin') ? pricing.metadata?.superMarginPercentage - 1 : pricing.metadata?.marginPercentage - 1) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.discountPercentage || !!pricing?.metadata?.superDiscountPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Dynamic Price Discount: -{renderLoader((((role === 'superadmin') ? 1 - pricing?.metadata?.superDiscountPercentage: 1 - pricing.metadata?.discountPercentage) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {pricing?.metadata?.marginProfiles?.map((x) => {
                    if (x && x.percentage) {
                        return (
                            <Row key={x?.name} className='m-0 w-100'>
                                <small className='d-flex align-items-center'>Profile ({x.name}): {renderLoader(x.price)} (+{renderLoader(((x.percentage - 1) * 100)?.toFixed(2))}%)</small>
                            </Row>
                        );
                    } else {
                        return null;
                    }
                })}
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details.user,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPreviewByComponent);
