import React from 'react';
import { useModal } from '../../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    Modal
} from 'reactstrap';

const ModalWithCallback = (props) => {
    const { isOpen, toggle, title, content, callback } = props;

    const {
        actions
    } = useModal();

    const onClick = () => {
        callback();
        actions.setIsOpen(false);
    };

    return ( 
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={isOpen}
                toggle={toggle}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        {title}
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggle}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    {content}
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggle}
                    >
                        Close
                    </Button>
                    {callback && (
                        <Button 
                            color='primary' 
                            type='button'
                            onClick={onClick}
                        >
                            Confirm
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};
 
export default ModalWithCallback;