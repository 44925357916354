import React, { useState } from 'react';
import { Formik } from 'formik';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    Card,
    CardBody,
    Form,
    Modal,
    Row,
    Col,
} from 'reactstrap';

import client from '../../feathers.js';

const AssignMargin = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ marginName, setMarginName ] = useState('');

    const onSubmit = async(value) => {
        setLoading(true);
        let processInsert = await props.checkboxChecked.map(async(x) => {
            let r = await props.productsData.map(async(x1) => {
                if(x === x1._id){
                    return await new Promise(resolve => {

                        client.authenticate()
                            .then(()=>{
                                return client.service('products').patch(x1._id, {
                                    marginName,
                                    // new function

                                });
                            })
                            .then((res) => {
                                res.marginName = props.marginsData.find(e => e._id === marginName);

                                props.updateProducts(res);
                                resolve(x1);
                            })
                            .catch((err)=>{
                                console.log(err.message);
                            });
                    });
                }else{
                    return x1;
                }
            });
            if(r){
                return x;
            }
        });
    
        const result = await Promise.all(processInsert);
        if(result){
            setLoading(false);
            props.toggleModal();
            props.notificationOpen(true, 'success', 'Products Margin Assigned Successfully');
        }
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered status-modal'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Assign Products Margin
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                    }}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body'>
                                    <Card className='shadow mb-4'>
                                        <CardBody>
                                            <small className='text-uppercase font-weight-bold'>
                                                Profit Margin
                                            </small>
                                            <Row>
                                                <Col md='12' className='text-center'>
                                                    <Input 
                                                        className='mr-2 w-auto d-inline-block'
                                                        value={props.marginsData.find(e => e._id === marginName)? props.marginsData.find(e => e._id === marginName).marginName: ''} 
                                                        disabled
                                                    />
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                            Apply Margin
                                                        </DropdownToggle>
                                                        <DropdownMenu className='w-100'>
                                                            <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                                <DropdownItem 
                                                                    className='bg-danger text-white text-capitalize' 
                                                                    value={''} 
                                                                    onClick={() => {
                                                                        setMarginName('');
                                                                    }}
                                                                >
                                                                    Unlink Margin
                                                                </DropdownItem>
                                                                {
                                                                    props.marginsData.map((x, i) => {
                                                                        return (
                                                                            <DropdownItem
                                                                                key={i} 
                                                                                className='text-capitalize' 
                                                                                value={x.marginName} 
                                                                                onClick={() => {
                                                                                    setMarginName(x._id);
                                                                                }}
                                                                            >
                                                                                {x.marginName}
                                                                            </DropdownItem>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <br/>
                                                    <br/>
                                                    <p>*To edit margin, Please go to margin page to edit and apply again</p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                        disabled={loading}
                                    >
                                        {loading? (
                                            <div className='spinner-border mr-1' role='status' style={{height: '1rem', width: '1rem'}}>
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        ):null}
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default AssignMargin;