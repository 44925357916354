import React, { useState } from 'react';

// reactstrap components
import {
    Button,
    Row,
    Col,
} from 'reactstrap';

import client from '../../../feathers';
import axios from 'axios';
import ModalEmpty from '../../../components/Extra/ModalEmpty.js';

const UploadCsvCreateProduct = ({
    modalVisible,
    toggleModal,
    userId,
    notificationOpen
}) => {
    const [ loading, setLoading ] = useState(false);
    const [ multipleCsvFile, setMultipleCsvFile ] = useState([]);

    const csvUpload = async(file) => {

        const formData = new FormData();
        formData.append('NAME', 'Fred');
        formData.append('file', file);
    
        return await axios({
            method: 'post',
            url: `${client.io.io.uri}uploadCSVLocal`,
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
            .then((res) => {
                return (res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFileChange = async(e) => {

        const checkIfAllFiles = (arr) => {
            return arr.every(item => item instanceof File);
        };
      
        // Convert FileList to Array
        let files = [];
        Array.from(e.target.files).map(x => {
            let singleFile = x;
            let fileType = (singleFile.type === 'application/vnd.ms-excel' || singleFile.type === 'text/csv');
            if(fileType){

                files.push(x);

                return e.target.value = null;
            }else{
                window.alert('Only Accept CSV File!');
                files.push(false);
                return false;
            }
        });
    
        if(checkIfAllFiles(files)){
            setMultipleCsvFile(files);
        }

    };

    function generateCustomString() {
        const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
  
        // Generate 2 to 3 random alphabetic characters
        const alphaCount = Math.floor(Math.random() * 2) + 2; // Randomly choose 2 or 3
        let randomAlphabets = '';
        for (let i = 0; i < alphaCount; i++) {
            randomAlphabets += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
        }
  
        // Generate 4 random digits
        let randomNumbers = '';
        for (let i = 0; i < 4; i++) {
            randomNumbers += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
  
        // Combine alphabets and numbers with a hyphen
        return `${randomAlphabets}-${randomNumbers}`;
    }

    const handleSubmitMultiple = async() => {
      
        setLoading(true);

        multipleCsvFile.map(async(x) => {
            let singleFile = x; 
            let value = {
                csvFile: {
                    fileUrl: '',
                    fileName: '',
                },
                'status' : true,
                'isComponentBased' : true,
                'source' : 'csv',
                userId,
                code: generateCustomString(),
                name: x.name.replace('.csv', ''),
            };

            let newFileName = (Math.random().toString(36).substring(2, 15) + '-' + 
          singleFile.lastModified + '-' + 
          singleFile.name);
            let formData = new FormData();
            formData.append('file', singleFile, newFileName);

            let modified = formData.get('file');

            let checkExists = await client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            name: modified.name, // check original file name
                            userId,
                            $limit: 1,
                        }
                    });
                });
              
            if(checkExists.data.length === 0){

                if(modified.name){
                    let productDataUrl = await csvUpload(modified);
              
                    value.csvFile.fileUrl = productDataUrl;
                    value.csvFile.fileName = modified.name;
                }

                client.authenticate()
                    .then(()=>{
                        return client.service('products').create(value);
                    })
                    .then((res) => {
                        setLoading(false);
                        // notificationOpen(true, 'success', 'Product ' + res.name + ' Added Successfully!');
                        // setMultipleCsvFile([]);
                        toggleModal();
                    })
                    .catch((err)=>{
                        console.log(err);
                        setLoading(false);
                    });
            }else{
                setLoading(false);
                notificationOpen(true, 'danger', 'Product ' + x.name + ' Is Exists!');
            }
        });
    };

    return (  
        <>
            <ModalEmpty 
                modalVisible={modalVisible}
                title={'Upload'}
                toggleModal={toggleModal}
                content={
                    <>
                        <div>

                            <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                                <h4 className='m-0'>Upload CSV Files</h4>
                            </Row>

                            <Row>
                                <Col md='12'>
                                    <input 
                                        type='file' 
                                        accept='.csv' 
                                        multiple
                                        onChange={(e) => handleFileChange(e)} 
                                    />
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={toggleModal}
                                >
                                    Close
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='button'
                                    disabled={loading}
                                    onClick={handleSubmitMultiple}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
};
 
export default UploadCsvCreateProduct;