import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Field } from 'formik';
import { getQuantities } from '../../../../utils/productComponentUtil.js';
import { convertUnit } from '../../../../utils/converter.js';

// reactstrap components
import {
    Row,
    Col,
} from 'reactstrap';

import client from '../../../../feathers.js';
import axios from 'axios';
import Dropdown from '../../../Common/Dropdown.js';

const LooseSheetPreviewByComponent = (props) => {
    const { role, values, userInfo, userId, productDynamicPriceId, productSuperDynamicPriceId, marginId, marginSuperId, setFieldValue, packageDetails } = props;
    const [ materials, setMaterials ] = useState();
    const [ selectedMaterial, setSelectedMaterial ] = useState();
    const [ printingSize, setPrintingSize ] = useState();
    const [ printingCost, setPrintingCost ] = useState();
    const [ quantity, setQuantity ] = useState();
    const [ remainingComponents, setRemainingComponents ] = useState([]);
    const [ pricing, setPricing ] = useState(0);
    const [ additionalData, setAdditionalData ] = useState([]);
    const [ loadingPreview, setLoadingPreview ] = useState(false);

    const [ enabledCostBreakdown, setEnabledCostBreakdown ] = useState(true);

    const timeoutRef = useRef(null);

    useEffect(() => {
        if (userInfo.package) {
            if (packageDetails?.moduleRestrictions?.costBreakdown) {
                setEnabledCostBreakdown(false);
            }
        }
    }, [userInfo, packageDetails]);
    
    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    const materialComponentsIds = values?.materialComponents.map((x) => x.componentId);
                    const printCostComponentsIds = values?.printCostComponents.map((x) => x.componentId);
                    const printSizeComponentsIds = values?.printSizeComponents.map((x) => x.componentId);
                    const quantityComponentsIds = values?.quantityComponents.map((x) => x.componentId);
                    const additionalComponentsIds = values?.additionalComponents.map((x) => x.componentId);

                    const componentIds = [
                        ...materialComponentsIds,
                        ...printCostComponentsIds,
                        ...printSizeComponentsIds,
                        ...quantityComponentsIds,
                        ...additionalComponentsIds,
                    ];

                    return client.service('components').find({
                        query: {
                            _id: { $in: componentIds },
                            $limit: 1000,
                        }
                    });
                })
                .then((res)=>{
                    const materialsData = res.data.filter((x) => x.componentTypeName === 'Material');
                    materialsData.sort((a, b) => {
                        return values.materialComponents.findIndex((a1) => a1._id === a._id) - values.materialComponents.findIndex((b1) => b1._id === b._id);
                    });
                    setMaterials(materialsData);
                    setPrintingSize(res.data.find((x) => x.componentTypeName === 'Printing Size'));
                    setPrintingCost(res.data.find((x) => x.componentTypeName === 'Printing Cost'));
                    setQuantity(res.data.find((x) => x.componentTypeName === 'Quantity'));

                    const allRemaining = res.data.filter((x) => 
                        x.componentTypeName !== 'Material' &&
                        x.componentTypeName !== 'Printing Size' &&
                        x.componentTypeName !== 'Printing Cost' &&
                        x.componentTypeName !== 'Artwork Service' &&
                        x.componentTypeName !== 'Production Timeline' &&
                        x.componentTypeName !== 'Quantity'
                    );

                    const remaining = values?.additionalComponents.map((x) => {
                        return allRemaining.find((c) => c._id.toString() === x.componentId);
                    });

                    setRemainingComponents([...remaining]);

                    const data = materialsData.find((x) => x._id === values.preview.material);
                    setSelectedMaterial(data);

                    return remaining;
                })
                .then(async (remaining)=>{
                    const selectedMaterials = values?.materialComponents.find((x) => x.componentId === values.preview.material && x.nestedComponents.length > 0);

                    const nestedComponentIds = [];
                    if (selectedMaterials?.nestedComponents.length > 0) {
                        selectedMaterials.nestedComponents.map((y) => {
                            nestedComponentIds.push(y.componentId);
                        });
                    }
                    const res = await client.service('components').find({
                        query: {
                            _id: { $in: nestedComponentIds },
                            $limit: 1000,
                        }
                    });

                    return {
                        data: res.data,
                        remaining,
                    };
                })
                .then(({ data, remaining })=>{
                    const selectedMaterials = values?.materialComponents.find((x) => x.componentId === values.preview.material && x.nestedComponents.length > 0);

                    if (selectedMaterials) {
                        const nestedPrintingSize = data.find((y) => y.componentTypeName === 'Printing Size');
                        if (nestedPrintingSize) {
                            setPrintingSize(nestedPrintingSize);
                        }

                        const nestedPrintingCost = data.find((y) => y.componentTypeName === 'Printing Cost');
                        if (nestedPrintingCost) {
                            setPrintingCost(nestedPrintingCost);
                        }

                        const nestedQuantity = data.find((y) => y.componentTypeName === 'Quantity');
                        if (nestedQuantity) {
                            setQuantity(nestedQuantity);
                        }

                        const nestedOptions = selectedMaterials.nestedComponents.filter((y) => y.componentTypeName !== 'Printing Size'
                        &&  y.componentTypeName !== 'Printing Cost'
                        &&  y.componentTypeName !== 'Quantity');

                        const sortedNestedOptions = [];

                        nestedOptions.map((x) => {
                            const nested = data.find((c) => c._id.toString() === x.componentId.toString());
                            if (nested) {
                                sortedNestedOptions.push(nested);
                            }
                        });

                        if (nestedOptions.length > 0) {
                            setRemainingComponents([...sortedNestedOptions, ...remaining]);
                        }
                    }
                });
        }
    },[userId, values]);


    useEffect(() => {
        if(values.preview.printCostsDisplay?.length > 0) {
            values.preview.printCostsDisplay.map((x, i) => {
                const printCostData = printingCost.data.printingCost[i];

                setFieldValue(`preview.printCosts[${i}]`, `${printingCost._id}~~${printCostData._id}~~${x}`); 
            });
        }
    }, [values.preview.printCostsDisplay]);

    useEffect(() => {
        if(values.printCostComponents.length > 0) {
            setFieldValue('preview.printCosts', []); 
        }
    }, [values.printCostComponents]);
    
    const updatePrice = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            axios({
                method: 'post',
                url: `${client.io.io.uri}changeProductSpecs`,
                data: {
                    ...values,
                    productData: {
                        productId: values._id,
                        ...values
                    },
                    type: 'component',
                    preview: {
                        ...values.preview,
                        productionTimelineComponentId: values.productionComponents[0]?.componentId ?? '',
                        artworkId: values.artworkComponents[0]?.componentId ?? '',
                        productDynamicPriceId,
                        productSuperDynamicPriceId,
                        marginId,
                        marginSuperId,
                    }
                },
                config: { headers: {'Content-Type': 'application/json' }}
            })
                .then((res) => {
                    setPricing(res.data);
                    setLoadingPreview(false);

                    if(res.data.additionalData) {
                        setAdditionalData([...res.data.additionalData]);
                    }
                })
                .catch((err) => {
                    setLoadingPreview(false);
                    console.log(err);
                });
        }, 500);
    };

    // on change preview
    useEffect(() => {
        if (values.preview.material) {
            setLoadingPreview(true);
            updatePrice();
            // Cleanup the previous timeout on re-render
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [values]);


    const renderLoader = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            if (enabledCostBreakdown) {
                return value;
            } else {
                return <span className='xs font-italic'> Upgrade to view</span>;
            }
        }
    };

    const renderFinalPrice = (value) => {
        if(loadingPreview) {
            return (
                <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            );
        } else {
            return value;
        }
    };

    const renderRemainingComponents = (remainingComponents) => {
        return (
            <>
                {remainingComponents.map((x, i) => {
                    const price = pricing.pricingBreakdown?.optionPrices?.find((x) => x.index === i)?.price;
                    return (
                        <Row key={`${x.name}-${i}`} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>
                                {x.name} - {renderLoader(price)}
                            </h5>
                            <Dropdown
                                name={`preview.options[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data.options?.map((o) => {
                                    return {
                                        value: `${x._id}~~${o._id}`,
                                        display: o.label
                                    };
                                })}
                            />
                        </Row>
                    );
                })}
            </>
        );
    };

    return ( 
        <>
            <div className='preview-container bg-secondary rounded py-2 mb-3'>
                {loadingPreview && (
                    <div
                        className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 100 }}
                    >
                        <div className='spinner-border ml-1' role='status' style={{height: '0.8rem', width: '0.8rem'}}>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    </div>
                )}
                <Row className='m-0 px-4 pt-2 w-100'>
                    <h3 className='m-0 color-primary'>Price Calculator</h3>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <small>Preview the cost of your product here.</small>
                </Row>
                <Row className='m-0 px-4 py-2 w-100'>
                    <h5 className='d-flex align-items-center'>Material - {renderLoader(pricing.pricingBreakdown?.materialPrice)}</h5>
                    {(materials) && (
                        <Dropdown
                            name={'preview.material'}
                            setFieldValue={setFieldValue}
                            values={materials?.map((x) => {
                                return {
                                    value: x._id,
                                    display: x.name
                                };
                            })}
                        />
                    )}
                </Row>
            
                {(printingSize) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <h5 className='d-flex align-items-center'>Size - {renderLoader(pricing.pricingBreakdown?.sizePrice)}</h5>
                        <Dropdown
                            name={'preview.printingSize'}
                            setFieldValue={setFieldValue}
                            values={
                                [...printingSize.data.printingSizeDetails.sizes.map((x) => {
                                    return {
                                        value: `${printingSize._id}~~${x._id}`,
                                        display: x.label
                                    };
                                }),
                                (printingSize.data.printingSizeDetails.hasCustomSize) ? {
                                    value: `${printingSize._id}~~Custom`,
                                    display: 'Custom',
                                } : undefined
                                ]
                            }
                        />
                    </Row>
                )}
                {((values.preview.printingSize) && values.preview.printingSize.includes('Custom')) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        <Col className='m-0 p-0 pr-1' md='6'>
                            <small>Width ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customWidth'}
                            />
                            {values.preview.size.customWidth < printingSize.data.printingSizeDetails.minWidth && (
                                <small className='text-danger xs'>Must be higher than {printingSize.data.printingSizeDetails.minWidth}</small>
                            )}
                            {convertUnit(values.preview.size.customWidth, printingSize.defaultSize, selectedMaterial.defaultSize) > selectedMaterial.data.printingArea.maxWidth && (
                                <small className='text-danger xs'>Must be lower than {convertUnit(selectedMaterial.data.printingArea.maxWidth, selectedMaterial.defaultSize, printingSize.defaultSize)}</small>
                            )}
                        </Col>
                        <Col className='m-0 p-0 pl-1' md='6'>
                            <small>Height ({printingSize?.defaultSize ?? 'mm'})</small>
                            <Field 
                                className='form-control form-control-sm form-control-alternative'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                name={'preview.size.customHeight'}
                            />
                            {values.preview.size.customHeight < printingSize.data.printingSizeDetails.minHeight && (
                                <small className='text-danger xs'>Must be higher than {printingSize.data.printingSizeDetails.minHeight}</small>
                            )}
                            {convertUnit(values.preview.size.customHeight, printingSize.defaultSize, selectedMaterial.defaultSize) > selectedMaterial.data.printingArea.maxHeight && (
                                <small className='text-danger xs'>Must be lower than {convertUnit(selectedMaterial.data.printingArea.maxHeight, selectedMaterial.defaultSize, printingSize.defaultSize)}</small>
                            )}
                        </Col>
                    </Row>
                )}
                {(printingCost) && (
                    <>
                        {(printingCost.listingType === 'listByQuantity') ? (
                            printingCost.data.printingCost.map((x, i) => {
                                return (
                                    <Row key={x.label} className='m-0 px-4 py-2 w-100'>
                                        <h5 className='d-flex align-items-center'>{x.label} - {renderLoader(pricing.pricingBreakdown?.printCostPrices.find((y) => x.label === y.name)?.price)}</h5>
                                        <Field 
                                            className='form-control form-control-sm form-control-alternative'
                                            type='number'
                                            onWheel={(e) => e.target.blur()}
                                            max={99999}
                                            min={0}
                                            name={`preview.printCostsDisplay[${i}]`}
                                            defaultValue={0}
                                        />
                                    </Row>
                                );
                            })
                        ) : (
                            <Row className='m-0 px-4 py-2 w-100'>
                                <h5 className='d-flex align-items-center'>{printingCost.name} - {renderLoader(pricing.pricingBreakdown?.printCostPrices[0]?.price)}</h5>
                                <Dropdown
                                    name={'preview.printCosts[0]'}
                                    setFieldValue={setFieldValue}
                                    values={printingCost.data.printingCost.map((x) => {
                                        return {
                                            value: `${printingCost._id}~~${x._id}`,
                                            display: x.label
                                        };
                                    })
                                    }
                                />
                            </Row>
                        )}
                    </>
                )}
                {renderRemainingComponents(remainingComponents)}
    
                {(quantity) && (
                    <Row className='m-0 px-4 py-2 w-100'>
                        {(quantity.data.quantity.isCustom) ? (
                            <>
                                <h5>Quantity</h5>
                                <Dropdown
                                    name={'preview.quantity'}
                                    setFieldValue={setFieldValue}
                                    values={getQuantities(quantity.data.quantity.isCustom, quantity)}
                                />
                            </>
                        ) : (
                            <>
                                <h5>
                                    Quantity ({quantity.data.quantity.min} ~ {(quantity.data.quantity.isInfinite) ? '∞' : quantity.data.quantity.max})
                                </h5>
                                <Field 
                                    className='form-control form-control-sm form-control-alternative'
                                    type='number'
                                    onWheel={(e) => e.target.blur()}
                                    max={quantity.data.quantity.isInfinite ? 9999999 : quantity.data.quantity.max}
                                    min={quantity.data.quantity.min}
                                    name={'preview.quantity'}
                                />
                            </>
                        )}
                    </Row>
                )}

                {additionalData.map((x, i) => {
                    return (
                        <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                            <h5 className='d-flex align-items-center'>{x.title} - {renderLoader(values.additionalDataPrices[i])}</h5>
                            <Dropdown
                                name={`additionalDataPrices[${i}]`}
                                setFieldValue={setFieldValue}
                                values={x.data?.map((d) => {
                                    return {
                                        value: d.price,
                                        display: d.title
                                    };
                                })}
                            />
                        </Row>
                    );
                })}

                {(values.fileStorageComponents.length > 0) && (
                    <>
                        <Row className='m-0 px-4 py-2 w-100'>
                            <h5>File Upload</h5>
                            <Col md='12' className='d-flex justify-content-center p-3 m-0 rounded additional-info-container border-dashed'>
                                <small>Upload files here</small>
                            </Col>
                        </Row>
                        
                    </>
                )}

                {(values.customFields.length > 0) && (
                    <>
                        {values.customFields.map((x, i) => {
                            return (
                                <Row key={x.title} className='m-0 px-4 py-2 w-100'>
                                    <h5 className='d-flex align-items-center'>{x}</h5>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative'
                                        type='text'
                                        name={`customFieldsTemp[${i}]`}
                                    />
                                </Row>
                            );
                        })}
                    </>
                )}
    
                <Row className='mt-4 m-0 px-4 py-0 w-100'>
                    <h5 className='m-0 d-flex align-items-center'>Order Total</h5>
                </Row>
                <Row className='m-0 px-4 py-0 w-100'>
                    <h1 className='d-flex align-items-center color-primary'>{renderFinalPrice((Number(pricing.price ?? 0) + values.additionalDataPrices?.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2))}</h1>
                </Row>
            </div>

            <Row className='m-0 px-4 py-3 w-100 rounded additional-info-container'>
                <h3 className='d-flex align-items-center'>Additional Info</h3>
                <Row className='m-0 w-100'></Row>
                {(!!pricing?.metadata?.materialSize) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Material Size: {renderLoader(`${pricing?.metadata?.materialSize}`)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.width && !!pricing?.metadata?.height) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Sheet Size: {renderLoader(`${pricing?.metadata?.width}${printingSize?.defaultSize} x ${pricing?.metadata?.height}${printingSize?.defaultSize}`)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.totalPrintingSqFeet) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Printing Area: {renderLoader(pricing.metadata?.totalPrintingSqFeet?.toFixed(4))} sqft</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.wastageArea) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Wastage Area: {renderLoader(pricing.metadata?.wastageArea?.toFixed(4))} sqft</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.yield) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Imposition per Sheet: {renderLoader(pricing.metadata?.yield)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.numOfSheet) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Total Sheets: {renderLoader(pricing.metadata?.numOfSheet)}</small>
                    </Row>
                )}
                {'-----------------------------'}
                {(!!pricing?.metadata?.packingSize) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Packing Volume: {renderLoader(pricing.metadata?.packingSize)}</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.packingWeight) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Packing Weight: {renderLoader(pricing.metadata?.packingWeight)}</small>
                    </Row>
                )}
                {'-----------------------------'}
                {(!!pricing?.metadata?.marginPercentage || !!pricing?.metadata?.superMarginPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Margin Percentage: +{renderLoader((((role === 'superadmin') ? pricing.metadata?.superMarginPercentage - 1 : pricing.metadata?.marginPercentage - 1) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}
                {(!!pricing?.metadata?.discountPercentage || !!pricing?.metadata?.superDiscountPercentage) && (
                    <Row className='m-0 w-100'>
                        <small className='d-flex align-items-center'>Dynamic Price Discount: -{renderLoader((((role === 'superadmin') ? 1 - pricing?.metadata?.superDiscountPercentage: 1 - pricing.metadata?.discountPercentage) * 100)?.toFixed(2))}%</small>
                    </Row>
                )}

                {pricing?.metadata?.marginProfiles?.map((x) => {
                    if (x && x.percentage) {
                        return (
                            <Row key={x?.name} className='m-0 w-100'>
                                <small className='d-flex align-items-center'>Profile ({x.name}): {renderLoader(x.price)} (+{renderLoader(((x.percentage - 1) * 100)?.toFixed(2))}%)</small>
                            </Row>
                        );
                    } else {
                        return null;
                    }
                })}
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details.user,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LooseSheetPreviewByComponent);
