import React from 'react';
import { Field } from 'formik';
import { ReactComponent as DragIcon} from '../../assets/img/icons/common/drag.svg';

import {
    Row,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
} from 'reactstrap';
const ComponentTextInput = (props) => {
    const { className, location, onBlur, hasDrag, placeholder, chipText } = props;
    return (  
        <Row className={`m-0 d-flex align-items-center justify-content-between rounded bg-white w-100 ${className}`}>
            <div className='input-card d-flex align-items-center w-100'>
                {hasDrag && (
                    <DragIcon 
                        width={13}
                        height={13}
                        className='mr-1'
                    />
                )}
                <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0 w-100' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF' }}>
                    <Field 
                        className='form-control form-control-sm form-control-alternative mr-1 bg-transparent'
                        style={{ borderRadius: 50 }}
                        type={'text'}
                        placeholder={placeholder}
                        onWheel={(e) => e.target.blur()}
                        name={location}
                        onBlur={(e) => (onBlur) ? onBlur(e) : undefined}
                    />
                    {chipText && (
                        <InputGroupAddon addonType='append'>
                            <InputGroupText style={{ backgroundColor: '#0866FF', borderRadius: 50 }}>
                                <small className='m-0' style={{ color: 'white' }}>{chipText}</small>
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                </InputGroup>
            </div>
        </Row>
    );
};
 
export default ComponentTextInput;
