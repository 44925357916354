import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchDynamicPrice, pushDynamicPrice, updateDynamicPrice, removeDynamicPrice } from '../redux/actions/dynamicPriceActions';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    CardBody,
    Input
} from 'reactstrap';

import ModalConfirmation from '../components/Extra/ModalConfirmation.js';
import Notifications from '../components/Extra/Notifications.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';

import client from '../feathers';
import axios from 'axios';

const PAGESIZE = 30;

function paginationCount(length, currentPage) {
    return {
        total: length,
        per_page: PAGESIZE,
        current_page: currentPage,
        last_page: Math.ceil(length / PAGESIZE),
        from: ((currentPage - 1) * PAGESIZE) + 1,
        to: currentPage * PAGESIZE
    };
}

// http://localhost:3000/auth/login?key=6663ef2c085ac298ccfe59ba&path=/admin/dynamic-price

const DynamicPrice = (props) => {
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ dataEdit, setDataEdit ] = useState({});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ totalDynamicePrice, setTotalDynamicePrice ] = useState(0);
    const [ dynamicPriceData, setDynamicPriceData ] = useState([]);
    const [ regexDynamicName, setRegexDynamicName ] = useState('');
    const [ tableSorting, setTableSorting ] = useState({
        dynamicPriceName: 1,
        type: 1,
        createdAt: 1
    });

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,
    
        actions: modalActions,
    } = useModal();
    
    useEffect(() => {
        fetchData(props.userId, props.fetchDynamicPrice, notificationOpen, 0, 1);
    }, [props.userId, props.fetchDynamicPrice, tableSorting, regexDynamicName]);

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm);
    };

    const modalCallback = () => {
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}deleteDynamicPriceAndPatchProducts`, {
                    role: props.role,
                    dynamicPriceId: dataEdit._id
                },
                {
                    headers: {
                        'Authorization': auth.accessToken
                    }
                });
            })
            .then(() => {
                props.removeDynamicPrice({
                    _id: dataEdit._id
                });
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };

    const sortList = (item, value) => {
        setTableSorting(prevState => {
            delete prevState[item];
            return {
                [item]: value,
                ...prevState,
            };
        });
    };
  
    const fetchData = (userId, fetchDynamicPrice, notificationOpen, skip, currentPage) => {

        if(userId){

            client.authenticate()
                .then(()=>{
                    return client.service('dynamicPrice').find({
                        query: {
                            userId: userId,
                            orRegex: { dynamicPriceName: regexDynamicName },
                            $skip: skip,
                            $limit: PAGESIZE,
                            $sort: tableSorting
                        }
                    });
                })
                .then((res) => {
                    setDynamicPriceData(res.data);
                    setPagination(paginationCount(res.total, currentPage));
                    setTotalDynamicePrice(res.total);
                    // fetchDynamicPrice(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                                fetchData(props.userId, props.fetchDynamicPrice, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        setPagination(paginationCount(pagination.total, i)); 
                                        fetchData(props.userId, props.fetchDynamicPrice, notificationOpen, (i-1)*PAGESIZE, i);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                                fetchData(props.userId, props.fetchDynamicPrice, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>Dynamic Price {(props.packageDetails && props.role !== 'superadmin') ? `(${totalDynamicePrice}/${props.packageDetails.productRestrictions.total})` : ''}</h2>
                </Row>
                <Row className='mt-3'>
                    <div className='col'>
                        <Card className='shadow px-2 rounded'>
                            <CardBody className='py-0'>
                                <CardHeader className='bg-transparent border-0'>
                                    <Row className='d-flex align-items-center justify-content-between'>
                                        <Media>
                                            <Media body>
                                                {/* <h3 className='mb-0'>Dynamic Price Table</h3> */}
                                                <Input
                                                    placeholder='Search Dynamic Name...'
                                                    className='w-auto d-inline-block mr-2'
                                                    type='text'
                                                    bsSize='sm'
                                                    size={29}
                                                    value={regexDynamicName}
                                                    onChange={(e) => setRegexDynamicName(e.target.value)}
                                                />
                                            </Media>
                                        </Media>
                                        <Button
                                            color='primary'
                                            onClick={() => props.history.push(`/${props.role}/dynamic-price-create`)}
                                        >
                                            <span className='btn-inner--text'>Create Dynamic Price</span>
                                        </Button>
                                    </Row>
                                </CardHeader>
                                <div className='table-responsive mac-scrollbar rounded border'>
                                    <Table className='align-items-center table-flush' style={{minHeight: 200}}>
                                        <thead style={{ height: 50 }}>
                                            <tr>
                                                <th scope='col'>
                                                    Name
                                                    {
                                                        tableSorting.dynamicPriceName === 1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('dynamicPriceName', -1)}>
                                                            <i className='fa fa-arrow-down' />
                                                        </span>)
                                                    }
                                                    {
                                                        tableSorting.dynamicPriceName === -1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('dynamicPriceName', 1)}>
                                                            <i className='fa fa-arrow-up' />
                                                        </span>)
                                                    }
                                                </th>
                                                <th scope='col'>
                                                    Created Date
                                                    {
                                                        tableSorting.createdAt === 1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('createdAt', -1)}>
                                                            <i className='fa fa-arrow-down' />
                                                        </span>)
                                                    }
                                                    {
                                                        tableSorting.createdAt === -1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('createdAt', 1)}>
                                                            <i className='fa fa-arrow-up' />
                                                        </span>)
                                                    }
                                                </th>
                                                <th scope='col'>Description</th>
                                                <th scope='col'>
                                                    Applicable
                                                    {
                                                        tableSorting.type === 1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('type', -1)}>
                                                            <i className='fa fa-arrow-down' />
                                                        </span>)
                                                    }
                                                    {
                                                        tableSorting.type === -1 &&
                                                        (<span className='ml-1 cursor-pointer' onClick={() => sortList('type', 1)}>
                                                            <i className='fa fa-arrow-up' />
                                                        </span>)
                                                    }
                                                </th>
                                                <th scope='col'>Deployments</th>
                                                <th scope='col' className='text-right'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dynamicPriceData.length > 0? (
                                                dynamicPriceData.map((v, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                {v.dynamicPriceName}
                                                            </td>
                                                            <td>{moment(v.createdAt).format('DD-MM-YYYY')}</td>
                                                            <td>{v.description}</td>
                                                            <td>{v.type}</td>
                                                            <td>{v.deploymentCount}</td>
                                                            <td className='text-right'>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => {
                                                                        props.history.push(`/${props.role}/dynamic-price-edit/${v._id}`);
                                                                        setDataEdit(v);
                                                                    }}
                                                                >
                                                                    <EditIcon height={15} width={15}/>
                                                                </Button>
                                                                <Button
                                                                    color='grey'
                                                                    className='btn-square'
                                                                    onClick={() => {
                                                                        setDataEdit(v);
                                                                        toggleModalConfirm();
                                                                    }}
                                                                >
                                                                    <DeleteIcon height={15} width={15}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ): (
                                                <tr>
                                                    <td>
                                                        <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <CardFooter className='py-4'>
                                    <nav aria-label='...'>
                                        <PaginationRender />
                                    </nav>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
     
            <ModalConfirmation 
                modalVisible={modalConfirm} 
                toggleModal={toggleModalConfirm} 
                modalCallback={modalCallback} 
                title='Delete Dynamic Price'
                content={(
                    <div className='text-center'>
                        <div className='text-danger mb-1'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='currentColor' className='bi bi-exclamation-triangle-fill' viewBox='0 0 16 16'>
                                <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'/>
                            </svg>
                        </div>
                        <h3>Are you sure want delete this Dynamic Price?</h3>
                        <hr style={{ marginTop: '1rem', marginBottom: '1rem', width: '80%'}}/>
                        <p className='font-weight-400 text-muted'><strong>Product&apos;s Dynamic Price</strong> will also be removed!</p>
                    </div>
                )}
            />
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    fetchDynamicPrice: fetchDynamicPrice,
    pushDynamicPrice: pushDynamicPrice,
    updateDynamicPrice: updateDynamicPrice,
    removeDynamicPrice: removeDynamicPrice,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicPrice);