import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/common/delete.svg';
import { Field } from 'formik';

import ComponentNumberInput from '../ComponentNumberInput.js';
import ComponentTextInput from '../ComponentTextInput.js';

// reactstrap components
import {
    Button,
    Row,
    Col,
} from 'reactstrap';

const Frame = ({
    values,
    index,
    remove,
    setFieldValue
}) => {
    return (  
        <Col md='12' className='p-0'>
            <Row className='m-0 mb-1 align-items-center justify-content-start'>
                <Col md='3' className='pr-0'>
                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                        <ComponentTextInput
                            hasDrag
                            placeholder='Label'
                            location={`data.options[${index}].label`}
                        />
                    </Row>
                </Col>
                <Col md='2' className='p-0 d-flex align-items-center justify-content-between'>
                    <Field
                        type='checkbox'
                        name={`data.options[${index}].top`}
                        checked={values.data.options[index].top}
                        onChange={() =>
                            setFieldValue(`data.options[${index}].top`, !values.data.options[index].top)
                        }
                    />
                    <Field
                        type='checkbox'
                        name={`data.options[${index}].bottom`}
                        checked={values.data.options[index].bottom}
                        onChange={() =>
                            setFieldValue(`data.options[${index}].bottom`, !values.data.options[index].bottom)
                        }
                    />
                    <Field
                        type='checkbox'
                        name={`data.options[${index}].left`}
                        checked={values.data.options[index].left}
                        onChange={() =>
                            setFieldValue(`data.options[${index}].left`, !values.data.options[index].left)
                        }
                    />
                    <Field
                        type='checkbox'
                        name={`data.options[${index}].right`}
                        checked={values.data.options[index].right}
                        onChange={() =>
                            setFieldValue(`data.options[${index}].right`, !values.data.options[index].right)
                        }
                    />
                </Col>
                <Col md='1' className='pr-0'>
                    <Field 
                        className='form-control form-control-sm form-control-alternative'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={`data.options[${index}].minPerSide`}
                    />
                </Col>
                <Col md='1' className='pr-0'>
                    <Field 
                        className='form-control form-control-sm form-control-alternative'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={`data.options[${index}].maxPerSide`}
                    />
                </Col>
                <Col md='1' className='pr-0'>
                    <Field 
                        className='form-control form-control-sm form-control-alternative'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={`data.options[${index}].basePrice`}
                    />
                </Col>
                <Col md='1' className='pr-0'>
                    <Field 
                        className='form-control form-control-sm form-control-alternative'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={`data.options[${index}].minAmount`}
                    />
                </Col>
                <Col md='1' className='pr-0'>
                    <Field 
                        className='form-control form-control-sm form-control-alternative'
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        name={`data.options[${index}].pricePer`}
                    />
                </Col>
                <Col md='1'>
                    <Button 
                        key={'asd'}
                        color='danger'
                        className='btn-round'
                        onClick={() => remove(index)}
                    >
                        <DeleteIcon 
                            width={14}
                            height={14}
                        />
                    </Button>
                </Col>
            </Row>
            <Row className='m-0 mb-1 align-items-center justify-content-start'>
                {values.calcType === 'Sqft' && (
                    <Col className='p-0'>
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'Wastage'}
                            location={`data.options[${index}].wastage`}
                            chipText={values.defaultSize || values.calcType}
                        />
                    </Col>
                )}
            </Row>
        </Col>
    );
};
 
export default Frame;