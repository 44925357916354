import {
    FETCH_STATUS,
    PUSH_STATUS,
    UPDATE_STATUS,
    REMOVE_STATUS,
} from '../actions/statusActions';

const INITIAL_STATE = {
    data: [],
};

export default function statusReducer(state = INITIAL_STATE, action){
    switch(action.type){
        case FETCH_STATUS:
            return {
                ...state,
                data:action.data,
            };
        case PUSH_STATUS:
            return {
                ...state,
                data:[action.data, ...state.data],
            };
        case UPDATE_STATUS:
            let updated = state.data.map((v)=>{
                if(v._id === action.data._id) {
                    return action.data;
                } else return v;
            });
            return {
                ...state,
                data: updated
            };
        case REMOVE_STATUS:
            return {
                ...state,
                data: [...state.data.filter( (item) => item._id !== action.data._id)]
            };
        default:
            return state;

    }
}