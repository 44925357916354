import React from 'react';
import {
    Pagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';


const ReportPagination = (props) => {
    const { currentIndex, maxIndex, setIndex } = props;

    if(maxIndex > 0){
        let pageArr = [];
        for(let i = 0; i <= maxIndex; i++){
            pageArr.push(i);
        }
        return (
            <Pagination
                className='pagination justify-content-center mb-0'
                listClassName='justify-content-center mb-0'
            >
                <PaginationItem className={(currentIndex > 0?'':'disabled')}>
                    <PaginationLink
                        onClick={() => {
                            setIndex(currentIndex-1);
                        }} 
                        tabIndex='-1'
                    >
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {pageArr.map((i) => {
                    return (
                        <PaginationItem key={i} className={(currentIndex === i? 'active': '')}>
                            <PaginationLink
                                onClick={() => {
                                    setIndex(i);
                                }} 
                            >
                                {i + 1}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}
                <PaginationItem className={((currentIndex+1) > maxIndex?'disabled':'')}>
                    <PaginationLink
                        onClick={() => {
                            setIndex(currentIndex+1);
                        }} 
                    >
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    }else{
        return null;
    }
};
 
export default ReportPagination;