/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import AdminFooter from '../components/Footers/AdminFooter.js';
import Sidebar from '../components/Sidebar/Sidebar.js';

import routes from '../routes.js';
import PrivateRoute from '../PrivateRoute.js';

const Admin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/admin') {
                if(props.userInfo?.user?.isInternal && prop.path === '/csv') {
                    prop.show = true;
                    return (
                        <PrivateRoute
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                } else {
                    return (
                        <PrivateRoute
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }
            } else {
                return null;
            }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            let splitPath = props.location.pathname.split('/');
            let getLast = splitPath[splitPath.length-1];
            let isId = getLast?.length > 20 && getLast?.indexOf(' ') <= 0;
      
            let realPath = isId? props.location.pathname.replace(getLast, ':id'): props.location.pathname;
      
            if (
                realPath === (routes[i].layout + routes[i].path)
            ) {
                return routes[i].name;
            }
        }
        return 'Brand';
    };

    return (
        <>
            {(window.self === window.top) && (
                <Sidebar
                    {...props}
                    routes={routes}
                    layout={'/admin'}
                    logo={{
                        innerLink: '/admin/index',
                        imgSrc: '/logo.png',
                        imgAlt: '...',
                    }}
                />
            )}
            <div className='main-content' ref={mainContent}>
                {(window.self === window.top) && (
                    <AdminNavbar
                        {...props}
                        brandText={getBrandText(props.location.pathname)}
                    />
                )}
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from='*' to='/admin/index' />
                </Switch>
                {/* <Container fluid>
          <AdminFooter />
        </Container> */}
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.role.details
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
