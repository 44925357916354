import React, { useState, useEffect } from 'react';
import ObjectId from 'bson-objectid';
import { withRouter } from 'react-router';
import { useComponents } from '../../../zustand/componentStore';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/common/search.svg';

import {
    Row,
    Col,
    Modal,
    Input,
    InputGroup,
    Button
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import client from '../../../feathers';

const ProductSelector = (props) => {
    const {
        userId,
        applicableSource,
        productIds,
        callback,
    } = props;

    const {
        productSelectorModalIsOpen: isOpen,
        actions,
    } = useComponents();

    const {
        setProductSelectorModalIsOpen: toggle,
    } = actions;

    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState(-1);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            _id: (productIds) ? { $in: productIds.map((x) => new ObjectId(x)) } : undefined,
                            userId,
                            isComponentBased: true,
                            isClustered: false,
                            source: applicableSource,
                            orRegex: { name: searchQuery },
                            $sort: { createdAt: filter },
                            $limit: 1000,
                        }
                    });
                })
                .then((res) => {
                    setProducts(res.data);
                });
        }
    }, [searchQuery, productIds, filter]);


    return (
        <Modal
            className='modal-dialog-centered'
            isOpen={isOpen}
            toggle={() => toggle(!isOpen)}
        >
            <div className='pt-3 pb-0 modal-header d-flex justify-content-between align-items-center'>
                <div></div>
                <button
                    aria-label='Close'
                    className='modal-close'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => toggle(!isOpen)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className='modal-body py-2'>
                <div className='mb-2'>
                    <InputGroup className='input-group-alternative d-flex align-items-center'>
                        <SearchIcon height={20} className='mx-1'/>
                        <Input
                            placeholder='Search Product Name...'
                            className='w-auto d-inline-block mr-2'
                            type='text'
                            bsSize='sm'
                            size={20}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div className='mb-2'>
                    <Button
                        className={`shadow-none rounded bg-grey p-1 px-2 border-0 ${(filter === -1) ? 'active' : ''}`}
                        onClick={() => setFilter(-1)}
                    >
                        Most Recent
                    </Button>
                    <Button
                        className={`shadow-none rounded bg-grey p-1 px-2 border-0 ${(filter === 1) ? 'active' : ''}`}
                        onClick={() => setFilter(1)}
                    >
                        View All
                    </Button>
                </div>
                {products.length > 0 ? (
                    <div className='w-100 bg-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                        {products.map((x) => {
                            return (
                                <Row
                                    key={x._id}
                                    className='d-flex py-2 mx-1 component-selector-item rounded align-items-center'
                                    onClick={() => callback(x._id)}
                                >
                                    <Col md={2} className='component-code m-0 text-uppercase'>
                                        {x.code}
                                    </Col>
                                    <Col md={10}>
                                        <h4 className='m-0'>
                                            {x.name}
                                        </h4>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                ) : (
                    <div className='w-100 d-flex justify-content-center rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                        No products found.
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default withRouter(ProductSelector);
