import React, { useState } from 'react';
import {connect} from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DragIcon } from '../../../assets/img/icons/common/drag.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/common/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/common/plus.svg';
import { FieldArray } from 'formik';
import { useComponents } from '../../../zustand/componentStore';

import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import { cloneDeep } from 'lodash';

const DraggableComponent = (props) => {
    const {
        componentTypeColors,

        values,
        index,
        name,
        componentTypeName,
        code,
        component,
        supportNested,
        location,
        setFieldValue,
        color,
        description,
    } = props;

    const { actions } = useComponents();
    const [isHover, setIsHover] = useState(false);

    // const getComponentColor = (name) => {
    //     if(componentTypeColors) {
    //         const color =  componentTypeColors.find((x) => x.name === name)?.color;
    //         if (color) {
    //             return color;
    //         } else {
    //             return 'white';
    //         }
    //     }
    // };

    const removeItem = (location, index) => {
        const cloned = cloneDeep(eval('values.' + location));
        cloned.splice(index, 1);

        setFieldValue(location, cloned);
    };

    const getNestedExcludedTypes = () => {
        let exclude = [];
        if (componentTypeName === 'Material' && values.source.includes('booklet')) {
            exclude = ['Material', 'Binding Type', 'Printing Size', 'Printing Cost', 'Quantity', 'Production Timeline', 'Artwork Service', 'File Storage'];
            actions.setSelectedComponentType('Options');
        } else if(componentTypeName === 'Material') {
            exclude = ['Material', 'Binding Type', 'Production Timeline', 'Artwork Service', 'File Storage'];
        } else if (componentTypeName === 'Printing Cost') {
            exclude = ['Material', 'Binding Type', 'Printing Cost', 'Production Timeline', 'Artwork Service', 'File Storage'];
        } else if (componentTypeName === 'Model') {
            exclude = ['Model', 'Material', 'Binding Type', 'Production Timeline', 'Artwork Service', 'File Storage', 'Quantity', 'Printing Size'];
        } else {
            exclude = ['Material', 'Binding Type', 'Printing Cost', 'Quantity', 'Printing Size', 'Production Timeline', 'Artwork Service', 'File Storage'];
        }
        actions.setExcludedComponentTypes([...exclude]);
    };

    return (
        <div className='position-relative'>
            <Draggable key={index} draggableId={`${location}[${index}]`} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Row
                            className='d-flex m-0 p-1 my-1 rounded align-items-center justify-content-between bg-white border-primary'
                            style={{ height: 45 }}
                        >
                            <DragIcon 
                                width={13}
                                height={13}
                                className='mr-0'
                            />
                            <div className='d-flex px-2 py-1 align-items-center rounded bg-primary' style={{ color: 'white' }}>
                                <small className='xs bold m-0'>{componentTypeName}</small>
                            </div>  
                           
                            <Col md='1' className='p-0'>
                                <small className='component-code m-0 text-uppercase'>{code}</small>
                            </Col>
                            <Col 
                                md='7'
                                className='pr-0'
                            >
                                {(isHover) && (
                                    <Card className='bg-yellow shadow rounded position-absolute' style={{ width: 300, minHeight: 100, zIndex: 10, left: '-30%', top: -110}}>
                                        <CardBody className='p-3'>
                                            <Row className='p-0 m-0 mb-2'>
                                                <small>{code} | {name}</small>
                                            </Row>
                                            <Row className='p-0 m-0'>
                                                <h5 className='m-0'>Description:</h5>
                                            </Row>
                                            <Row className='p-0 m-0'>
                                                <small>{(description) ? description : '-'}</small>
                                            </Row>
                                        </CardBody>
                                        <div className='position-absolute'
                                            style={{
                                                left: '45%',
                                                bottom: '-10px',
                                                width:0,
                                                borderTop:'10px solid #FFCA3A',
                                                borderLeft:'7px solid transparent',
                                                borderRight:'7px solid transparent',
                                            }} />
                                    </Card>
                                )}
                                <small
                                    className='font-weight-bold'
                                    onMouseOver={() => setIsHover(true)} onMouseOut={() =>setIsHover(false)}
                                >{name}</small>
                            </Col>

                            <Col md='1' className='p-0'>
                                <a
                                    className='nav-item'
                                    style={{color: 'red'}}
                                    onClick={() => [
                                        removeItem(`${location}`, index), 
                                    ]}
                                >
                                    <p className='font-weight-bold m-0 text-center'>
                                        <CloseIcon 
                                            width={22}
                                            height={22}
                                            stroke={'red'}
                                        />
                                    </p>
                                </a>
                            </Col>
                            {(supportNested) && (
                                <Button
                                    color='white'
                                    className='shadow-none rounded-circle p-1 m-0 d-flex position-absolute border'
                                    style={{ height: 25, width: 25, left: '50%', bottom: '-10px', zIndex: 10 }}
                                    onClick={() => [
                                        actions.setStoredLocation(`${location}[${index}].nestedComponents`),
                                        actions.setSelectedComponentType(''),
                                        actions.setSelectedComponentTypeId(''),
                                        actions.setModalAllowBack(true),
                                        getNestedExcludedTypes(),
                                        actions.setAddComponentModalIsOpen(true),
                                    ]}
                                >
                                    <PlusIcon
                                        height={15}
                                        width={15}
                                        stroke={'#004EFF'}
                                        fill={'#004EFF'}
                                    />
                                </Button>
                            )}
                        </Row>
                        
                        {(supportNested) && (
                            <Droppable droppableId={`${location}[${index}].nestedComponents`}>
                                {(nestedProvided) => (
                                    <div {...nestedProvided.droppableProps} ref={nestedProvided.innerRef}>
                                        <div style={{ minHeight: '3px'}}>
                                            <FieldArray
                                                name={`${location}[${index}].nestedComponents`}
                                                validateOnChange={true}
                                                render={({ remove }) => {
                                                    const sortedComponents = component.nestedComponents.sort((a, b) => a.order - b.order);
                                                    return sortedComponents.map((nestedComponent, childIndex) => {
                                                        return (
                                                            <div key={`${nestedComponent.name}[${childIndex}]`} className='ml-4'>
                                                                <DraggableComponent
                                                                    values={values}
                                                                    key={`${nestedComponent.name}[${childIndex}]`}
                                                                    index={childIndex}
                                                                    name={nestedComponent.name}
                                                                    componentTypeName={nestedComponent.componentTypeName}
                                                                    code={nestedComponent.code}
                                                                    remove={() => removeItem(`${location}[${index}].nestedComponents`, childIndex)}
                                                                    component={nestedComponent}
                                                                    supportNested={nestedComponent?.nestedComponents?.length > 0 ? true : false}
                                                                    location={`${location}[${index}].nestedComponents`}
                                                                    setFieldValue={setFieldValue}
                                                                    description={nestedComponent.description}
                                                                />
                                                            </div>
                                                            
                                                        );
                                                    });
                                                }}
                                            />
                                            {nestedProvided.placeholder}
                                        </div>
                                    </div>
                                )}  
                            </Droppable>
                        )}
                        <div className={`${(supportNested) ? 'mb-3' : ''}`}></div>

                    </div>
                )}
            </Draggable>
        </div>
    );
};
const mapStateToProps = state => ({
    componentTypeColors: state.componentsType.componentTypeColors,
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(DraggableComponent);
