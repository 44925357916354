import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ObjectId from 'bson-objectid';
import { connect } from 'react-redux';
import { fetchComponents, pushComponents, updateComponents, removeComponents } from '../redux/actions/componentsActions.js';
import { fetchComponentsType } from '../redux/actions/componentsTypeActions.js';
import getSymbolFromCurrency from 'currency-symbol-map';
import CreateComponentsTypeModal from '../components/Components/CreateComponentsTypeModal.js';
import { useReport } from '../zustand/reportStore.js';
import { useModal } from '../zustand/modalStore.js';
import { useComponents } from '../zustand/componentStore.js';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import ReportPagination from '../components/Common/ReportPagination.js';
import StatusChip from '../components/Common/StatusChip.js';
import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/common/search.svg';
import Breadcrumb from '../components/Common/Breadcrumb.js';
import ProductSelector from '../components/Products/ProductsByComponents/ProductSelector.js';
import { ReactComponent as DuplicateIcon } from '../assets/img/icons/common/copy.svg';
import ModalEmpty from '../components/Extra/ModalEmpty.js';
import { ReactComponent as UploadIcon } from '../assets/img/icons/common/upload.svg';
import papa from 'papaparse';
import { isEmpty } from 'lodash';

// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardBody,
    CardFooter,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Progress,
    FormGroup
} from 'reactstrap';

import client from '../feathers';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const searchList = [{
    name: 'Name',
    value: 'name',
}, {
    name: 'Code',
    value: 'code',
}, {
    name: 'Source',
    value: 'applicableSource',
}
];

const findComponentType = (type) => {
    switch(type) {
        case 'Material': {
            return 'material';
        }
        case 'Printing Size': {
            return 'printingSize';
        }
        case 'Printing Cost': {
            return 'printingCost';
        }
        case 'Quantity': {
            return 'quantity';
        }
        case 'Binding Type': {
            return 'bindingType';
        }
        case 'Production Timeline': {
            return 'productionTimeline';
        }
        case 'Artwork Service': {
            return 'artwork';
        } case 'File Storage': {
            return 'fileStorage';
        }
        case 'Packaging': {
            return 'packaging';
        }
        default: {
            return 'additionalComponent';
        }
    }
};

const ComponentsListing = (props) => {
    const { userId, components, role } = props;
    const uploadFileRef = useRef();
    const downloadcsv = useRef();
    const [ notificationMessage, setNotificationMessage ] = useState('');
    const [ deploymentProductIds, setDeploymentProductIds ] = useState([]);
    
    const [ notificationColor, setNotificationColor ] = useState('');
    const [ isNotificationOpen, setIsNotificationOpen ] = useState(false);
    
    const [ isOpenComponentTypeCreate, setIsOpenComponentTypeCreate ] = useState(false);
    const [ componentTypes, setComponentTypes ] = useState([]);
    const [ selectedComponentType, setSelectedComponentType ] = useState();
    const [ existingComponentsType, setExistingComponentsType ] = useState();
    const [regexComponentName, setRegexComponentName] = useState('');
    const { search } = useLocation();
    const timeoutRef = useRef(null);

    const [ tableSorting, setTableSorting ] = useState({
        code: 1,
        name: 1,
        applicableSource: 1,
        createdAt: 1
    });
    const [ selectedSearch, setSelectedSearch ] = useState(searchList[0].name);
    const [ searchObj, setSearchObj ] = useState({});
    const { actions: componentActions } = useComponents();
    const [ modalVisible, setModalVisible ] = useState(false);
    const [genReport, setGenReport] = useState(0);// 0:ready; 1:loading; 2:done
    const [progress, setProgress] = useState(0);
    const [csvProgress, setCsvProgress] = useState(0);
    const [upladLoading, setUpladLoading] = useState(false);
    const [data, setData] = useState([]);
    const [fileName, setFilename] = useState('');
    const [ newCsvFile, setNewCsvFile ] = useState(null);

    const {
        componentCurrentIndex,
        componentPerPage,
        componentMaxIndex,
        componentData,

        actions: reportActions,
    } = useReport();

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();
    
    useEffect(() => {
        const params = new URLSearchParams(search);
        setSelectedComponentType({
            name: params.get('category'),
            _id: params.get('categoryId'),
        });
    }, []);

    useEffect(() => {
        fetchDataComponentTypes();
    }, []);

    useEffect(() => {
        fetchData();
    }, [searchObj, componentCurrentIndex, selectedComponentType, componentPerPage, tableSorting]);
  

    function sourceName(source){
        if(source === 'csv'){
            return 'CSV';
        }else if(source === 'api'){
            return 'API';
        }else if(source === 'poster'){
            return 'SC-Area';
        }else if(source === 'booklet'){
            return 'SC-Booklet';
        }else if(source === 'loose-sheet'){
            return 'SC-Sheet';
        }else if(source === 'loose-sheet-v2'){
            return 'SC-Sheet';
        }else if(source === 'product'){
            return 'SC-Item';
        }else{
            return source;
        }
    }

    const fetchDataComponentTypes = () => {
        if(userId){
            client.authenticate()
                .then(()=>{
                    let query = {
                        $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                        $limit: 1000,
                        $sort: { createdAt: 1 },
                    };
                    if(role === 'superadmin') {
                        query = {
                            $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                            $limit: 1000,
                            $sort: { createdAt: 1 },
                        };
                    }
                    return client.service('componentsType').find({
                        query
                    });
                })
                .then((res) => {
                    setComponentTypes(res.data);
                });
        }
    };

    const fetchData = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            if(userId && selectedComponentType?._id){    
                client.authenticate()
                    .then(()=>{
                        let query = {
                            componentsTypeId: selectedComponentType?._id,
                            userId,
                            $limit: componentPerPage,
                            $skip: componentCurrentIndex * componentPerPage,
                            $sort: tableSorting,
                            orRegex: searchObj,
                        };
                        if(isEmpty(searchObj)){
                            delete query.orRegex;
                        }
                        return client.service('components').find({
                            query
                        });
                    })
                    .then((res) => {
                        reportActions.setComponentMaxIndex(Math.floor(res.total / componentPerPage));
                        reportActions.setComponentData(res.data);
                    });
            }
        }, 100);
    };

    const deleteComponent = (id) => {
        if(userId){
            client.authenticate()
                .then(() => {
                    return client.service('components').remove(id);
                })
                .then((res) => {
                    fetchData();
                });
        }
    };

    const sortList = (item, value) => {
        setTableSorting(prevState => {
            delete prevState[item];
            return {
                [item]: value,
                ...prevState,
            };
        });
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsNotificationOpen(bool);
        // setNotificationColor(color);
        // setNotificationMessage(message);
    };

    const getExcelData = async () => {
        setGenReport(1);
        setProgress(0);
        setFilename(`${props.userInfo.email}-${selectedComponentType.name}.csv`);

        let continueGet = true;
        let skip = 0;
        let formatData = [];
        const excelDataLimit = 500;
        while (continueGet !== false) {
            await client.authenticate()
                .then(()=>{
                    let query = {
                        componentsTypeId: selectedComponentType?._id,
                        userId,
                        // $limit: componentPerPage,
                        // $skip: componentCurrentIndex * componentPerPage,
                        $limit: excelDataLimit,
                        $skip: skip,
                    // $sort: tableSorting,
                    // orRegex: searchObj,
                    };
                    // if(isEmpty(searchObj)){
                    //     delete query.orRegex;
                    // }
                    return client.service('components').find({
                        query
                    });
                })
                .then((res) => {

                    if (res.data.length === 0) {
                        continueGet = false;
                    } else {
                        skip += excelDataLimit;
                        setProgress((skip / res.data.total) * 100);
                    }
    
                    const params = new URLSearchParams(search);

                    res.data.map((i) => {
                        formatData = formatData.concat(
                            generateCSVByComponent(params, i)
                        );
                    });
                })
                .catch((err) => {
                    console.log('e', { err });
                });
            setData(formatData);
        }
        setGenReport(2);
    };

    const importCsv = () => {
        setModalVisible(!modalVisible);
    };

    const toggleUploadModal = () => {
        setModalVisible(!modalVisible);
    };

    const handleFileChange = async(e) => {
        setCsvProgress(Math.floor(Math.random() * 101));
        
        let singleFile = e.target.files[0];
        
        let fileType = (singleFile.type === 'application/vnd.ms-excel' || singleFile.type === 'text/csv');
    
        if(fileType){    
            setNewCsvFile(singleFile);
            setCsvProgress(100);
            return e.target.value = null;
        }else{
            // eslint-disable-next-line no-undef
            window.alert('Only Accept CSV File!');
            return e.target.value = null;
        }
    };

    const generateCSVByComponent = (params, i) => {
        switch(params.get('category')) {
            case 'Material': {
                return {
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,
                    'Default Size': i.defaultSize,
                    
                    // meta
                    'is Content': i.metadata?.isContent,
                    'is Cover': i.metadata?.isCover,
                    'Max PP': i.metadata?.maxPP,
                    'Min PP': i.metadata?.minPP,

                    'PrintingArea Max Width': i.data?.printingArea?.maxWidth,
                    'PrintingArea Max Height': i.data?.printingArea?.maxHeight,

                    'costing Base Price': i.data?.costing?.basePrice || 0,
                    'costing per Sheet Price': i.data?.costing?.perSheetPrice || 0,
                    'costing per Sqft Price': i.data?.costing?.perSqftPrice || 0,
                    'costing per Wastage Price': i.data?.costing?.wastage || 0,

                };
            }
            case 'Printing Size': {
                let child = [];
                if(i.data?.printingSizeDetails?.sizes?.length > 0){
                    child = i.data?.printingSizeDetails?.sizes?.map((x, index) => {
                        return {
                            'Printing Size Details ParentId': i._id,
                            'Printing Size Details Label': x.label,
                            'Printing Size Details Width': x.width,
                            'Printing Size Details Height': x.height,
                            'Printing Size Details Base Price': x.basePrice,
                            'Printing Size Details Enable Wastage': x.enableWastage,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,
                    'Default Size': i.defaultSize,
                    
                    'Printing Size Details ParentId': i._id,
                    'Printing Size Details Label': '',
                    'Printing Size Details Width': '',
                    'Printing Size Details Height': '',
                    'Printing Size Details Base Price': '',
                    'Printing Size Details Enable Wastage': '',
                }].concat(child);
            }
            case 'Printing Cost': {
                let child = [];
                if(i.data?.printingCost?.length > 0){
                    child = i.data?.printingCost.map((x, index) => {
                        return {
                            'Printing Cost ParentId': i._id,
                            'Printing Cost Label': x.label,
                            'Printing Cost MinSqft': x.minSqft,
                            'Printing Cost Max Sqft': x.maxSqft,
                            'Printing Cost Base Price': x.basePrice,
                            'Printing Cost Price Per': x.pricePer,
                            'Printing Cost Wastage': x.wastage,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,
                    'Default Size': i.defaultSize,
                    'Calc Type (Sqft/Absolute)': i.calcType,
                    'Listing Type (single/listByQuantity)': i.listingType,
                    'Printing Cost ParentId': '',
                    'Printing Cost Label': '',
                    'Printing Cost MinSqft': '',
                    'Printing Cost Max Sqft': '',
                    'Printing Cost Base Price': '',
                    'Printing Cost Price Per': '',
                    'Printing Cost Wastage': '',
                }].concat(child);
            }
            case 'Packaging': {
                return {
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'Packaging Type': i.data?.packaging?.type,
                    'Packaging Model': i.data?.packaging?.model,

                };
            }
            case 'Quantity': {
                let child = [];
                if(i.data?.quantity?.custom?.length > 0){
                    child = i.data?.quantity?.custom?.map((x, index) => {
                        return {
                            'Quantity ParentId': i._id,
                            'Quantity custom': x.quantity,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,
                    'Default Size': i.defaultSize,

                    'Quantity ParentId': '',
                    'Quantity custom': '',
                    'Quantity isCustom': i.data?.quantity?.isCustom,
                    'Quantity min': i.data?.quantity?.min,
                    'Quantity max': i.data?.quantity?.max,
                    'Quantity isInfinite': i.data?.quantity?.isInfinite,
                    'Quantity start': i.data?.quantity?.start,
                    'Quantity inMultipleOf': i.data?.quantity?.inMultipleOf,
                    'Quantity end': i.data?.quantity?.end,
                }].concat(child);
            }
            case 'Binding Type': {
                return {
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'Costing BasePrice': i.data?.costing?.basePrice,
                    'Costing perSheetPrice': i.data?.costing?.perSheetPrice,
                    // 'Costing minAmount': i.data?.costing?.minAmount,
                    // 'Costing wastage': i.data?.costing?.wastage,
                    // 'Costing perSqftPrice': i.data?.costing?.perSqftPrice,
                    // 'Costing perBindingPrice': i.data?.costing?.perBindingPrice,

                };
            }
            case 'Production Timeline': {
                let child = [];
                if(i.data?.productionTimeline?.length > 0){
                    child = i.data?.productionTimeline.map((x, index) => {
                        return {
                            'Production Timeline ParentId': i._id,
                            'Production Timeline Label': x.label,
                            'Production Timeline Percent': x.percent,
                            'Production Timeline MinAmount': x.minAmount,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'Production Timeline ParentId': '',
                    'Production Timeline Label': '',
                    'Production Timeline Percent': '',
                    'Production Timeline MinAmount': '',

                }].concat(child);
            }
            case 'Artwork Service': {
                let child = [];
                if(i.data?.artworkService?.length > 0){
                    child = i.data?.artworkService.map((x, index) => {
                        return {
                            'Artwork Service ParentId': i._id,
                            'Artwork Service Label': x.label,
                            'Artwork Service Amount': x.minAmount,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'Artwork Service ParentId': '',
                    'Artwork Service Label': '',
                    'Artwork Service Amount': '',


                }].concat(child);
            }
            case 'File Storage': {
                return {
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'File Storage Location(Wordpress/Dropbox)': i.data?.fileStorage?.location,
                    'File Storage AccessKey': i.data?.fileStorage?.accessKey,
                    'File Storage Secret': i.data?.fileStorage?.secret,
                };
            }
            default: {
                let child = [];
                if(i.data?.productionTimeline?.length > 0){
                    child = i.data?.productionTimeline.map((x, index) => {
                        return {
                            'Options ParentId': i._id,
                            'Options Base Price': x.basePrice,
                            'Options Price Per': x.pricePer,
                            'Options Wastage': x.wastage,
                        };
                    });
                }
                return [{
                    _id: i._id,
                    'Component Type Name': i.componentTypeName,
                    'Applicable Source': i.applicableSource,
                    Code: i.code,
                    Name: i.name,
                    Description: i.description,

                    'options ParentId': '',
                    'Options Base Price': '',
                    'Options Price Per': '',
                    'Options Wastage': '',

                }].concat(child);
            }
        }
    };

    const importCSVByComponent = (parsedCsvData, params) => {
        switch(params.get('category')) {
            case 'Material': {
                let newDataSet = [];
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
                            'defaultSize': i[6],
                            
                            meta: {
                                isContent: i[7],
                                isCover: i[8],
                                maxPP: i[9],
                                minPP: i[10],
                            },
                            data: {
                                printingArea: {
                                    'maxWidth': i[11],
                                    'maxHeight': i[12],
                                },
                                costing: {
                                    'costing basePrice': i[13],
                                    'perSheetPrice': i[14],
                                    'perSqftPrice': i[15],
                                    'wastage': i[16],
                                },
                            },
                        });
                });
                return newDataSet;
            }
            case 'Printing Size': {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[7]) {
                        if (!child[x[7]]) {
                            child[x[7]] = [];
                        }
                        child[x[7]].push({
                            label: x[8],
                            width: x[9],
                            height: x[10],
                            basePrice: x[11],
                            enableWastage: x[12],
                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
                            'defaultSize': i[6],
                            
                            data: {
                                printingSizeDetails: {
                                    'sizes': child[i[0]]? child[i[0]]: [],
                                    'hasCustomSize': i[13],
                                    'minWidth': i[14],
                                    'minHeight': i[15],
                                    'customSizePrice': i[16],
                                    'customEnableWastage': i[17],
                                    'bleedTop': i[18],
                                    'bleedBottom': i[19],
                                    'bleedLeft': i[20],
                                    'bleedRight': i[21],
                                },
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
            case 'Printing Cost': {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[9]) {
                        if (!child[x[9]]) {
                            child[x[9]] = [];
                        }
                        child[x[9]].push({
                            label: x[10],
                            minSqft: x[11],
                            maxSqft: x[12],
                            basePrice: x[13],
                            pricePer: x[14],
                            wastage: x[15],

                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
                            'defaultSize': i[6],
                            'CalcType': i[7],
                            'listingType': i[8],
                            data: {
                                printingCost: child[i[0]]? child[i[0]]: []
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
            case 'Packaging': {
                let newDataSet = [];
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
                            
                            data: {
                                packaging: {
                                    'type': i[6],
                                    'model': i[7],
                                },
                            },
                        });
                });
                return newDataSet;
            }
            case 'Quantity': {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[7]) {
                        if (!child[x[7]]) {
                            child[x[7]] = [];
                        }
                        child[x[7]].push({
                            custom: x[8],
                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
                            'defaultSize': i[6],
        
                            data: {
                                quantity: {
                                    'custom': child[i[0]]? child[i[0]]: [],
                                    'isCustom': i[9]?.toLowerCase() === 'true',
                                    'min': i[10],
                                    'max': i[11],
                                    'isInfinite': i[12]?.toLowerCase() === 'true',
                                    'start': i[13],
                                    'inMultipleOf': i[14],
                                    'end': i[15],
                                },
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
            case 'Binding Type': {
                let newDataSet = [];
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
        
                            data: {
                                costing: {
                                    'costing basePrice': i[6],
                                    'perSheetPrice': i[7],
                                    // 'minAmount': i[8],
                                    // 'wastage': i[9],
                                    // 'perSqftPrice': i[10],
                                    // 'perBindingPrice': i[11],
                                },
                            },
                            
                            // 'Costing minAmount': i.data?.costing?.minAmount,
                            // 'Costing wastage': i.data?.costing?.wastage,
                            // 'Costing perSqftPrice': i.data?.costing?.perSqftPrice,
                            // 'Costing perBindingPrice': i.data?.costing?.perBindingPrice,
                        });
                });
                return newDataSet;
            }
            case 'Production Timeline': {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[6]) {
                        if (!child[x[6]]) {
                            child[x[6]] = [];
                        }
                        child[x[6]].push({
                            label: x[7],
                            percent: x[8] || 0,
                            minAmount: x[9] || 0,
                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
        
                            data: {
                                'productionTimeline': child[i[0]]? child[i[0]]: [],
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
            case 'Artwork Service': {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[6]) {
                        if (!child[x[6]]) {
                            child[x[6]] = [];
                        }
                        child[x[6]].push({
                            label: x[7],
                            amount: x[8] || 0,
                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            // 'Component Type Name': isCsv? i[1] : i.componentTypeName,
                            // 'Applicable Source': isCsv? i[2] : i.applicableSource,
                            code: i[3],
                            name: i[4],
                            description: i[5],
        
                            data: {
                                'artworkService': child[i[0]]? child[i[0]]: [],
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
            case 'File Storage': {
                let newDataSet = [];
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],

                            data: {
                                fileStorage: {
                                    'location': i[6],
                                    'accessKey': i[7],
                                    'secret': i[8],
                                }
                            }
                        });
                });
                return newDataSet;
            }
            default: {
                let newDataSet = [];
                let child = {};
                for (let i = 0; i < parsedCsvData.length; i++) {
                    let x = parsedCsvData[i];
                    if (x[6]) {
                        if (!child[x[6]]) {
                            child[x[6]] = [];
                        }
                        child[x[6]].push({
                            label: x[7],
                            amoubasePricent: x[8] || 0,
                            pricePer: x[9] || 0,
                            wastage: x[10] || 0,
                        });
                        parsedCsvData.splice(i, 1);
                        i--; // Adjust the index after removal
                    }
                }
                parsedCsvData.map(i => {
                    if(i[1] === params.get('category'))
                        newDataSet.push({
                            _id: i[0],
                            code: i[3],
                            name: i[4],
                            description: i[5],
        
                            data: {
                                'options': child[i[0]]? child[i[0]]: [],
                            },

                            componentsTypeId: selectedComponentType?._id
        
                        });
                });
                return newDataSet;
            }
        }
    };

    const handleSubmit = () => {
        if(!newCsvFile){
            // notificationOpen(true, 'danger', 'Please Upload Csv File!');
            window.alert('Please Upload Csv File!');
            return false;
        }
        if(upladLoading){
            return false;
        }
        setUpladLoading(true);
        setCsvProgress(0);

        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = papa.parse(target.result);

            let parsedCsvData = csv?.data;

            const params = new URLSearchParams(search);

            parsedCsvData.shift();

            let result = importCSVByComponent(parsedCsvData, params);
            if(result?.length <= 0){
                setUpladLoading(false);
                setNewCsvFile(null);
                setModalVisible(false);
                notificationOpen(true, 'danger', 'The uploaded data category does not match the current component\'s category!');
                return false;
            }
            
            if(parsedCsvData[0][1] !==  params.get('category')){
                setUpladLoading(false);
                setNewCsvFile(null);
                setModalVisible(false);
                notificationOpen(true, 'danger', 'The uploaded data category does not match the current component\'s category!');
                return false;
            }

            // if(parsedCsvData[0][1] !==  params.get('category')){
            // }
            
            axios.post(`${client.io.io.uri}v1/csv/updateComponents`,
                {
                    data: result,
                    userId,
                    componentType: params.get('category'),
                    componentName: findComponentType(params.get('category')),
                },
                {
                    headers: {
                        'Authorization': props.accessToken
                    }
                })
                .then((res) => {
                    setUpladLoading(false);
                    setNewCsvFile(null);
                    setModalVisible(false);
                    notificationOpen(true, 'danger', 'File update successful!');
                    fetchData();
                })
                .catch((err) => {
                    setUpladLoading(false);
                    setNewCsvFile(null);
                    setModalVisible(false);
                    notificationOpen(true, 'danger', (err?.response?.data?.message || 'Error occured!'));
                });

        };
        reader.readAsText(newCsvFile);
    };

    useEffect(() => {
        if(genReport === 2){
            if (downloadcsv.current) {
                downloadcsv.current.link.click();
            }     
        }
    }, [genReport]);

    return (  
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='mr-4 mb-0'>{(selectedComponentType) ? selectedComponentType.name : 'Components Listing'}</h2>
                </Row>
                <Breadcrumb
                    items={[
                        {title: 'Components Category', link: 'components' },
                        {title: (selectedComponentType?.name || 'Components Listing') },
                    ]}
                />
                <Card className='mt-3'>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='5'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <InputGroupAddon addonType='append' className='bg-transparent'>
                                        <InputGroupText className='bg-transparent p-1'>
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                    {selectedSearch || 'Select'}
                                                </DropdownToggle>
                                                <DropdownMenu className='w-100'>
                                                    <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                        {
                                                            searchList.map((x, i) => {
                                                                return (
                                                                    <DropdownItem 
                                                                        key={i}
                                                                        className='text-capitalize' 
                                                                        value={x._id} 
                                                                        onClick={() => {
                                                                            setSelectedSearch(x.name);
                                                                        }}
                                                                    >
                                                                        {x.name}
                                                                    </DropdownItem>
                                                                );
                                                            })
                                                        }
                                                        {/* {
                                                            componentTypes.map((x, i) => {
                                                                return (
                                                                    <DropdownItem 
                                                                        key={i}
                                                                        className='text-capitalize' 
                                                                        value={x._id} 
                                                                        onClick={() => {
                                                                            setSelectedComponentType(x);
                                                                        }}
                                                                    >
                                                                        {x.name}
                                                                    </DropdownItem>
                                                                );
                                                            })
                                                        } */}
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder='Search Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        value={regexComponentName}
                                        onChange={(e) => setRegexComponentName(e.target.value)}
                                    />
                                    <Button
                                        color='primary'
                                        onClick={() => {
                                            // code: {$regex: "ac|ivc", "$options": "i"}
                                            let findType = searchList.find(x => x.name === selectedSearch);
                                            if(findType){
                                                let obj = {...searchObj};
                                                if(obj[findType.value]){
                                                    obj[findType.value] = `${obj[findType.value]}|${regexComponentName}`;
                                                }else{
                                                    obj[findType.value] = regexComponentName;
                                                }
                                                setSearchObj(obj);
                                                setRegexComponentName('');
                                            }
                                        }}
                                    >
                                        <span className='btn-inner--text'
                                            style={{
                                                filter: 'invert(100%) sepia(51%) saturate(971%) hue-rotate(285deg) brightness(222%) contrast(97%)'
                                            }}
                                        >
                                            <SearchIcon color='white' height={20} className='mx-1'/>
                                        </span>
                                    </Button>
                                </InputGroup>
                                <div className='mt-1'>
                                    {
                                        Object.entries(searchObj).map((x, i) => {
                                            if(x[1].includes('|')){
                                                return x[1].split('|').map((x1, i1) => {
                                                    return <Badge className='mr-2' color='primary' key={x[0] + x1 + i1}>
                                                        <div className='text-capitalize'>
                                                            {x[0]}: {x1}{' '}
                                                            <span
                                                                className='cursor-pointer'
                                                                onClick={() => {
                                                                    let obj = {...searchObj};
                                                                    x[1] = x[1].replace(`${x1}`, '');
                                                                    x[1] = x[1].replace('||', '|');
                                                                    if(x[1].startsWith('|')) {
                                                                        x[1] = x[1].slice(1);
                                                                    }
                                                                    if(x[1].endsWith('|')) {
                                                                        x[1] = x[1].slice(0, -1);
                                                                    }
                                                                    obj[x[0]] = x[1];
                                                                    setSearchObj(obj);
                                                                }}
                                                            >
                                                                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x' viewBox='0 0 16 16'>
                                                                    <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708'/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </Badge>;
                                                });
                                            }else{
                                                return <Badge className='mr-2' color='primary' key={x[0] + x[1] + i}>
                                                    <div className='text-capitalize'>
                                                        {x[0]}: {x[1]}{' '}
                                                        <span
                                                            className='cursor-pointer'
                                                            onClick={() => {
                                                                let obj = {...searchObj};
                                                                delete obj[x[0]];
                                                                setSearchObj(obj);
                                                            }}
                                                        >
                                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x' viewBox='0 0 16 16'>
                                                                <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708'/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Badge>;
                                            }
                                        })
                                    }
                                </div>
                            </Col>

                            {selectedComponentType && (
                                <>
                                    <Col md='7' className='text-right'>
                                        <Button
                                            loading={genReport === 1}
                                            color='primary'
                                            onClick={() => {
                                                importCsv();
                                            }}
                                        >
                                            <span className='btn-inner--text'>+ Import CSV</span>

                                        </Button>
                                        <div style={{ display: genReport === 2 ? 'inline' : 'none' }}>
                                            <CSVLink
                                                data={data}
                                                filename={fileName}
                                                target='_blank'
                                                ref={downloadcsv}
                                            >
                                                <Button
                                                    color='primary'
                                                    className='mr-2'
                                                >
                                                    Download CSV
                                                </Button>
                                            </CSVLink>
                                        </div>
                                        {(genReport !== 2) ? (
                                            <Button
                                                loading={genReport === 1}
                                                color='primary'
                                                onClick={() => {
                                                    getExcelData();
                                                }}
                                            >
                                                Download CSV
                                                {genReport === 1 && (
                                                    <div style={{
                                                        height: '10px',
                                                        // width: 80
                                                    }}>
                                                        <Progress
                                                            animated
                                                            // className='my-3'
                                                            value={Math.floor(progress)}
                                                        />
                                                    </div>
                                                )}
                                            </Button>
                                        ) : null}
                                        <Button
                                            color='primary'
                                            className='ml-0'
                                            onClick={() => props.history.push(`/${props.role}/components-create?category=${selectedComponentType?.name}&categoryId=${selectedComponentType._id}`)}
                                        >
                                            <span className='btn-inner--text'>+ {selectedComponentType.name}</span>
                                        </Button>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row>
                            <div className='col'>
                                <Card>
                                    <div className='table-responsive mac-scrollbar'>
                                        <Table className='align-items-center table-flush'>
                                            <thead style={{ height: 50}}>
                                                <tr>
                                                    <th scope='col'>
                                                        Code
                                                        {
                                                            tableSorting.code === 1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('code', -1)}>
                                                                <i className='fa fa-arrow-down' />
                                                            </span>)
                                                        }
                                                        {
                                                            tableSorting.code === -1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('code', 1)}>
                                                                <i className='fa fa-arrow-up' />
                                                            </span>)
                                                        }
                                                    </th>
                                                    <th scope='col'>
                                                        Name
                                                        {
                                                            tableSorting.name === 1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', -1)}>
                                                                <i className='fa fa-arrow-down' />
                                                            </span>)
                                                        }
                                                        {
                                                            tableSorting.name === -1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', 1)}>
                                                                <i className='fa fa-arrow-up' />
                                                            </span>)
                                                        }
                                                    </th>
                                                    <th scope='col'>
                                                        Data Source
                                                        {
                                                            tableSorting.applicableSource === 1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('applicableSource', -1)}>
                                                                <i className='fa fa-arrow-down' />
                                                            </span>)
                                                        }
                                                        {
                                                            tableSorting.applicableSource === -1 &&
                                                            (<span className='ml-1 cursor-pointer' onClick={() => sortList('applicableSource', 1)}>
                                                                <i className='fa fa-arrow-up' />
                                                            </span>)
                                                        }
                                                    </th>
                                                    <th scope='col'>
                                                        Description
                                                    </th>
                                                    <th scope='col'>
                                                        Deployment
                                                    </th>
                                                    <th scope='col'>
                                                        Status
                                                    </th>
                                                    <th scope='col' className='text-right'>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {componentData.length > 0? (
                                                    componentData.map((v, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {v.code}
                                                                </td>
                                                                <td>
                                                                    {v.name}
                                                                </td>
                                                                <td style={{ textTransform: 'uppercase'}}>
                                                                    {sourceName(v.applicableSource)}
                                                                </td>
                                                                <td>{v.description || '-'}</td>
                                                                <td>
                                                                    <a style={{ cursor: 'pointer' }} onClick={() => [
                                                                        setDeploymentProductIds(v.deploymentProductIds),
                                                                        componentActions.setProductSelectorModalIsOpen(true)
                                                                    ]}>
                                                                        {v.deploymentCount}
                                                                    </a>
                                                                </td>
                                                                <td><StatusChip isActive={v.status}/></td>
                                                                <td className='text-right'>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => props.history.push(`/${props.role}/components-create?category=${selectedComponentType?.name}&categoryId=${v.componentsTypeId}&id=${v._id}`)}

                                                                    >
                                                                        <EditIcon height={15} width={15}/>
                                                                    </Button>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => props.history.push(`/${props.role}/components-create?category=${selectedComponentType?.name}&categoryId=${v.componentsTypeId}&id=${v._id}&duplicate=true`)}

                                                                    >
                                                                        <DuplicateIcon height={15} width={15}/>
                                                                    </Button>
                                                                    
                                                                    {(v.deploymentCount > 0) ?
                                                                        (
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => 
                                                                                    notificationOpen(true, 'danger', 'Component has deployments!')
                                                                                }
                                                                            >
                                                                                <DeleteIcon height={15} width={15}/>
                                                                            </Button>
                                                                        )
                                                                        : (
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => [
                                                                                    modalActions.setIsOpen(true),
                                                                                    modalActions.setTitle('Delete Component'),
                                                                                    modalActions.setContent('Are you sure you want to delete this Component?'),
                                                                                    modalActions.setCallback(() => deleteComponent(v._id)),
                                                                                ]}
                                                                            >
                                                                                <DeleteIcon height={15} width={15}/>
                                                                            </Button>
                                                                        )}
                                                                    
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ): (
                                                    <tr>
                                                        <td>
                                                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <CardFooter className='py-4'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className='btn-icon-only text-black p-0'
                                                        style={{width: 100}}
                                                        caret
                                                        href='#pablo'
                                                        role='button'
                                                        size='sm'
                                                        color=''
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        {componentPerPage} / page
                                                    </DropdownToggle>
                                                    <DropdownMenu className='w-100'>
                                                        <DropdownItem 
                                                            value={30}
                                                            onClick={() => {
                                                                reportActions.setComponentPerPage(30);
                                                                fetchData();
                                                            }
                                                            }
                                                        >
                                                            30 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={50} 
                                                            onClick={() => {
                                                                reportActions.setComponentPerPage(50);
                                                                fetchData();
                                                            }}
                                                        >
                                                            50 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={100} 
                                                            onClick={() => {
                                                                reportActions.setComponentPerPage(100);
                                                                fetchData();
                                                            }}
                                                        >
                                                            100 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={150} 
                                                            onClick={() => {
                                                                reportActions.setComponentPerPage(150);
                                                                fetchData();
                                                            }}
                                                        >
                                                            150 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={200} 
                                                            onClick={() => {
                                                                reportActions.setComponentPerPage(200);
                                                                fetchData();
                                                            }}
                                                        >
                                                            200 / page
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            
                                            <nav aria-label='...'>
                                                <ReportPagination
                                                    setIndex={(data) => reportActions.setComponentCurrentIndex(data)}
                                                    currentIndex={componentCurrentIndex}
                                                    maxIndex={componentMaxIndex}
                                                />
                                            </nav>
                                        </div> 
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </CardBody>
                </Card> 
            </Container>

            <ProductSelector
                userId={userId}
                applicableSource={undefined}
                productIds={deploymentProductIds}
                callback={(value) => {
                    props.history.push(`/${props.role}/products-create-with-components?id=${value}`);
                }}
            />

            {/* <Notifications 
                isOpen={isNotificationOpen} 
                handleOpen={notificationOpen} 
                message={notificationMessage} 
                color={notificationColor}
            /> */}

            <ModalEmpty 
                modalVisible={modalVisible}
                title={'Upload'}
                toggleModal={toggleUploadModal}
                content={
                    <>
                        <div>

                            <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                                <h4 className='m-0'>Upload CSV Pricing File*</h4>
                            </Row>

                            <Row>
                                <Col md='12'>
                                    <FormGroup className='text-center mb-0'>
                                        <div className='file-upload'>
                                            <input 
                                                type='file' 
                                                ref={uploadFileRef}
                                                accept='.csv' 
                                                onChange={(e) => handleFileChange(e)} 
                                                hidden />                
                                            <div className='progessBar' style={{ width: csvProgress }}>
                                            </div>
                                            <Button
                                                className='m-0 w-100 py-3 shadow-none'
                                                disabled={newCsvFile}
                                                onClick={() => uploadFileRef.current.click()}
                                            >
                                                <div className='btn-inner--icon d-flex align-items-center justify-content-center'>
                                                    <span className='btn-inner--text' style={{ opacity: 0.5 }}>Select File</span>
                                                    <UploadIcon />
                                                </div>
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='px-3 py-2 d-flex justify-content-end align-items-center'>
                                {(newCsvFile?.name) && (
                                    <>
                                        <small className='mr-2'>{newCsvFile?.name}</small>
                                        <DeleteIcon 
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => [
                                                setNewCsvFile()
                                            ]}
                                        />
                                    </>
                                )}
                            </Row>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    color='secondary'
                                    data-dismiss='modal'
                                    type='button'
                                    onClick={toggleUploadModal}
                                >
                                    Close
                                </Button>
                                <Button 
                                    color='primary' 
                                    type='button'
                                    disabled={upladLoading}
                                    onClick={handleSubmit}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </>
                }
            />

            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
            <CreateComponentsTypeModal
                existingComponentsType={existingComponentsType}
                isOpen={isOpenComponentTypeCreate}
                toggle={() => setIsOpenComponentTypeCreate(!isOpenComponentTypeCreate)}
                // reFetchData={() => fetchData()}
            />
        </>
    );
};
 

const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    dynamicPriceData: state.dynamicPrice.data,
    componentsTypeData: state.componentsType.data,
    components: state.components.data,
    role: state.role.details.user?state.role.details.user.role:'',
    accessToken: state.role.details.accessToken,
    userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
    fetchComponents: fetchComponents,
    pushComponents: pushComponents,
    updateComponents: updateComponents,
    removeComponents: removeComponents,
    fetchComponentsType: fetchComponentsType,
};
export default connect(mapStateToProps, mapDispatchToProps)(ComponentsListing);