//Action Types
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const PUSH_GROUPS = 'PUSH_GROUPS';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const REMOVE_GROUPS = 'REMOVE_GROUPS';

//Action Creator
export const fetchGroups = (data) => ({
    type: FETCH_GROUPS,
    data: data
});

export const pushGroups = (data) => ({
    type: PUSH_GROUPS,
    data: data
});

export const updateGroups = (data) => ({
    type: UPDATE_GROUPS,
    data: data
});

export const removeGroups = (data) => ({
    type: REMOVE_GROUPS,
    data: data
});