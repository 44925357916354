import React from 'react';

import ComponentNumberInput from '../ComponentNumberInput.js';
import ComponentTextInput from '../ComponentTextInput.js';

// reactstrap components
import {
    Row,
    Col,
} from 'reactstrap';

const Eyelet = ({
    values,
}) => {
    return (  
        <Col md='12' className='p-0'>
            <Row className='m-0 mb-1 align-items-center justify-content-start'>
                {/* <Col className='pr-0'>
                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                        <ComponentTextInput
                            // hasDrag
                            placeholder='Label'
                            location={'data.option.label'}
                        />
                    </Row>
                </Col> */}
                {/* <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Total Eyelet'}
                        location={'data.option.eyelet'}
                    />
                </Col> */}
                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Min Spacing'}
                        location={'data.option.minSpacing'}
                        chipText={values.defaultSize}
                    />
                </Col>
                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Base Price'}
                        location={'data.option.basePrice'}
                    />
                </Col>

                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Min Amount'}
                        location={'data.option.minAmount'}
                    />
                </Col>

                <Col className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Per Eyelet'}
                        location={'data.option.perEyelet'}
                    />
                </Col>
            </Row>
        </Col>
    );
};
 
export default Eyelet;