import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { fetchMargins } from '../redux/actions/marginsActions';
import { fetchMarginProfiles } from '../redux/actions/marginProfilesActions';
import { fetchDynamicPrice } from '../redux/actions/dynamicPriceActions';
import { fetchProducts, pushProducts, updateProducts, moveProducts, removeProducts } from '../redux/actions/productsActions';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import getSymbolFromCurrency from 'currency-symbol-map';
import StatusChip from '../components/Common/StatusChip.js';
import { ReactComponent as DeleteIcon } from '../assets/img/icons/common/trash.svg';
import { ReactComponent as EditIcon } from '../assets/img/icons/common/edit.svg';
import { ReactComponent as DuplicateIcon } from '../assets/img/icons/common/copy.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/common/search.svg';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';
import { useModal } from '../zustand/modalStore.js';

// reactstrap components
import {
    Button,
    // Badge,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    UncontrolledTooltip
} from 'reactstrap';

// import ProductModal from "../components/Products/ProductModal.js"
// import ProductModalEdit from "../components/Products/ProductModalEdit.js"
import ProductDescModalEdit from '../components/Products/ProductDescModalEdit.js';
import ModalPreview from '../components/Extra/ModalPreview.js';

import AssignProducts from '../components/UserProducts/AssignProducts.js';
import AssignMargin from '../components/Products/AssignMargin.js';
import AssignProductGroups from '../components/Products/AssignProductGroups.js';
import UpdateSupplier from '../components/Products/UpdateSupplier.js';
import DisableProducts from '../components/Products/DisableProducts.js';
import ActiveProducts from '../components/Products/ActiveProducts.js';

import ModalConfirmation from '../components/Extra/ModalConfirmation.js';
import Notifications from '../components/Extra/Notifications.js';

import client from '../feathers';
import axios from 'axios';
import env from '../env.js';
import UploadCsvCreateProduct from '../components/Products/Modals/UploadCsvCreateProduct.js';
import CreateSample from '../components/Products/Modals/CreateSample.js';

// const PAGESIZE = 30;
var typingTimer = 0;

function getConfirmTitle(modalEvent){
    if(modalEvent === 'duplicate'){
        return 'Product Duplicate';
    }else if(modalEvent === 'updateAll'){
        return 'Update All Product';
    }else if(modalEvent === 'delete'){
        return 'Delete Product';
    }else if(modalEvent === 'sample'){
        return 'Create Sample Product';
    }else{
        return 'Product Status';
    }
}

function getConfirmContent(modalEvent, status){
    if(modalEvent === 'duplicate'){
        return 'Are you sure want to duplicate this?';
    }else if(modalEvent === 'updateAll'){
        return 'Update All Merchant Product Info(Except Margin)';
    }else if(modalEvent === 'delete'){
        return 'Are you sure want to Delete this Product?';
    }else if(modalEvent === 'sample'){
        return 'Are you sure want to Create this Sample Product?';
    }else{
        if(status){
            return 'Are you sure want to Disable this?';
        }else{
            return 'Are you sure want to Enable this?';
        }
    }
}

function sourceName(source){
    if(source === 'csv'){
        return 'CSV';
    }else if(source === 'api'){
        return 'Excard (Api)';
    }else if(source === 'apiSg'){
        return 'Excard SG (Api)';
    }else if(source === 'apiEast'){
        return 'Excard East (Api)';
    }else if(source === 'sticker'){
        return 'SC-Sticker';
    }else if(source === 'certificate'){
        return 'SC-Certificate';
    }else if(source === 'document'){
        return 'SC-Document';
    }else if(source === 'poster'){
        return 'SC-Area';
    }else if(source === 'booklet'){
        return 'SC-Booklet';
    }else if(source === 'loose-sheet'){
        return 'SC-Sheet';
    }else if(source === 'loose-sheet-v2'){
        return 'SC-Sheet';
    }else if(source === 'csv-prefix'){
        return 'CSV-assigned';
    }else if(source === 'sticker-prefix'){
        return 'SC-Sticker-assigned';
    }else if(source === 'certificate-prefix'){
        return 'SC-Certificate-assigned';
    }else if(source === 'document-prefix'){
        return 'SC-Document-assigned';
    }else if(source === 'poster-prefix'){
        return 'SC-Area-assigned';
    }else if(source === 'loose-sheet-prefix'){
        return 'SC-Sheet-assigned';
    }else if(source === 'api-prefix'){
        return 'API-EC-assigned';
    }else if(source === 'product'){
        return 'SC-Item';
    }else if(source === 'packaging'){
        return 'SC-Packaging';
    }else{
        return source;
    }
}

function paginationCount(length, currentPage, pageSize) {
    return {
        total: length,
        per_page: pageSize,
        current_page: currentPage,
        last_page: Math.ceil(length / pageSize),
        from: ((currentPage - 1) * pageSize) + 1,
        to: currentPage * pageSize
    };
}

const Products = (props) => {
    const [ modalVisible, setMmodalVisible ] = useState(false);
    const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false);
    const [ modalVisiblePreview, setModalVisiblePreview ] = useState(false);
    // const [ modalVisibleMarginEdit, setModalVisibleMarginEdit ] = useState(false)
    const [ modalVisibleDetailEdit, setModalVisibleDetailEdit ] = useState(false);
    const [ modalVisibleAssignMargin, setModalVisibleAssignMargin ] = useState(false);
    const [ modalVisibleUpdateSupplier, setModalVisibleUpdateSupplier ] = useState(false);
    const [ modalVisibleDisableProduct, setModalVisibleDisableProduct ] = useState(false);
    const [ modalVisibleActiveProduct, setModalVisibleActiveProduct ] = useState(false);
    const [ modalVisibleAssign, setModalVisibleAssign ] = useState(false);
    const [ modalVisibleAssignGroup, setModalVisibleAssignGroup ] = useState(false);
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalUploadVisible, setModalUploadVisible ] = useState(false);
    const [ modalSampleVisible, setModalSampleVisible ] = useState(false);
    const [ modalEvent, setModalEvent ] = useState('disable');
    const [ dataEdit, setDataEdit ] = useState({});
    const [ statusOnChange, setStatusOnChange ] = useState({id:'', status:true});
    const [ pagination, setPagination ] = useState({});
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ checkboxChecked, setCheckboxChecked ] = useState([]);
    const [ userList, setUserList ] = useState([]);
    const [ activeProduct, setActiveProduct ] = useState(5);
    const [ regexProductName, setRegexProductName ] = useState('');
    const [ supplier, setSupplier ] = useState('');
    const [ source, setSource ] = useState('');
    const [ totalProduct, setTotalProduct ] = useState(0);
    const [ tableSorting, setTableSorting ] = useState({
        name: 1,
        source: 1,
        supplier: 1,
        updatedAt: 1
    });
    const [ productGroups, setProductGroups ] = useState([]);
    const [ PAGESIZE, setPAGESIZE ] = useState(30);

    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallbackNew,

        actions: modalActions,
    } = useModal();
    
    useEffect(() => {
        if(props.role !== 'superadmin'){
            client.authenticate()
                .then(async(auth)=>{
                    let numbOfActiveProduct = props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5;

                    let numOfActive = await client.service('products').find({
                        query: {
                            source: {
                                $in: [
                                    'csv-prefix',
                                    'sticker-prefix',
                                    'certificate-prefix',
                                    'document-prefix',
                                    'poster-prefix',
                                    'loose-sheet-prefix',
                                    'api-prefix'
                                ]
                            },
                            status: true,
                            userId: props.userId,
                            $select: ['_id'],
                            $limit: numbOfActiveProduct
                        }
                    });
        
                    setActiveProduct(numOfActive.data.length);
                })
                .catch((err)=>{
                    console.log(err);
                });
        }
    }, [props.userId, props.role]);

    useEffect(() => {
    
        client.authenticate()
            .then((auth)=>{
                return client.service('product-groups').find({
                    query: {
                        userIds: {
                            $in: props.userId
                        }
                    }
                });
            })
            .then((res)=>{
                setProductGroups(res.data);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    }, []);

    useEffect(() => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
      
            fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, PAGESIZE);

        }, 500);

    }, [props.userId, props.fetchProducts, props.fetchMargins, props.fetchDynamicPrice, props.fetchMarginProfiles, regexProductName, supplier, source, tableSorting, PAGESIZE]);

    const toggleModalPreview = () => {
        setModalVisiblePreview(!modalVisiblePreview);
    };

    // const toggleModalMarginEdit = () => {
    //   setModalVisibleMarginEdit(!modalVisibleMarginEdit)
    // }

    const toggleUploadModal = () => {
        setModalUploadVisible(!modalUploadVisible);
    };

    const toggleSampleModal = () => {
        setModalSampleVisible(!modalSampleVisible);
    };

    const toggleModalAssignMargin = () => {
        setModalVisibleAssignMargin(!modalVisibleAssignMargin);
    };

    const toggleModalUpdateSupplier = () => {
        setModalVisibleUpdateSupplier(!modalVisibleUpdateSupplier);
    };
  
    const toggleModalDisableProduct = () => {
        setModalVisibleDisableProduct(!modalVisibleDisableProduct);
    };

    const toggleModalActiveProduct = () => {
        setModalVisibleActiveProduct(!modalVisibleActiveProduct);
    };

    const assignMargin = () => {
        if(checkboxChecked.length > 0){
            toggleModalAssignMargin();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const updateSupplier = () => {
        if(checkboxChecked.length > 0){
            toggleModalUpdateSupplier();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const disableProducts = () => {
        if(checkboxChecked.length > 0){
            toggleModalDisableProduct();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const activeProductsToggle = () => {
        if(checkboxChecked.length > 0){
            toggleModalActiveProduct();
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const toggleModalDetailEdit = () => {
        setModalVisibleDetailEdit(!modalVisibleDetailEdit);
    };

    const toggleModalAssign = () => {
        setModalVisibleAssign(!modalVisibleAssign);
    };

    const toggleModalAssignGroup = () => {
        setModalVisibleAssignGroup(!modalVisibleAssignGroup);
    };

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm);
    };

    const modalCallback = (e) => {
        if(modalEvent === 'updateAll'){
            updateAllProduct(dataEdit.name, dataEdit.name, dataEdit);
        }else if(modalEvent === 'duplicate'){
            duplicateProduct();
        }else if(modalEvent === 'delete'){
            deleteProduct();
        }else{
            productStatus(statusOnChange.id, statusOnChange.status);
        }
    };

    const notificationOpen = (bool, color, message) => {
        modalActions.setIsOpen(bool);
        modalActions.setTitle('Alert');
        modalActions.setContent(message);
        modalActions.setCallback(null);
        // setIsOpen(bool);
        // setColor(color);
        // setMessage(message);
    };
  
    const sortList = (item, value) => {
        setTableSorting(prevState => {
            delete prevState[item];
            return {
                [item]: value,
                ...prevState,
            };
        });
    };
  
    const fetchData = (userId, fetchProducts, fetchDynamicPrice, notificationOpen, skip, currentPage, fetchMargins, fetchMarginProfiles, regexProductName, filterSupplier, source, pageSize) => {
        let searchParams = {};

        if(userId){
            if(source) {
                searchParams['$or'] = [
                    { source: source },
                    { apiSource: source },
                ];
            }
      
            client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            ...searchParams,
                            userId,
                            $skip: skip,
                            orRegex: { name: regexProductName },
                            $limit: pageSize,
                            $sort: tableSorting
                        }
                    });
                })
                .then((res) => {
                    setCheckboxChecked([]);
                    setPagination(paginationCount(res.total, currentPage, pageSize));
                    setTotalProduct(res.total);
                    fetchProducts(res.data);
                })
                .then(()=>{
                    return client.service('margins').find({
                        query: {
                            userId: userId,
                            $limit: 100,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    fetchMargins(res.data);
                })
                .then(()=>{
                    return client.service('margin-profiles').find({
                        query: {
                            userId: userId,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    fetchMarginProfiles(res.data);
                })
                .then(()=>{
                    return client.service('dynamicPrice').find({
                        query: {
                            userId: userId,
                            $limit: 100000,
                            $sort: {
                                createdAt: -1
                            }
                        }
                    });
                })
                .then((res) => {
                    if(res?.data?.length > 0){
                        res.data.sort((a, b) => a.dynamicPriceName.localeCompare(b.dynamicPriceName));
                    }
                    fetchDynamicPrice(res.data);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };

    const productStatus = (_id, status) => {
        function toggleStatus(){
            client.service('products').patch(_id, {status: !status})
                .then((res) => {
                    if(!status){
                        if(res.source.includes('prefix')){
                            setActiveProduct(activeProduct+1);
                        }
                    }else{
                        if(res.source.includes('prefix')){
                            setActiveProduct(activeProduct-1);
                        }
                    }
                    props.updateProducts(res);
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('Product');
                    modalActions.setContent(`Product ${res.name} edited successfully!`);
                    modalActions.setCallback(undefined);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }

        if(!status){
            client.authenticate()
                .then(async(auth)=>{
                    let numbOfActiveProduct = props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5;

                    let numOfActive = await client.service('products').find({
                        query: {
                            source: {
                                $in: [
                                    'csv-prefix',
                                    'sticker-prefix',
                                    'certificate-prefix',
                                    'document-prefix',
                                    'poster-prefix',
                                    'loose-sheet-prefix',
                                    'api-prefix'
                                ]
                            },
                            status: true,
                            userId: props.userId,
                            $select: ['_id'],
                            $limit: numbOfActiveProduct
                        }
                    });

                    // Check numbOfActiveProduct
                    if(numOfActive.data.length < numbOfActiveProduct){
                        toggleStatus();
                    }else{
                        notificationOpen(true, 'danger', 'Exceed Number of Active Product!');
                    }
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }else{
            // If disable
            toggleStatus();
        }    
    };

    // const listItemTop = (_id) => {
    //   client.authenticate()
    //   .then(()=>{
    //     return client.service('products').patch(_id, {dateToFirst: new Date()})
    //   })
    //   .then((res) => {
    //     props.updateProducts(res)
    //     props.moveProducts(res)
    //     notificationOpen(true, 'success', "Product " + res.name + " Edited Successfully!")
    //   })
    //   .catch((err)=>{
    //     if(err.name === "NotAuthenticated"){
    //       notificationOpen(true, 'danger', "Please Sign-in to continue!")
    //     }else{
    //       notificationOpen(true, 'danger', err.message)
    //     }
    //   })
    // }
  
    const PaginationRender = () => {
        if(pagination.last_page > 0){
            let pageArr = [];
            for(let i = 1; i <= pagination.last_page; i++){
                pageArr.push(i);
            }
            return (
                <Pagination
                    className='pagination justify-content-center mb-0'
                    listClassName='justify-content-center mb-0'
                >
                    <PaginationItem className={(pagination.current_page > 1?'':'disabled')}>
                        <PaginationLink
                            onClick={() => {
                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, PAGESIZE);
                            }} 
                            tabIndex='-1'
                        >
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                        </PaginationLink>
                    </PaginationItem>
                    {pageArr.map((i) => {
                        return (
                            <PaginationItem key={i} className={(pagination.current_page === i? 'active': '')}>
                                <PaginationLink
                                    onClick={() => {
                                        fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, (i-1)*PAGESIZE, i, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, PAGESIZE);
                                    }} 
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem className={((pagination.current_page+1) > pagination.last_page?'disabled':'')}>
                        <PaginationLink
                            onClick={() => {
                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, PAGESIZE);
                            }} 
                        >
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            );
        }else{
            return null;
        }
    };

    const duplicateProduct = () => {

        let cloneData = cloneDeep(dataEdit);
        delete cloneData._id;
        delete cloneData.__v;
        delete cloneData.updatedAt;
        delete cloneData.updatedBy;
        delete cloneData.userId;
        delete cloneData.createdAt;
        delete cloneData.createdBy;
        delete cloneData.productImg;

        delete cloneData.status;

        cloneData.timelines.map(x => {
            delete x._id;
            return x;
        });
        if(cloneData.manualCostBetween){
            cloneData.manualCostBetween.map(x => {
                delete x._id;
                return x;
            });
        }
   
        if(cloneData.additionOption){
            if(cloneData.additionOption.length > 0){
                cloneData.additionOption.map(x => {
                    delete x._id;
                    x.option.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    return x;
                });
            }
        }

        if(cloneData.items){
            if(cloneData.items.length > 0){
                cloneData.items.map(x => {
                    delete x._id;
                    x.amountBetween.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    x.variations.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    return x;
                });
            }
        }

        if(isEmpty(cloneData.csvFile)){
            cloneData.csvFile = {
                fileName: '',
                fileUrl: '',  
            };
        }

        cloneData.name = cloneData.name + '-duplicate';
    
        client.authenticate()
            .then(()=>{
                return client.service('products').create(cloneData);
            })
            .then((res) => {
                props.pushProducts(res);
                modalActions.setIsOpen(true);
                modalActions.setTitle('Product');
                modalActions.setContent(`Product ${res.name} added successfully!`);
                modalActions.setCallback(undefined);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const updateAllProduct = (oldName, newName, newData) => {
        let cloneData = cloneDeep(newData);
        delete cloneData._id;
        delete cloneData.__v;
        delete cloneData.updatedAt;
        delete cloneData.updatedBy;
        delete cloneData.userId;
        delete cloneData.createdAt;
        delete cloneData.createdBy;
        delete cloneData.productImg;
        delete cloneData.timelines;
        delete cloneData.artworkLink;
        delete cloneData.artworkServiceAmount;
        delete cloneData.artworkoption;
        delete cloneData.artworkServices;
        delete cloneData.availability;
        delete cloneData.category;
        delete cloneData.code;
        delete cloneData.dateToFirst;
        delete cloneData.description;
        delete cloneData.keywords;
        delete cloneData.mostPopular;
        delete cloneData.shippingInfo;
        delete cloneData.packageSize;
        delete cloneData.productDetail;
        delete cloneData.marginName;
        delete cloneData.marginSuperName;
        delete cloneData.source;
    
        if(cloneData.manualCostBetween){
            cloneData.manualCostBetween.map(x => {
                delete x._id;
                return x;
            });
        }
        if(cloneData.additionOption){
            if(cloneData.additionOption.length > 0){
                cloneData.additionOption.map(x => {
                    delete x._id;
                    x.option.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    return x;
                });
            }
        }

        if(cloneData.items){
            if(cloneData.items.length > 0){
                cloneData.items.map(x => {
                    delete x._id;
                    x.amountBetween.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    x.variations.map(x1 => {
                        delete x1._id;
                        return x1;
                    });
                    return x;
                });
            }
        }

        if(isEmpty(cloneData.csvFile)){
            cloneData.csvFile = {
                fileName: '',
                fileUrl: '',  
            };
        }

        cloneData.name = oldName;

        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}updateAllProduct`,
                    {
                        data: cloneData,
                        newName,
                        userId: props.userId
                    },
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                modalActions.setIsOpen(true);
                modalActions.setTitle('Product');
                modalActions.setContent(`Product ${res.name} updated successfully!`);
                modalActions.setCallback(undefined);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const updateAllProductLight = (oldName, newName, newData) => {
        let data = {
            name: oldName,
            supplier: newData.supplier
        };
   
        client.authenticate()
            .then((auth)=>{
                return axios.post(`${client.io.io.uri}updateAllProduct`,
                    {
                        data: data,
                        newName,
                        userId: props.userId
                    },
                    {
                        headers: {
                            'Authorization': auth.accessToken
                        }
                    });
            })
            .then((res) => {
                modalActions.setIsOpen(true);
                modalActions.setTitle('Product');
                modalActions.setContent(`Product ${res.name} updated successfully!`);
                modalActions.setCallback(undefined);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    const handleCheckboxChange = (id) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        if(cloneCheckbox.includes(id)){
            cloneCheckbox = cloneCheckbox.filter(item => item !== id);
        }else{
            cloneCheckbox.push(id);
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const handleCheckAll = (check) => {
        let cloneCheckbox = cloneDeep(checkboxChecked);
        cloneCheckbox = [];
        if(check){
            props.productsData.map(x => {
                cloneCheckbox.push(x._id);
                return x;
            });
        }
        setCheckboxChecked(cloneCheckbox);
    };

    const assignProduct = () => {
        if(checkboxChecked.length > 0){
            client.authenticate()
                .then((auth)=>{
                    return axios.post(`${client.io.io.uri}fetchUserList`,
                        {},
                        {
                            headers: {
                                'Authorization': auth.accessToken
                            }
                        });
                })
                .then((res) => {
                    setUserList(res.data);
                    toggleModalAssign();
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        notificationOpen(true, 'danger', err.message);
                    }
                });
        }else{
            notificationOpen(true, 'warning', 'No Product is selected!');
        }
    };

    const deleteProduct = () => {
        client.authenticate()
            .then(()=>{
                return client.service('products').remove(dataEdit._id);
            })
            .then((res) => {
                props.removeProducts(res);

                modalActions.setIsOpen(true);
                modalActions.setTitle('Product deleted');
                modalActions.setContent(`Product ${res.name} deleted successfully!`);
                modalActions.setCallback(undefined);
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container fluid>
                <Row className='m-1 mt-3'>
                    <h2 className='m-0'>Products {(props.packageDetails && props.role !== 'superadmin') ? `(${totalProduct}/${props.packageDetails.productRestrictions.total})` : ''}</h2>
                </Row>
                <Card className='mt-3'>
                    <CardBody>
                        <Row className='pt-0 pb-3 d-flex align-items-center justify-content-between'>
                            <Col md='4'>
                                <InputGroup className='input-group-alternative d-flex align-items-center'>
                                    <SearchIcon height={20} className='mx-1'/>
                                    <Input
                                        placeholder='Search Product Name...'
                                        className='w-auto d-inline-block mr-2'
                                        type='text'
                                        bsSize='sm'
                                        size={20}
                                        value={regexProductName}
                                        onChange={(e) => setRegexProductName(e.target.value)}
                                    />
                                    <InputGroupAddon addonType='append' className='bg-transparent'>
                                        <InputGroupText className='bg-transparent p-1'>
                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle caret color='secondary' className='text-uppercase'>
                                                    {
                                                        (source?.length > 20 && source?.indexOf(' ') <= 0)?
                                                            productGroups?.find(x => x._id === source)?.groupName
                                                            :
                                                            sourceName(source) || 'Data Source'
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu className='w-100'>
                                                    <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                                        {
                                                            productGroups.map((x, i) => {
                                                                return (
                                                                    <DropdownItem 
                                                                        key={i}
                                                                        className='text-capitalize' 
                                                                        value={x._id} 
                                                                        onClick={() => {
                                                                            setSource(x._id);
                                                                        }}
                                                                    >
                                                                        {x.groupName}
                                                                    </DropdownItem>
                                                                );
                                                            })
                                                        }
                                                        {
                                                            props.role === 'superadmin'?
                                                                env.componentSources.map((x, i) => {
                                                                    return (
                                                                        <DropdownItem 
                                                                            key={i}
                                                                            className='text-uppercase' 
                                                                            value={x.value} 
                                                                            onClick={() => {
                                                                                setSource(x.value);
                                                                            }}
                                                                        >
                                                                            {x.display}
                                                                        </DropdownItem>
                                                                    );
                                                                })
                                                                :
                                                                (env.componentSources).filter((x) => x.value !== 'api').map((x, i) => {
                                                                    return (
                                                                        <DropdownItem 
                                                                            key={i}
                                                                            className='text-uppercase' 
                                                                            value={x.value} 
                                                                            onClick={() => {
                                                                                setSource(x.value);
                                                                            }}
                                                                        >
                                                                            {x.display}
                                                                        </DropdownItem>
                                                                    );
                                                                })
                                                        }
                                                        <DropdownItem 
                                                            className='text-capitalize text-white bg-custom-yellow' 
                                                            value={''} 
                                                            onClick={() => {
                                                                setSource('');
                                                            }}
                                                        >
                                                            Clear
                                                        </DropdownItem>
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                
                            </Col>
                            <Col md='7' className='text-right'>
                                {
                                    props.role === 'superadmin' && (
                                        <Button
                                            color='default'
                                            onClick={() => toggleModalAssignGroup()}
                                        >
                                            <span className='btn-inner--text'>Assign Group</span>
                                        </Button>
                                    )
                                }
                                <Button
                                    color='tertiary'
                                    onClick={assignMargin}
                                >
                                    <span className='btn-inner--text'>Assign Profit Margin</span>
                                </Button>
                                
                                {/* <Button
                                    color='primary'
                                    onClick={() => toggleUploadModal()}
                                >
                                    <span className='btn-inner--text'>+ Multi CSV Products</span>
                                </Button> */}
                                <Button
                                    color='primary'
                                    // onClick={() => props.history.push(`/${props.role}/products-create`)} //OLD
                                    onClick={() => props.history.push(`/${props.role}/products-create-with-components`)}
                                >
                                    <span className='btn-inner--text'>+ Product</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className='col'>
                                <Card>
                                    <div className='table-responsive mac-scrollbar'>
                                        <Table className='align-items-center table-flush'>
                                            <thead>
                                                <tr>
                                                    <th scope='col' className='d-flex align-items-center'>
                                                        <input
                                                            className='mr-3'
                                                            type='checkbox'
                                                            id='rememberMe'
                                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                                        />
                                                        Product
                                                        {
                                                            tableSorting.name === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                        }
                                                        {
                                                            tableSorting.name === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('name', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                        }
                                                    </th>
                                                    <th scope='col'>
                                                        Data Type
                                                        {
                                                            tableSorting.source === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                        }
                                                        {
                                                            tableSorting.source === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('source', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                        }
                                                    </th>
                                                    <th scope='col'>Description</th>
                                                    <th>
                                                        Last Update
                                                        {
                                                            tableSorting.updatedAt === 1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('updatedAt', -1)}>
                              <i className='fa fa-arrow-down' />
                          </span>)
                                                        }
                                                        {
                                                            tableSorting.updatedAt === -1 &&
                          (<span className='ml-1 cursor-pointer' onClick={() => sortList('updatedAt', 1)}>
                              <i className='fa fa-arrow-up' />
                          </span>)
                                                        }
                                                    </th>
                                                    <th scope='col' className=''>Status</th>
                                                    {
                                                        props.role !== 'superadmin'? (
                                                            <th>Margin Assigned</th>
                                                        ): null
                                                    }
                                                    <th scope='col' className='text-right'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.productsData.length > 0? (
                                                    props.productsData.map((v, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className='d-flex align-items-center'>
                                                                    <input
                                                                        className='mr-3'
                                                                        type='checkbox'
                                                                        id={v._id}
                                                                        checked={checkboxChecked.includes(v._id)}
                                                                        value={checkboxChecked.includes(v._id)}
                                                                        onChange={(e) => handleCheckboxChange(v._id)}
                                                                    />
                                                                    <span className='mb-0'>
                                                                        {v.name}
                                                                    </span>
                                                                </td>
                                                                <td style={{ textTransform: 'uppercase'}}>{
                                                                    (v.isComponentBased && v.source.includes('api'))
                                                                        ? (productGroups?.find(x => x._id === v.apiSource)?.groupName ?? sourceName(v.apiSource))
                                                                        : (sourceName(v.source))
                                                                }</td>
                                                                <td>{v.description || '-'}</td>
                                                                <td>{moment(v.updatedAt).format('DD-MM-YYYY')}</td>
                                                                <td><StatusChip isActive={v.status}/></td>
                                                                {
                                                                    props.role !== 'superadmin'? (
                                                                        <td>{!isEmpty(v.marginName)?v.marginName.marginName:'-'}</td>
                                                                    ): null
                                                                }
                                                                <td className='text-right'>
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => {
                                                                            setDataEdit(v); 
                                                                            props.history.push(`/${props.role}/products-edit/${v._id}?isComponent=${(v.isComponentBased) ? 'true' : 'false'}`);
                                                                        }}
                                                                        id={'Edit-' + v._id}
                                                                    >
                                                                        <EditIcon height={15} width={15}/>
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        placement={'item.placement'}
                                                                        target={'Edit-' + v._id}
                                                                    >
                                                                        Edit Product
                                                                    </UncontrolledTooltip>

                                                                    {
                                                                        (props.role === 'superadmin' || props.role === 'whitelabelsuperadmin') &&
                                                                        <>
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => {
                                                                                    setDataEdit(v); 
                                                                                    // toggleModalConfirm();
                                                                                    // setModalEvent('sample');
                                                                                    toggleSampleModal();
                                                                                }}
                                                                                id={'Sample-' + v._id}
                                                                            >
                                                                                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-file-earmark-medical' viewBox='0 0 16 16'>
                                                                                    <path d='M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z'/>
                                                                                    <path d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z'/>
                                                                                </svg>
                                                                            </Button>
                                                                            <UncontrolledTooltip
                                                                                placement={'item.placement'}
                                                                                target={'Sample-' + v._id}
                                                                            >
                                                                                Create Sample
                                                                            </UncontrolledTooltip>

                                                                        </>
                                                                    }
                                                                    <Button
                                                                        color='grey'
                                                                        className='btn-square'
                                                                        onClick={() => {
                                                                            setDataEdit(v);
                                                                            toggleModalConfirm();
                                                                            setModalEvent('duplicate');
                                                                        }}
                                                                        id={'Duplicate-' + v._id}
                                                                    >
                                                                        <DuplicateIcon height={15} width={15}/>
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        placement={'item.placement'}
                                                                        target={'Duplicate-' + v._id}
                                                                    >
                                                                        Duplicate Product
                                                                    </UncontrolledTooltip>

                                                                    {
                                                                        props.role === 'superadmin'? (
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => {
                                                                                    setDataEdit(v);
                                                                                    toggleModalConfirm();
                                                                                    setModalEvent('delete');
                                                                                }}
                                                                                id={'Delete-' + v._id}
                                                                            >
                                                                                <DeleteIcon height={15} width={15}/>
                                                                            </Button>
                                                                        ): !v.source?.includes('prefix')? (
                                                                            <Button
                                                                                color='grey'
                                                                                className='btn-square'
                                                                                onClick={() => {
                                                                                    setDataEdit(v);
                                                                                    toggleModalConfirm();
                                                                                    setModalEvent('delete');
                                                                                }}
                                                                                id={'Delete-' + v._id}
                                                                            >
                                                                                <DeleteIcon height={15} width={15}/>
                                                                            </Button>
                                                                        ):null
                                                                    }
                                                                    <UncontrolledTooltip
                                                                        placement={'item.placement'}
                                                                        target={'Delete-' + v._id}
                                                                    >
                                                                        Delete Product
                                                                    </UncontrolledTooltip>
                                                                    {/* <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className='btn-icon-only text-light'
                                                                            href='#pablo'
                                                                            role='button'
                                                                            size='sm'
                                                                            color=''
                                                                            onClick={(e) => e.preventDefault()}
                                                                        >
                                                                            <i className='fas fa-ellipsis-v' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='dropdown-menu-arrow' right>
                                                                            <DropdownItem
                                                                                className='bg-secondary'
                                                                                onClick={() => {
                                                                                    setDataEdit(v); 
                                                                                    props.history.push(`/${props.role}/products-edit/${v._id}?isComponent=${(v.isComponentBased) ? 'true' : 'false'}`);
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </DropdownItem>
                                                                            {(!v?.isComponentBased) && (
                                                                                <DropdownItem
                                                                                    onClick={() => {setDataEdit(v); toggleModalPreview();}}
                                                                                >
                                                                                    Preview
                                                                                </DropdownItem>
                                                                            )}
                                  
                                                                            {
                                                                                props.role === 'superadmin'? (
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            setDataEdit(v);
                                                                                            toggleModalConfirm();
                                                                                            setModalEvent('updateAll');
                                                                                        }}
                                                                                    >
                                                                                        Update all product
                                                                                    </DropdownItem>
                                                                                ): null
                                                                            } */}
                                                                    {/* <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalDetailEdit()}}
                                  >
                                    Edit Detail
                                  </DropdownItem> */}
                                                                    {/* {
                                                                                props.role === 'superadmin'? (
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            setDataEdit(v);
                                                                                            toggleModalConfirm();
                                                                                            setModalEvent('duplicate');
                                                                                        }}
                                                                                    >
                                                                                        Duplicate
                                                                                    </DropdownItem>
                                                                                ): null
                                                                            }
                                                                            {
                                                                                props.role === 'superadmin'? (
                                                                                    <DropdownItem
                                                                                        className='text-danger'
                                                                                        onClick={() => {
                                                                                            setDataEdit(v);
                                                                                            toggleModalConfirm();
                                                                                            setModalEvent('delete');
                                                                                        }}
                                                                                    >
                                                                                        Delete
                                                                                    </DropdownItem>
                                                                                ): !v.source?.includes('prefix')? (
                                                                                    <DropdownItem
                                                                                        className='text-danger'
                                                                                        onClick={() => {
                                                                                            setDataEdit(v);
                                                                                            toggleModalConfirm();
                                                                                            setModalEvent('delete');
                                                                                        }}
                                                                                    >
                                                                                        Delete
                                                                                    </DropdownItem>
                                                                                ):null
                                                                            } */}
                                                                    {/* <DropdownItem
                                    onClick={() => listItemTop(v._id)}
                                  >
                                    List Top
                                  </DropdownItem> */}
                                                                    {/* </DropdownMenu>
                                                                    </UncontrolledDropdown> */}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ): (
                                                    <tr>
                                                        <td>
                                                            <span style={{padding: '0px 10px'}}>No Record Found!</span>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <CardFooter className='py-4'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className='btn-icon-only text-black p-0'
                                                        style={{width: 100}}
                                                        caret
                                                        href='#pablo'
                                                        role='button'
                                                        size='sm'
                                                        color=''
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        {PAGESIZE} / page
                                                    </DropdownToggle>
                                                    <DropdownMenu className='w-100'>
                                                        <DropdownItem 
                                                            value={30}
                                                            onClick={() => {
                                                                // setPAGESIZE(30);
                                                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, 30);
                                                            }
                                                            }
                                                        >
                                                            30 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={50} 
                                                            onClick={() => {
                                                                // setPAGESIZE(50);
                                                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, 50);
                                                            }}
                                                        >
                                                            50 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={100} 
                                                            onClick={() => {
                                                                // setPAGESIZE(100);
                                                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, 100);
                                                            }}
                                                        >
                                                            100 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={150} 
                                                            onClick={() => {
                                                                // setPAGESIZE(150);
                                                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, 150);
                                                            }}
                                                        >
                                                            150 / page
                                                        </DropdownItem>
                                                        <DropdownItem 
                                                            value={200} 
                                                            onClick={() => {
                                                                // setPAGESIZE(200);
                                                                fetchData(props.userId, props.fetchProducts, props.fetchDynamicPrice, notificationOpen, 0, 1, props.fetchMargins, props.fetchMarginProfiles, regexProductName, supplier, source, 200);
                                                            }}
                                                        >
                                                            200 / page
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            
                                            <nav aria-label='...'>
                                                <PaginationRender />
                                            </nav>
                                        </div> 
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </CardBody>
                </Card>        
            </Container>
            
            <UploadCsvCreateProduct 
                modalVisible={modalUploadVisible}
                toggleModal={toggleUploadModal}
                userId={props.userId}
                notificationOpen={notificationOpen}
            />

            <CreateSample 
                dataEdit={dataEdit}
                modalVisible={modalSampleVisible}
                toggleModal={toggleSampleModal}
                userId={props.userId}
                notificationOpen={notificationOpen}
            />
            
            <ProductDescModalEdit 
                dataEdit={dataEdit}
                modalVisible={modalVisibleDetailEdit} 
                toggleModal={toggleModalDetailEdit}
                notificationOpen={notificationOpen} 
                updateProducts={props.updateProducts}
                rateMYRtoSGD={props.rateMYRtoSGD}
            />
            <ModalConfirmation 
                modalVisible={modalConfirm} 
                toggleModal={toggleModalConfirm} 
                modalCallback={modalCallback} 
                title={getConfirmTitle(modalEvent)}
                content={getConfirmContent(modalEvent, statusOnChange.status)}
            />
            <AssignProducts 
                userList={userList}
                checkboxChecked={checkboxChecked}
                productsData={props.productsData}
                modalVisible={modalVisibleAssign} 
                toggleModal={toggleModalAssign}
                notificationOpen={notificationOpen}
                marginsData={props.marginsData}
            />
            <AssignProductGroups 
                userId={props.userId}
                userList={userList}
                checkboxChecked={checkboxChecked}
                productsData={props.productsData}
                modalVisible={modalVisibleAssignGroup} 
                toggleModal={toggleModalAssignGroup}
                notificationOpen={notificationOpen}
                total={checkboxChecked.length}
            />
            <UpdateSupplier 
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleUpdateSupplier} 
                toggleModal={toggleModalUpdateSupplier}
                notificationOpen={notificationOpen}
                userId={props.userId}
                updateProducts={props.updateProducts}
                productsData={props.productsData}
            />
            <AssignMargin 
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleAssignMargin} 
                toggleModal={toggleModalAssignMargin}
                notificationOpen={notificationOpen}
                updateProducts={props.updateProducts}
                marginsData={props.marginsData}
                productsData={props.productsData}
            />
            <DisableProducts 
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleDisableProduct} 
                toggleModal={toggleModalDisableProduct}
                notificationOpen={notificationOpen}
                updateProducts={props.updateProducts}
                productsData={props.productsData}
            />
            <ActiveProducts 
                userId={props.userId}
                numbOfActiveProduct={props.userInfo.numbOfActiveProduct?props.userInfo.numbOfActiveProduct: 5}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
                userInfo={props.userInfo}
                checkboxChecked={checkboxChecked}
                modalVisible={modalVisibleActiveProduct} 
                toggleModal={toggleModalActiveProduct}
                notificationOpen={notificationOpen}
                updateProducts={props.updateProducts}
                productsData={props.productsData}
            />
            <ModalPreview 
                dataEdit={dataEdit}
                userId={props.userId}
                currency={props.currency}
                modalVisible={modalVisiblePreview} 
                toggleModal={toggleModalPreview}
            />

            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallbackNew}
            />
            <Notifications 
                isOpen={isOpen} 
                handleOpen={notificationOpen} 
                message={message} 
                color={color}
            />
        </>
    );
};
 
const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    role: state.role.details.user?state.role.details.user.role:'',
    currency: state.role.details.user?state.role.details.user.currency?getSymbolFromCurrency(state.role.details.user.currency):'rm':'rm',
    userInfo: state.role.details.user?state.role.details.user:{},
    productsData: state.products.data,
    companyInfo: state.company.data,
    marginsData: state.margins.data,
    marginProfilesData: state.marginProfiles.data,
    dynamicPriceData: state.dynamicPrice.data,
    rateMYRtoSGD: state?.company?.data?.rateMYRtoSGD?state.company.data.rateMYRtoSGD:4.13,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    fetchProducts: fetchProducts,
    pushProducts: pushProducts,
    updateProducts: updateProducts,
    moveProducts: moveProducts,
    removeProducts: removeProducts,
    fetchMargins: fetchMargins,
    fetchDynamicPrice: fetchDynamicPrice,
    fetchMarginProfiles: fetchMarginProfiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);