import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import classnames from 'classnames';
import {
    FormGroup,
    Input,
    Card,
    CardBody,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    TabPane,
} from 'reactstrap';
import CoverMaterialForm from './CoverMaterialForm';
import ContentMaterialForm from './ContentMaterialForm';
import CoverToContentPairingForm from './CoverToContentPairingForm';

const PerfectBindForm = ({
    amountFocused,
    setAmountFocused,
    handleChange,
    handleBlur,
    values,
    currency,
}) => {
    const { bookletData } = values;
    const { perfectBind } = bookletData;

    const [labels, setLabels] = useState(perfectBind.labels);
    const [coverMaterials, setCoverMaterials] = useState(perfectBind.coverMaterials);
    const [contentMaterials, setContentMaterials] = useState(perfectBind.contentMaterials);
    const [coverToContentPairing, setCoverToContentPairing] = useState(perfectBind.coverToContentPairing ?? []);

    useEffect(() => {
        perfectBind.labels = labels;
    }, [labels]);

    useEffect(() => {
        perfectBind.coverMaterials = coverMaterials;
    }, [coverMaterials]);

    useEffect(() => {
        perfectBind.contentMaterials = contentMaterials;
    }, [contentMaterials]);

    useEffect(() => {
        perfectBind.coverToContentPairing = coverToContentPairing;
    }, [coverToContentPairing]);

    useEffect(() => {
        const newCoverToContentPairing = cloneDeep(coverToContentPairing);

        coverMaterials.forEach((item, index) => {
            if (!newCoverToContentPairing[index]) {
                newCoverToContentPairing.push({
                    cover: item.name,
                    content: contentMaterials.map((content) => {
                        return  {
                            name: content.name,
                            enabled: true,
                        };
                    }),
                });
            } else {
                newCoverToContentPairing[index].cover = item.name;
            }
        });

        setCoverToContentPairing(newCoverToContentPairing);
        
    }, [coverMaterials]);

    useEffect(() => {
        const newCoverToContentPairing = cloneDeep(coverToContentPairing);

        newCoverToContentPairing.forEach((ccp, ccpIndex) => {
            contentMaterials.forEach((content, contentIndex) => {
                if(!ccp.content[contentIndex]) {
                    newCoverToContentPairing[ccpIndex].content.push({
                        name: contentMaterials[contentIndex].name,
                        enabled: true,
                    });
                } else {
                    newCoverToContentPairing[ccpIndex].content[contentIndex].name = contentMaterials[contentIndex].name;
                }

            });
        });
        setCoverToContentPairing(newCoverToContentPairing);
        
    }, [contentMaterials]);


    const removeChild = (original, setter, index) => {
        const newArray = [...original.slice(0, index), ...original.slice(index + 1)];
        setter(newArray);
    };

    const childOnChange = (original, setter, variable, value, index) => {
        const massagedItems = [
            ...original.slice(0, index),
            {
                ...original[index],
                [variable]: value,
            },
            ...original.slice(index + 1),
        ];

        setter(massagedItems);
    };

    const pushLabels = () => {
        const cloneItems = cloneDeep(labels);
        cloneItems.push({ name: '', basePrice: 0, width: 0, height: 0 });

        setLabels(cloneItems);
    };

    const pushCoverMaterials = () => {
        const cloneItems = cloneDeep(coverMaterials);
        cloneItems.push({
            name: '',
            price: 0,
            coverPrintings: [],
            options: [],
        });

        setCoverMaterials(cloneItems);
    };

    const pushContentMaterials = () => {
        const cloneItems = cloneDeep(contentMaterials);
        cloneItems.push({
            name: '',
            price: 0,
            minPP: 0,
            maxPP: 0,
            contentPrintings: [],
            options: [],
        });

        setContentMaterials(cloneItems);
    };

    const getPieces = (width, height) => {
        if(width <= 0 || height <= 0) {
            return 0;
        }
        return Math.floor(perfectBind.printingDimension.maxWidth / width) * Math.floor(perfectBind.printingDimension.maxHeight / height);
    };
    return (
        <TabPane tabId='perfectBind'>
            <Card className='shadow mb-5'>
                <CardBody>
                    <Col sm='12' className='d-flex flex-row align-items-center' style={{ gap: 5 }}>
                        <h2>Perfect Bind</h2>
                        <div>
                            <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                <input
                                    type='checkbox'
                                    id='bookletData.perfectBind.enabled'
                                    checked={perfectBind.enabled}
                                    onChange={handleChange}
                                />
                                <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON' />
                            </label>
                        </div>
                    </Col>

                    {(perfectBind.enabled) && (
                        <>
                            <Row className='d-flex justify-content-center mb-5'>
                                <Col md='4' className='d-flex flex-column justify-content-center'>
                                    <div>
                                        <small className=' font-weight-bold'>
                                            Label
                                        </small>
                                    </div>
                                    <FormGroup
                                        className={classnames('mb-0', {
                                            focused: amountFocused,
                                        })}
                                    >
                                        <InputGroup className='input-group-alternative'>
                                            <Input
                                                type='text'
                                                id='bookletData.perfectBind.label'
                                                value={perfectBind.label ?? 'Perfect Bind'}
                                                onChange={handleChange}
                                                onFocus={() => setAmountFocused(true)}
                                                onBlur={(e) => {
                                                    setAmountFocused(false);
                                                    handleBlur(e);
                                                }}
                                                onWheel={(event) => event.currentTarget.blur()}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mb-5'>
                                <Col md='4' className='d-flex flex-column justify-content-center'>
                                    <div>
                                        <small className=' font-weight-bold'>
                                            Perfect Bind Price (
                                            <span className='text-uppercase'>{currency}</span>
                                            )
                                        </small>
                                    </div>
                                    <FormGroup
                                        className={classnames('mb-0', {
                                            focused: amountFocused,
                                        })}
                                    >
                                        <InputGroup className='input-group-alternative'>
                                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                <InputGroupText className='bg-transparent'>
                                                    <span className='text-uppercase'>{currency}</span>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type='number'
                                                id='bookletData.perfectBind.price'
                                                value={Number(perfectBind.price)}
                                                onChange={handleChange}
                                                onFocus={() => setAmountFocused(true)}
                                                onBlur={(e) => {
                                                    setAmountFocused(false);
                                                    handleBlur(e);
                                                }}
                                                onWheel={(event) => event.currentTarget.blur()}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md='4' className='d-flex flex-column justify-content-center'>
                                    <div>
                                        <small className=' font-weight-bold'>
                                            Per Perfect Bind (
                                            <span className='text-uppercase'>{currency}</span>
                                            )
                                        </small>
                                    </div>
                                    <FormGroup
                                        className={classnames('mb-0', {
                                            focused: amountFocused,
                                        })}
                                    >
                                        <InputGroup className='input-group-alternative'>
                                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                <InputGroupText className='bg-transparent'>
                                                    <span className='text-uppercase'>{currency}</span>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type='number'
                                                id='bookletData.perfectBind.perPrice'
                                                value={Number(perfectBind.perPrice)}
                                                onChange={handleChange}
                                                onFocus={() => setAmountFocused(true)}
                                                onBlur={(e) => {
                                                    setAmountFocused(false);
                                                    handleBlur(e);
                                                }}
                                                onWheel={(event) => event.currentTarget.blur()}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Col sm='12' className='d-flex flex-row align-items-center' style={{ gap: 5 }}>
                                <h2>Paper Dimensions</h2>
                            </Col>
                            <Row className='d-flex align-items-center mb-4 ml-1'>
                                <Col md='3'>
                                    <div>
                                        <small className=' font-weight-bold'>
                                            Max Width (mm)
                                        </small>
                                    </div>
                                    <InputGroup className='input-group-alternative'>
                                        <Input
                                            className='form-control-alternative'
                                            type='number'
                                            id='bookletData.perfectBind.printingDimension.maxWidth'
                                            bsSize='sm'
                                            value={Number(perfectBind.printingDimension?.maxWidth)}
                                            onChange={handleChange}
                                            onWheel={(event) => event.currentTarget.blur()}
                                        />
                                        <InputGroupAddon addonType='append' className='bg-secondary'>
                                            <InputGroupText className='bg-transparent'>
                                                <span>mm</span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                <Col md='3'>
                                    <div>
                                        <small className=' font-weight-bold'>
                                            Max Height (mm)
                                        </small>
                                    </div>
                                    <InputGroup className='input-group-alternative'>
                                        <Input
                                            className='form-control-alternative'
                                            type='number'
                                            id='bookletData.perfectBind.printingDimension.maxHeight'
                                            bsSize='sm'
                                            value={Number(perfectBind.printingDimension?.maxHeight)}
                                            onChange={handleChange}
                                            onWheel={(event) => event.currentTarget.blur()}
                                        />
                                        <InputGroupAddon addonType='append' className='bg-secondary'>
                                            <InputGroupText className='bg-transparent'>
                                                <span>mm</span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <hr className='my-1 mb-3' />
                            <Col sm='12' className='d-flex flex-column' style={{ gap: 5 }}>
                                <h2>Default Size</h2>
                                <Row className='mb-2'>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='4'>
                                                <small className='font-weight-bold'>
                                                    Size Label
                                                </small>
                                            </Col>
                                            <Col md='2'>
                                                <small className='font-weight-bold'>
                                                    Base Price
                                                </small>
                                            </Col>
                                            <Col md='2'>
                                                <small className='font-weight-bold'>
                                                    Width (Opened)
                                                </small>
                                            </Col>
                                            <Col md='2'>
                                                <small className='font-weight-bold'>
                                                    Height (Opened)
                                                </small>
                                            </Col>
                                            <Col md='2'>
                                                <small className='font-weight-bold'>
                                                    No. of UP
                                                </small>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    labels.map((value, index) => (
                                        <Row key={index} className='mb-2'>
                                            <Col md='10'>
                                                <Row>
                                                    <Col md='4'>
                                                        <Input
                                                            className='form-control-alternative'
                                                            placeholder='Label'
                                                            type='text'
                                                            bsSize='sm'
                                                            value={value.name}
                                                            onChange={(e) => childOnChange(labels, setLabels, 'name', e.target.value, index)}
                                                        />
                                                    </Col>
                                                    <Col md='2' className='d-flex flex-column justify-content-center'>
                                                        <InputGroup className='input-group-alternative'>
                                                            <InputGroupAddon addonType='prepend' className='bg-secondary mr-2'>
                                                                <InputGroupText className='bg-transparent'>
                                                                    <span className='text-uppercase'>{currency}</span>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type='number'
                                                                value={Number(value.basePrice)}
                                                                onChange={(e) => childOnChange(labels, setLabels, 'basePrice', e.target.value, index)}
                                                                onFocus={() => setAmountFocused(true)}
                                                                onBlur={(e) => {
                                                                    setAmountFocused(false);
                                                                    handleBlur(e);
                                                                }}
                                                                onWheel={(event) => event.currentTarget.blur()}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md='2'>
                                                        <Input
                                                            className='form-control-alternative'
                                                            type='number'
                                                            bsSize='sm'
                                                            value={Number(value.width)}
                                                            onChange={(e) => childOnChange(labels, setLabels, 'width', Number(e.target.value), index)}
                                                            onWheel={(event) => event.currentTarget.blur()}
                                                        />
                                                    </Col>
                                                    <Col md='2'>
                                                        <Input
                                                            className='form-control-alternative'
                                                            type='number'
                                                            bsSize='sm'
                                                            value={Number(value.height)}
                                                            onChange={(e) => childOnChange(labels, setLabels, 'height', Number(e.target.value), index)}
                                                            onWheel={(event) => event.currentTarget.blur()}
                                                        />
                                                    </Col>
                                                    <Col md='2' className='d-flex align-items-center'>
                                                        <small className='font-weight-bold'>
                                                            {getPieces(Number(value.width), Number(value.height))}
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md='2' className='d-flex justify-content-end align-items-center'>
                                                <Button
                                                    color='secondary'
                                                    type='button'
                                                    size='sm'
                                                    onClick={() => removeChild(labels, setLabels, index)}
                                                >
                                                    <div className='btn-inner--text text-red'>
                                                        <i
                                                            className='fa fa-minus'
                                                        />
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                <Row>
                                    <Col md='12'>
                                        <a
                                            href='/'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                pushLabels();
                                            }}
                                        >
                                            <span>
                                                <i className='mr-1 fa fa-plus-circle' style={{ fontSize: '1rem' }} />
                                                Add new line
                                            </span>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </CardBody>
            </Card>

            {(perfectBind.enabled) && (
                <>
                    <Row className='mb-5' />
                    <CoverMaterialForm
                        coverMaterials={coverMaterials}
                        setCoverMaterials={setCoverMaterials}
                        pushCoverMaterials={pushCoverMaterials}
                        childOnChange={childOnChange}
                        removeChild={removeChild}
                        currency={currency}
                        setAmountFocused={setAmountFocused}
                        handleBlur={handleBlur}
                    />
                    <Row className='mb-5' />
                    <ContentMaterialForm
                        contentMaterials={contentMaterials}
                        setContentMaterials={setContentMaterials}
                        pushContentMaterials={pushContentMaterials}
                        childOnChange={childOnChange}
                        removeChild={removeChild}
                        currency={currency}
                        setAmountFocused={setAmountFocused}
                        handleBlur={handleBlur}
                    />
                    <Row className='mb-5' />
                    <CoverToContentPairingForm
                        coversToContentPairing={coverToContentPairing}
                        setCoverToContentPairing={setCoverToContentPairing}
                        coverMaterials={coverMaterials}
                        contentMaterials={contentMaterials}
                        setAmountFocused={setAmountFocused}
                        handleBlur={handleBlur}
                    />
                    <Row className='mb-5' />
                </>
            )}
        </TabPane>
    );
};

export default PerfectBindForm;
