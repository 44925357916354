import React, { useState, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';

import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Field } from 'formik';
import { withRouter } from 'react-router';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/common/plus.svg';
import { ReactComponent as DragIcon } from '../../../../assets/img/icons/common/drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/img/icons/common/close.svg';

import { useComponents } from '../../../../zustand/componentStore.js';

import {
    Row,
    Col,
    Button,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import ProductSelector from '../ProductSelector.js';
import PricingRuleSelector from '../PricingRuleSelector.js';
import client from '../../../../feathers.js';

const ClusteredProductComponentStructure = (props) => {
    const {
        userId,
        values,
        setFieldValue,
        notificationOpen,
    } = props;

    const [productWithDetails, setProductWithDetails] = useState([]);
    const { storedLocation, actions } = useComponents();
    
    const {
        setProductSelectorModalIsOpen: toggle,
    } = actions;

    const removeItem = (name, index) => {
        const clone = cloneDeep(eval('values.' + name));
        clone.splice(index, 1);

        setFieldValue(name, clone);
    };

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            _id: { $in: values.clusteredProducts },
                            $limit: 1000,
                        }
                    });
                })
                .then((res) => {
                    setProductWithDetails(res.data);
                });
        }
    }, [values.clusteredProducts]);

    return (
        <>
            <div className='rounded bg-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <Col md='6'>
                        <Row className='d-flex align-items-center'>
                            <h4 className='m-0 mr-2'>Label: </h4>
                            <Field 
                                className='form-control form-control-sm form-control-alternative w-75'
                                placeholder='Name'
                                type='text'
                                name={'clusterLabel'}
                            />
                        </Row>
                        
                    </Col>
                    
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex float-right'
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('clusteredProducts'),
                            actions.setModalAllowBack(false),
                            actions.setProductSelectorModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='clusteredProducts'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                            {values.clusteredProducts.map((productId, index) => {
                                const details = productWithDetails.find(x => x._id.toString() === productId);
                                return (
                                    <Draggable key={index} draggableId={`clusteredProducts[${index}]`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Row
                                                    className='d-flex m-0 p-1 my-1 rounded align-items-center justify-content-between bg-white border-primary'
                                                    style={{ height: 45 }}
                                                >
                                                    <DragIcon 
                                                        width={13}
                                                        height={13}
                                                        className='mr-0'
                                                    />
                
                                                    <div className='d-flex px-2 py-1 align-items-center justify-content-start rounded bg-primary' style={{ color: 'white' }}>
                                                        <small className='xs bold m-0'>{details?.source}</small>
                                                    </div>
                                                
                                                    <Col md='1' className='pr-0'>
                                                        <small className='component-code m-0 text-uppercase'>{details?.code}</small>
                                                    </Col>
                                                    <Col md='7' className='pr-0'>
                                                        <small className='font-weight-bold'>{details?.name}</small>
                                                    </Col>
                
                                                    <Col md='1' className='p-0'>
                                                        <a
                                                            className='nav-item'
                                                            style={{color: 'red'}}
                                                            onClick={() => [
                                                                removeItem('clusteredProducts', index), 
                                                            ]}
                                                        >
                                                            <p className='font-weight-bold m-0 text-center'>
                                                                <CloseIcon 
                                                                    width={22}
                                                                    height={22}
                                                                    stroke={'red'}
                                                                />
                                                            </p>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </Draggable>  
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
            
            <ProductSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                callback={(value) => {
                    const cloned = cloneDeep(eval('values.' + storedLocation));
                    cloned.push(value);
            
                    setFieldValue(storedLocation, uniqBy(cloned));
                    toggle(false);
                }
                }
                notificationOpen={notificationOpen}
            />

            <PricingRuleSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:'',
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClusteredProductComponentStructure));
