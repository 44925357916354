import React from 'react';
import { Field, FieldArray } from 'formik';

// reactstrap components
import {
    Button,
    FormGroup,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

const CSVForm = ({
    values,
}) => {
    return ( 
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h4>Options</h4>
            </div>
            <Card className='shadow mb-4'>
                <CardBody>
                    <Row>
                        <Col md='12'>
                            <div className=''>
                                <small className=' font-weight-bold'>
                                    Rounded Corners Options
                                </small>
                            </div>
                            <div className='mb-2'>
                                <small className='text-muted'>
                                    *Filter by the first column of CSV file
                                </small>
                            </div>
                            <FormGroup>
                                <FieldArray
                                    name='roundedOptions'
                                    render={arrayHelpers => (
                                        <div>
                                            {values.roundedOptions && values.roundedOptions.length > 0 ? (
                                                values.roundedOptions.map((rounded, index) => (
                                                    <div key={index} className='mb-2'>
                                                        <Row className='align-items-center'>
                                                            <Col md='10'>
                                                                <Field className='form-control' name={`roundedOptions.${index}`} placeholder='Input Value' />
                                                            </Col>
                                                            <Col md='2' className='d-flex justify-content-end align-items-center'>
                                                                <Button
                                                                    color='secondary'
                                                                    type='button'
                                                                    size='sm'
                                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                >
                                                                    <div className='btn-inner--text text-red'>
                                                                        <i 
                                                                            className='fa fa-minus'
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : null}
                                            <a 
                                                href='/' 
                                                onClick={ (event) => {
                                                    event.preventDefault();
                                                    arrayHelpers.push('');
                                                }} 
                                            >
                                                <span>
                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                    Add new line
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='12'>
                            <div className=''>
                                <small className=' font-weight-bold'>
                                    Punch Hole Options
                                </small>
                            </div>
                            <div className='mb-2'>
                                <small className='text-muted'>
                                    *Filter by the first column of CSV file
                                </small>
                            </div>
                            <FormGroup>
                                <FieldArray
                                    name='punchHoleOptions'
                                    render={arrayHelpers => (
                                        <div>
                                            {values.punchHoleOptions && values.punchHoleOptions.length > 0 ? (
                                                values.punchHoleOptions.map((punchhole, index) => (
                                                    <div key={index} className='mb-2'>
                                                        <Row className='align-items-center'>
                                                            <Col md='10'>
                                                                <Field className='form-control' name={`punchHoleOptions.${index}`} placeholder='Input Value' />
                                                            </Col>
                                                            <Col md='2' className='d-flex justify-content-end align-items-center'>
                                                                <Button
                                                                    color='secondary'
                                                                    type='button'
                                                                    size='sm'
                                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                >
                                                                    <div className='btn-inner--text text-red'>
                                                                        <i 
                                                                            className='fa fa-minus'
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : null}
                                            <a 
                                                href='/' 
                                                onClick={ (event) => {
                                                    event.preventDefault();
                                                    arrayHelpers.push('');
                                                }} 
                                            >
                                                <span>
                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                    Add new line
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='12'>
                            <div className=''>
                                <small className=' font-weight-bold'>
                                    Hotstamp Options
                                </small>
                            </div>
                            <div className='mb-2'>
                                <small className='text-muted'>
                                    *Filter by the first column of CSV file
                                </small>
                            </div>
                            <FormGroup>
                                <FieldArray
                                    name='hotstampOptions'
                                    render={arrayHelpers => (
                                        <div>
                                            {values.hotstampOptions && values.hotstampOptions.length > 0 ? (
                                                values.hotstampOptions.map((punchhole, index) => (
                                                    <div key={index} className='mb-2'>
                                                        <Row className='align-items-center'>
                                                            <Col md='10'>
                                                                <Field className='form-control' name={`hotstampOptions.${index}`} placeholder='Input Value' />
                                                            </Col>
                                                            <Col md='2' className='d-flex justify-content-end align-items-center'>
                                                                <Button
                                                                    color='secondary'
                                                                    type='button'
                                                                    size='sm'
                                                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                >
                                                                    <div className='btn-inner--text text-red'>
                                                                        <i 
                                                                            className='fa fa-minus'
                                                                        />
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : null}
                                            <a 
                                                href='/' 
                                                onClick={ (event) => {
                                                    event.preventDefault();
                                                    arrayHelpers.push('');
                                                }} 
                                            >
                                                <span>
                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                    Add new line
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </> 
    );
};
 
export default CSVForm;