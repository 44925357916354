import React from 'react';
import { Field } from 'formik';

import {
    Row,
    Col
} from 'reactstrap';
const DefaultSize = (props) => {
    const { width, height, background } = props;
    return (  
        <div style={{ width: width, }}>
            <Row className='m-0 d-flex align-items-center'>
                <small className='mr-1 mb-1'>Measurement Unit* </small>
            </Row>
            <Row className='m-0 px-3 d-flex align-items-center justify-content-between border rounded' style={{ background: background, height, }}>
                <label className='d-flex align-items-center mb-0 mr-2'>
                    <Field style={{marginRight: '3px'}} type='radio' name='defaultSize' value='mm' default/>
                    <small className='mr-1 mb-0 w-10'>mm</small>
                </label>
                <label className='d-flex align-items-center mb-0 mr-2'>
                    <Field style={{marginRight: '3px'}} type='radio' name='defaultSize' value='cm' />
                    <small className='mr-1 mb-0 w-10'>cm</small>
                </label>
                <label className='d-flex align-items-center mb-0 mr-2'>
                    <Field style={{marginRight: '3px'}} type='radio' name='defaultSize' value='inch' />
                    <small className='mr-1 mb-0 w-10'>inch</small>
                </label>
                <label className='d-flex align-items-center mb-0 mr-2'>
                    <Field style={{marginRight: '3px'}} type='radio' name='defaultSize' value='feet' />
                    <small className='mr-1 mb-0 w-10'>feet</small>
                </label>
            </Row>
        </div>
    );
};
 
export default DefaultSize;
