import React from 'react';
import { Field } from 'formik';

const Dropdown = ({
    name,
    values,
    filtered,
    width,
    toUppercase = true,
    callback,
    disabled,
    setFieldValue,
    className,
}) => {
    if(values) {
        const filteredValues = values.filter((x) => (x));
        
        return (  
            <Field
                className={`form-control h-auto p-1 mr-1 ${(toUppercase) ?? 'text-uppercase'} ${className}`}
                as='select'
                name={`${name}`}
                style={{ minHeight: 35, width: width }}
                disabled={disabled}
                onChange={(callback)
                    ? (e) => [callback(e.target.value), setFieldValue(name, e.target.value)]
                    : (e) => setFieldValue(name, e.target.value)}
            >
                <option className={`${(toUppercase) ?? 'text-uppercase'}`} value={undefined}>Please Select</option>
                {
                    filteredValues.map((x, i) => {
                        let disabledItem = filtered?.find(item => x.value?.includes(item._id));
                        return (
                            <option disabled={disabledItem} className={`${(toUppercase) ?? 'text-uppercase'}`} key={x.value} value={x.value}>{`${x.display}`}</option>
                        );
                    })
                }
            </Field>
        );
    } else {
        return (<></>);
    }
};
 
export default Dropdown;
