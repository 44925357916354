import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { withRouter } from 'react-router';
import { useComponents } from '../../../zustand/componentStore';

import {
    Row,
    Col,
    Modal,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import client from '../../../feathers';

const fields = ['Remarks'];

const CustomFieldSelector = (props) => {
    const {
        userId,
        values,
        applicableSource,
        setFieldValue,
        notificationOpen,
    } = props;

    const {
        customFieldModalIsOpen: isOpen,
        actions,
    } = useComponents();

    const {
        setCustomFieldModalIsOpen: toggle,
    } = actions;

    const onAdd = (value) => {
        const cloned = cloneDeep(values.customFields);
        cloned.push(value);

        setFieldValue('customFields', uniqBy(cloned));
        toggle(false);
    };

    return (
        <Modal
            className='modal-dialog-centered'
            isOpen={isOpen}
            toggle={() => toggle(!isOpen)}
        >
            <div className='pt-3 pb-0 modal-header d-flex justify-content-between align-items-center'>
                <div></div>
                <button
                    aria-label='Close'
                    className='modal-close'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => toggle(!isOpen)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className='modal-body py-2'>
                <div className='w-100 bg-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    {fields.map((x) => {
                        return (
                            <Row
                                key={x}
                                className='d-flex py-2 mx-1 component-selector-item rounded align-items-center'
                                onClick={() => onAdd(x)}
                            >
                                <Col md={2} className='component-code m-0 text-uppercase'>
                                    Field
                                </Col>
                                <Col md={10}>
                                    <h4 className='m-0'>
                                        {x}
                                    </h4>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default withRouter(CustomFieldSelector);
