import React from 'react';
import { ReactComponent as ChevronRightIcon } from '../../assets/img/icons/common/chevronRight.svg';

import {
    Row
} from 'reactstrap';

const Breadcrumb = ({
    items,
}) => {

    return (
        <Row className='m-1 d-flex align-items-center gap-5 mb-3'>
            {items.map((x, i) => {
                if (i === items.length - 1) {
                    return (
                        <>
                            <a className='mb-1 a-disabled'>{x.title}</a>
                        </>
                    );
                } else {
                    return (
                        <>
                            <a className='mb-1' href={x.link}>{x.title}</a>
                            <ChevronRightIcon />
                        </>
                    );
                    
                }
            })}
        </Row>
    );
    
};
 
export default Breadcrumb;
