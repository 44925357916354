import React, { useEffect, useState } from 'react';

// nodejs library that concatenates classes
import client from '../../feathers.js';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from 'reactstrap';

const CreateComponentsTypeModal = (props) => {
    const {
        existingComponentsType,
        isOpen,
        toggle,
        reFetchData,
    } = props;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    
    useEffect(() => {
        if (existingComponentsType) {
            setName(existingComponentsType.name);
            setDescription(existingComponentsType.description);
        }  
    }, [existingComponentsType]);


    const onSubmit = async() => {
        if(existingComponentsType) {
            await client.authenticate()
                .then(()=>{
                    return client.service('componentsType').patch(existingComponentsType._id, {
                        name,
                        description,
                    });
                })
                .then((res) => {
                    reFetchData();
                    toggle();
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        } else {
            await client.authenticate()
                .then(()=>{
                    return client.service('componentsType').create({
                        name,
                        description,
                        isCompulsory: false,
                        userId: props.userId,
                        toShowInCalculator: true,
                    });
                })
                .then((res) => {
                    reFetchData();
                    toggle();
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    };
    return (  
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{(existingComponentsType) ? 'Edit Component' : 'Create New Component'}</ModalHeader>
            <ModalBody>
                <Input
                    className='form-control-alternative mb-3'
                    placeholder='Name'
                    id='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete='off'
                    type='text'
                />
                <Input
                    className='form-control-alternative'
                    placeholder='Description'
                    id='description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete='off'
                    type='textarea'
                />
            </ModalBody>
            <ModalFooter>
                <Button color='secondary' onClick={toggle}>
                    Cancel
                </Button>
                <Button color='primary' onClick={onSubmit}>
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
};
 
export default CreateComponentsTypeModal;
